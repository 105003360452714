import Shell from '../../../layouts/Shell.vue'
import PricingMain from '../views/PricingMain.vue'
 
const routes = [
    {
        path: '/',
        name: 'LayoutModule',
        component: Shell,
        children: [
        {
            path: '/pricing',
            name: 'PricingMain',
            component: PricingMain,
            meta: {
                requiresAuth: true
            }
        }]
    }
]

export default routes
