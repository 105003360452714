<template>
  <main class="login-shell">
    <router-view></router-view>
  </main>
</template>

<script>
export default {
};
</script>

<style lang="scss">
  @import "../assets/scss/modules/auth.scss";
</style>