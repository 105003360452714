import Shell from "../../../layouts/Shell.vue";
import SummaryMain10 from "../views/SummaryMain10.vue";

const routes = [
  {
    path: "/",
    name: "LayoutModule",
    component: Shell,
    children: [
      {
        path: "/summary10",
        name: "SummaryMain10",
        component: SummaryMain10,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
