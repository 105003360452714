import Shell from '../../../layouts/Shell.vue'
import operationalimprovementplan from '../views/operationalimprovementplan.vue'
 
const routes = [
    {
        path: '/',
        name: 'LayoutModule',
        component: Shell,
        children: [
        {
            path: '/operationalimprovementplan',
            name: 'operationalimprovementplan',
            component: operationalimprovementplan,
            meta: {
                requiresAuth: true
            }
        }]
    }
]

export default routes
