import Shell from "../../../layouts/Shell.vue";
import workforce from "../views/Workforce.vue";

const routes = [
  {
    path: "/",
    name: "LayoutModule",
    component: Shell,
    children: [
      {
        path: "/workforce",
        name: "workforce",
        component: workforce,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
