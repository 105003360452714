<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">Financial Projections</h1>
      </div>

      <div class="validation-cards--item cert no-hover">
        <span class="card-header">Certification Status</span>
        <div class="card-icon" margin-top="0px">
          <img
            src="@/assets/img/circular-label-with-certified-stamp.png"
            alt=""
          />
          <span class="card-total">100% Validation</span>
        </div>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Bahrain</span>
        </div>
      </div>      
    </div>

  <nav class="route-nav">
    <ul>
      <li @click="editStageSummary('three')" :class="stageSummary == 'three' ? 'active' : ''"><span>3 Year Summary</span></li>
      <li @click="editStageSummary('consolidated position')" :class="stageSummary == 'consolidated position' ? 'active' : ''"><span>Consolidated Position</span></li>
      <li @click="editStageSummary('bdf run-rate')" :class="stageSummary == 'bdf run-rate' ? 'active' : ''"> <span>BDF Run-Rate</span></li>
      <li @click="editStageSummary('khuh run-rate')" :class="stageSummary == 'khuh run-rate' ? 'active' : ''"><span>KHUH Run-Rate</span></li>
      <!-- li @click="editStageSummary('three')" :class="stageSummary == 'three' ? 'active' : ''"><span>Three</span></li -->
      <!-- li @click="editStageSummary('test3')" :class="stageSummary == 'test3' ? 'active' : ''"><span>test3</span></li -->
    </ul>
  </nav>


  <div v-if="stageSummary == 'three'">
          <div class="route-content">
        <div class="search-table-outter wrapper">
        <table class="search-table inner">
          <thead>
            <th scope="col"  v-for="(v) in getHeaderData(this.fieldName)" :key="v">{{v}}</th>                                  
          </thead>
          <tbody>
              <tr v-for="(row, idx1) in dataTable" :key="row">
                <td v-for="(col, idx2) in row" :key="col">            
                  <div v-if="dataTable[idx1][idx2] == ''"><div v-html="message"/></div>
                  <div v-else-if="dataTable[idx1][idx2] == '-'"><div v-html="message"/></div>
                  <div v-else> {{  dataTable[idx1][idx2] }}</div>     
                </td>                         
              </tr>                              
            </tbody>
          </table>
          </div>
          </div>
</div>

<div v-if="stageSummary == 'bdf run-rate' || stageSummary == 'consolidated position' || stageSummary == 'khuh run-rate' ">
  <div class="route-content-speciality">
        <div class="route-speciality items-center secondary">                
          <div class="route-speciality--box">
          <div class="box">
          <span class="box-header">Financial Year</span>
          <span class="box-label">
            <v-select
              v-model="metricYear"
              label="year"
              :clearable="false"
              @input="setMetricYear"
              :options="$store.state.prototypedata.yearsFinanceProjections"
            />
          </span>
        </div>
        </div>          
        </div>

        <div class="route-content">
        <div class="search-table-outter wrapper">
        <table class="search-table inner">          
          <thead>
              <th scope="col"  v-for="(v) in getHeaderData(this.fieldName)" :key="v">{{v}}</th>              
            </thead>
          <tbody>
              <tr v-for="(row, idx1) in dataTable" :key="row">
                <td v-for="(col, idx2) in row" :key="col">            
                  <div v-if="dataTable[idx1][idx2] == ''"><div v-html="message"/></div>
                  <div v-else-if="dataTable[idx1][idx2] == '-'"><div v-html="message"/></div>
                  <div v-else> {{  dataTable[idx1][idx2] }}</div>     
                </td>                         
              </tr>                              
            </tbody>        
          </table>
          </div>
          </div>
</div>

</div>


  </div>
</template>
<script>
//import { Bar } from "vue-chartjs/legacy";
//import { Bubble } from "vue-chartjs/legacy";

/*import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);*/

export default {
  //name: "bar",
  //components: {
//    Bar,
    //Bubble,
  //},
  /*props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },*/
  data() {
    return {
      //stageSummary: "consolidated position",
      stageSummary: "three",
      apiPayload: { speciality: "", apiString: "new_bdf_financial_projections" },      
      specialityType: "Patient Episodes",
      firstUpdate: false,
      message: "<BR />",
      metricUpdate: true,
      metricYear: "2023",
      numValues: 0,
      numSets: 0,
      newObjectKeys: {},
      numItems: 0,
      header: {},
      indexYear: -1,
      objectTest: {},
      dataTable: {},
      dataRow: {},    
      fieldName: "financialprojections",  
      headerLine: true,
    };
  },
  updated() {
    if (this.firstUpdate == false) {
      this.editStageSummary (this.stageSummary);
      console.log("updated");
          
      this.firstUpdate = true;
    }
  },
  beforeMount() {
    console.log("before mounted");
    this.apiPayload.apiString = "new_bdf_financial_projections";
    this.getMongoDBData();
    //this.fillData("workforceCost");
  },
  mounted() {
    console.log("mounted");
    
    //console.log ("this.numValues: ", this.numValues);
    //console.log ("this.numSets: ", this.numSets);
    //console.log ("this.numItems: ", this.$store.state.prototypedata.specialTypeData[this.getDataSetIndex("control")].control.length);

    //this.numItems = this.$store.state.prototypedata.specialTypeData[this.getDataSetIndex("control")].control.length;        
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },
    specialityName() {
      return this.$store.state.prototypedata.specialityName;
    },    
  },
  methods: { 

    getFirstColumData (fieldname)
    {
      var index = -1;
      var colData = new Array();
      //colData.push ("");

      if (this.$store.state.prototypedata.specialTypeData.length > 0) 
      {
        index = this.getDataSetNamedandValueIndex ("field", fieldname, false);

        if (index > -1)
        {
          //console.log (" this.$store.state.prototypedata.specialTypeData[" + String(index) + "]: ",  this.$store.state.prototypedata.specialTypeData[index]);

          for (let i = 0; i< this.$store.state.prototypedata.specialTypeData[index].control.length; i++)
          {
            colData.push (this.$store.state.prototypedata.specialTypeData[index].control[i]);
          }
          return colData;
        }

      }
    },
    
    getHeaderData (fieldname)
    {
      var index = -1;
      var headerData = new Array();

      if (this.headerLine)
      {
            headerData.push ("");
      }

      if (this.$store.state.prototypedata.specialTypeData.length > 0) 
      {
        index = this.getDataSetNamedandValueIndex ("field", fieldname, false);

        if (index > -1)
        {
          //console.log (" this.$store.state.prototypedata.specialTypeData[" + String(index) + "]: ",  this.$store.state.prototypedata.specialTypeData[index]);

          for (let i = 0; i< this.$store.state.prototypedata.specialTypeData[index].header.length; i++)
          {
            headerData.push (this.$store.state.prototypedata.specialTypeData[index].header[i]);
          }
          return headerData;
        }

      }
    },
    createDataTable (year, newdocumentset)
    {

      var documentset = newdocumentset + "_year" + year;
      this.indexYear = -1;

      var columns = this.getHeaderData (newdocumentset);

      //console.log ("columns", columns);
      var columnCount = columns.length;

      //console.log ("columnCount: ", columnCount);

      //console.log ("newdocumentset: ", newdocumentset);

      var controlColumnItems =  this.getFirstColumData (newdocumentset);

      //console.log ("controlItems: ", controlColumnItems);

      //console.log ("generateTableData: ", year);
        //console.log ("documentSet: ", documentset);

        this.indexYear = this.getDataSetNamedandValueIndex ("name", documentset,  false);

        console.log ("createNewDataTable: (" + String (controlColumnItems.length) + ", " + String (columnCount - 1) + ");");

        this.createNewDataTable (controlColumnItems.length, columnCount -1);

        //console.log ("this.dataTable: ", this.dataTable[0].length);
        //console.log ("this.dataTable: ", this.dataTable[0][0].length);
        //console.log ("this.dataTable: ", this.dataTable);

        //console.log ("controlColumnItems: ", controlColumnItems.length);

        for (let i = 0; i<controlColumnItems.length; i++)
        {
            var documentName = newdocumentset + "_" + String (i+1);
            //console.log ("documentName: ", documentName);

            for (let j = 0; j<columnCount; j++)
            {
              if (j == 0)
              {
                  this.dataTable[i][j] = controlColumnItems[i];
              }
              else
              {
                //console.log ("[" + String(i) + "][" + String(j) + "] = specialTypeData[" + String(this.indexYear) + "]["+ documentName +"][" + String(j) + "] = ", this.$store.state.prototypedata.specialTypeData[this.indexYear][documentName][j]);
                  this.dataTable[i][j] = this.$store.state.prototypedata.specialTypeData[this.indexYear][documentName][j-1];
              }
            }
        }

    },

    setMetricYear()
    {
        this.metricUpdate = true;
        var year = "1";
      
      
        if (!this.isUndefined (this.metricYear.yr))
        {
          year = this.metricYear.yr;
        }

        this.createDataTable (year, this.fieldName);
       
    },
   
    
    getDataSetIndex(item) {
      var i = 0;
      var id = -1;

      if (this.$store.state.prototypedata.specialTypeData.length > 0) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.specialTypeData.length;
          i++
        ) {
          if (item in this.$store.state.prototypedata.specialTypeData[i]) {
            id = i;
          }
        }
      }

      return id;
    },

    getDataSetNamedandValueIndex(item, value, debug) {
      var i = 0;
      var id = -1;
  
      if (debug)
      {
        console.log ("getDataSetNamedandValueIndex");
        console.log ("this.$store.state.prototypedata.specialTypeData.length ", this.$store.state.prototypedata.specialTypeData.length );
      }

      if (this.$store.state.prototypedata.specialTypeData.length > 0) 
      {
        for (i = 0; i < this.$store.state.prototypedata.specialTypeData.length; i++) 
        {
          if (item in this.$store.state.prototypedata.specialTypeData[i]) 
          {
            for (let trait in this.$store.state.prototypedata.specialTypeData[i])
            {
              if (trait == item)
              {
                if (this.$store.state.prototypedata.specialTypeData[i][trait] == value)
                {
                    id = i;
                    
                    if (debug)
                    {
                      console.log ("trait: ", trait);                
                      console.log ("this.$store.state.prototypedata.specialTypeData[i][trait] = ", this.$store.state.prototypedata.specialTypeData[i][trait]);
                      console.log ("id = ", id);
                    }
                    
                    break;
                }
              }

            }
            //console.log ("value: ", value);
            //console.log (item);
            //id = i;
          }
        }
      }
      //console.log("getDataSetIndex", item, id);

      return id;
    },
   
    getMongoDBData() {
        this.$store.dispatch("GetSpecialTypeData", this.apiPayload).then((data) => {
        console.log("data", data);
      });
    },    
    createNewDataTable (i, j)
    {
      delete this.dataTable;
      this.dataTable = {};

      for (let i_i = 0; i_i<=i; i_i++)
      {
          this.dataTable[i_i] = []
          for (let j_j= 0; j_j<=j; j_j++)
          {
            this.dataTable [i_i][j_j] = "";
          }
       }    
    },
   
    isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },  
    isUndefined(obj)  
    {
      let undefinedVal = true;

      if (typeof obj != "undefined")
      {
        undefinedVal = false;
      }

      return undefinedVal;
    },
    isNumeric(str) 
    {
        if (typeof str != "string") 
        {
            return false // we only process strings!  
        }
        
        // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        return !isNaN(str) && !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
   
    editStageSummary(stageSummary) {
      this.stageSummary = stageSummary;

      if (this.stageSummary == "consolidated position") {      
        this.fieldName = "financialprojections";
        this.headerLine = true;
        this.setMetricYear();
      }

      if (this.stageSummary == "bdf run-rate")
      {
        this.headerLine = true;
        this.fieldName = "bdfrunrate";
        this.setMetricYear();
      }

      if (this.stageSummary == "khuh run-rate")
      {
        this.fieldName = "khuhrunrate";
        this.headerLine = true;
        this.setMetricYear();
      }

      if (this.stageSummary == "three")
      {
        this.fieldName = "threeyearsummary";
        this.headerLine = false;
        this.setMetricYear();
      }

    },
  },
};
</script>
<style scoped>
.chartcontainersummaryone {
  border: 1px solid;
  border-color: #e5e9f2;
  height: 400px;
  display: inline-block;
  width: 350px; /* was 26 rem */
  margin-right: 20px;
  padding-bottom: 5px;
}
.title_text1 {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.hello1 {
color: #000000;
font-family: arial, sans-serif;
font-size: 16px;
font-weight: bold;
margin-top: 20px;
margin-bottom: 30px;
}

.search-table-outter {border:0px; /*solid red; */}
.search-table{table-layout: fixed; margin:40px auto 0px auto; }
.search-table, td, th{border-collapse:collapse; border:1px solid #1d0363;}
th{padding: 1rem; font-size:16px; background: rgba(#E5E9F2, .5); text-align: left; font-weight: 500; line-height: 1;}
td{ color: #858d9b; padding: 0.75rem 1rem;  border-bottom: 1px solid #e5e9f2;  line-height: 1;font-size: 12px; }
thead{padding: 1rem; font-size:22px; background-color: rgba(229, 233, 242, .5); text-align: left; font-weight: 500; line-height: 1;}

.search-table-outter { overflow-x: scroll; }
th, td { min-width: 200px; }
</style>
