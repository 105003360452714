import Shell from '../../../layouts/Shell.vue'
import SIA from '../views/SIA.vue'
 
const routes = [
    {
        path: '/',
        name: 'LayoutModule',
        component: Shell,
        children: [
        {
            path: '/sia',
            name: 'SIA',
            component: SIA,
            meta: {
                requiresAuth: true
            }
        }]
    }
]

export default routes
