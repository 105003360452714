import Shell from "../../../layouts/Shell.vue";
import SummaryMain4 from "../views/SummaryMain4.vue";

const routes = [
  {
    path: "/",
    name: "LayoutModule",
    component: Shell,
    children: [
      {
        path: "/summary4",
        name: "SummaryMain4",
        component: SummaryMain4,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
