<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">
          Operational Improvement Plan
        </h1>
      </div>

      <div class="validation-cards--item cert no-hover">
        <span class="card-header">Certification Status</span>
        <div class="card-icon" margin-top="0px">
          <img
            src="@/assets/img/circular-label-with-certified-stamp.png"
            alt=""
          />
          <span class="card-total">100% Validation</span>
        </div>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Bahrain</span>
        </div>
      </div>
    </div>

    <nav class="route-nav">
<ul>
<li
@click="editStageSummary('executive summary')"
:class="stageSummary == 'executive summary' ? 'active' : ''">
<span>Executive Summary</span>
</li>
<li
@click="editStageSummary('intro & strategic approach')"
:class="stageSummary == 'intro & strategic approach' ? 'active' : ''">
<span>Intro & Strategic Approach</span>
</li>
<li
@click="editStageSummary('strategic situation')"
:class="stageSummary == 'strategic situation' ? 'active' : ''">
<span>Strategic Situation</span>
</li>
<li
@click="editStageSummary('strategic factors')"
:class="stageSummary == 'strategic factors' ? 'active' : ''">
<span>Strategic Factors</span>
</li>
<li
@click="editStageSummary('aim')"
:class="stageSummary == 'aim' ? 'active' : ''">
<span>Aim</span>
</li>
<li
@click="editStageSummary('strategic execution')"
:class="stageSummary == 'strategic execution' ? 'active' : ''">
<span>Strategic Execution</span>
</li>
<li
@click="editStageSummary('strategic ex phase 1')"
:class="stageSummary == 'strategic ex phase 1' ? 'active' : ''">
<span>Strategic Ex Phase 1</span>
</li>
<li
@click="editStageSummary('strategic ex phase 2')"
:class="stageSummary == 'strategic ex phase 2' ? 'active' : ''">
<span>Strategic Ex Phase 2</span>
</li>
<li
@click="editStageSummary('strategic ex phase 3')"
:class="stageSummary == 'strategic ex phase 3' ? 'active' : ''">
<span>Strategic Ex Phase 3</span>
</li>
</ul>
</nav>
  
    
   
 
<div v-if="stageSummary == 'executive summary'">
<div class="route-content">
<!--div class="new_stat-cards" -->
<!---- insert code here -------->

<div class="hello1">1 - Executive Summary</div>

<table class="table-primary">
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1.1</td>
          <td class="border" style="padding: 10px; ">Operational Improvement of all clinical and non-clinical services will be delivered concurrent to the major strategic implementation tasks: IT Integration, Finance, Workforce and Costing & Coding.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1.2</td>
          <td class="border" style="padding: 10px; ">Review of all operational areas throughout the Group hospitals has been carried out, including understanding the levels of potential productivity at 100% capacity and utilisation.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1.3</td>
          <td class="border" style="padding: 10px; ">The comparison of the 100% productivity potential with current actual utilisation demonstrates significant underutilised capacity throughout the Group.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1.4</td>
          <td class="border" style="padding: 10px; ">However the levels of workforce deployed (and resultant expenditure) equate in some cases to be more than 100% of utilisation (see Workforce Plan).</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1.5</td>
          <td class="border" style="padding: 10px; ">An overall misbalancing of optimizing productivity, Assets, Workforce and Budget is evident compared to Actual.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1.6</td>
          <td class="border" style="padding: 10px; "><b>Interdependency</b><br>
            The delivery of operational improvement is inherently linked to outcomes of Workforce Planning and Financial efficiency.  By addressing operational improvement (efficacy of clinical pathways, operational management, planning and execution of activity efficiently), very significant impact on workforce planning, employment and financial waste removal will be generated.</td>
</tr>
</tbody>
</table>




<!-- /div -->
</div>
</div>





<div v-if="stageSummary == 'intro & strategic approach'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->

<div class="hello1">2 - Introduction</div>

<table class="table-primary">
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2.1</td>
          <td class="border" style="padding: 10px; ">The delivery of operational improvement of all services requires the integration of all key system organisational pillars to deliver complete transformation outputs to support the development of the RMS Strategy.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2.2</td>
          <td class="border" style="padding: 10px; ">The establishment of long-term continuous operational improvement will require a dedicated focus on understanding total demand versus requisite capacity.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2.3</td>
          <td class="border" style="padding: 10px; ">The management of resources in an ongoing process – effectively daily – is essential to match resources allocation to operational demand.  </td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2.4</td>
          <td class="border" style="padding: 10px; ">The essential component will be the implementation of thorough and intelligent operational planning process affecting all clinical and non-clinical services.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Additional planning details and developments will be incorporated into this document for guidance where agreed.</td>
</tr>
</tbody>
</table>

<div class="hello1">3 - Approach</div>

<table class="table-primary">
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3.1</td>
          <td class="border" style="padding: 10px; ">Methodology.  Understanding the current operational performance has involved a series of methodologies.  This has enabled us to very quickly establish themes and patterns of operational performance and delivery, indicating productivity against asset utilisation and occupancy, as well as the impact on workforce.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3.2</td>
          <td class="border" style="padding: 10px; ">Daily Monitoring of Activity. We have been able to receive twice daily operational utilisation reports for the past 8 weeks, from each hospital.  The overview results from this information is that overall levels of occupancy are relatively low.  </td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3.3</td>
          <td class="border" style="padding: 10px; ">Daily Data Demand twice per day.  The gradual increase of information flow at several points during each day enables complete oversight over actual activity, utilisation, occupancy and balance against deployed resources.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3.4</td>
          <td class="border" style="padding: 10px; ">Asset Benchmarking.  Supporting data flow on current performance, we have conducted detailed Asset benchmarking on each hospital.  This has involved looking at 100% utilisation of all assets, calculating total workforce at 100% occupancy, and indicating the full productivity potential at full utilisation.  </td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3.5</td>
          <td class="border" style="padding: 10px; ">The benchmark is then conducted against actual productivity, asset and workforce deployment which presents an accurate indicator of total Demand Capacity</td>
</tr>
</tbody>
</table>






</div>
</div>
</div>





<div v-if="stageSummary == 'strategic situation'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->

<div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">Summary Analysis</span>
            <span class="card-total3">The summary analysis identified from modelling and daily data analysis points to the following outputs:
Utilization is low across the Group (overall less than 50%, except for BOC).
Employed workforce is deployed at more than 100% occupancy levels.
Assets are underutilised.  The lack of planning or efficiency operational delivery generates wasted asset cost.
Productivity is low versus capacity: in this context the presence of waiting lists is ridiculous.
Financial Costbase is too high versus the levels of productivity.  Workforce % of Income is over 80%: much higher than hospital/industry norms.
In Sum:  Throughout the Group, inefficient use of valuable resources such as Operating Theatres, Outpatients Clinics, beds etc. is evident.  Future improvement of operations will require a complete ‘operational grip’ to be in place.
</span>            
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">Beds</span>
            <span class="card-total3">Clear imbalance between operational productivity and available capacity
Bed capacity has significant underutilisation in BDF Military Hospital and MKCC.
Normal utilisation is apparent in KHUH and BOC.  However certain areas of KHUH are underutilised constantly.
Nurse Staffing is as per 100% utilisation in all areas.
KHUH ICU beds high occupancy – however this is caused by misuse of ICU beds by Palliative Care from BOC.
BDF ICU – always around 90/100% occupancy.  Plan is in place to increase capacity to 21 from 11.
KHUH Medical Beds – under pressure at regular 90%.  
</span> 
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">Radiology</span>
            <span class="card-total3">Capacity is medium level across the group,
Certain modalities are over-utilised and waiting lists evident.
Operating Theatres and Intervention (Cath Labs, Endoscopy)
All hospitals have low utilisation of operating theatres versus capacity.
Operating efficiency is low.  Specific Surgeons plan and operate effective theatres use rather than being systematically driven.
The average volume of cases per day is 2.3 in BDFH, and 3.1 in KHUH.  MKCC is less than 1.
All capacity is generally low utilisation and not efficiently planned and managed.
Endoscopy is low productive in the 2 centres:
BDF Hospital does not require the second planned endoscopy room.  It has less than 50% productivity and has more than 4 times the normal staffing allocated.
KHUH delivers x3 the caseload from one of its 4 endoscopy units; albeit with the same volume of endoscopists available (2).
</span> 
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">Outpatient Clinics</span>
            <span class="card-total3">The following aspects of the operation of Outpatient Clinics are apparent:
Imbalance between New and Follow-Up patients, by 1:4.  
Many patients are booked for Pharmacy replication.  This is a waste of valuable resources.
Both main Hospitals have an excess of Nursing and Practical Nursing staff in the Outpatient Clinics.
Clinics start without Doctors present, due to being dual-roled in bed management or operating theatres.  
Clinics are not planned effectively.
No management of the operation is present, so patients are often left unattended.
Patient activity fluctuates wildly between Consultants and Doctors.
Patients are not effectively discharged after 2 Follow-ups.
There are far too many OPD clinics opened.
KHUH has a major issue of relocating clinics to establish a clear differentiation with BOC, however this project to relocate must be linked to a significant reduction in the volume of patients attending for unnecessary follow-ups.
</span> 
        </div>


</div>
</div>
</div>





<div v-if="stageSummary == 'strategic factors'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->

<div class="hello1">Key Factors - So What?</div>

<div class="new_stat-cards--item single no-hover" style="width: 100%">
        
            <span class="card-total3">The outputs indicate a significant amount of asset and workforce capacity currently expended however with little output.  	
We have assessed why this is the case, and have indicated a series of key factors:	
</span>            
          </div>


          <div class="hello1">Operational Weakness Factors</div>


          <table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Item </th>
          <th class="border px-4 py-2">Operational Weakness Factor</th>
          <th class="border px-4 py-2">Consideration</th>
          <th class="border px-4 py-2">Deduction</th>
          <th class="border px-4 py-2">So What?</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Lack of operational Planning</td>
          <td class="border" style="padding: 10px; ">All activity is short term.  No decision-makers involved in OT planning, OPD planning, Bed Management, Diagnostics.  This enables Medical staff to work at their own pace without supervision or appropriate governance.  This leads to Nursing staffs being deployed in OPD and OT without sufficient activity to justify</td>
          <td class="border" style="padding: 10px; ">Lack of Planning leads to:<br />·Asset wastage<br />·Workforce Excess<br />·Financial cost</td>
          <td class="border" style="padding: 10px; ">Operational Improvement will focus on comprehensive Activity planning integrated with all key factors:<br/>·Asset utilisation<br/>·Workforce plan<br/>·Financial Income/cost/pricing</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Lack of operational command and control</td>
          <td class="border" style="padding: 10px; ">Activity has not been overseen and controlled in any of the hospitals by leaders until recently.  This has lead to a situation whereby individual clinicians operate according to their own practice, and not within set guidelines.</td>
          <td class="border" style="padding: 10px; ">The establishment of a Group HQ command and control centre linked to site-level command and control centres designed for planning operations, conducting oversight and intervention and driving operational efficiency in workforce and assets.</td>
          <td class="border" style="padding: 10px; ">Site-level Command and Control Centre will be based at KHUH to cover BOC and KH.</td>
</tr>

<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">No activity-based planning</td>
          <td class="border" style="padding: 10px; ">Forward-planning based on planned activity to coordinate Nursing staffing, Medical staffing and productive use of assets must improve across all hospitals.</td>
          <td class="border" style="padding: 10px; ">Planning must be based on booked activity.<br/>Non-elective activity must be assumed based on activity trends.</td>
          <td class="border" style="padding: 10px; ">Communications and control teams at each control area must focus on ensuring those booking cases are linked to those planning workforce rotas and deployment.</td>
</tr>

<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Lack of Operational Management to coordinate, control and communicate daily</td>
          <td class="border" style="padding: 10px; ">There are few, if any, dedicated professional managers in any of the hospitals. </td>
          <td class="border" style="padding: 10px; ">Professional managers must be deployed to enable smooth running of planned activity</td>
          <td class="border" style="padding: 10px; ">Potential to re-role excess Nursing Managers and Administrators to coordinate activity on an operational basis.</td>
</tr>

<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">5</td>
          <td class="border" style="padding: 10px; ">Lack of use of information systems</td>
          <td class="border" style="padding: 10px; ">The Group boasts 2 excellent HIS platforms.  However operational activity data is not regularly utilised to the fullest extent.</td>
          <td class="border" style="padding: 10px; ">Command and Control Planning Centres must be trained and guided to extract relevant data on a daily basis from the</td>
          <td class="border" style="padding: 10px; "></td>
</tr>

<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">6</td>
          <td class="border" style="padding: 10px; ">Lack of Consultant Job Planning</td>
          <td class="border" style="padding: 10px; ">(So What? – duplication of effort, patients waiting in clinics while consultants do ward rounds).</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
</tr>

<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">7</td>
          <td class="border" style="padding: 10px; ">Obvious discrepancies between Colleagues</td>
          <td class="border" style="padding: 10px; ">Unbalanced workloads</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">8</td>
          <td class="border" style="padding: 10px; ">Resource Wastage</td>
          <td class="border" style="padding: 10px; ">Wasted Clinic resources and lack of operational coordination</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
</tr>

<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">9</td>
          <td class="border" style="padding: 10px; ">Pathways</td>
          <td class="border" style="padding: 10px; ">Inefficient</td>
          <td class="border" style="padding: 10px; ">Inefficient Pathways – day-case unit, endoscopy etc. – excess staffing, wasted resources</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
</tbody>
</table>

<div class="hello1">Negative Factors on Operations</div>

<table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Item </th>
          <th class="border px-4 py-2">Impact Area</th>
          <th class="border px-4 py-2">Consideration</th>
          <th class="border px-4 py-2">Deduction</th>
          <th class="border px-4 py-2">So What?</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Excess Workforce (confirmed by high % Workforce: Total Income and compared to productivity.</td>
          <td class="border" style="padding: 10px; ">Workforce costs are more than 80% of costbase.  This guarantees operating losses, and indicates excess and unnecessary staffing.</td>
          <td class="border" style="padding: 10px; ">Workforce must be reduced to match activity.</td>
          <td class="border" style="padding: 10px; ">Workforce reduction plan essential to support operational improvement.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Cumbersome Operational Structures</td>
          <td class="border" style="padding: 10px; ">Nursing staff structure in BDFH has 7 layers.</td>
          <td class="border" style="padding: 10px; ">Too many Nursing Managers restricts innovation and leadership.  It is also expensive. </td>
          <td class="border" style="padding: 10px; ">Convert Nursing structure to 2 strata.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Waiting Lists</td>
          <td class="border" style="padding: 10px; ">The main proof of inefficient planning is the fact there are waiting lists when there is so much unused capacity.  This confirms serious inefficiency.</td>
          <td class="border" style="padding: 10px; ">Waiting Lists indicate out of control operations.</td>
          <td class="border" style="padding: 10px; ">A complete plan to reduce waiting lists must be core to the operational improvement plan.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Poor patient experience</td>
          <td class="border" style="padding: 10px; ">Unmanaged operations lead to poor patient experience – for example Consultants booking clinics however attending ward rounds while patients wait to be seen in clinic.</td>
          <td class="border" style="padding: 10px; ">All operational activity must be planned and managed effectively.</td>
          <td class="border" style="padding: 10px; ">Operational improvement will lead to better patient experience and increased growth of turnover.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">5</td>
          <td class="border" style="padding: 10px; ">Underutilised capacity and financial waste</td>
          <td class="border" style="padding: 10px; ">Inadequate planning creates significant imbalances between utilisation of capacity within key areas such as OT.</td>
          <td class="border" style="padding: 10px; ">Underutilised capacity leads to exaggerated financial waste and losses due to bad planning.</td>
          <td class="border" style="padding: 10px; ">Optimised capacity demand data must drive all operational planning.  Control over bookings and over resource allocations must be clear.</td>
</tr>
</tbody>
</table>

<div class="hello1"><br/></div>

<div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">Interdependencies</span>
            <span class="card-total3">Intelligence – lead<br /><br />	
Workforce Impact<br /><br />
Operational Efficiency and Planning<br /><br />
Financial Efficiency<br /><br />	
</span>            
          </div>




</div>
</div>
</div>





<div v-if="stageSummary == 'aim'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->

<div class="hello1">AIM</div>

<div class="new_stat-cards--item single no-hover" style="width: 100%">        
            <span class="card-total3">The aim of the operational improvement plan is to establish Group-wide processes to ensure systematic uplift of effective planning and management to deliver improved healthcare services to patients, demonstrating improved productivity, asset utilisation, workforce deployment and resource optimization and value for money.</span>            
          </div>


</div>
</div>
</div>





<div v-if="stageSummary == 'strategic execution'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->

<div class="hello1">Execution</div>


<table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Concept</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">The concept will be to conduct a 3-phase approach to delivering ongoing operational improvement throughout the RMS Group of hospitals:</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Right-Size<br/>This will ensure the appropriate levels of productivity are applied to all Demand, and ensuring that the correct levels of resources (Capacity) are right-sized to meet that activity.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Consolidate<br/>This will see the application of improved quality, patient-focus and generation of augmented management systems (including the integration of the Group-wide application of ERP-HIS).</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Grow<br/>This phase will focus on the growth of key services benefitting the national health systems, the Bahraini population, and the use of effective marketing, communications and Public Relations to grow the demand into RMS Group to maximise the full use of the resource capacity.  Grow means growing demand to meet the existing capacity.  </td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Additional growth will also mean the ability to acquire new services, for example Awali Hospital purchasing BDF RMS support as at June 2023.</td>
</tr>
</tbody>
</table>


<div class="hello1"><br/></div>

<div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">Decisive Focus</span>
            <span class="card-total3">The main effort will be the establishment of an effective Group-level Command, Control and Communications office linked to equivalent hospital-level C3 offices in KHUH (covering BOC), and MKCC.</span>            
          </div>


</div>
</div>
</div>





<div v-if="stageSummary == 'strategic ex phase 1'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->
<div class="hello1">PHASE 1 Right Size - Interdependent: Workforce, Finance</div>

<div class="new_stat-cards--item single no-hover" style="width: 100%">    
            <span class="card-total3">This phase is a natural follow-on from the information build-up and planning of Stage 2.  The BDF Military Hospital will be the lead entity, and will set the precedent for others to coordinate concurrently.  During this phase, HSi will</span>            
          </div>

          <table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Stage</th>
          <th class="border px-4 py-2">Action</th>
          <th class="border px-4 py-2">Responsible</th>
          <th class="border px-4 py-2">Timeline</th>
          <th class="border px-4 py-2">Remarks</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Operational Planning Mechanism in place</td>
          <td class="border" style="padding: 10px; ">MG</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Commence BDF Hospital</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Command Control and Communications HQ</td>
          <td class="border" style="padding: 10px; ">MG/HSi</td>
          <td class="border" style="padding: 10px; ">In place</td>
          <td class="border" style="padding: 10px; ">Established May and initial data-flow commenced and collated twice daily.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Commence Implementation Operations</td>
          <td class="border" style="padding: 10px; ">Commander BDFH/HSi</td>
          <td class="border" style="padding: 10px; ">16-Jul</td>
          <td class="border" style="padding: 10px; ">Control Room Established.<br/>Medical and Nursing Liaison established.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Briefing Medical HODs</td>
          <td class="border" style="padding: 10px; ">Commander BDFH/HSi</td>
          <td class="border" style="padding: 10px; ">16-Jul</td>
          <td class="border" style="padding: 10px; ">Confirm plans to manage daily caseload and weekly planning process</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">5</td>
          <td class="border" style="padding: 10px; ">Briefing Nursing Senior Staff</td>
          <td class="border" style="padding: 10px; ">Commander BDFH/HSi</td>
          <td class="border" style="padding: 10px; ">16-Jul</td>
          <td class="border" style="padding: 10px; ">Confirm plans to manage daily caseload and weekly planning process</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">6</td>
          <td class="border" style="padding: 10px; ">Daily Planning and Coordination Action</td>
          <td class="border" style="padding: 10px; ">HSi/BDFH Team</td>
          <td class="border" style="padding: 10px; ">16-Jul</td>
          <td class="border" style="padding: 10px; ">Total Episode Productivity Optimized: versus Actual Productivity</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">7</td>
          <td class="border" style="padding: 10px; ">Daily Planning and Coordination Action</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">16-Jul</td>
          <td class="border" style="padding: 10px; ">Establish Daily Operational Improvement Comms, Planning and Execution</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">8</td>
          <td class="border" style="padding: 10px; ">Weekly Planning and Coordination Action</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">16-Jul</td>
          <td class="border" style="padding: 10px; ">Establish Weekly Coordination and Planning: Activity, Assets, Workforce, Financial Control</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">9</td>
          <td class="border" style="padding: 10px; ">Establish Group Operations Dashboard</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">16-Jul</td>
          <td class="border" style="padding: 10px; ">Accessible initially only to GCRMS and CBDF</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">10</td>
          <td class="border" style="padding: 10px; ">Command Control and Communications - KHUH</td>
          <td class="border" style="padding: 10px; ">DC KHUH</td>
          <td class="border" style="padding: 10px; ">23-Jul</td>
          <td class="border" style="padding: 10px; ">KHUH covering BOC</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">11</td>
          <td class="border" style="padding: 10px; ">Establish Hospital Business Implementation Plan MKCC</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">07-Aug</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">12</td>
          <td class="border" style="padding: 10px; ">Establish Hospital Business Plan – BDFH and MKCC</td>
          <td class="border" style="padding: 10px; ">Commander BDFH/HSi</td>
          <td class="border" style="padding: 10px; "> July</td>
          <td class="border" style="padding: 10px; ">Detailed Optimization per Activity Area</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">13</td>
          <td class="border" style="padding: 10px; ">Establish Hospital Business Plan KHUH</td>
          <td class="border" style="padding: 10px; ">HSi/DC KHUH</td>
          <td class="border" style="padding: 10px; ">30 Jul</td>
          <td class="border" style="padding: 10px; ">Detailed Optimization per Activity Area</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">14</td>
          <td class="border" style="padding: 10px; ">Establish Hospital Business Plan BOC</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">07-Aug</td>
          <td class="border" style="padding: 10px; ">HSi/MG will become Group Commander’s 2ic on BOC transformation.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">15</td>
          <td class="border" style="padding: 10px; ">Establish Operational Improvement Utilisation plan per hospital for OPD, OT, Diagnostics, Beds, ER</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">45535</td>
          <td class="border" style="padding: 10px; ">Detailed utilisation plan.  E.g.<br/>OPD:60% New Patients, 40% Follow-Up<br/>OT: Minimum case thresholds per list<br/>Diagnostics: Minimum case thresholds per list.</td>
</tr>
</tbody>
</table>





</div>
</div>
</div>





<div v-if="stageSummary == 'strategic ex phase 2'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->
<div class="hello1">PHASE 2 Consolidation - Interdependent: Workforce, Finance, IT Integration</div>


<table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Stage</th>
          <th class="border px-4 py-2">Action</th>
          <th class="border px-4 py-2">Responsible</th>
          <th class="border px-4 py-2">Timeline</th>
          <th class="border px-4 py-2">Remarks</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Establish Group-Level weekly Operational Oversight</td>
          <td class="border" style="padding: 10px; ">Commander BDFH/HSi</td>
          <td class="border" style="padding: 10px; ">01-Sep</td>
          <td class="border" style="padding: 10px; ">Zoom Meeting updating planned Activity and Actual Delivered Activity versus Optimized Capacity Benchmarking</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Establish Hospital Waiting List Management Plans</td>
          <td class="border" style="padding: 10px; ">HSi/Hospital Commanders/Deputies</td>
          <td class="border" style="padding: 10px; ">01-Sep</td>
          <td class="border" style="padding: 10px; ">1.  Establish backlogs and regular referral activity by specialty.<br/>2.  Confirm % New-Follow Up increases for planning.<br/>3.  Confirm OT activity targets.<br/>4.  Confirm additional capacity/cost requirements where required to eradicate waiting lists</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Confirm Operational Requirements for all Specialties and Assets in all Hospitals. </td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">14-Sep</td>
          <td class="border" style="padding: 10px; ">HSi to propose.<br/>Interdependent Workforce.<br/>Interdependent Workforce<br/>Confirm to Workforce Plan exact volumes of staff that are surplus.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Establish fully optimised operational requirements.</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Confirm options for<br/>(a)  Re-purposing<br/>(b)  Upskilling<br/>(c)  Redundant</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">5</td>
          <td class="border" style="padding: 10px; ">Confirm Consolidation to Workforce Right-size Optimization</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">30-Sep</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">6</td>
          <td class="border" style="padding: 10px; ">Confirm Quality Performance Management approach per hospital</td>
          <td class="border" style="padding: 10px; ">BDF Quality Teams</td>
          <td class="border" style="padding: 10px; ">30-Sep</td>
          <td class="border" style="padding: 10px; ">Liaise Quality Teams</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">7</td>
          <td class="border" style="padding: 10px; ">Confirm Live Group and Hospital level dashboards</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">30-Sep</td>
          <td class="border" style="padding: 10px; ">Subject to Stage 4 agreement (licensing)</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">8</td>
          <td class="border" style="padding: 10px; ">Confirm high value services to prepare for increased market exposure</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">30-Sep</td>
          <td class="border" style="padding: 10px; ">Best value services to generate increased revenues in Phase 3.</td>
</tr>
</tbody>
</table>





</div>
</div>
</div>





<div v-if="stageSummary == 'strategic ex phase 3'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->
<div class="hello1">PHASE 3 Grow Operations - Interdependent: Finance, Workforce, Costing Coding, IT Integration</div>

<table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Stage</th>
          <th class="border px-4 py-2">Action</th>
          <th class="border px-4 py-2">Responsible</th>
          <th class="border px-4 py-2">Timeline</th>
          <th class="border px-4 py-2">Remarks</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Establish Income Generation Target Operating Model</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">01-Oct</td>
          <td class="border" style="padding: 10px; ">Interdependent FInance</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Establish detailed Income Generation Private Practice Target Operating Model</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">10-Oct</td>
          <td class="border" style="padding: 10px; ">Work directly with Specialty Groups to drive up private practice volumes</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Establish full Income Growth Marketing Plan</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">01-Nov</td>
          <td class="border" style="padding: 10px; ">All services</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Establish PR and Market Reach plan to drive income generation:<br/>MKCC Healthchecks and Screening,<br/>Specific Services Growth Plans: Bariatric, Joints, Cataracts, Angios, Endoscopy, Diagnostics<br/>
            General Healthchecks: Womens’ and Male checks,<br/>Screening Growth<br/>Medical Tourism, Target Markets:<br/>a)  Dubai<br/>b)  Eastern Region Saudi Arabia - Cardiac, Cardiology, Paediatrics, Gynaecology, Urology<br/>c)  United Kingdom: holiday/diagnostic/therapeutic treatments for those on long waiting lists: Angio, Endoscopy, Radiology</td>
          <td class="border" style="padding: 10px; ">MG/HSi</td>
          <td class="border" style="padding: 10px; ">01-Nov</td>
          <td class="border" style="padding: 10px; ">Social Media, Mainline media<br/>Target Private Market and win business<br/>Self-Pay Pricing deals<br/>Available Capacity will be identified following Phase 1 Right-Sizing</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">5</td>
          <td class="border" style="padding: 10px; ">Acquire new business additions: Awali, Kidney Centre, Long Term Care and more short-range opportunity targets.</td>
          <td class="border" style="padding: 10px; ">Commander BDFH</td>
          <td class="border" style="padding: 10px; ">Ongoing</td>
          <td class="border" style="padding: 10px; ">HSi support by establishing full business planning and commercial modelling of pricing.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">6</td>
          <td class="border" style="padding: 10px; ">Establish proposals for increased market share from MoH using HSi Insight population health demand data to identify and secure opportunities</td>
          <td class="border" style="padding: 10px; ">MG/HSi</td>
          <td class="border" style="padding: 10px; ">Ongoing</td>
          <td class="border" style="padding: 10px; ">Subject to licensing</td>
</tr>
</tbody>
</table>




</div>
</div>
</div>





  

  

  
   
 </div>
</template>
<script>


export default {
  data() {
    return {
      stageSummary: "executive summary",
      selectedSpeciality: "Acute",
      apiPayload: { speciality: "", apiString: "" },
      activeSummary: "",
      selectedSpecialityArea: "Patient Episodes",
      specialityType: "acute",
      specialityApi: "ahpstwo-collection",
      firstUpdate: false,    
    };
  },
  updated() {
   
  },
  beforeMount() {
    console.log("before mounted");
  },
  mounted() {
    console.log("mounted");
    this.activeSummary = "workforceCost";
  },
  watch: {
    selectedSpeciality: {
      handler: function (val) {
        console.log("selectedSpeciality: label: ", val.label);
        console.log("selectedSpeciality: string: ", val.string);
      
        this.editSpecialityType(val.string);
        //console.log("speciality: ", this.specialityApi);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      },
      deep: true,
    },
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },
    specialityName() {
      return this.$store.state.prototypedata.specialityName;
    },
    specialityData() {
      return true;
    },  
  },
  methods: {  
    
   h1(text) {
    return `<h1>${text} </h1>`
   },
    specialityHeader(header) {
      return header;
    },
    editActiveStageSummary(activeSummary) {
      this.activeSummary = activeSummary;
    },
    editSpecialityType(specialityTypeName) {

      console.log ("specialityTypeName: ", specialityTypeName);

      if (specialityTypeName == "primary") {
        this.specialityType = "primary";
      }
      if (specialityTypeName == "acute") {
        this.specialityType = "acute";
      }
      if (specialityTypeName == "tertiary") {
        this.specialityType = "tertiary";
      }
      if (specialityTypeName == "mental") {
        this.specialityType = "mental";
      }
      if (specialityTypeName == "virt") {
        this.specialityType = "virt";
      }
      if (specialityTypeName == "long") {
        this.specialityType = "long";
      }

      console.log(
        "editSpecialityType (",
        specialityTypeName,
        ")=",
        this.specialityType
      );
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    editStageSummary(stageSummary) {
      this.stageSummary = stageSummary;

      if (this.stageSummary == "stage") {
        //this.apiPayload.apiString = "grandsummarytwo-collection";

        //console.log("stage: getData");

        //this.getMongoDBData();
        //this.fillData("workforceCost");
      }

      if (this.stageSummary == "buildingsFM") {
        //this.apiPayload.apiString = "fm_build2_collection";
        //this.getMongoDBData();
      }

      if (this.stageSummary == "specialities") {
        console.log(
          " this.selectedSpecialityArea.selected",
          this.selectedSpecialityArea
        );
      }
    },
  },
};
</script>
<style>
.chartcontainer {
  border: 1px solid;
  border-color: #e5e9f2;
  height: 400px;
  display: inline-block;
  width: 350px; /* was 26 rem */
  margin-right: 20px;
  padding-bottom: 5px;
}
.title_text {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.hello1 {
color: #000000;
font-family: arial, sans-serif;
font-size: 16px;
font-weight: bold;
margin-top: 20px;
margin-bottom: 30px;
}
</style>
