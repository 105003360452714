import Shell from "../../../layouts/Shell.vue";
import SummaryMain789 from "../views/SummaryMain789.vue";

const routes = [
  {
    path: "/",
    name: "LayoutModule",
    component: Shell,
    children: [
      {
        path: "/summary7_8_9",
        name: "SummaryMain789",
        component: SummaryMain789,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
