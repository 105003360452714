import Shell from '../../../layouts/Shell.vue'
import ToolkitMain from '../views/ToolkitMain.vue'
 
const routes = [
    {
        path: '/',
        name: 'LayoutModule',
        component: Shell,
        children: [
        {
            path: '/toolkit',
            name: 'ToolkitMain',
            component: ToolkitMain,
            meta: {
                requiresAuth: true
            }
        }]
    }
]

export default routes