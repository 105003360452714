<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">
          Strategic Workforce Planning
        </h1>
      </div>

      <div class="validation-cards--item cert no-hover">
        <span class="card-header">Certification Status</span>
        <div class="card-icon" margin-top="0px">
          <img
            src="@/assets/img/circular-label-with-certified-stamp.png"
            alt=""
          />
          <span class="card-total">100% Validation</span>
        </div>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Surrey - United Kingdom</span>
        </div>
      </div>
    </div>

    <nav class="route-nav">
      <ul>
        <li
          @click="editStageSummary('stage1')"
          :class="stageSummary == 'stage1' ? 'active' : ''"
        >
          <span>Stage 1</span>
        </li>
        <li
          @click="editStageSummary('stage2')"
          :class="stageSummary == 'stage2' ? 'active' : ''"
        >
          <span>Stage 2</span>
        </li>
        <li
          @click="editStageSummary('stage3')"
          :class="stageSummary == 'stage3' ? 'active' : ''"
        >
          <span>Stage 3 Implementation</span>
        </li>
        <li
          @click="editStageSummary('nls')"
          :class="stageSummary == 'nls' ? 'active' : ''"
        >
          <span>National Level Strategy</span>
        </li>
        <li
          @click="editStageSummary('benefits')"
          :class="stageSummary == 'benefits' ? 'active' : ''"
        >
          <span>Summary Benefits</span>
        </li>
        </ul>
    </nav>

    <div v-if="stageSummary == 'stage1'">
      <div class="route-content">
        <table class="table-primary border-fix">
        <thead>
          <tr>
            <th class="border px-4 py-2" style="width: 50%"></th>
            <th class="border px-4 py-2" style="width: 50%"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-assets-infrastructure.svg" alt="" /> Establish Workforce based on PHD</div>
            </td>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-assets-infrastructure.svg" alt="" /> By Speciality, Skillset</div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-workforce-black.svg" alt="" /> Workforce Cost</div>
            </td>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-assets-infrastructure.svg" alt="" /> Establish Demand Capacity linked to Episodes/Assets</div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="" /> Establish Equalization Plan to reduce unnecessary hiring and rationalizing across health systems to support model of care/transformation</div>
            </td>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="" /> Establish Consultant Job Plans</div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="" /> Establish Nursing Demand and Activity Rotas</div>
            </td>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="" /> Establish training plans based on skill gaps identified</div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="" /> Establish Medical and Nursing Key Skills Recruitment Plans</div>
            </td>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="" /> Establish long term Organizational Development Plans</div>
            </td>
          </tr>              
        </tbody>
      </table>
      </div>
    </div>

    <div v-if="stageSummary == 'stage2'">
      <div class="route-content">
        <table class="table-primary border-fix" style="width: 80%;">
        <thead>
          <tr>
            <th class="text-xl ml-3">HSi Insight Supports Workforce Optimization<br><br><br>
              <span class="text-sm ml-1">(Linked to Clinical Transformation & Assets, Operations & Financial Improvement)</span>
</th>
           </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-assets-infrastructure.svg" alt="" /> Optimization Plan: Target Operating Model</div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-workforce-black.svg" alt="" /> Skills Gap Confirmation</div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="" /> Recruitment Volume Requirement and Implementation (Technical, Nursing, Medical)</div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="" /> Equalization Plan and Implementation – repurposing resources to meet demand</div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="" /> Operational Improvement: Direct Workforce Planning to improve Operating Room, Emergency Room, Outpatient Clinic, Bed Management, Primary Care.
</div>
            </td>
          </tr>    
        </tbody>
      </table>
      </div>
    </div>

    <div v-if="stageSummary == 'benefits'">
      <div class="route-content">
        <table class="table-primary border-fix" style="width: 60%;">
        <thead>
          <tr>
            <th class="text-xl ml-3">Workforce Planning Summary Benefits</th>
            <th class="text-xl ml-3"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border" style="padding: 10px;"><jam-check /></td>
            <td class="border" style="padding: 10px;">Clinical Specialty based</td>
          </tr>  
          <tr>
            <td class="border" style="padding: 10px;"><jam-check /></td>
            <td class="border" style="padding: 10px;">Medical Job Planning linked to Demand Activity</td>
          </tr> 
          <tr>
            <td class="border" style="padding: 10px;"><jam-check /></td>
            <td class="border" style="padding: 10px;">Nursing Activity and Rota Planning</td>
          </tr> 
          <tr>
            <td class="border" style="padding: 10px;"><jam-check /></td>
            <td class="border" style="padding: 10px;">Workforce Cost Efficiency</td>
          </tr> 
          <tr>
            <td class="border" style="padding: 10px;"><jam-check /></td>
            <td class="border" style="padding: 10px;">Workforce Planning based on Activity</td>
          </tr> 
          <tr>
            <td class="border" style="padding: 10px;"><jam-check /></td>
            <td class="border" style="padding: 10px;">Workforce Linked to Clinical Pathway Episodes based on Population Health Data Engine</td>
          </tr> 
          <tr>
            <td class="border" style="padding: 10px;"><jam-check /></td>
            <td class="border" style="padding: 10px;">Establishes Workforce Target Operating Models per Specialty, per Cluster</td>
          </tr> 
        </tbody>
      </table>
      </div>
    </div>

    <div v-if="stageSummary == 'nls'">
      <div class="route-content">
        <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Strategy</span>          
              <span class="card-total3">Total Population Demand</span>
        </div> 

        <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Strategy</span>          
              <span class="card-total3">Support Saudiization - Reduction of foreign Medical and Nursing staffing</span>
        </div>

        <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Strategy</span>          
              <span class="card-total3">National Specialty Demand Driven Recruitment Strategy and Plan</span>
        </div>
        
        <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Strategy</span>          
              <span class="card-total3">Medical Job-Planning linked to demand activity</span>
        </div>

        <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Strategy</span>          
              <span class="card-total3">Long-term Medical Recruitment and Training planning</span>
        </div>

        <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Strategy</span>          
              <span class="card-total3">Technician Planning and Recruitment targeted on demand</span>
        </div>

        <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Strategy</span>          
              <span class="card-total3">National Equalization Capability</span>
        </div>

        <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Strategy</span>          
              <span class="card-total3">National Organization Development Framework</span>
        </div>
         
           
          
      </div>
    </div>


    <div v-if="stageSummary == 'stage3'">
      <div class="route-content">
        <div class="route-content"></div>
          <div class="container">
              <ols class="level-3-wrapper">
                  <li>
                    <h3 class="level-3 rectangle">Medical Job Planning </h3>
                    <ol class="level-4-wrapper"></ol>
                    <h3 class="level-3 rectangle">Consultant Recruitment</h3>
                    <ol class="level-4-wrapper"></ol>
                    <h3 class="level-3 rectangle">Nursing Training and Specialist Skills Demand</h3>
                  </li>
                  <li>
                    <h3 class="level-3 rectangle">Primary Care, Growth to Support, Model of Care</h3>
                    <ol class="level-90-wrapper"></ol>
                    <h3 class="level-3 rectangle">Workforce Efficiency KPIs</h3>
                    <ol class="level-90-wrapper"></ol>
                    <h3 class="level-4 rectangle">Workforce Performance Management</h3>
                  </li>
               </ols>
           </div>
         </div>
      </div>

    <div v-if="stageSummary == 'sysstrat'">
      <div class="route-content">
        <button class="btn btn-primary btn-outline mr-4 mb-4" :class="button1 ? 'active' : ''" @click="showButton1">Excess Administration FTEs</button>
        <button class="btn btn-primary btn-outline mr-4 mb-4" :class="button2 ? 'active' : ''" @click="showButton2">Excess Budget versus Demand</button>
        <button class="btn btn-primary btn-outline mr-4 mb-4" :class="button3 ? 'active' : ''" @click="showButton3">Low level Productivity: Asset and Productivity: Workforce</button>
        <button class="btn btn-primary btn-outline mr-4 mb-4" :class="button4 ? 'active' : ''" @click="showButton4">Duplication of System Management</button>
        <button class="btn btn-primary btn-outline mr-4 mb-4" :class="button5 ? 'active' : ''" @click="showButton5">Insufficient Diagnostic Modalities</button>

        <div v-if="button1">
          <div class="new_new_stat-cards">
            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Excess Administration FTEs</span>           
              <span class="card-total3">Establish Long-term plan to reduce Administrator workforce rationalising versus digital care capacity</span>
            </div>

            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Excess Administration FTEs</span>           
              <span class="card-total3">Balance Workforce directly with Activity Demand</span>
            </div>
          </div>

          <table class="table-primary border-fix">
        <thead>
          <tr>
            <th class="border px-4 py-2">Summary</th>
            <th class="border px-4 py-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-assets-infrastructure.svg" alt="Assets and Infrastructure" /> Assets and Infrastructure</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Reduced Offices and associated costs</li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-workforce-black.svg" alt="Workforce" /> Workforce</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Sucession Planning</li>
                  <li>Recruitment Strategy</li>
                  <li>Administration Reduction by natural wastage/Nil Recruitment</li>
                  <li>Increased GPs</li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> Financial OPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Cost Reduction Impact: £145M by 2027</li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> CAPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-supplychain.svg" alt="Supply Chain" /> Supply Chain</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-fms.svg" alt="FMS" /> FMS</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>


          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Digital IT/E Health" /> Digital IT/E Health</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Media and Communications" /> Media and Communications</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>          
        </tbody>
      </table>
    </div>
        

    <div v-if="button2">
      <div class="new_new_stat-cards">
            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Excess Budget versus Demand</span>           
              <span class="card-total3">Reduced Workforce based on Activity Demand to reduce cost budget</span>
            </div>

            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Excess Budget versus Demand</span>           
              <span class="card-total3">Balance Budget to Productivity </span>
            </div>
          </div>

          <table class="table-primary border-fix">
        <thead>
          <tr>
            <th class="border px-4 py-2">Summary</th>
            <th class="border px-4 py-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-assets-infrastructure.svg" alt="Assets and Infrastructure" /> Assets and Infrastructure</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>


          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-workforce-black.svg" alt="Workforce" /> Workforce</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>FTE Reduction in Administration by 3,500 FTEs by 2027</li>                
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> Financial OPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> CAPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-supplychain.svg" alt="Supply Chain" /> Supply Chain</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-fms.svg" alt="FMS" /> FMS</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>


          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Digital IT/E Health" /> Digital IT/E Health</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Media and Communications" /> Media and Communications</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>          
        </tbody>
      </table>
    </div>

        <div v-if="button3">
          <div class="new_new_stat-cards">
            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Low level Productivity:Asset and Productivity: Workforce</span>           
              <span class="card-total3">Establish minimum productivity levels per Asset.   Establish minimum productivity levels per Workforce Planning.</span>
            </div>

            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Low level Productivity:Asset and Productivity: Workforce</span>           
              <span class="card-total3">Reduce % Workforce:Total Income</span>
            </div>
          </div>

          <table class="table-primary border-fix">
        <thead>
          <tr>
            <th class="border px-4 py-2">Summary</th>
            <th class="border px-4 py-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-assets-infrastructure.svg" alt="Assets and Infrastructure" /> Assets and Infrastructure</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Minimum levels of activity per asset</li>
                </ul>
              </div>
            </td>
          </tr>


          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-workforce-black.svg" alt="Workforce" /> Workforce</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Activity-Linked Job-Planning for Medical and Nursing Staff</li>                
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> Financial OPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> CAPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-supplychain.svg" alt="Supply Chain" /> Supply Chain</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-fms.svg" alt="FMS" /> FMS</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>


          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Digital IT/E Health" /> Digital IT/E Health</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Media and Communications" /> Media and Communications</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>          
        </tbody>
      </table>
        </div>

        <div v-if="button4">
          <div class="new_new_stat-cards">
            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Duplication of System Management</span>           
              <span class="card-total3">Establish new System Executive Team structure based on Activity Management at clinical specialty levels. Remove per Facility Infrastructure Management, e.g. CEO per hospital etc.</span>
            </div>

            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Duplication of System Management</span>           
              <span class="card-total3">Establish Executive Structure based on Service Lines.  Reduced Executive management. </span>
            </div>
          </div>

          <table class="table-primary border-fix">
        <thead>
          <tr>
            <th class="border px-4 py-2">Summary</th>
            <th class="border px-4 py-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-assets-infrastructure.svg" alt="Assets and Infrastructure" /> Assets and Infrastructure</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Reduced Asset/office costs</li>
                </ul>
              </div>
            </td>
          </tr>


          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-workforce-black.svg" alt="Workforce" /> Workforce</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Restructure Executive Management Team.  Service Lines Focussed.</li>                
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> Financial OPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> CAPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-supplychain.svg" alt="Supply Chain" /> Supply Chain</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-fms.svg" alt="FMS" /> FMS</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>


          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Digital IT/E Health" /> Digital IT/E Health</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Media and Communications" /> Media and Communications</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>          
        </tbody>
      </table>
        </div>

        <div v-if="button5">
          <div class="new_new_stat-cards">
            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Insufficient Diagnostic Modalities</span>           
              <span class="card-total3">Establish immediate demand-driven plan for increased diagnostics including expanded out of hours timing.  Option 2: identify full demand requirement and price for outsourcing to local private sector providers at set pricing.  Block purchase for 3 year period with discounted rate</span>
            </div>

            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Insufficient Diagnostic Modalities</span>           
              <span class="card-total3">Increased Diagnositcs Throughput in Labs and Radiology by 15%</span>
            </div>
          </div>

          <table class="table-primary border-fix">
        <thead>
          <tr>
            <th class="border px-4 py-2">Summary</th>
            <th class="border px-4 py-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-assets-infrastructure.svg" alt="Assets and Infrastructure" /> Assets and Infrastructure</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Cost of new Infrastructure - £14M, and Assets - £9.5M</li>
                </ul>
              </div>
            </td>
          </tr>


          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-workforce-black.svg" alt="Workforce" /> Workforce</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Increased Technicians for Radiology: 19 Radiograhpers and 3 Radiologists for increased reporting demand</li>                
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> Financial OPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Increased caseload = Increased Income by £5.5m per annum</li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> CAPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Immediate Capex: £23.5M</li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-supplychain.svg" alt="Supply Chain" /> Supply Chain</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Minimal</li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-fms.svg" alt="FMS" /> FMS</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Increase FM coverage of £200k</li>
                </ul>
              </div>
            </td>
          </tr>


          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Digital IT/E Health" /> Digital IT/E Health</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Increae current licenses</li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Media and Communications" /> Media and Communications</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>          
        </tbody>
      </table>
        </div>


      </div>   
    </div>

  
  
        
 </div>
</template>
<script>


export default {
  data() {
    return {
      stageSummary: "stage1",
      selectedSpeciality: "Acute",
      apiPayload: { speciality: "", apiString: "" },
      activeSummary: "",
      selectedSpecialityArea: "Patient Episodes",
      specialityType: "acute",
      specialityApi: "ahpstwo-collection",
      firstUpdate: false,
      button1: true,
      button2: false,
      button3: false,
      button4: false,
      button5: false, 
    };
  },
  updated() {
   
  },
  beforeMount() {
    console.log("before mounted");
  },
  mounted() {
    console.log("mounted");
    this.activeSummary = "workforceCost";
  },
  watch: {
    selectedSpeciality: {
      handler: function (val) {
        console.log("selectedSpeciality: label: ", val.label);
        console.log("selectedSpeciality: string: ", val.string);
      
        this.editSpecialityType(val.string);
        //console.log("speciality: ", this.specialityApi);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      },
      deep: true,
    },
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },
    specialityName() {
      return this.$store.state.prototypedata.specialityName;
    },
    specialityData() {
      return true;
    },  
  },
  methods: {  
    specialityHeader(header) {
      return header;
    },
    editActiveStageSummary(activeSummary) {
      this.activeSummary = activeSummary;
    },
    editSpecialityType(specialityTypeName) {

      console.log ("specialityTypeName: ", specialityTypeName);

      if (specialityTypeName == "primary") {
        this.specialityType = "primary";
      }
      if (specialityTypeName == "acute") {
        this.specialityType = "acute";
      }
      if (specialityTypeName == "tertiary") {
        this.specialityType = "tertiary";
      }
      if (specialityTypeName == "mental") {
        this.specialityType = "mental";
      }
      if (specialityTypeName == "virt") {
        this.specialityType = "virt";
      }
      if (specialityTypeName == "long") {
        this.specialityType = "long";
      }

      console.log(
        "editSpecialityType (",
        specialityTypeName,
        ")=",
        this.specialityType
      );
    },
    showButton1() {
      this.button1 = true;
      this.button2 = false;
      this.button3 = false;
      this.button4 = false;
      this.button5 = false;
    },
    showButton2() {
      this.button1 = false;
      this.button2 = true;
      this.button3 = false;
      this.button4 = false;
      this.button5 = false;
    },
    showButton3() {
      this.button1 = false;
      this.button2 = false;
      this.button3 = true;
      this.button4 = false;
      this.button5 = false;
    },
    showButton4() {
      this.button1 = false;
      this.button2 = false;
      this.button3 = false;
      this.button4 = true;
      this.button5 = false;
    },
    showButton5() {
      this.button1 = false;
      this.button2 = false;
      this.button3 = false;
      this.button4 = false;
      this.button5 = true;
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    editStageSummary(stageSummary) {
      this.stageSummary = stageSummary;

      if (this.stageSummary == "stage") {
        //this.apiPayload.apiString = "grandsummarytwo-collection";

        //console.log("stage: getData");

        //this.getMongoDBData();
        //this.fillData("workforceCost");
      }

      if (this.stageSummary == "buildingsFM") {
        //this.apiPayload.apiString = "fm_build2_collection";
        //this.getMongoDBData();
      }

      if (this.stageSummary == "specialities") {
        console.log(
          " this.selectedSpecialityArea.selected",
          this.selectedSpecialityArea
        );
      }
    },
  },
};
</script>
<style>
.chartcontainer {
  border: 1px solid;
  border-color: #e5e9f2;
  height: 400px;
  display: inline-block;
  width: 350px; /* was 26 rem */
  margin-right: 20px;
  padding-bottom: 5px;
}
.title_text {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.triangle-down {
	width: 0;
	height: 0;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	border-top: 50px solid #555;
}
.triangle-right {
	width: 0;
	height: 0;
	border-top: 25px solid transparent;
	border-left: 50px solid #555;
	border-bottom: 25px solid transparent;
}
.triangle-left {
	width: 0;
	height: 0;
	border-top: 25px solid transparent;
	border-right: 50px solid #555;
	border-bottom: 25px solid transparent;
}
.square {
  height: 100px;
  width: 50px;
  background-color: #555;
}

/* RESET STYLES & HELPER CLASSES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.body_x {
  margin: 50px 0 100px;
  text-align: center;
  font-family: "Inter", sans-serif;
}

.container {
  max-width: 1000px;
  padding: 0 10px;
  margin: 0 auto;
}

.rectangle {
  position: relative;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

/* LEVEL-3 STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.level-3-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  width: 90%;
  margin: 0 auto;
}

.level-3 {
  margin-bottom: 20px;
  background: #7b9fe0;
}

ols {
  list-style: none;
}


/* LEVEL-4 STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.level-4-wrapper {
  position: relative;
  width: 80%;
  margin-left: auto;
}

.level-90-wrapper {
  position: relative;
  width: 80%;
  margin-left: auto;
}

.level-90-wrapper::before {
  content: "";
  position: absolute;
  top: -20px;
  right: 60px;
  width: 20px;
  height: calc(100% + 20px);
  background: black;
}

.level-4-wrapper::before {
  content: "";
  position: absolute;
  top: -20px;
  left: -20px;
  width: 20px;
  height: calc(100% + 20px);
  background: black;
}

.level-4-wrapper li + li {
  margin-top: 20px;
}

.level-4 {
  background: #7b9fe0;
}

.level-4::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-100%, -50%);
  width: 20px;
  height: 20px;
  background: black;
}
</style>
