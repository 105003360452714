<template>
    <div>
      <header class="route-header">
        <div class="route-header--left">
          <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
        </div>
        <div class="route-header--right">
          <h1 class="text-3xl">Workforce Plan</h1>
        </div>
  
        <div class="validation-cards--item cert no-hover">
          <span class="card-header">Certification Status</span>
          <div class="card-icon" margin-top="0px">
            <img
              src="@/assets/img/circular-label-with-certified-stamp.png"
              alt=""
            />
            <span class="card-total">100% Validation</span>
          </div>
        </div>
      </header>
  
      <div class="route-header items-center secondary">
        <div class="route-header--box">
          <div class="box">
            <span class="box-header">Location</span>
            <span class="box-label">Bahrain</span>
          </div>
        </div>
      </div>
  
      <nav class="route-nav">
<ul>
<li
@click="editStageSummary('workforce strategy')"
:class="stageSummary == 'workforce strategy' ? 'active' : ''">
<span>Workforce Strategy</span>
</li>
<li
@click="editStageSummary('group structure')"
:class="stageSummary == 'group structure' ? 'active' : ''">
<span>Group Structure</span>
</li>
<li
@click="editStageSummary('local hospital management')"
:class="stageSummary == 'local hospital management' ? 'active' : ''">
<span>Local Hospital Management</span>
</li>
<li
@click="editStageSummary('management functions')"
:class="stageSummary == 'management functions' ? 'active' : ''">
<span>Management Functions</span>
</li>
<li
@click="editStageSummary('right-sizing')"
:class="stageSummary == 'right-sizing' ? 'active' : ''">
<span>Right-Sizing & Equalization</span>
</li>
<li
@click="editStageSummary('specialist rect & succession')"
:class="stageSummary == 'specialist rect & succession' ? 'active' : ''">
<span>Specialist Rect & Succession</span>
</li>
</ul>
</nav>

  
      
<div v-if="stageSummary == 'workforce strategy'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->
<table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2" colspan="2">Strategic Objectives</th>
          <th class="border px-4 py-2">Timeline (Months)</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Meet Optimization trajectory of total workforce by Month 36</td>
          <td class="border" style="padding: 10px; ">36</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Rationalise to Group structure</td>
          <td class="border" style="padding: 10px; ">3</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Rationalise Hospital Management teams</td>
          <td class="border" style="padding: 10px; ">3</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Rationalise Group Functional structure</td>
          <td class="border" style="padding: 10px; ">3</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">5</td>
          <td class="border" style="padding: 10px; ">Rationalise Group Functional structure</td>
          <td class="border" style="padding: 10px; ">3</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">6</td>
          <td class="border" style="padding: 10px; ">Right-Size Workforce: Medical</td>
          <td class="border" style="padding: 10px; ">12</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">7</td>
          <td class="border" style="padding: 10px; ">Right-Size Workforce: Nursing</td>
          <td class="border" style="padding: 10px; ">12</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">8</td>
          <td class="border" style="padding: 10px; ">Right-Size Workforce: AHP</td>
          <td class="border" style="padding: 10px; ">12</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">9</td>
          <td class="border" style="padding: 10px; ">Right-Size Workforce: Administration and Management</td>
          <td class="border" style="padding: 10px; ">12</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">10</td>
          <td class="border" style="padding: 10px; ">Establish Specialist Recruitment Plan</td>
          <td class="border" style="padding: 10px; ">3</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">11</td>
          <td class="border" style="padding: 10px; ">Confirm Succession Planning per speciality: Medical and Nursing Staff</td>
          <td class="border" style="padding: 10px; ">6</td>
</tr>
</tbody>
</table>




</div>
</div>
</div>





<div v-if="stageSummary == 'group structure'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->
<img src="@/assets/img/group_structure.png" alt="Group Structure">



</div>
</div>
</div>





<div v-if="stageSummary == 'local hospital management'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->
<img src="@/assets/img/local_hospital_management.png" alt="Local Hospital Management">



</div>
</div>
</div>





<div v-if="stageSummary == 'management functions'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->        
          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">Finance</span>
            <span class="card-total3">Establish Group level Financial Management Office (Accounts Payable, Accounts Receivable, Finance Function)<BR/>
                Reduce Site-based Finance Teams to Business Unit only (maximum 18)<BR/>Remove non-essential financial staff
            </span>            
          </div>
         
          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">IT</span>
            <span class="card-total3">Establish Group level IT Department Office for Software Management, Hardware, Networks<BR/>
Reduce Site-based IT Teams to Business Unit only (maximum 12)<BR/>
Remove non-essential IT staff
</span>                      
          </div>
          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">Procurement</span>
            <span class="card-total3">Establish Group level Procurement Office (Capital, Stock, Pharmaceuticals, Consumables)<BR/>
Reduce Site-based ProcurementTeams to Business Unit only (maximum 12)<BR/>
Remove non-essential financial staff
</span>            
          </div>        
          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">Shared Services</span>
            <span class="card-total3">Establish Shared Services Cell<BR/>
Establish Facilities Management outsourcing model<BR/>
Confirm in-house potential value<BR/>
</span>            
          </div>
</div>
</div>
</div>





<div v-if="stageSummary == 'right-sizing'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->

<table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2"></th>
          <th class="border px-4 py-2">2024</th>
          <th class="border px-4 py-2">2025</th>
          <th class="border px-4 py-2">2026</th>
          <th class="border px-4 py-2">2027</th>
          <th class="border px-4 py-2">2028</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Total Workforce</td>
          <td class="border" style="padding: 10px; ">6,676</td>
          <td class="border" style="padding: 10px; ">6,104</td>
          <td class="border" style="padding: 10px; ">5,709</td>
          <td class="border" style="padding: 10px; ">5,303</td>
          <td class="border" style="padding: 10px; ">4,976</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Medical </td>
          <td class="border" style="padding: 10px; ">1,029</td>
          <td class="border" style="padding: 10px; ">1,008</td>
          <td class="border" style="padding: 10px; ">988</td>
          <td class="border" style="padding: 10px; ">968</td>
          <td class="border" style="padding: 10px; ">968</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Nursing</td>
          <td class="border" style="padding: 10px; ">2,900</td>
          <td class="border" style="padding: 10px; ">2,813</td>
          <td class="border" style="padding: 10px; ">2,729</td>
          <td class="border" style="padding: 10px; ">2,674</td>
          <td class="border" style="padding: 10px; ">2,674</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">AHPs</td>
          <td class="border" style="padding: 10px; ">1,595</td>
          <td class="border" style="padding: 10px; ">1,276</td>
          <td class="border" style="padding: 10px; ">1,148</td>
          <td class="border" style="padding: 10px; ">919</td>
          <td class="border" style="padding: 10px; ">643</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Technicians</td>
          <td class="border" style="padding: 10px; ">882</td>
          <td class="border" style="padding: 10px; ">750</td>
          <td class="border" style="padding: 10px; ">600</td>
          <td class="border" style="padding: 10px; ">510</td>
          <td class="border" style="padding: 10px; ">459</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Administrators</td>
          <td class="border" style="padding: 10px; ">270</td>
          <td class="border" style="padding: 10px; ">257</td>
          <td class="border" style="padding: 10px; ">244</td>
          <td class="border" style="padding: 10px; ">231</td>
          <td class="border" style="padding: 10px; ">231</td>
</tr>
</tbody>
</table>




</div>
</div>
</div>





<div v-if="stageSummary == 'specialist rect & succession'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->

<table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Surgery</th>
          <th class="border px-4 py-2">FTE Required</th>
          <th class="border px-4 py-2">Remarks</th>
          <th class="border px-4 py-2"></th>
          <th class="border px-4 py-2">Medicine</th>
          <th class="border px-4 py-2">FTE Required</th>
          <th class="border px-4 py-2">Remarks</th>
          <th class="border px-4 py-2"></th>
          <th class="border px-4 py-2">Support Specialties</th>
          <th class="border px-4 py-2">FTE Required</th>
          <th class="border px-4 py-2">Remarks</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">General Surgery</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Stroke/Neurology</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Radiology</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Medical</td>
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Colorectal Surgeon</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Medical</td>
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Interventional Neuro. Mechancial Thrombectomy</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Medical</td>
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Neuro-Radiology</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Nursing</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Nursing</td>
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Rehabilitation </td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Nursing</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Technician</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Technician</td>
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Neuro Case Managers</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Technician</td>
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">PET CT Technicians</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px;" colspan="11"></td>                  
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Cardiac Surgery</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">ER</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Laboratories</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Medical</td>
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Cardiothoracic Surgeon</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Medical</td>
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Trauma and ER Consultants</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Medical</td>
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Genetics, Histopathology</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Nursing</td>
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Nurse Specialist Cardiac</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Nursing</td>
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Triage Nursing</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Nursing</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Technician</td>
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">ECHO Techs</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Technician</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Technician</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px;" colspan="11"></td>                  
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Pulmonary Surgery</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Gastroenterology</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Anaesthesia</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Medical</td>
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Surgeon</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Medical</td>
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">ERCP Endoscopists</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Medical</td>
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">OT and Pain</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Nursing</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Nursing</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Nursing</td>
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">OT Nursing Recovery</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Technician</td>
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Respiratory Technician </td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Technician</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Technician</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px;" colspan="11"></td>                  
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Plastics Surgery</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Cardiology</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Oncology</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Medical</td>
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Burns and Flap</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Medical</td>
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Interventionists</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Medical</td>
          <td class="border" style="padding: 10px; ">7</td>
          <td class="border" style="padding: 10px; ">Haemo-Onc, Medical Oncology, Radiotherapist</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Nursing</td>
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Burns Treatment Nurse</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Nursing</td>
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Cath Lab Nurses</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Nursing</td>
          <td class="border" style="padding: 10px; ">8</td>
          <td class="border" style="padding: 10px; ">Cancer Nurse Specialists</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Technician</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Technician</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Technician</td>
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Oncology Social Workers</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px;" colspan="11"></td>                  
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Vascular Surgery</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Dermatology</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Primary Care</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Medical</td>
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Aorta Specialist</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Medical</td>
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Consultant Dermatology</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Medical</td>
          <td class="border" style="padding: 10px; ">6</td>
          <td class="border" style="padding: 10px; ">GP Cardiology</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Nursing</td>
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Specialist Vascular </td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Nursing</td>
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Cancer Nurse Specialist</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Nursing</td>
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Community Nurses</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Technician</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Technician</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Technician</td>
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">ECG Technicians</td>
</tr>
</tbody>
</table>




</div>
</div>
</div>













    
  
      
     
    
  
      
  
   
  
      
  
     
   </div>
  </template>
  <script>
  
  
  export default {
    data() {
      return {
        stageSummary: "workforce strategy",
        selectedSpeciality: "Acute",
        apiPayload: { speciality: "", apiString: "" },
        activeSummary: "",
        selectedSpecialityArea: "Patient Episodes",
        specialityType: "acute",
        specialityApi: "ahpstwo-collection",
        firstUpdate: false,    
      };
    },
    updated() {
     
    },
    beforeMount() {
      console.log("before mounted");
    },
    mounted() {
      console.log("mounted");
      this.activeSummary = "workforceCost";
    },
    watch: {
      selectedSpeciality: {
        handler: function (val) {
          console.log("selectedSpeciality: label: ", val.label);
          console.log("selectedSpeciality: string: ", val.string);
        
          this.editSpecialityType(val.string);
          //console.log("speciality: ", this.specialityApi);
          //this.apiPayload.apiString = this.specialityApi;
          //this.getMongoDBData();
        },
        deep: true,
      },
    },
    computed: {
      store() {
        return this.$store.state.prototypedata;
      },
      specialityName() {
        return this.$store.state.prototypedata.specialityName;
      },
      specialityData() {
        return true;
      },  
    },
    methods: {  
      
     h1(text) {
      return `<h1>${text} </h1>`
     },
      specialityHeader(header) {
        return header;
      },
      editActiveStageSummary(activeSummary) {
        this.activeSummary = activeSummary;
      },
      editSpecialityType(specialityTypeName) {
  
        console.log ("specialityTypeName: ", specialityTypeName);
  
        if (specialityTypeName == "primary") {
          this.specialityType = "primary";
        }
        if (specialityTypeName == "acute") {
          this.specialityType = "acute";
        }
        if (specialityTypeName == "tertiary") {
          this.specialityType = "tertiary";
        }
        if (specialityTypeName == "mental") {
          this.specialityType = "mental";
        }
        if (specialityTypeName == "virt") {
          this.specialityType = "virt";
        }
        if (specialityTypeName == "long") {
          this.specialityType = "long";
        }
  
        console.log(
          "editSpecialityType (",
          specialityTypeName,
          ")=",
          this.specialityType
        );
      },
      isEmpty(obj) {
        for (var prop in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
          }
        }
  
        return JSON.stringify(obj) === JSON.stringify({});
      },
      editStageSummary(stageSummary) {
        this.stageSummary = stageSummary;
  
        if (this.stageSummary == "stage") {
          //this.apiPayload.apiString = "grandsummarytwo-collection";
  
          //console.log("stage: getData");
  
          //this.getMongoDBData();
          //this.fillData("workforceCost");
        }
  
        if (this.stageSummary == "buildingsFM") {
          //this.apiPayload.apiString = "fm_build2_collection";
          //this.getMongoDBData();
        }
  
        if (this.stageSummary == "specialities") {
          console.log(
            " this.selectedSpecialityArea.selected",
            this.selectedSpecialityArea
          );
        }
      },
    },
  };
  </script>
  <style>
  .chartcontainer {
    border: 1px solid;
    border-color: #e5e9f2;
    height: 400px;
    display: inline-block;
    width: 350px; /* was 26 rem */
    margin-right: 20px;
    padding-bottom: 5px;
  }
  .title_text {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .hello1 {
  color: #000000;
  font-family: arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 30px;
  }
  </style>
  
