import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "chart.js"

import VueTailwind from 'vue-tailwind'
import VueAxios from 'vue-axios'
import vSelect from 'vue-select'
import VueJamIcons from 'vue-jam-icons'
import vueNumeralFilterInstaller from 'vue-numeral-filter'
import axios from './plugins/axios'

import 'vue-select/dist/vue-select.css'
import '@/assets/css/tailwind.css';

Vue.use(VueTailwind)
Vue.use(VueJamIcons)
Vue.use(vueNumeralFilterInstaller)
Vue.use(VueAxios, axios)

Vue.component('v-select', vSelect)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

