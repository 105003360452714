<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/logo.svg" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">Actual Locations</h1>
      </div>
    </header>

    <div class="route-content">
      <!-- h1 class="text-3xl my-6 active">Afghanistan</h1> -->
      <table class="table-primary">
        <thead>
          <tr>
            <th class="border px-4 py-2">Location</th>
            <th class="border px-4 py-2">Location Population</th>
            <th class="border px-4 py-2">Additional Information</th>
            <th class="border px-4 py-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border">
              <div>Kabul (C1)</div>
            </td>
            <td class="border">
              <div>4,273,156</div>
            </td>
            <td class="border">
              <div> is the capital and largest city of Afghanistan, located in the 
                    eastern section of the country. It is also a municipality, 
                    forming part of the greater Kabul Province, and it is divided
                    into 22 districts. According to estimates in 2021, the 
                    population of Kabul was 4.6 million
              </div>
            </td>
            <td class="border">
              <div>Actions</div>
            </td>
          </tr>
          <tr>
            <td class="border">
              <div>Kandahar (C2)</div>
            </td>
            <td class="border">
              <div>614,254</div>
            </td>
            <td class="border">
              <div>
                located in the south of the country on the Arghandab River, at an 
                elevation of 1,010 m (3,310 ft). It is Afghanistan's second largest 
                city after Kabul, with a population of about 614,118
              </div>
            </td>
            <td class="border">
              <div>Actions</div>
            </td>
          </tr>
          <tr>
            <td class="border">
              <div>Herat (C3)</div>
            </td>
            <td class="border">
              <div>556,205</div>
            </td>
            <td class="border">
              <div>
                is an oasis city and the third-largest city of Afghanistan.
                In 2020, it had an estimated population of 574,276, 
                and serves as the capital of Herat Province, situated south of the 
                Paropamisus Mountains (Selseleh-ye Safēd Kōh) in the fertile 
                valley of the Hari River in the western part of the country.
              </div>
            </td>
            <td class="border">
              <div>Actions</div>
            </td>
          </tr>
          <tr>
            <td class="border">
              <div>Mazar-i-Sharif (C4)</div>
            </td>
            <td class="border">
              <div>469,247</div>
            </td>
            <td class="border">
              <div>
                also called Mazār-e Sharīf, or just Mazār, is the fourth-largest 
                city of Afghanistan, with a population estimate of 500,207 people.
                It is the capital of Balkh province and is linked by highways with Kunduz
                in the east, Kabul in the southeast, Herat in the southwest and Termez, 
                Uzbekistan in the north. 
              </div>
            </td>
            <td class="border">
              <div>Actions</div>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <button class="btn btn-primary btn-large">Add new cluster</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeSummary: "annualTotals",
    };
  },
  mounted() {
    this.fetchAnnualTotals();
  },
  computed: {
    annualTotalsData() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData;
      }
      return null;
    },
    annualTotalsServedPopulationFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .served_population;
      }
      return null;
    },
    annualTotalsCostbaseFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0].costbase;
      }
      return null;
    },
    annualTotalsIncomeRequirementFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .income_requirement;
      }
      return null;
    },
    annualTotalsMedicalStaffFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .medical_staff_fte;
      }
      return null;
    },
    annualTotalsNursingAndMidwifeFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .nursing_and_midwife_fte;
      }
      return null;
    },
    annualTotalsTotalWorkforceFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .total_workforce_fte;
      }
      return null;
    },
    annualTotalsPrimaryCarePresentationsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .primary_care_presentations;
      }
      return null;
    },
    annualTotalsErPresentationsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .er_presentations;
      }
      return null;
    },
    annualTotalsOpdPresentationsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .opd_presentations;
      }
      return null;
    },
    annualTotalsOrEpisodesFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .total_or_episodes;
      }
      return null;
    },
    annualTotalsBedNightsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .total_bed_nights;
      }
      return null;
    },
    annualTotalsRadiologyEpisodesFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .total_radiology_episodes;
      }
      return null;
    },
    annualTotalsLaboratoryEpisodesFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .total_laboratory_episodes;
      }
      return null;
    },
    annualTotalsTotalAssetsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0].total_assets;
      }
      return null;
    },
    annualTotalsLifeCycleCostsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .life_cycle_costs;
      }
      return null;
    },
    annualTotalsMaintenanceCostsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .maintenance_costs;
      }
      return null;
    },
    annualTotalsPropertyLifeCycleFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .property_life_cycle;
      }
      return null;
    },
    annualTotalsPropertyFmCostsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .property_fm_costs;
      }
      return null;
    },
    annualTotalsCapexRequirementFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .capex_requirement;
      }
      return null;
    },
  },
  methods: {
    fetchAnnualTotals() {
      this.$store.dispatch("attemptFetchAnnualTotals").then((data) => {
        console.log(data);
      });
    },
  },
};
</script>
