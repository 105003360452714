import Shell from '../../../layouts/Shell.vue'
import StrategicInput from '../views/StrategicInput.vue'
 
const routes = [
    {
        path: '/',
        name: 'LayoutModule',
        component: Shell,
        children: [
        {
            path: '/strategicinput',
            name: 'StrategicInput',
            component: StrategicInput,
            meta: {
                requiresAuth: true
            }
        }]
    }
]

export default routes
