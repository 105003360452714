<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">Tariff and Pricing</h1>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Surrey - United Kingdom</span>
        </div>
      </div>
    </div>

    <nav class="route-nav">
      <ul>
        <li
          @click="editTariffSummary('OPD')"
          :class="tariffSummary == 'OPD' ? 'active' : ''"
        >
          <span>OPD</span>
        </li>
        <li
          @click="editTariffSummary('investigations')"
          :class="tariffSummary == 'investigations' ? 'active' : ''"
        >
          <span>Investigations</span>
        </li>
        <li
          @click="editTariffSummary('beds')"
          :class="tariffSummary == 'beds' ? 'active' : ''"
        >
          <span>Beds</span>
        </li>
        <li
          @click="editTariffSummary('procedures')"
          :class="tariffSummary == 'procedures' ? 'active' : ''"
        >
          <span>Procedures</span>
        </li>
      </ul>
    </nav>

    <div class="route-content">
      <div v-if="tariffSummary == 'OPD'">
        <!-- h1 class="text-3xl my-6 active">OPD</h1 -->

        <table class="table-primary" v-if="specialityData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Speciality</th>
              <th class="border px-4 py-2">Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(patientEpisodes, index) in opdSpecialityFilter"
                  :key="index"
                >
                  {{ patientEpisodes }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(
                    patientEpisodesQuantity, index
                  ) in opdSpecialityPriceFilter"
                  :key="index"
                >
                  {{ patientEpisodesQuantity | numeral }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="tariffSummary == 'investigations'">
        <!-- h1 class="text-3xl my-6 active">Investigations</h1 -->

        <table class="table-primary" v-if="specialityData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Speciality</th>
              <th class="border px-4 py-2"></th>
              <th class="border px-4 py-2">Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(
                    spaceAndProperty, index
                  ) in investigationSpecialityFilter"
                  :key="index"
                >
                 <span v-if="spaceAndProperty == ''"><div v-html="message"/></span>
                 <span v-else-if="spaceAndProperty == '-'"><div v-html="message"/></span>
                 <span v-else> {{  spaceAndProperty }}</span>                
                </div>  <!-- {{ spaceAndProperty }} -->
              </td>

              <td class="border">
                <div
                  v-for="(
                    totalCapitalCost, index
                  ) in investigationProcedureFilter"
                  :key="index"
                >
                <span v-if="totalCapitalCost == ''"><div v-html="message"/></span>
                 <span v-else-if="totalCapitalCost == '-'"><div v-html="message"/></span>
                 <span v-else> {{  totalCapitalCost }}</span>                
                </div>  <!-- {{ totalCapitalCost }} -->
              </td>

              <td class="border">
                <div
                  v-for="(lifeCycle, index) in investigationPriceFilter"
                  :key="index"
                >                  
                <span v-if="lifeCycle == ''"><div v-html="message"/></span>
                 <span v-else-if="lifeCycle == '-'"><div v-html="message"/></span>
                 <span v-else> {{  lifeCycle }}</span>                
                </div>  <!-- {{ lifeCycle }} -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="tariffSummary == 'beds'">
        <!-- h1 class="text-3xl my-6 active">Per Diem Bed Costs</h1 -->
        <table class="table-primary" v-if="specialityData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Bed Type</th>
              <th class="border px-4 py-2">Total Cost</th>
              <th class="border px-4 py-2">Overheads</th>
              <th class="border px-4 py-2">Profit</th>
              <th class="border px-4 py-2">Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(patientEpisodes, index) in bedTypeFilter"
                  :key="index"
                >
                 <span v-if="patientEpisodes == ''"><div v-html="message"/></span>
                 <span v-else-if="patientEpisodes == '-'"><div v-html="message"/></span>
                 <span v-else> {{  patientEpisodes }}</span>                
                </div> <!-- {{ patientEpisodes  }}-->
              </td>

              <td class="border">
                <div
                  v-for="(financeName, index) in bedTotalCostFilter"
                  :key="index"
                >
                 <span v-if="financeName == ''"><div v-html="message"/></span>
                 <span v-else-if="financeName == '-'"><div v-html="message"/></span>
                 <span v-else> {{  financeName }}</span> 
                </div> <!-- {{ financeName | numeral }}-->
              </td>

              <td class="border">
                <div
                  v-for="(financeName, index) in bedOverheadsFilter"
                  :key="index"
                >
                 <span v-if="financeName == ''"><div v-html="message"/></span>
                 <span v-else-if="financeName == '-'"><div v-html="message"/></span>
                 <span v-else> {{  financeName }}</span>
                </div> <!-- {{  financeName | nuneral }}-->
              </td>

              <td class="border">
                <div
                  v-for="(financeName1, index) in bedProfitFilter"
                  :key="index"
                >
                 <span v-if="financeName1 == ''"><div v-html="message"/></span>
                 <span v-else-if="financeName1 == '-'"><div v-html="message"/></span>
                 <span v-else> {{  financeName1 }}</span>
                </div> <!-- {{  financeName1 }}-->
              </td>

              <td class="border">
                <div
                  v-for="(financeName2, index) in bedPriceFilter"
                  :key="index"
                >
                 <span v-if="financeName2 == ''"><div v-html="message"/></span>
                 <span v-else-if="financeName2 == '-'"><div v-html="message"/></span>
                 <span v-else> {{  financeName2 }}</span>
                </div> <!-- {{  financeName2 }}-->
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="tariffSummary == 'procedures'">
        <!-- h1 class="text-3xl my-6 active">Procedures</h1 -->
        <table class="table-primary" v-if="specialityData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Speciality</th>
              <th class="border px-4 py-2">Procedure</th>
              <th class="border px-4 py-2">Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(workforceName, index) in procedureSpecialityFilter"
                  :key="index"
                >
                  <!-- {{ workforceName }} -->
                   <span v-if="workforceName == ''"><div v-html="message"/></span>
                   <span v-else>{{  workforceName }}</span>
                  </div>
              </td>

              <td class="border">
                <div
                  v-for="(financeName, index) in procedureProcedureFilter"
                  :key="index"
                >
                  <!-- {{ financeName }} -->
                  <span v-if="financeName == '-'"><div v-html="message"/></span>
                  <span v-else>{{  financeName }}</span>
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(workforceTotalCost, index) in procedurePriceFilter"
                  :key="index"
                >
                 <span v-if="workforceTotalCost == '-'"><div v-html="message"/></span>
                 <span v-else>{{  workforceTotalCost }}</span>  
                </div> <!-- {{ workforceTotalCost | numeral }}  -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tariffSummary: "OPD",
      selectedSpeciality: "AHPs",
      message: "<BR />",
    };
  },
  mounted() {
    console.log("TariffsMain.Vue: mounted");
    const speciality = {
      speciality: "",
      apiString: "tariff_pricing_collection",
    };
    this.fetchData(speciality);
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },
    specialityName() {
      return this.$store.state.prototypedata.specialityName;
    },
    specialityData() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return true;
      }
      return null;
    },
    getOPDSpecialityIndex() {
      var i = 0;
      var index = 0;

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "opd_speciality" in this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getInvestigationIndex() {
      var i = 0;
      var index = 0;

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "investigation_speciality" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getBedTypeIndex() {
      var i = 0;
      var index = 0;

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if ("bedtype" in this.$store.state.prototypedata.specialityData[i]) {
          index = i;
        }
      }

      return index;
    },
    getProcedureIndex() {
      var i = 0;
      var index = 0;

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "procedures_speciality" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    opdSpecialityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getOPDSpecialityIndex
        ].opd_speciality;
      }
      return null;
    },
    opdSpecialityPriceFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getOPDSpecialityIndex
        ].price;
      }
      return null;
    },
    investigationSpecialityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getInvestigationIndex
        ].investigation_speciality;
      }
      return null;
    },
    investigationProcedureFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getInvestigationIndex
        ].investigation_procedure;
      }
      return null;
    },
    investigationPriceFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getInvestigationIndex
        ].price;
      }
      return null;
    },
    bedTypeFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getBedTypeIndex
        ].bedtype;
      }
      return null;
    },
    bedTotalCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getBedTypeIndex
        ].totalcost;
      }
      return null;
    },
    bedOverheadsFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getBedTypeIndex
        ].overheads;
      }
      return null;
    },
    bedProfitFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getBedTypeIndex
        ].profit;
      }
      return null;
    },
    bedPriceFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getBedTypeIndex
        ].price;
      }
      return null;
    },
    procedureSpecialityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getProcedureIndex
        ].procedures_speciality;
      }
      return null;
    },
    procedureProcedureFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getProcedureIndex
        ].procedure;
      }
      return null;
    },
    procedurePriceFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getProcedureIndex
        ].price;
      }
      return null;
    },
  },
  methods: {
    fetchData(speciality) {
      console.log("TariffsMain.Vue: fetchData (", speciality);
      this.specialityHeader(speciality.speciality);
      this.$store
        .dispatch("attemptFetchSpecialityData", speciality)
        .then((data) => {
          console.log("data", data);
        });

      var i = 0;

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if ("bedtype" in this.$store.state.prototypedata.specialityData[i]) {
          this.indexBeds = i;
        }
      }

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "procedures_speciality" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          this.indexProcedures = i;
        }
      }

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "investigation_speciality" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          this.indexInvestigation = i;
        }
      }

      console.log("indexOPD             : ", this.indexOPD);
      console.log("indexBeds            : ", this.indexBeds);
      console.log("indexProcedures      : ", this.indexProcedures);
      console.log("indexInvestigation   : ", this.indexInvestigation);
    },
    specialityHeader(header) {
      return header;
    },
    editTariffSummary(tariffSummary) {
      this.tariffSummary = tariffSummary;
    },
  },
};
</script>
