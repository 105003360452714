import Shell from "../../../layouts/Shell.vue";
import SummaryMain5 from "../views/SummaryMain5.vue";

const routes = [
  {
    path: "/",
    name: "LayoutModule",
    component: Shell,
    children: [
      {
        path: "/summary5",
        name: "SummaryMain5",
        component: SummaryMain5,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
