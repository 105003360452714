<template>
  <!-- 1. Sort out the issue with changing hospital and no specialities appearing --->
  <!-- 2. The issue with grand summary front page -->
  <!-- 3. The issues with op improvements - needs updating -->
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">Daily Operational Performance</h1>
      </div>

      <div class="validation-cards--item cert no-hover">
        <span class="card-header">Certification Status</span>
        <div class="card-icon" margin-top="0px">
          <img
            src="@/assets/img/circular-label-with-certified-stamp.png"
            alt=""
          />
          <span class="card-total">100% Validation</span>
        </div>
      </div>
    </header>

    <div class="route-header items-center secondary">
        
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Hospital</span>
          <span class="box-label">
            <v-select
              v-model="selectedHospital"
              label="speciality"
              :clearable="false"
              @input="fetchData"
              :options="$store.state.prototypedata.stage8Hospitals"
            />
          </span>
        </div>
      </div>
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Date/Time</span>
          <span class="box-label"> {{ this.dateTime }}</span>
        </div>
      </div>
    </div>

    <nav class="route-nav">
      <ul>
        <li
          @click="editStageSummary('demand')"
          :class="stageSummary == 'demand' ? 'active' : ''"
        >
          <!-- was stage 3-->
          <span>Demand</span>
        </li>
        <li
          @click="editStageSummary('specialities')"
          :class="stageSummary == 'specialities' ? 'active' : ''"
        >
          <span>Asset & Workforce Capacity</span>
        </li>        
        <li
          @click="editStageSummary('opImprov')"
          :class="stageSummary == 'opImprov' ? 'active' : ''"
        >
          <span>Performance KPIs</span>
        </li>  
      </ul>
    </nav>

    <!-- eslint-disable -->

    <div v-if="stageSummary == 'demand'">
      <div class="route-content">

        <!-- button class="btn btn-primary btn-outline mr-4 mb-4" :class="year1To4Table ? 'active' : ''" @click="displayData">Display Data</button -->

        <!-- OPD -->
        <div v-if="hospitalName == 'RMS' || hospitalName == 'MH' || hospitalName == 'KHUH' || hospitalName == 'MKCC' || hospitalName == 'BOC'">
          
          
            <div class="stat-cards--item workforce_summary1 no-hover">
          
              <span class="card-header">OPD</span>
              <div class="card-icon">
               <div class="flex flex-col">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                   <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                     <div class="overflow-hidden">
                        <table class="min-w-full">
                          <thead class="border-b">
                            <template v-for="(v,k) in OPDHeader">
                                <th v-bind:class="style(k)" scope="col">{{v}}</th>
                            </template>
                          </thead>
                          <tbody>
                            <tr v-for="(row, idx1) in dataOPDTable">
                                <td v-bind:class="tableDataRow(idx2)" v-for="(col, idx2) in row">
                                    <div>{{ dataOPDTable[idx1][idx2] }}</div>
                                </td>                         
                                <!--/template -->
                            </tr>                              
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          
              <span class="card-info"></span>
            </div>
        </div>

               
       

        <!-- OT -->
 <div v-if="hospitalName == 'RMS' || hospitalName == 'MH' || hospitalName == 'KHUH' || hospitalName == 'MKCC' || hospitalName == 'BOC'">
  <div class="stat-cards--item no-hover" style="width: 45rem; padding:1rem">              
      <span class="card-header">OT</span>
      <div class="card-icon">                
        <div class="flex flex-col">                  
          <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">                    
            <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">                      
              <div class="overflow-hidden">                        
                <table class="min-w-full">
                  <thead class="border-b">
                    <template v-for="(v,k) in OTHeader">
                        <th v-bind:class="style(k)" scope="col">{{v}}</th>
                    </template>
                  </thead>
                  <tbody>                                                    
                    <tr v-for="(row, idx1) in dataOTTable">
                        <td v-bind:class="tableDataRow(idx2)" v-for="(col, idx2) in row">
                            <div>{{ dataOTTable[idx1][idx2] }}</div>
                        </td>                                                         
                    </tr>                                                                                                 
                  </tbody>
                </table>
              </div>                      
            </div>                   
          </div>                  
        </div>                
      </div>              
      <span class="card-info"></span>
  </div>
</div>


<!-- ER / Walk In / Triage -->
        <div v-if="hospitalName == 'RMS' || hospitalName == 'MH' || hospitalName == 'KHUH' || hospitalName == 'MKCC' || hospitalName == 'BOC'">
            <div class="stat-cards--item no-hover" style="width: 30rem; padding:1rem">
             
              <span class="card-header">{{  this.ERTitle }}</span>
              <div class="card-icon">
               
                <div class="flex flex-col">
                 
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                   
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                     
                      <div class="overflow-hidden">
                       
                        <table class="min-w-full">
                          <thead class="border-b">                            
                            <template v-for="(v,k) in ERHeader">
                                <th v-bind:class="style(k)" scope="col">{{v}}</th>
                            </template>                        
                          </thead>
                          <tbody>
                            <tr v-for="(row, idx1) in dataERTable">
                                <td v-bind:class="tableDataRow(idx2)" v-for="(col, idx2) in row">
                                    <div>{{ dataERTable[idx1][idx2] }}</div>
                                </td>                         
                                
                            </tr>                          
                          </tbody>
                        </table>
                      </div>
                     
                    </div>
                   
                  </div>
                 
                </div>
               
              </div>
             
              <span class="card-info"></span>
            </div>
        </div>

        <!-- Radiology -->
        <div v-if="hospitalName == 'RMS' || hospitalName == 'MH' || hospitalName == 'KHUH' || hospitalName == 'MKCC' || hospitalName == 'BOC'">
            <div class="stat-cards--item no-hover" style="width: 65rem; padding:1rem">
             
              <span class="card-header">Radiology</span>
              <div class="card-icon">
               
                <div class="flex flex-col">
                 
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                   
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                     
                      <div class="overflow-hidden">
                       
                        <table class="min-w-full">
                          <thead class="border-b">
                            <template v-for="(v,k) in RadiologyHeader">
                                <th v-bind:class="style(k)" scope="col">{{v}}</th>
                            </template>                  
                          </thead>
                          <tbody>
                            <tr v-for="(row, idx1) in dataRadiologyTable">
                                <td v-bind:class="tableDataRow(idx2)" v-for="(col, idx2) in row">
                                    <div>{{ dataRadiologyTable[idx1][idx2] }}</div>
                                </td>                         
                                
                            </tr>                            
                          </tbody>
                        </table>
                      </div>
                     
                    </div>
                   
                  </div>
                 
                </div>
               
              </div>
             
              <span class="card-info"></span>
            </div>
        </div>

        <!-- Dialysis -->
        <div v-if="hospitalName == 'RMS' || hospitalName == 'MH'">
            <div class="stat-cards--item no-hover" style="width: 30rem; padding:1rem">
             
              <span class="card-header">Dialysis</span>
              <div class="card-icon">
               
                <div class="flex flex-col">
                 
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                   
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                     
                      <div class="overflow-hidden">
                       
                        <table class="min-w-full">
                          <thead class="border-b">
                            <template v-for="(v,k) in DialysisHeader">
                                <th v-bind:class="style(k)" scope="col">{{v}}</th>
                            </template>  
                          </thead>
                          <tbody>
                            <tr v-for="(row, idx1) in dataDialysisTable">
                                <td v-bind:class="tableDataRow(idx2)" v-for="(col, idx2) in row">
                                    <div>{{ dataDialysisTable[idx1][idx2] }}</div>
                                </td>                         
                                
                            </tr>                             
                          </tbody>
                        </table>
                      </div>
                     
                    </div>
                   
                  </div>
                 
                </div>
               
              </div>
             
              <span class="card-info"></span>
            </div>
        </div>

        <!-- Radiation -->
        <div v-if="hospitalName == 'RMS' || hospitalName == 'BOC'">
            <div class="stat-cards--item no-hover" style="width: 30rem; padding:1rem">
             
              <span class="card-header">Radiation</span>
              <div class="card-icon">
               
                <div class="flex flex-col">
                 
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                   
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                     
                      <div class="overflow-hidden">
                       
                        <table class="min-w-full">
                          <thead class="border-b">
                            <template v-for="(v,k) in RadiationHeader">
                                <th v-bind:class="style(k)" scope="col">{{v}}</th>
                            </template>  
                          </thead>
                          <tbody>
                            <tr v-for="(row, idx1) in dataRadiationTable">
                                <td v-bind:class="tableDataRow(idx2)" v-for="(col, idx2) in row">
                                    <div>{{ dataRadiationTable[idx1][idx2] }}</div>
                                </td>                         
                                
                            </tr>                         
                          </tbody>
                        </table>
                      </div>
                     
                    </div>
                   
                  </div>
                 
                </div>
               
              </div>
             
              <span class="card-info"></span>
            </div>
        </div>

        <!-- Chemo -->

        <div v-if="hospitalName == 'RMS' || hospitalName == 'BOC' ">
            <div class="stat-cards--item no-hover" style="width: 30rem; padding:1rem">
             
              <span class="card-header">Chemotherapy</span>
              <div class="card-icon">
               
                <div class="flex flex-col">
                 
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                   
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                     
                      <div class="overflow-hidden">
                       
                        <table class="min-w-full">
                          <thead class="border-b">
                            <template v-for="(v,k) in ChemoHeader">
                                <th v-bind:class="style(k)" scope="col">{{v}}</th>
                            </template> 
                          </thead>
                          <tbody>
                            <tr v-for="(row, idx1) in dataChemoTable">
                                <td v-bind:class="tableDataRow(idx2)" v-for="(col, idx2) in row">
                                    <div>{{ dataChemoTable[idx1][idx2] }}</div>
                                </td>                         
                                
                            </tr>                         
                          </tbody>
                        </table>
                      </div>
                     
                    </div>
                   
                  </div>
                 
                </div>
               
              </div>
             
              <span class="card-info"></span>
            </div>
        </div>

        <!-- Pallative Care -->
        <div v-if="hospitalName == 'RMS' || hospitalName == 'BOC'">
            <div class="stat-cards--item no-hover" style="width: 30rem; padding:1rem">             
              <span class="card-header">Pallative Care</span>
              <div class="card-icon">                
                <div class="flex flex-col">                  
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">                   
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">                     
                      <div class="overflow-hidden">                       
                        <table class="min-w-full">
                          <thead class="border-b">
                            <template v-for="(v,k) in PallativeHeader">
                                <th v-bind:class="style(k)" scope="col">{{v}}</th>
                            </template> 
                          </thead>
                          <tbody>
                            <tr v-for="(row, idx1) in dataPallativeTable">
                                <td v-bind:class="tableDataRow(idx2)" v-for="(col, idx2) in row">
                                    <div>{{ dataPallativeTable[idx1][idx2] }}</div>
                                </td>                                                         
                            </tr>                            
                          </tbody>
                        </table>
                      </div>                      
                    </div>                   
                  </div>                  
                </div>                
              </div>              
              <span class="card-info"></span>
            </div>
        </div>                                            
        </div>      
    </div>

    

    <div v-if="stageSummary == 'opImprov'">
      <div class="route-content-speciality">
        <div class="route-content">
        

        <!-- now check to see if any of the specialities areas have changed -->
        <!-- div v-if="hospitalName == 'RMS' || hospitalName == 'KHUH' || " -->

          <div class="stat-cards--item workforce_summary1 no-hover" style="width:45rem; padding:1rem;">
              
              <span class="card-header">Performance KPIs</span>
              <div class="card-icon">
                
                <div class="flex flex-col">
                
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      
                      <div class="overflow-hidden">
                      
                        <table class="min-w-full">
                          <thead class="border-b">
                            <template v-for="(v,k) in PerformanceKPIHeader">
                                <th v-bind:class="style(k)" scope="col">{{v}}</th>
                            </template> 
                          </thead>
                          <tbody>
                            <tr class="border-b">
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in performance1"
                                  :key="index"
                                >
                                  
                                  {{ workforce }}
                                </div>
                                
                              </td>
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in performance2"
                                  :key="index"
                                >
                              
                                  {{ workforce }}
                                </div>
                              
                              </td>                                                                                                                       
                            </tr>                           
                          </tbody>
                        </table>
                      </div>
                    
                    </div>
                  
                  </div>
              
                </div>
              
              </div>
              
              <span class="card-info"></span>
          </div>
         
    </div>
    </div>
    </div>

     <!-- eslint-enable -->

    <!-- :options="$store.state.prototypedata.specialities2" -->
    <div v-if="stageSummary == 'specialities'">
      <div class="route-content-speciality">
       

        <!-- now check to see if any of the specialities areas have changed -->
        <!-- div v-if="hospitalName == 'RMS'" -->
          <div class="route-content">
                   
            <div class="stat-cards--item workforce_summary1 no-hover" style="width:45rem; padding:1rem;">
              <!-- 2 open -->
              <span class="card-header">Asset Capacity</span>
              <div class="card-icon">
                <!-- 3 open -->
                <div class="flex flex-col">
                  <!-- 4 open -->
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <!-- 5 open -->
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      <!-- 6 open -->
                      <div class="overflow-hidden">
                        <!-- 7 open -->
                        <table class="min-w-full">
                          <thead class="border-b">
                            <tr>
                              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">Live Capacity</th>
                              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-right">Capacity</th>
                              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-right">Utilised</th>                              
                              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-right">Occupancy</th>  
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="border-b">
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in asset1"
                                  :key="index"
                                >
                                  <!-- 8 open -->
                                  {{ workforce }}
                                </div>
                                <!-- 8 close-->
                              </td>
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in asset2"
                                  :key="index"
                                >
                                  <!-- 9 open-->
                                  {{ workforce }}
                                </div>
                                <!-- 9 close -->
                              </td>
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in asset3"
                                  :key="index"
                                >
                                  <!-- 10 open -->
                                  {{ workforce }}
                                </div>
                                <!-- 10 close -->
                              </td> 
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in asset4"
                                  :key="index"
                                >
                                  <!-- 10 open -->
                                  {{ workforce }}
                                </div>
                                <!-- 10 close -->
                              </td>                               
                            </tr>
                        
                          </tbody>
                        </table>
                      </div>
                      <!-- 7 close -->
                    </div>
                    <!--6 close -->
                  </div>
                  <!--5 close -->
                </div>
                <!--4 close -->
              </div>
              <!--3 close-->
              <span class="card-info"></span>
            </div>

            <div class="stat-cards--item workforce_summary1 no-hover" style="width: 45rem; padding: 1rem;">
              <!-- 2 open -->
              <!-- span class="card-header">Asset Capacity</span -->
              <div class="card-icon">
                <!-- 3 open -->
                <div class="flex flex-col">
                  <!-- 4 open -->
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <!-- 5 open -->
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      <!-- 6 open -->
                      <div class="overflow-hidden">
                        <!-- 7 open -->
                        <table class="min-w-full">
                          <thead class="border-b">
                            <tr>
                              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">Beds</th>
                              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-right">Capacity</th>
                              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-right">Utilised</th>                              
                              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-right">Occupancy</th>  
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="border-b">
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in assetBeds1"
                                  :key="index"
                                >
                                  <!-- 8 open -->
                                  {{ workforce }}
                                </div>
                                <!-- 8 close-->
                              </td>
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in assetBeds2"
                                  :key="index"
                                >
                                  <!-- 9 open-->
                                  {{ workforce }}
                                </div>
                                <!-- 9 close -->
                              </td>
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in assetBeds3"
                                  :key="index"
                                >
                                  <!-- 10 open -->
                                  {{ workforce }}
                                </div>
                                <!-- 10 close -->
                              </td>    
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in assetBeds4"
                                  :key="index"
                                >
                                  <!-- 9 open-->
                                  {{ workforce }}
                                </div>
                                <!-- 9 close -->
                              </td>                                                   
                            </tr>                            
                          </tbody>
                        </table>
                      </div>
                      <!-- 7 close -->
                    </div>
                    <!--6 close -->
                  </div>
                  <!--5 close -->
                </div>
                <!--4 close -->
              </div>
              <!--3 close-->
              <span class="card-info"></span>
            </div>

            <div class="stat-cards--item workforce_summary1 no-hover" style="width:45rem; padding:1rem;">
              <!-- 2 open -->
              <span class="card-header">Workforce Capacity</span>
              <div class="card-icon">
                <!-- 3 open -->
                <div class="flex flex-col">
                  <!-- 4 open -->
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <!-- 5 open -->
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      <!-- 6 open -->
                      <div class="overflow-hidden">
                        <!-- 7 open -->
                        <table class="min-w-full">
                          <thead class="border-b">
                            <tr>
                              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">Workforce Capacity</th>
                              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-right">1030</th>
                              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-right">Optimum</th>                              
                              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-right">Excess/Gap</th>  
                              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-right">Efficiency</th>  
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="border-b">
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in workforce1"
                                  :key="index"
                                >
                                  <!-- 8 open -->
                                  {{ workforce }}
                                </div>
                                <!-- 8 close-->
                              </td>
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in workforce2"
                                  :key="index"
                                >
                                  <!-- 9 open-->
                                  {{ workforce }}
                                </div>
                                <!-- 9 close -->
                              </td>
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in workforce3"
                                  :key="index"
                                >
                                  <!-- 10 open -->
                                  {{ workforce }}
                                </div>
                                <!-- 10 close -->
                              </td> 
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in workforce4"
                                  :key="index"
                                >
                                  <!-- 10 open -->
                                  {{ workforce }}
                                </div>
                                <!-- 10 close -->
                              </td>   
                              <td :class="getDataColour(100)">
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in workforce5"
                                  :key="index"
                                >
                                  <!-- 10 open -->
                                  {{ workforce }}
                                </div>
                                <!-- 10 close -->
                              </td>                                  
                            </tr>                           
                          </tbody>
                        </table>
                      </div>
                      <!-- 7 close -->
                    </div>
                    <!--6 close -->
                  </div>
                  <!--5 close -->
                </div>
                <!--4 close -->
              </div>
              <!--3 close-->
              <span class="card-info"></span>
            </div>
          </div>
        <!-- /div-->       
      </div>
    </div>
   
</div>
</template>
<script>
//import { Bar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "bar",
  components: {
    //Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stageSummary: "demand",
      selectedSpeciality: "AHPs",
      selectedYear: "2026",
      selectedNewHospital: "BOC",
      improvementSpecialityArea: "Diagnostics",
      selectedHospital: "RMS",
      selectedHospitalPrefix: "RMS",
      apiPayload: { reference: "", hospital: "", speciality: "", apiString: "", year: "", stage: "" },
      activeSummary: "workforceCost2024",      
      improvementType: "Diagnostics",
      selectedSpecialityArea: "Patient Episodes",
      specialityType: "Patient Episodes",
      hospitalName: "RMS",
      specialityApi: "ahps_surrey_stage_3_2023_collection",
      tileInformation: {},
      firstUpdate: true,  
      stageThreeData: [],
      activeStageYear: "2024",
      dataRadiologyTable: {},
      dataOTTable: {},
      dataOPDTable: {},
      dataERTable: {},
      dataDialysisTable: {},
      dataRadiationTable: {},
      dataChemoTable: {},
      dataPallativeTable: {},
      dataHeader: {},
      dataRow: {},
      ERTitle: "ER / Walk In / Triage",
      dateTime: "3rd January 2024 - 10:30",
      tableData: {
        "one": "ER",
        "two": "1030"
      },


      
      //chartOptions: {
      //  responsive: true
      //},
    };
  },
  updated () {
    console.log ("updated: ")
    if (this.firstUpdate == true)
    {
      console.log ("update: update only once");

       //var fullAPIString = "get" + this.selectedHospitalPrefix;

        //console.log ("4. ", fullAPIString);
        this.OTData();
        this.OPDData("updated");
        this.DialysisData();
        this.RadiologyData();
        this.PallativeData();
        this.ERData();
        this.ChemoData();
        this.RadiationData();

        //this.fetchDataSummaries(fullAPIString);

        //this.getBarGraphDataValues2028();
        //this.getBarGraphDataValues2024();
        //this.getBarGraphDataValues2025();
        //this.getBarGraphDataValues2026();
        //this.getBarGraphDataValues2027();


         //this.fillData(this.activeSummary, this.activeStageYear);
        this.firstUpdate = false;
    }    
  },
  beforeMount() {
    //console.log("Summary3.vue before mounted");
    this.apiPayload.apiString = "grandsummarytwo-collection";
    this.apiPayload.reference = "1";
    this.apiPayload.stage = "3";
  
    //var fullAPIString = "get" + this.selectedHospitalPrefix;

    //console.log ("1. ", fullAPIString);
    //console.log ("->1");
    //this.fetchDataSummaries (fullAPIString);
    //console.log ("<-1");


  },
  mounted() {
    //const speciality = {
//      speciality: "Grand Summary 3",
  //    apiString: "grandsummarythreeyearone-collection",
    //};
    //console.log ("->2a");
    //this.fetchData(speciality);

    //console.log ("->2b");

    this.apiPayload.speciality = "AHPs";
    this.apiPayload.apiString = "grandsummarythreeyearone-collection";
    this.apiPayload.year = "2024";
    this.apiPayload.hospital = "boc";
    this.apiPayload.reference = "2";

    this.datacollection = {};

    //console.log ("mounted - apiPayload", this.apiPayload);

    //var fullAPIString = "get" + this.selectedHospitalPrefix;

    //console.log ("2 ", fullAPIString);

    //console.log ("->2c");

    //this.fetchDataSummaries(fullAPIString);

    //console.log ("->2d");

    //this.fillData("assets2024", this.activeStageYear);
    //console.log ("->2e");

    this.createDataHeader (20);

    this.dataRow = new Array ();

this.dataRow.push("text-sm text-gray-900 font-light px-6  whitespace-nowrap text-left");
this.dataRow.push("text-sm text-gray-900 font-light px-6  whitespace-nowrap text-right");
this.dataRow.push("text-sm text-gray-900 font-light px-6  whitespace-nowrap text-right");
this.dataRow.push("text-sm text-gray-900 font-light px-6  whitespace-nowrap text-right");
this.dataRow.push("text-sm text-gray-900 font-light px-6  whitespace-nowrap text-right");
this.dataRow.push("text-sm text-gray-900 font-light px-6  whitespace-nowrap text-right");
this.dataRow.push("text-sm text-gray-900 font-light px-6  whitespace-nowrap text-right");
this.dataRow.push("text-sm text-gray-900 font-light px-6  whitespace-nowrap text-right");
this.dataRow.push("text-sm text-gray-900 font-light px-6  whitespace-nowrap text-right");
this.dataRow.push("text-sm text-gray-900 font-light px-6  whitespace-nowrap text-right");


    //this.getOptionsMethod();
    this.OTData();
    this.OPDData("mounted");
     
     this.ERData();
     this.RadiologyData();
     this.RadiationData();
     this.ChemoData();
     this.DialysisData();
     this.PallativeData();
     

    this.$forceUpdate();
//   
  },
  watch: {
    selectedHospital: 
    {    
      handler: function (val) 
      {
        //var fullAPIString = "";
        console.log(
          "selectedHospital: Value Change: ",
          val.label,
          val.string
        );
        this.editHospitalName(val.string);      

        if (val.string == "RMS")
        {
          this.ERTitle = "ER / Walk In / Triage";
        }

        
        if (val.string == "MH")
        {
          this.ERTitle = "ER";
        }

        
        if (val.string == "MKCC")
        {
          this.ERTitle = "ER";
        }

        
        if (val.string == "BOC")
        {
          this.ERTitle = "Walk-in & Triage";
        }

        
        if (val.string == "KHUH")
        {
          this.ERTitle = "ER";
        }


        //console.log ("2. OPData");
        this.OTData();
        this.OPDData("watch");
        this.ERData();
        this.DialysisData();
        this.RadiationData();
        this.RadiologyData();
        this.ChemoData();
        this.PallativeData();
      },
      deep: true,
    },
    selectedNewHospital: 
    {    
      handler: function (val) 
      {
        //var fullAPIString = "";
        console.log(
          "selectedNewHospital: Value Change: ",
         val.label,
          val.string
        );

        console.log ("selctedSpeciality is changed to: ", this.selectedSpeciality);
        this.selectedSpeciality = "AHPs";
        this.setSpecialityHospital (val.String);
      },
      deep: true,
    },
    improvementSpecialityArea: {
      handler: function (val) {
        //console.log(
         // "improvementArea: Value Change: ",
          //val.label,
          //val.string
       // );
        this.editImprovementType(val.label);
        //console.log("speciality: ", this.specialityApi);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      },
      deep: true,
    },
    activeSummary: {
      handler: function (newvalue) {
        console.log("The new activeSummary value is ", newvalue);
        //this.fillData (newvalue, this.activeStageYear);
      },
      deep: true,
    },
    stageSummary: {
      handler: function () {
        //console.log("The new stageSummary value is ", newvalue);      
      },
      deep: true,
    },
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },
          
    assetBeds1()
    {
      var data = new Array();

      if (this.hospitalName == "RMS")
      {

      data.push ('ICU');
      data.push ('HDU');
      data.push ('CICU');
      data.push ('CCU');
      data.push ('Day-Case');
      data.push ('Medical');
      data.push ('Surgical');
      data.push ('Burns');
      data.push ('Dialysis');
      data.push ('Obstetric');
      data.push ('Paediatrics');
      data.push ('Paediatric Onc');
      data.push ('Surgical Oncology');
      data.push ('Palliative Care');
      data.push ('Medical Oncology');
      data.push ('Haemotology');
      }

      if (this.hospitalName == "MH")
      {
        data.push ('ICU');
        data.push ('HDU');
        data.push ('Day-Case');
        data.push ('Medical');
        data.push ('Surgical');
        data.push ('Burns');
        data.push ('Dialysis');
        data.push ('Obstetric'); 
        data.push ('Paediatrics');


      }

      if (this.hospitalName == "KHUH")
      {
        data.push ('ICU');
data.push ('HDU');
data.push ('Day-Case');
data.push ('Medical');
data.push ('Surgical');
data.push ('Burns');
data.push ('Dialysis');
data.push ('Obstetric');
data.push ('Paediatrics');

      }

      if (this.hospitalName == "MKCC")
      {
data.push ('CICU');
data.push ('CCU');
data.push ('Day-Case');
data.push ('Medical');
data.push ('Surgical');
    }

    if (this.hospitalName == "BOC")
      {

data.push ('ICU');
data.push ('Day-Case');
data.push ('Medical Oncology');
data.push ('Haemotology');
data.push ('Surgical Oncology');
data.push ('Paediatrics');
data.push ('Palliative Care');
    }

      return data;

    },
    assetBeds2()
    {
      var data = new Array();

      if (this.hospitalName == "RMS")
      {

      data.push ('56');	
			
			
      data.push ('107');
      data.push ('10');
      data.push ('18');
      data.push ('83');
      data.push ('177');
      data.push ('186');
      data.push ('12');
      data.push ('59');
      data.push ('107');
      data.push ('50');
      data.push ('6');
      data.push ('16');
      data.push ('39');
      data.push ('34');
      data.push ('18');
      }

      if (this.hospitalName == "MH")
      {
        data.push ('11');
        data.push ('17');
        data.push ('39');
        data.push ('92');
        data.push ('86');
        data.push ('12');
        data.push ('33');
        data.push ('57');
        data.push ('26');

      }

      if (this.hospitalName == "KHUH")
      {
        data.push ('31');
data.push ('15');
data.push ('31');
data.push ('55');
data.push ('79');
data.push ('0');
data.push ('26');
data.push ('50');
data.push ('24');
      }

      if (this.hospitalName == "MKCC")
      {
        data.push ('10');
data.push ('18');
data.push ('9');
data.push ('30');
data.push ('21');
      }

      if (this.hospitalName == "BOC")
      {
        data.push ('14');
data.push ('4');
data.push ('34');
data.push ('18');
data.push ('16');
data.push ('6');
data.push ('39');
      }

      return data;

    },
    assetBeds3()
    {
      var data = new Array();

      if (this.hospitalName == "RMS")
      {

      data.push ('44');
      data.push ('41');
      data.push ('8');
      data.push ('12');
      data.push ('58');
      data.push ('135');
      data.push ('94');
      data.push ('2');
      data.push ('55');
      data.push ('46');
      data.push ('31');
      data.push ('2');
      data.push ('4');
      data.push ('36');
      data.push ('31');
      data.push ('12');
      }

      if (this.hospitalName == "MH")
      {

data.push ('8');
data.push ('14');
data.push ('24');
data.push ('65');
data.push ('43');
data.push ('2');
data.push ('33');
data.push ('21');
data.push ('17');

      }

      if (this.hospitalName == "KHUH")
      {
        data.push ('24');
data.push ('15');
data.push ('22');
data.push ('53');
data.push ('45');
data.push ('0');
data.push ('22');
data.push ('25');
data.push ('14');
      }

      if (this.hospitalName == "MKCC")
      {
        data.push ('8');
data.push ('12');
data.push ('8');
data.push ('17');
data.push ('6');
      }

      if (this.hospitalName == "BOC")
      {
      data.push ('12');
data.push ('4');
data.push ('31');
data.push ('12');
data.push ('4');
data.push ('2');
data.push ('36');

      }

      return data;

    },
    assetBeds4()
    {
      var data = new Array();

      if (this.hospitalName == "MH")
      {

         
 

      data.push ('72.73');
      data.push ('82.35');
      data.push ('61.54');
      data.push ('70.65');
      data.push ('50.00');
      data.push ('16.67');
      data.push ('100.00');
      data.push ('36.84');
      data.push ('65.38');
      //data.push ('');
      //data.push ('');
      //data.push ('');
      //data.push ('');
      //data.push ('');
      //data.push ('');
      //data.push ('');
      }

      if (this.hospitalName == "KHUH")
      {
        data.push ('77');
data.push ('100');
data.push ('71');
data.push ('96');
data.push ('57');
data.push ('0');
data.push ('85');
data.push ('50');
data.push ('58');
      }

      if (this.hospitalName == "MKCC")
      {
        data.push ('80.00');
data.push ('66.67');
data.push ('88.89');
data.push ('56.67');
data.push ('28.57');
      }

     

      return data;

    },
    PerformanceKPIHeader ()
    {
      var data = new Array();
      
      data.push ('Live Capacity');
  data.push ('Capacity');
  return data;
    },

    AssetHeader()
    {
      var data = new Array();



  data.push ('Live Capacity');
  data.push ('Capacity');
  data.push ('Utilised');
  data.push ('Occupancy');

  return data;
    },
    performance1()
    {
      var data = new Array();

      console.log ("this.hospitalNAme: = ", this.hospitalName);



        data.push ('DNAs');
        data.push ('Cancellations');
        data.push ('Complaints');
        data.push ('Waiting Time ER');
        data.push ('Inpatient Waiting List (>6 Weeks)');
        data.push ('Outpatient Waiting List (>6 Weeks)');
        data.push ('Diagnostic Waiting List (>6 Weeks)');
        data.push ('Patient Adverse Incident');
        data.push ('Medications Shortages');
        data.push ('Discharges (Previous 24 Hours)');
        data.push ('Discharges (Planned next 24 Hours)');
        data.push ('Long-Stay Patients');
  
      

      return data;

    },
    performance2()
    {
      var data = new Array();


      if (this.hospitalName == "RMS")
      {

      data.push ('328');
      data.push ('40');
      data.push ('11');
      data.push ('72');
      data.push ('549');
      data.push ('1240');
      data.push ('57');
      data.push ('9');
      data.push ('39');
      data.push ('126');
      data.push ('118');
      data.push ('77');
      }

      if (this.hospitalName == "BOC")
      {
        data.push ('4');
        data.push ('3');
        data.push ('1');
        data.push ('12');
        data.push ('0');
        data.push ('23');
        data.push ('0');
        data.push ('2');
        data.push ('19');
        data.push ('19');
        data.push ('0');
        data.push ('9');

      }

      if (this.hospitalName == "MKCC")
      {
        data.push ('34');
        data.push ('3');
        data.push ('2');
        data.push ('9');
        data.push ('0');
        data.push ('0');
        data.push ('0');
        data.push ('2');
        data.push ('3');
        data.push ('21');
        data.push ('24');
        data.push ('3');


      }

      if (this.hospitalName == "MH")
      {

      data.push ('98');
      data.push ('289');
      data.push ('3');
      data.push ('32');
      data.push ('0');
      data.push ('230');
      data.push ('23');
      data.push ('2');
      data.push ('8');
      data.push ('54');
      data.push ('49');
      data.push ('34');
      }

      if (this.hospitalName == "KHUH")
      {

      data.push ('290');
      data.push ('290');
      data.push ('290');
      data.push ('290');
      data.push ('549');
      data.push ('987');
      data.push ('34');
      data.push ('3');
      data.push ('9');
      data.push ('32');
      data.push ('45');
      data.push ('31');
      }
      

      return data;

    },
    workforce1() {
      var data = new Array();

      if (this.hospitalName == "RMS")
      {
      data.push ('Medical Staff');
      data.push ('Nursing Staff');
      data.push ('Technicians');
      data.push ('AHPs');
      data.push ('Administration');
      }

      if (this.hospitalName == "MH")
      {
      data.push ('Medical Staff');
      data.push ('Nursing Staff');
      data.push ('Technicians');
      data.push ('AHPs');
      data.push ('Administration');
      }

      if (this.hospitalName == "KHUH")
      {
      data.push ('Medical Staff');
      data.push ('Nursing Staff');
      data.push ('Technicians');
      data.push ('AHPs');
      data.push ('Administration');
      }

      if (this.hospitalName == "MKCC")
      {
      data.push ('Medical Staff');
      data.push ('Nursing Staff');
      data.push ('Technicians');
      data.push ('AHPs');
      data.push ('Administration');
      }

      if (this.hospitalName == "BOC")
      {
      data.push ('Medical Staff');
      data.push ('Nursing Staff');
      data.push ('Technicians');
      data.push ('AHPs');
      data.push ('Administration');
      }
      return data;
      
    },
    workforce2() {
      var data = new Array();

      if (this.hospitalName == "RMS")
      {
      data.push ('862');
      data.push ('2491');
      data.push ('323');
      data.push ('549');
      data.push ('1223');
      }

      if (this.hospitalName == "MH")
      {

        data.push ('393');
data.push ('1,028');
data.push ('78');
data.push ('431');
data.push ('692');
      }

      if (this.hospitalName == "KHUH")
      {
        data.push ('403');
data.push ('890');
data.push ('189');
data.push ('61');
data.push ('430');
      }

      if (this.hospitalName == "MKCC")
      {
        data.push ('34');
data.push ('382');
data.push ('39');
data.push ('43');
data.push ('87');
      }

      if (this.hospitalName == "BOC")
      {
        data.push ('32');
data.push ('191');
data.push ('17');
data.push ('14');
data.push ('14');
      }


      return data;
      
    },
    workforce3() {
      var data = new Array();

      if (this.hospitalName == "RMS")
      {
      data.push ('725');
      data.push ('2029');
      data.push ('157');
      data.push ('267');
      data.push ('499');
      }
      
      if (this.hospitalName == "MH")
      {
        data.push ('342');
data.push ('810');
data.push ('40');
data.push ('199');
data.push ('183');
      }

      if (this.hospitalName == "BOC")
      {
      data.push ('34');
      data.push ('183');
      data.push ('12');
      data.push ('12');
      data.push ('4');
      }

      

      return data;

      
    },
    workforce4() {
      var data = new Array();

      if (this.hospitalName == "RMS")
      {
      data.push ('137');  
      data.push ('462');
      data.push ('166');
      data.push ('282');
      data.push ('724');
      }

      if (this.hospitalName == "MH")
      {
        data.push ('51');
data.push ('218');
data.push ('38');
data.push ('232');
data.push ('509');

      }

      if (this.hospitalName == "BOC")
      {
      data.push ('-2');
      data.push ('8');
      data.push ('5');
      data.push ('2');
      data.push ('10');
      }

      return data;
      
    },
    workforce5() {
      var data = new Array();

      data.push ('');
      data.push ('');
      data.push ('');
      data.push ('');
      data.push ('');
      return data;
      
    },
    asset1() {
      var data = new Array();

      if (this.hospitalName == "RMS")
      {

      data.push ('OPD');
      data.push ('OT IP');
      data.push ('OT DC');
      data.push ('OT CPOD');
      data.push ('Endoscopy');
      data.push ('Cath Lab PPCI');
      data.push ('Chemo Radiology');
      }

      if (this.hospitalName == "MH" || this.hospitalName == "KHUH")
      {
        data.push ('OPD');
      data.push ('OT IP');
      data.push ('OT DC');
      data.push ('OT CPOD');
      data.push ('Endoscopy');

      }

      if (this.hospitalName == "MKCC")
      {
        data.push ('OPD');
      data.push ('OT IP');
      data.push ('OT DC');
      data.push ('OT CPOD');
      data.push ('Cath Lab PPCI');
      }

      if (this.hospitalName == "BOC")
      {
        data.push ('OPD');
      data.push ('OT IP');
      data.push ('OT DC');
      data.push ('Chemo');
      data.push ('Radiation');
      }
          
      return data;
      
    },
    asset2() {
      var data = new Array();

      if (this.hospitalName == "RMS")
      {
      data.push ('185');
      data.push ('27');
      data.push ('7');
      data.push ('36');
      data.push ('13');
      data.push ('5');
      data.push ('35');
      }

      if (this.hospitalName == "MH")
      {
        data.push ('90');
        data.push ('12');
        data.push ('3');
        data.push ('1');
        data.push ('2');

      }

      if (this.hospitalName =="KHUH")
      {
        data.push ('62');
        data.push ('10');
        data.push ('2');
        data.push ('1');
        data.push ('4');

      }

      if (this.hospitalName == "MKCC")
      {
        data.push ('14');
        data.push ('3');
        data.push ('1');
        data.push ('1');
        data.push ('5');

      }

      if (this.hospitalName == "BOC")
      {
        data.push ('19');
        data.push ('2');
        data.push ('1');
        data.push ('33');
        data.push ('2');

      }
          
      return data;
      
    },
    asset3() {
      var data = new Array();

      if (this.hospitalName == "RMS")
      {

      data.push ('142');
      data.push ('20');
      data.push ('3');
      data.push ('35');
      data.push ('8');
      data.push ('3');
      data.push ('35');
      }

      if (this.hospitalName == "MH")
      {
        data.push ('55');
        data.push ('9');
        data.push ('1');
        data.push ('1');
        data.push ('1');

      }

      if (this.hospitalName == "MKCC")
      {
        data.push ('12');
        data.push ('1');
        data.push ('0');
        data.push ('0');
        data.push ('3');

      }

      if (this.hospitalName == "BOC")
      {
        data.push ('17');
        data.push ('2');
        data.push ('1');
        data.push ('33');
        data.push ('2');

      }

      if (this.hospitalName == "KHUH")
      {
        data.push ('58');
        data.push ('8');
        data.push ('1');
        data.push ('1');
        data.push ('2');

      }
          
      return data;
      
    },
    asset4() {
      var data = new Array();

      data.push ('');
      data.push ('');
      data.push ('');
      data.push ('');
      data.push ('');
      data.push ('');
      data.push ('');
          
      return data;
      
    },   
    ERHeader()
    {
        var data = new Array ();


        data.push (this.ERTitle);
        data.push ("1030");

        return data;
    },
    OPDHeader()
    {
        var data = new Array ();

        data.push ("OPD");
        data.push ("New");
        data.push ("FU");        

        return data;
    },
    /*dataRadiologyTable()
    {
      return this.dataRadiologyTable;
    },*/
    /*dataOTTable()
    {
      console.log ("function: this.dataOTable: ", this.dataOTTable);
      return this.dataOTTable;
    },*/
    /*dataOPDTable()
    {
      console.log ("function: this.dataOPDTable: ", this.dataOPDTable);
      return this.dataOPDTable;
    },*/
    /*dataDialysisTable()
    {
      return this.dataDialysisTable;
    },
    dataPallativeTable()
    {
      return this.dataPallativeTable;
    },
    dataChemoTable()
    {
      return this.dataChemoTable;
    },
    dataRadiationTable()
    {
      return this.dataRadiationTable;
    },*/
    OTHeader()
    {
        var data = new Array ();

        data.push ("OT");

        if (this.hospitalName == "RMS")
        {
            data.push ("IP");
            data.push ("DC");
            data.push ("CPOD");
            data.push ("Endoscopy");
            data.push ("Cath Lab");          
        }

        if (this.hospitalName == "MH")
        {
            data.push ("IP");
            data.push ("DC");
            data.push ("CPOD");
            data.push ("Endoscopy");                                    
        }

        if (this.hospitalName == "KHUH")
        {
            data.push ("IP");
            data.push ("DC");
            data.push ("CPOD");
            data.push ("Endoscopy");                             
        }

        if (this.hospitalName == "MKCC")
        {
            data.push ("IP");
            data.push ("DC");
            data.push ("Cath Lab");        
        }

        if (this.hospitalName == "BOC")
        {
            data.push ("IP");
            data.push ("DC")
        }
        return data;
    },

    RadiologyHeader()
    {
        var data = new Array ();

        data.push ("Radiology");

        if (this.hospitalName == "RMS")
        {
            data.push ("XR");
            data.push ("US");
            data.push ("MRI");
            data.push ("CT");
            data.push ("PET CT");
            data.push ("Intervention");
            data.push ("Nuclear");
            data.push ("ECHO");           
        }

        if (this.hospitalName == "MH")
        {
            data.push ("XR");
            data.push ("US");
            data.push ("MRI");
            data.push ("CT");
            data.push ("PET CT");
            data.push ("Intervention");
            data.push ("Nuclear");    
        }

        if (this.hospitalName == "KHUH")
        {
            data.push ("XR");
            data.push ("US");
            data.push ("MRI");
            data.push ("CT");
            data.push ("PET CT");
            data.push ("Intervention");
            data.push ("Nuclear");  
        }

        if (this.hospitalName == "MKCC")
        {
            data.push ("XR");
            data.push ("US");
            data.push ("MRI");
            data.push ("CT");
            data.push ("PET CT");
            data.push ("Intervention");          
            data.push ("ECHO");
        }

        if (this.hospitalName == "BOC")
        {
            data.push ("XR");
            data.push ("MRI");
            data.push ("CT");
        }

        return data;
    },
    DialysisHeader()
    {
        var data = new Array ();

        data.push ("Dialysis");
        data.push ("1030");


        return data;
    },
    RadiationHeader()
    {
        var data = new Array ();

        data.push ("Radiation");
        data.push ("1030");


        return data;
    },
   
    ChemoHeader() {
      
      var data = new Array();

data.push ('Chemotherapy');
data.push ('1030')

return data;
    },
    PallativeHeader() {
      
      var data = new Array();

data.push ('Pallative');
data.push ('1030');
return data;
    },
    PallativeHeader1() {
      
      var data = new Array();

      if (this.hospitalName == 'RMS')
      {

data.push ('Live Capacity');
data.push ('Capacity');
data.push ('Utilised');
data.push ('Occupancy');
      }

      if (this.hospitalName == 'MH')
      {
        data.push ('Live Capacity');
data.push ('Capacity');
data.push ('Utilised');

      }

      if (this.hospitalName == 'KHUH')
      {
        data.push ('Live Capacity');
data.push ('Capacity');
data.push ('Utilised');

      }

      if (this.hospitalName == 'MKCC')
      {
        data.push ('Live Capacity');
data.push ('Capacity');
data.push ('Utilised');
      }

      if (this.hospitalName == 'BOC')
      {
        data.push ('Live Capacity');
data.push ('Capacity');
data.push ('Utilised');
      }

return data;
    },
  },
  methods: {   

    createNewDataRadiologyTable (i, j)
    {

      for (let i_i = 0; i_i<=i; i_i++)
      {
          this.dataRadiologyTable[i_i] = []
          for (let j_j= 0; j_j<=j; j_j++)
          {
            this.dataRadiologyTable [i_i][j_j] = "";
          }
       }

     

    },

    createNewDataDialysisTable (i, j)
    {

      for (let i_i = 0; i_i<=i; i_i++)
      {
          this.dataDialysisTable[i_i] = []
          for (let j_j= 0; j_j<=j; j_j++)
          {
            this.dataDialysisTable [i_i][j_j] = "";
          }
       }

       

    },

    createNewDataPallativeTable (i, j)
    {

      for (let i_i = 0; i_i<=i; i_i++)
      {
          this.dataPallativeTable[i_i] = []
          for (let j_j= 0; j_j<=j; j_j++)
          {
            this.dataPallativeTable [i_i][j_j] = "";
          }
       }

    },

    createNewDataRadiationTable (i, j)
    {

      for (let i_i = 0; i_i<=i; i_i++)
      {
          this.dataRadiationTable[i_i] = []
          for (let j_j= 0; j_j<=j; j_j++)
          {
            this.dataRadiationTable [i_i][j_j] = "";
          }
       }

       

    },

    createNewDataOTTable (i, j)
    {

      for (let i_i = 0; i_i<=i; i_i++)
      {
          this.dataOTTable[i_i] = []
          for (let j_j= 0; j_j<=j; j_j++)
          {
            this.dataOTTable [i_i][j_j] = "";
          }
       }

      

    },
    createNewDataERTable (i, j)
    {

      for (let i_i = 0; i_i<=i; i_i++)
      {
          this.dataERTable[i_i] = []
          for (let j_j= 0; j_j<=j; j_j++)
          {
            this.dataERTable [i_i][j_j] = "";
          }
       }

      

    },
    createNewDataOPDTable (i, j)
    {

      for (let i_i = 0; i_i<=i; i_i++)
      {
          this.dataOPDTable[i_i] = []
          for (let j_j= 0; j_j<=j; j_j++)
          {
            this.dataOPDTable [i_i][j_j] = "";
          }
       }

       

    },
    createNewDataChemoTable (i, j)
    {

      for (let i_i = 0; i_i<=i; i_i++)
      {
          this.dataChemoTable[i_i] = []
          for (let j_j= 0; j_j<=j; j_j++)
          {
            this.dataChemoTable [i_i][j_j] = "";
          }
       }    

    },

    createDataHeader (i)
    {
      if (this.dataHeader != null)
      {
        delete this.dataHeader;
        this.dataHeader = null;
      }

      this.dataHeader = new Array ();

      for (let index = 0; index <= i;  index++)
      {
        this.dataHeader.push ("text-sm font-medium text-gray-900 px-6 py-2 text-left");
      }

    },

    

    OTData ()
    {
      console.log (">> OTData: ", this.hospitalName);

      if (this.hospitalName == "RMS")
      {
           console.log ("Creating RMS: ", this.hospitalName);
           this.createNewDataOTTable (3,5);

           this.dataOTTable [0][0] = "Planned"
           this.dataOTTable [0][1] = "69";
           this.dataOTTable [0][2] = "55";
           this.dataOTTable [0][3] = "9";
           this.dataOTTable [0][4] = "26";
           this.dataOTTable [0][5] = "16";
           this.dataOTTable [1][0] = "Previous 24hrs"
           this.dataOTTable [1][1] = "71";
           this.dataOTTable [1][2] = "52";
           this.dataOTTable [1][3] = "5";
           this.dataOTTable [1][4] = "22";
           this.dataOTTable [1][5] = "14";
           this.dataOTTable [2][0] = "Capacity"
           this.dataOTTable [2][1] = "129";
           this.dataOTTable [2][2] = "122";
           this.dataOTTable [2][3] = "16";
           this.dataOTTable [2][4] = "56";
           this.dataOTTable [2][5] = "40";
           this.dataOTTable [3][0] = "Available"
           this.dataOTTable [3][1] = "60";
           this.dataOTTable [3][2] = "67";
           this.dataOTTable [3][3] = "7";
           this.dataOTTable [3][4] = "30";
           this.dataOTTable [3][5] = "24";     
         
      }

      if (this.hospitalName == "MH")
      {
           console.log ("Creating MH: ", this.hospitalName);
           this.createNewDataOTTable (4,5);

           this.dataOTTable [0][0] = "Planned"
           this.dataOTTable [0][1] = "29";
           this.dataOTTable [0][2] = "32";
           this.dataOTTable [0][3] = "7";
           this.dataOTTable [0][4] = "11";

           this.dataOTTable [1][0] = "Previous 24hrs"
           this.dataOTTable [1][1] = "28";
           this.dataOTTable [1][2] = "27";
           this.dataOTTable [1][3] = "4";
           this.dataOTTable [1][4] = "5";
           
           this.dataOTTable [2][0] = "Capacity"
           this.dataOTTable [2][1] = "60";
           this.dataOTTable [2][2] = "64";
           this.dataOTTable [2][3] = "8";
           this.dataOTTable [2][4] = "28";
           
           this.dataOTTable [3][0] = "Available"
           this.dataOTTable [3][1] = "31";
           this.dataOTTable [3][2] = "32";
           this.dataOTTable [3][3] = "1";
           this.dataOTTable [3][4] = "17";
        

          
          
      }

      if (this.hospitalName == "BOC")
      {
           console.log ("Creating BOC: ", this.hospitalName);
           this.createNewDataOTTable (4,3);

           this.dataOTTable [0][0] = "Planned"
           this.dataOTTable [0][1] = "6";
           this.dataOTTable [0][2] = "2";

           this.dataOTTable [1][0] = "Previous 24hrs"
           this.dataOTTable [1][1] = "5";
           this.dataOTTable [1][2] = "2";
           
           this.dataOTTable [2][0] = "Capacity"
           this.dataOTTable [2][1] = "10";
           this.dataOTTable [2][2] = "14";
           
           this.dataOTTable [3][0] = "Available"
           this.dataOTTable [3][1] = "4";
           this.dataOTTable [3][2] = "12";
      }

      if (this.hospitalName == "KHUH")
      {

           console.log ("Creating KHUH: ", this.hospitalName);
           this.createNewDataOTTable (4,5);

           this.dataOTTable [0][0] = "Planned"
           this.dataOTTable [0][1] = "31";
           this.dataOTTable [0][2] = "21";
           this.dataOTTable [0][3] = "2";
           this.dataOTTable [0][4] = "15";         


           this.dataOTTable [1][0] = "Previous 24hrs"
           this.dataOTTable [1][1] = "34";
           this.dataOTTable [1][2] = "23";
           this.dataOTTable [1][3] = "1";
           this.dataOTTable [1][4] = "17";
           
           this.dataOTTable [2][0] = "Capacity"
           this.dataOTTable [2][1] = "50";
           this.dataOTTable [2][2] = "36";
           this.dataOTTable [2][3] = "8";
           this.dataOTTable [2][4] = "28";
           
           this.dataOTTable [3][0] = "Available"
           this.dataOTTable [3][1] = "19";
           this.dataOTTable [3][2] = "15";
           this.dataOTTable [3][3] = "6";
           this.dataOTTable [3][4] = "13";
        
          
      }

      if (this.hospitalName == "MKCC")
      {

           console.log ("Creating MKCC: ", this.hospitalName);
           this.createNewDataOTTable (4,4);

           this.dataOTTable [0][0] = "Planned"
           this.dataOTTable [0][1] = "3";
           this.dataOTTable [0][2] = "0";
           this.dataOTTable [0][3] = "16";

           this.dataOTTable [1][0] = "Previous 24hrs"
           this.dataOTTable [1][1] = "4";
           this.dataOTTable [1][2] = "0";
           this.dataOTTable [1][3] = "14";
           
           this.dataOTTable [2][0] = "Capacity"
           this.dataOTTable [2][1] = "9";
           this.dataOTTable [2][2] = "8";
           this.dataOTTable [2][3] = "40";
           
           this.dataOTTable [3][0] = "Available"
           this.dataOTTable [3][1] = "6";
           this.dataOTTable [3][2] = "8";
           this.dataOTTable [3][3] = "24";
      }

      console.log ("this.dataOTTable: ", this.dataOTTable);
      console.log ("this.dataHeader: ", this.dataHeader);
      console.log ("this.dataRow", this.dataRow);


      console.log (">> End OTData: ", this.hospitalName);

    },
    OPDData (calledfrom)
    {
      
      
      console.log (">> OPData: ", this.hospitalName);
      console.log ("called from: ", calledfrom);

      if (this.hospitalName == "RMS")
      {
        
           this.createNewDataOPDTable (3,2);

           this.dataOPDTable [0][0] = "Planned"
           this.dataOPDTable [0][1] = "435";
           this.dataOPDTable [0][2] = "2,329";

           this.dataOPDTable [1][0] = "Previous 24hrs"
           this.dataOPDTable [1][1] = "418";
           this.dataOPDTable [1][2] = "2,321";

           this.dataOPDTable [2][0] = "Capacity"
           this.dataOPDTable [2][1] = "1,200";
           this.dataOPDTable [2][2] = "3,004";

           this.dataOPDTable [3][0] = "Available"
           this.dataOPDTable [3][1] = "765";
           this.dataOPDTable [3][2] = "675";

      }

      if (this.hospitalName == "MH")
      {
           this.createNewDataOPDTable (4,3);

           this.dataOPDTable [0][0] = "Planned"
           this.dataOPDTable [0][1] = "130";
           this.dataOPDTable [0][2] = "1,020";

           this.dataOPDTable [1][0] = "Previous 24hrs"
           this.dataOPDTable [1][1] = "110";
           this.dataOPDTable [1][2] = "1,102";
           
           this.dataOPDTable [2][0] = "Capacity"
           this.dataOPDTable [2][1] = "750";
           this.dataOPDTable [2][2] = "1,500";
           
           this.dataOPDTable [3][0] = "Available"
           this.dataOPDTable [3][1] = "620";
           this.dataOPDTable [3][2] = "480";
        

      }

      if (this.hospitalName == "BOC")
      {
        console.log ("creating BOC");
           this.createNewDataOPDTable (4,3);

           this.dataOPDTable [0][0] = "Planned"
           this.dataOPDTable [0][1] = "90";
           this.dataOPDTable [0][2] = "210";

           this.dataOPDTable [1][0] = "Previous 24hrs"
           this.dataOPDTable [1][1] = "98";
           this.dataOPDTable [1][2] = "184";
           
           this.dataOPDTable [2][0] = "Capacity"
           this.dataOPDTable [2][1] = "152";
           this.dataOPDTable [2][2] = "266";
           
           this.dataOPDTable [3][0] = "Available"
           this.dataOPDTable [3][1] = "62";
           this.dataOPDTable [3][2] = "56";
        

      }

      if (this.hospitalName == "KHUH")
      {
           this.createNewDataOPDTable (4,3);

           this.dataOPDTable [0][0] = "Planned"
           this.dataOPDTable [0][1] = "201";
           this.dataOPDTable [0][2] = "1,115";


           this.dataOPDTable [1][0] = "Previous 24hrs"
           this.dataOPDTable [1][1] = "210";
           this.dataOPDTable [1][2] = "1,039";
           
           this.dataOPDTable [2][0] = "Capacity"
           this.dataOPDTable [2][1] = "300";
           this.dataOPDTable [2][2] = "1,250";
           
           this.dataOPDTable [3][0] = "Available"
           this.dataOPDTable [3][1] = "99";
           this.dataOPDTable [3][2] = "135";
        

      }

      if (this.hospitalName == "MKCC")
      {
           this.createNewDataOPDTable (4,3);

           this.dataOPDTable [0][0] = "Planned"
           this.dataOPDTable [0][1] = "98";
           this.dataOPDTable [0][2] = "192";

           this.dataOPDTable [1][0] = "Previous 24hrs"
           this.dataOPDTable [1][1] = "93";
           this.dataOPDTable [1][2] = "178";
           
           this.dataOPDTable [2][0] = "Capacity"
           this.dataOPDTable [2][1] = "140";
           this.dataOPDTable [2][2] = "240";
           
           this.dataOPDTable [3][0] = "Available"
           this.dataOPDTable [3][1] = "42";
           this.dataOPDTable [3][2] = "48";

           

      }


      console.log ("this.dataOPDTable: ", this.dataOPDTable);
      console.log ("this.dataHeader: ", this.dataHeader);
      console.log ("this.dataRow: ", this.dataRow);
      console.log (">>> End OPDData ");

    },

    DialysisData ()
    {
      console.log (">> DialysisData: ", this.hospitalName);

      if (this.hospitalName == "RMS")
      {
           this.createNewDataDialysisTable (4,2);

           this.dataDialysisTable [0][0] = "Planned"
           this.dataDialysisTable [0][1] = "128";

           this.dataDialysisTable [1][0] = "Previous 24hrs"
           this.dataDialysisTable [1][1] = "130";

           this.dataDialysisTable [2][0] = "Capacity"
           this.dataDialysisTable [2][1] = "149";

           this.dataDialysisTable [3][0] = "Available"
           this.dataDialysisTable [3][1] = "21";

      }

      if (this.hospitalName == "MH")
      {
           this.createNewDataDialysisTable (4,2);

           this.dataDialysisTable [0][0] = "Planned"
           this.dataDialysisTable [0][1] = "116";

           this.dataDialysisTable [1][0] = "Previous 24hrs"
           this.dataDialysisTable [1][1] = "122";
           
           this.dataDialysisTable [2][0] = "Capacity"
           this.dataDialysisTable [2][1] = "116";
           
           this.dataDialysisTable [3][0] = "Available"
           this.dataDialysisTable [3][1] = "0";
        

      }


    },
    RadiationData ()
    {
      console.log ("RadiationData: ", this.hospitalName);
      if (this.hospitalName == "RMS")
      {
           this.createNewDataRadiationTable (4,2);

           this.dataRadiationTable [0][0] = "Episodes"
           this.dataRadiationTable [0][1] = "32";

           this.dataRadiationTable [1][0] = "Previous 24hrs"
           this.dataRadiationTable [1][1] = "39";

           this.dataRadiationTable [2][0] = "Capacity"
           this.dataRadiationTable [2][1] = "48";

           this.dataRadiationTable [3][0] = "Available"
           this.dataRadiationTable [3][1] = "16";

      }

      if (this.hospitalName == "BOC")
      {
           this.createNewDataRadiationTable (4,2);

           this.dataRadiationTable [0][0] = "Episodes"
           this.dataRadiationTable [0][1] = "32";

           this.dataRadiationTable [1][0] = "Previous 24 hrs"
           this.dataRadiationTable [1][1] = "39";
           
           this.dataRadiationTable [2][0] = "Capacity"
           this.dataRadiationTable [2][1] = "48";
           
           this.dataRadiationTable [3][0] = "Available"
           this.dataRadiationTable [3][1] = "16";
        

      }


    },
    ChemoData ()
    {
      console.log ("ChemoData: ", this.hospitalName);
      if (this.hospitalName == "RMS")
      {
           this.createNewDataChemoTable (4,2);

           this.dataChemoTable [0][0] = "Episodes"
           this.dataChemoTable [0][1] = "90";

           this.dataChemoTable [1][0] = "Previous 24hrs"
           this.dataChemoTable [1][1] = "93";

           this.dataChemoTable [2][0] = "Capacity"
           this.dataChemoTable [2][1] = "99";

           this.dataChemoTable [3][0] = "Available"
           this.dataChemoTable [3][1] = "9";

      }

      if (this.hospitalName == "BOC")
      {
           this.createNewDataChemoTable (4,2);

           this.dataChemoTable [0][0] = "Episodes"
           this.dataChemoTable [0][1] = "90";

           this.dataChemoTable [1][0] = "Previous 24 hrs"
           this.dataChemoTable [1][1] = "93";
           
           this.dataChemoTable [2][0] = "Capacity"
           this.dataChemoTable [2][1] = "99";
           
           this.dataChemoTable [3][0] = "Available"
           this.dataChemoTable [3][1] = "3";
        

      }


    },
    PallativeData ()
    {
      console.log ("PallativeData: ", this.hospitalName);
      if (this.hospitalName == "RMS")
      {
           this.createNewDataPallativeTable (2,2);

           this.dataPallativeTable [0][0] = "Impatients"
           this.dataPallativeTable [0][1] = "39";

           this.dataPallativeTable [1][0] = "Home Care"
           this.dataPallativeTable [1][1] = "0";

      }

      if (this.hospitalName == "BOC")
      {
        this.createNewDataPallativeTable (2,2);

          this.dataPallativeTable [0][0] = "Impatients"
          this.dataPallativeTable [0][1] = "39";

          this.dataPallativeTable [1][0] = "Home Care"
          this.dataPallativeTable [1][1] = "0";

      }


    },
    RadiologyData ()
    {
      console.log (">> Radialology Data: ", this.hospitalName);
      if (this.hospitalName == "MKCC")
      {
        this.createNewDataRadiologyTable (4,8);

this.dataRadiologyTable [0][0] = "Planned"
this.dataRadiologyTable [0][1] = "10";
this.dataRadiologyTable [0][2] = "13";
this.dataRadiologyTable [0][3] = "9";
this.dataRadiologyTable [0][4] = "7";
this.dataRadiologyTable [0][5] = "1";
this.dataRadiologyTable [0][6] = "1";
this.dataRadiologyTable [0][7] = "14";
//this.dataRadiologyTable [0][8] = "14";

this.dataRadiologyTable [1][0] = "Previous 24hrs"
this.dataRadiologyTable [1][1] = "12";
this.dataRadiologyTable [1][2] = "9";
this.dataRadiologyTable [1][3] = "10";
this.dataRadiologyTable [1][4] = "5";
this.dataRadiologyTable [1][5] = "0";
this.dataRadiologyTable [1][6] = "1";
this.dataRadiologyTable [1][7] = "17";
//this.dataRadiologyTable [1][8] = "14";


this.dataRadiologyTable [2][0] = "Capacity"
this.dataRadiologyTable [2][1] = "24";
this.dataRadiologyTable [2][2] = "36";
this.dataRadiologyTable [2][3] = "12";
this.dataRadiologyTable [2][4] = "14";
this.dataRadiologyTable [2][5] = "6";
this.dataRadiologyTable [2][6] = "8";
this.dataRadiologyTable [2][7] = "40";
//this.dataRadiologyTable [2][8] = "14";

this.dataRadiologyTable [3][0] = "Available"
this.dataRadiologyTable [3][1] = "14";
this.dataRadiologyTable [3][2] = "23";
this.dataRadiologyTable [3][3] = "3";
this.dataRadiologyTable [3][4] = "7";
this.dataRadiologyTable [3][5] = "5";
this.dataRadiologyTable [3][6] = "7";
this.dataRadiologyTable [3][7] = "26";
//this.dataRadiologyTable [3][8] = "14";

      }

      if (this.hospitalName == "MH")
      {
        this.createNewDataRadiologyTable (4,8);

this.dataRadiologyTable [0][0] = "Planned"
this.dataRadiologyTable [0][1] = "58";
this.dataRadiologyTable [0][2] = "40";
this.dataRadiologyTable [0][3] = "24";
this.dataRadiologyTable [0][4] = "11";
this.dataRadiologyTable [0][5] = "4";
this.dataRadiologyTable [0][6] = "2";
this.dataRadiologyTable [0][7] = "3";
//this.dataRadiologyTable [0][8] = "14";

this.dataRadiologyTable [1][0] = "Previous 24hrs"
this.dataRadiologyTable [1][1] = "65";
this.dataRadiologyTable [1][2] = "40";
this.dataRadiologyTable [1][3] = "24";
this.dataRadiologyTable [1][4] = "11";
this.dataRadiologyTable [1][5] = "4";
this.dataRadiologyTable [1][6] = "3";
this.dataRadiologyTable [1][7] = "3";
//this.dataRadiologyTable [1][8] = "14";


this.dataRadiologyTable [2][0] = "Capacity"
this.dataRadiologyTable [2][1] = "192";
this.dataRadiologyTable [2][2] = "54";
this.dataRadiologyTable [2][3] = "24";
this.dataRadiologyTable [2][4] = "36";
this.dataRadiologyTable [2][5] = "8";
this.dataRadiologyTable [2][6] = "8";
this.dataRadiologyTable [2][7] = "6";
//this.dataRadiologyTable [2][8] = "14";

this.dataRadiologyTable [3][0] = "Available"
this.dataRadiologyTable [3][1] = "134";
this.dataRadiologyTable [3][2] = "14";
this.dataRadiologyTable [3][3] = "0";
this.dataRadiologyTable [3][4] = "25";
this.dataRadiologyTable [3][5] = "4";
this.dataRadiologyTable [3][6] = "6";
this.dataRadiologyTable [3][7] = "3";
//this.dataRadiologyTable [3][8] = "14";

    }

      if (this.hospitalName == "BOC")
      {
        this.createNewDataRadiologyTable (4,4);

this.dataRadiologyTable [0][0] = "Planned"
this.dataRadiologyTable [0][1] = "9";
this.dataRadiologyTable [0][2] = "8";
this.dataRadiologyTable [0][3] = "8";

this.dataRadiologyTable [1][0] = "Previous 24hrs"
this.dataRadiologyTable [1][1] = "8";
this.dataRadiologyTable [1][2] = "9";
this.dataRadiologyTable [1][3] = "8";


this.dataRadiologyTable [2][0] = "Capacity"
this.dataRadiologyTable [2][1] = "24";
this.dataRadiologyTable [2][2] = "12";
this.dataRadiologyTable [2][3] = "16";

this.dataRadiologyTable [3][0] = "Available"
this.dataRadiologyTable [3][1] = "15";
this.dataRadiologyTable [3][2] = "4";
this.dataRadiologyTable [3][3] = "8";

      }

      if (this.hospitalName == "KHUH")
      {
           this.createNewDataRadiologyTable (4,8);

           this.dataRadiologyTable [0][0] = "Planned"
           this.dataRadiologyTable [0][1] = "123";
           this.dataRadiologyTable [0][2] = "31";
           this.dataRadiologyTable [0][3] = "20";
           this.dataRadiologyTable [0][4] = "27";      
           this.dataRadiologyTable [0][5] = "9";
           this.dataRadiologyTable [0][6] = "7";
           this.dataRadiologyTable [0][7] = "4";    


           this.dataRadiologyTable [1][0] = "Previous 24hrs"
           this.dataRadiologyTable [1][1] = "149";
           this.dataRadiologyTable [1][2] = "39";
           this.dataRadiologyTable [1][3] = "21";
           this.dataRadiologyTable [1][4] = "34";
           this.dataRadiologyTable [1][5] = "8";
           this.dataRadiologyTable [1][6] = "8";
           this.dataRadiologyTable [1][7] = "3";
           
           this.dataRadiologyTable [2][0] = "Capacity"
           this.dataRadiologyTable [2][1] = "192";
           this.dataRadiologyTable [2][2] = "48";
           this.dataRadiologyTable [2][3] = "24";
           this.dataRadiologyTable [2][4] = "36";
           this.dataRadiologyTable [2][5] = "10";
           this.dataRadiologyTable [2][6] = "18";
           this.dataRadiologyTable [2][7] = "16";
           
           this.dataRadiologyTable [3][0] = "Available"
           this.dataRadiologyTable [3][1] = "69";
           this.dataRadiologyTable [3][2] = "17";
           this.dataRadiologyTable [3][3] = "4";
           this.dataRadiologyTable [3][4] = "9";
           this.dataRadiologyTable [3][5] = "1";
           this.dataRadiologyTable [3][6] = "11";
           this.dataRadiologyTable [3][7] = "12";
        

      }

      if (this.hospitalName == "RMS")
      {
        this.createNewDataRadiologyTable (4,10);

this.dataRadiologyTable [0][0] = "Planned"
this.dataRadiologyTable [0][1] = "200";
this.dataRadiologyTable [0][2] = "84";
this.dataRadiologyTable [0][3] = "61";
this.dataRadiologyTable [0][4] = "53";
this.dataRadiologyTable [0][5] = "14";
this.dataRadiologyTable [0][6] = "10";
this.dataRadiologyTable [0][7] = "7";
this.dataRadiologyTable [0][8] = "14";

this.dataRadiologyTable [1][0] = "Previous 24hrs"
this.dataRadiologyTable [1][1] = "234";
this.dataRadiologyTable [1][2] = "88";
this.dataRadiologyTable [1][3] = "64";
this.dataRadiologyTable [1][4] = "58";
this.dataRadiologyTable [1][5] = "12";
this.dataRadiologyTable [1][6] = "12";
this.dataRadiologyTable [1][7] = "5";
this.dataRadiologyTable [1][8] = "17";


this.dataRadiologyTable [2][0] = "Capacity"
this.dataRadiologyTable [2][1] = "432";
this.dataRadiologyTable [2][2] = "138";
this.dataRadiologyTable [2][3] = "72";
this.dataRadiologyTable [2][4] = "102";
this.dataRadiologyTable [2][5] = "24";
this.dataRadiologyTable [2][6] = "34";
this.dataRadiologyTable [2][7] = "22";
this.dataRadiologyTable [2][8] = "40";

this.dataRadiologyTable [3][0] = "Available"
this.dataRadiologyTable [3][1] = "232";
this.dataRadiologyTable [3][2] = "54";
this.dataRadiologyTable [3][3] = "11";
this.dataRadiologyTable [3][4] = "49";
this.dataRadiologyTable [3][5] = "10";
this.dataRadiologyTable [3][6] = "24";
this.dataRadiologyTable [3][7] = "15";
this.dataRadiologyTable [3][8] = "26";

      }

      console.log ("this.dataRadiologyTable: ", this.dataRadiologyTable);
      console.log ("this.dataHeader: ", this.dataHeader);
      console.log ("this.dataRow: ", this.dataRow);



     

    },
    ERData ()
    {
      console.log ("ERData: ", this.hospitalName);
      if (this.hospitalName == "MKCC")
      {
        this.createNewDataERTable (3,2);

this.dataERTable [0][0] = "Presentations"
this.dataERTable [0][1] = "510";

this.dataERTable [1][0] = "Admissions"
this.dataERTable [1][1] = "49";


this.dataERTable [2][0] = "Medical Admissions"
this.dataERTable [2][1] = "23";

      }

      if (this.hospitalName == "MH")
      {
        this.createNewDataERTable (3,2);

this.dataERTable [0][0] = "Presentations"
this.dataERTable [0][1] = "224";

this.dataERTable [1][0] = "Admissions"
this.dataERTable [1][1] = "20.16";


this.dataERTable [2][0] = "Medical Admissions"
this.dataERTable [2][1] = "8.87";

        

                }

      if (this.hospitalName == "BOC")
      {
        this.createNewDataERTable (3,2);

this.dataERTable [0][0] = "Presentations"
this.dataERTable [0][1] = "1";

this.dataERTable [1][0] = "Admissions"
this.dataERTable [1][1] = "1";


this.dataERTable [2][0] = "Medical Admissions"
this.dataERTable [2][1] = "1";


      }

      if (this.hospitalName == "KHUH")
      {
           this.createNewDataERTable (3,2);

           this.dataERTable [0][0] = "Presentations"
           this.dataERTable [0][1] = "276";


           this.dataERTable [1][0] = "Admissions";
           this.dataERTable [1][1] = "25";
           
           this.dataERTable [2][0] = "Medical Admissions"
           this.dataERTable [2][1] = "11";
       

      }

      if (this.hospitalName == "RMS")
      {
        this.createNewDataERTable (3,2);

this.dataERTable [0][0] = "Presentations"
this.dataERTable [0][1] = "510";

this.dataERTable [1][0] = "Admissions"
this.dataERTable [1][1] = "49";


this.dataERTable [2][0] = "Medical Admissions"
this.dataERTable [2][1] = "23";


      }
    },

    style(k) {
      //console.log ("style k = ", this.dataHeader[k]);

      //console.log ("dataHeader: ", this.dataHeader.length);
      ///console.log ("dataHeader: ", this.dataHeader);

      return this.dataHeader[k];
    },
    tableDataRow(k) {
      //console.log ("tableDataRow k= ", k);
      return this.dataRow[k];
    },

    getDataColour (value)
    {
      var colourString = "";

      if (value < 10.00)
      {
        colourString = "green-colour"; // red
      }
      else if (value > 10.01 && value < 20.01)
      {
        colourString = "amber-colour";
      }
      else if (value > 20.01)
      {
        colourString = "red-colour";
      }

      console.log ("colourString: ", colourString)

      return colourString;

    },   
    setSpeciality ()
    {        
        if (typeof this.selectedNewHospital.string != "undefined") 
        {
          this.apiPayload.hospital = this.selectedNewHospital.string;
        }
        else if (typeof this.selectedNewHospital != "undefined") 
        {
          this.apiPayload.hospital = this.selectedNewHospital;
        }

        console.log ("setSpeciality: this.apiPayload.hospital: ", this.apiPayload.hospital);

        if (typeof this.selectedYear.year != "undefined") 
        {
          this.apiPayload.year = this.selectedYear.year;
        }
        else if (typeof this.selectedYear != "undefined") 
        {
          this.apiPayload.year = this.selectedYear;
        }

        console.log ("setSpeciality: this.apiPayload.year: ", this.apiPayload.year);

        if (typeof this.selectedSpeciality.speciality != "undefined") 
        {
          this.apiPayload.speciality = this.selectedSpeciality.speciality;
        }
        else if (typeof this.selectedSpeciality != "undefined") 
        {
          this.apiPayload.speciality = this.selectedSpeciality;
        }

        console.log ("setSpeciality: this.apiPayload.speciality: ", this.apiPayload.speciality);

        this.apiPayload.apiString = "";
        this.apiPayload.reference = "5";

        //this.fetchSpecialityData ();

    },
    setHospital ()
    {
        this.specialityHospital (this.selectedNewHospital.speciality);

        this.apiPayload.year = this.selectedYear.year;
        this.apiPayload.speciality = this.selectedSpeciality.speciality;
        
        this.apiPayload.apiString = "";
        this.apiPayload.reference = "6";

        this.fetchSpecialityData ();

    },
    setNewHospital ()
    {
        if (typeof this.selectedNewHospital.string != "undefined") 
        {
          this.apiPayload.hospital = this.selectedNewHospital.string;
        }
        else if (typeof this.selectedNewHospital != "undefined") 
        {
          this.apiPayload.hospital = this.selectedNewHospital;
        }


       console.log ("setNewHospital: this.apiPayload.hospital: ", this.apiPayload.hospital);

        if (typeof this.selectedYear.year != "undefined") 
        {
          this.apiPayload.year = this.selectedYear.year;
        }
        else if (typeof this.selectedYear != "undefined") 
        {
          this.apiPayload.year = this.selectedYear;
        }

        console.log ("setNewHospital: this.apiPayload.year: ", this.apiPayload.year);

        if (typeof this.selectedSpeciality.speciality != "undefined") 
        {
          this.apiPayload.speciality = this.selectedSpeciality.speciality;
        }
        else if (typeof this.selectedSpeciality != "undefined") 
        {
          this.apiPayload.speciality = this.selectedSpeciality;
        }

        console.log ("setNewHospital: this.apiPayload.speciality: ", this.apiPayload.speciality);

        this.apiPayload.apiString = "";
        this.apiPayload.reference = "99";

        this.fetchSpecialityData ();

    },
    fetchSpecialityData() {
      //console.log ("api ==================================");
      //console.log("api.speciality = ", this.apiPayload.speciality);
      //console.log("api.year = ", this.apiPayload.year);
      //console.log("api.hospital = ", this.apiPayload.hospital);
      //console.log("api.apiString = ", this.apiPayload.apiString);
      
      //console.log("api.apiString =", this.apiPayload.apiString);

      this.$store
        .dispatch("GetSpecialityDataByYear", this.apiPayload)
        .then((data) => {
          console.log("data", data);

          this.logData ();
        });
    },
    
    logData() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        console.log(
          "logData: length: ",
          this.$store.state.prototypedata.specialityData.length
        );
        for (
          var i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          console.log(
            "item[",
            i,
            "]=",
            this.$store.state.prototypedata.specialityData[i]
          );
        }
      }
    },
    fetchData(speciality) {
      //console.log ("fetchData: ", speciality.apiString);

      if (typeof speciality.apiString != "undefined") 
      {
        this.$store
          .dispatch("attemptFetchStageThree", speciality)
          .then((data) => {
            //console.log("stage 3 - fetched data:");
            if (typeof data == "undefined") 
            {
              console.log(data);
            }

            //this.fillData(this.activeSummary, this.activeStageYear);
          });
        }
        //else
        //{
          //console.log ("fetchData: ", speciality);
          //console.log ("was abandoned.");
        //}
    }, 
    getMongoDBData() {
      //console.log ("SummaryMain3.vue: getMongoDBData: this.apiPayload: ", this.apiPayload);
      this.$store.dispatch("GetData", this.apiPayload).then((data) => {
        console.log("data", data);
      });
    },
    specialityHeader(header) {
      return header;
    },
    editActiveStageSummary(activeSummary) {
      this.activeSummary = activeSummary;
    },
    editHospitalName(specialityTypeName) {

      console.log ("edit: ", specialityTypeName);

      this.hospitalName = specialityTypeName;
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    getIndex(dataStruct, item) {
      var id = -1;
      var i = 0;

    

      if (typeof dataStruct != "undefined") {
        //console.log("datastruct", dataStruct);
        if (dataStruct.length > 0) {
          for (i = 0; i < dataStruct.length; i++) {
            if (item in dataStruct[i]) {
              id = i;
            }
          }
        }
      } else {
        //console.log("GetIndex: dataStruct is undefined");
      }

      return id;
    },
    searchIndex(dataStruct, item) {
      var id = -1;
      var i = 0;

      if (typeof dataStruct != "undefined") {
        //console.log("datastruct", dataStruct);
        if (dataStruct.length > 0) {
          for (i = 0; i < dataStruct.length; i++) {
            //console.log("dataStruc[i]", dataStruct[i]);
            if (item == dataStruct[i]) {
              //console.log("item == dataStruct!");
              id = i;
            }
          }
        }
      } else {
        //console.log("GetIndex: dataStruct is undefined");
      }

      return id;
    },      
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
       
    // 
    setSpecialityName(specialityName) {
      this.activeSpeciality = specialityName;
    },
    //
    editImprovementType(specialityTypeName) {
      //console.log ("editImprovement: ", specialityTypeName);
      if (specialityTypeName == "Diagnostics") {
        this.improvementType = "diagnostics";
      }
      if (specialityTypeName == "Outpatient Clinics") {
        this.improvementType = "OPD";
      }
      if (specialityTypeName == "OR") {
        this.improvementType = "OR";
      }
      if (specialityTypeName == "Emergency Room (ER)") {
        this.improvementType = "ER";
      }

      if (specialityTypeName == "Bed Flow") {
        this.improvementType = "bedflow";
      }

      //console.log(
//        "editImprovementType (",
  //      specialityTypeName,
    //    ")=",
      //  this.improvementType
      //);
    },
    editStageSummary(stageSummary) {
      this.stageSummary = stageSummary;


      if (this.stageSummary == "specialities") {
        //console.log(
        //  " this.selectedSpecialityArea.selected",
        //  this.selectedSpecialityArea
       // );

        //this.editSpeciality
        //this.editSpecialityArea(this.selectedSpecialityArea);
        //this.specialityApi = this.getSpecialityAPI();        
        //this.apiPayload.apiString = this.specialityApi;

      }

      if (this.stageSummary == "opImprov") {
       // console.log(
       //   " this.improvementSpecialityAra.selected",
       //   this.improvementSpecialityArea
      //  );

        this.editImprovementType(this.improvementSpecialityArea);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      }
    },
  },
};
</script>
<style>
.red-colour {
  background-color:  #FF0000;
}

.green-colour {
  background-color: #008000;
}

.amber-colour {
  background-color: #ffbf00;
}

.chartcontainer3 {
  border: 1px solid;
  border-color: #e5e9f2;
  height: 450px;
  display: inline-block;
  width: 600px; /* was 26 rem */
  margin-right: 20px;
}
.title_text3 {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.chart_view3 {
  width: 600px;
  height: 400px;
  /*border: solid #5b6dcd 10px;*/
  box-sizing: content-box;
}
</style>
