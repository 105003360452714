<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">
          Business Plan
        </h1>
      </div>

      <div class="validation-cards--item cert no-hover">
        <span class="card-header">Certification Status</span>
        <div class="card-icon" margin-top="0px">
          <img
            src="@/assets/img/circular-label-with-certified-stamp.png"
            alt=""
          />
          <span class="card-total">100% Validation</span>
        </div>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Bahrain</span>
        </div>
      </div>
    </div>

    <nav class="route-nav">
      <ul>
        <li
          @click="editStageSummary('stage')"
          :class="stageSummary == 'stage' ? 'active' : ''"
        >
          <span>Strategic Objectives</span>
        </li>
        <li
          @click="editStageSummary('sysstrat')"
          :class="stageSummary == 'sysstrat' ? 'active' : ''"
        >
          <span>Strategic Factors</span>
        </li>
        <li
          @click="editStageSummary('strat')"
          :class="stageSummary == 'strat' ? 'active' : ''"
        >
          <span>Strategic Opportunities</span>
        </li>
        <li
          @click="editStageSummary('aim')"
          :class="stageSummary == 'aim' ? 'active' : ''"
        >
          <span>Aim</span>
        </li>
        <li
          @click="editStageSummary('planexecution')"
          :class="stageSummary == 'planexecution' ? 'active' : ''"
        >
          <span>Execution</span>
        </li>
        <li
          @click="editStageSummary('review')"
          :class="stageSummary == 'review' ? 'active' : ''"
        >
          <span>Execution Phase</span>
        </li>
      </ul>
    </nav>

    <div v-if="stageSummary == 'aim'">
      <div class="route-content">
        <div class="new_stat-cards">
          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">4.1</span>
            <span class="card-total3">The aim of this strategic plan is to outline the immediate key steps required to fully integrate the 4 hospital sites comprising the BDF Royal Medical Services in order to deliver world-class healthcare services as an acknowledged Centre of Excellence to all beneficiaries and value for money to the Kingdom of Bahrain as part of a long-term 10-year Business Plan.</span>            
          </div>
        </div>
      </div>
    </div>

    <div v-if="stageSummary == 'review'">
      <div class="route-content">
        <div class="new_stat-cards">

            <div class="hello1">Stage 1 - Review and Evaluation</div>

            <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Item</th>
                    <th class="border px-4 py-2">Action</th>
                    <th class="border px-4 py-2">Responsible</th>
                    <th class="border px-4 py-2">Timeline</th>     
                    <th class="border px-4 py-2">Remarks</th>     
                  </tr>
                </thead>
                <tbody>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">1</td>
                    <td class="border" style="padding: 10px;">Financial System Outline Plan</td>                    
                    <td class="border" style="padding: 10px;">HSi/Finance Teams</td>   
                    <td class="border" style="padding: 10px;">18 May 23</td> 
                    <td class="border" style="padding: 10px;">Complete - See Financial Plan Annex A</td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;">IT System Outline Plan</td>                    
                    <td class="border" style="padding: 10px;">HSi/IT Teams</td>   
                    <td class="border" style="padding: 10px;">18 May 23</td>  
                    <td class="border" style="padding: 10px;">Complete – See IT Plan Annex B
                      Detailed IT Plan – Ongoing and in Stage 2 (In Progress) </td>                                        
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">3</td>
                    <td class="border" style="padding: 10px;">Costing, Coding and Revenue Cycle Management Outline Plan</td>                    
                    <td class="border" style="padding: 10px;">HSi/Finance</td>   
                    <td class="border" style="padding: 10px;">18 May 23</td>                                        
                    <td class="border" style="padding: 10px;">Complete – See CCRCM Plan, Annex D</td>  
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">4</td>
                    <td class="border" style="padding: 10px;">Operational Improvement Outline Plan</td>                    
                    <td class="border" style="padding: 10px;">HSi</td>   
                    <td class="border" style="padding: 10px;">18 May 23</td>   
                    <td class="border" style="padding: 10px;">Complete – See Operational Improvement Plan, Annex E</td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;">Workforce Outline Plan</td>                    
                    <td class="border" style="padding: 10px;">HSi</td>   
                    <td class="border" style="padding: 10px;">18 May 23</td>  
                    <td class="border" style="padding: 10px;">Complete – See Workforce Strategic Planning, Annex F</td>                                        
                  </tr>	      
                  
                  
                </tbody>
              </table> 

              <div class="hello1">Stage 2 - System Integration Planning</div>

             <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Item</th>
                    <th class="border px-4 py-2">Key Strategic Area</th>
                    <th class="border px-4 py-2">Action</th>
                    <th class="border px-4 py-2">Responsible</th>
                    <th class="border px-4 py-2">Timeline</th>     
                    <th class="border px-4 py-2">Remarks</th>     
                  </tr>
                </thead>
                <tbody>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">1</td>
                    <td class="border" style="padding: 10px;">IT</td>                    
                    <td class="border" style="padding: 10px;">Integration Plan of Selected HIS EPR across all hospitals based on HOPE (HIS) and Developed EPR</td>   
                    <td class="border" style="padding: 10px;">DM/MH/MD/DM</td> 
                    <td class="border" style="padding: 10px;">(1)	October 2023
(2)	March 2023</td>  
                    <td class="border" style="padding: 10px;"></td>                                     
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;">Finance - Costing</td>                    
                    <td class="border" style="padding: 10px;">Establishment of universal costing mechanism plan applicable across all system hospitals.</td>   
                    <td class="border" style="padding: 10px;">HSi RB/GB/MG/MD</td>  
                    <td class="border" style="padding: 10px;">30 June 2023</td>    
                    <td class="border" style="padding: 10px;">All new Coding and Pricing (Stage 1) complete by 04 May 23.</td>                                     
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">3</td>
                    <td class="border" style="padding: 10px;">3	Finance - Coding	</td>                    
                    <td class="border" style="padding: 10px;">Establishment of coding plan across all system hospitals and synchronised within the new Financial ERP</td>   
                    <td class="border" style="padding: 10px;">HSi RB/GB/MG/MD</td>                                        
                    <td class="border" style="padding: 10px;">30 June 2023</td>  
                    <td class="border" style="padding: 10px;">Benchmarked with HRG/DRG and SCH plans</td> 
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">4</td>
                    <td class="border" style="padding: 10px;">Operations</td>                    
                    <td class="border" style="padding: 10px;">Establish Workflow Plan for Operational Efficiency Improvements: Operating Rooms, Emergency Rooms, Bed Flow and establishment of SOPs for Operational Planning, Execution and Business as Usual Management</td>   
                    <td class="border" style="padding: 10px;">MG/BN</td>   
                    <td class="border" style="padding: 10px;">30 June 2023</td> 
                    <td class="border" style="padding: 10px;">To attach</td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;">Operations</td>                    
                    <td class="border" style="padding: 10px;">Plan full operational rollout of System Operations Command and Control Centre (SOCC)			 
</td>   
                    <td class="border" style="padding: 10px;">HSi MG/RB/GB</td>   
                    <td class="border" style="padding: 10px;">Initial Set-up (Phase 1): 16 May 

(Phase 2): 30 June</td> 
                    <td class="border" style="padding: 10px;">Outline Document attached at Annex</td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Procurement</td>   
                    <td class="border" style="padding: 10px;"></td>   
                    <td class="border" style="padding: 10px;"></td> 
                    <td class="border" style="padding: 10px;">Outline Document attached at Annex </td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Demand Capacity Alignment</td>   
                    <td class="border" style="padding: 10px;"></td>   
                    <td class="border" style="padding: 10px;"></td> 
                    <td class="border" style="padding: 10px;">Summary Document attached at Annex</td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Operational Flow</td>   
                    <td class="border" style="padding: 10px;"></td>   
                    <td class="border" style="padding: 10px;"></td> 
                    <td class="border" style="padding: 10px;">Flow Protocols attached at Annex</td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Kidney Dialysis Unit (BDF)</td>   
                    <td class="border" style="padding: 10px;"></td>   
                    <td class="border" style="padding: 10px;"></td> 
                    <td class="border" style="padding: 10px;">Outline Plan attached at Annex</td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Develop Hospital Business Plans			</td>   
                    <td class="border" style="padding: 10px;">HSi/Commanders</td>   
                    <td class="border" style="padding: 10px;">July 31</td> 
                    <td class="border" style="padding: 10px;"></td>                                       
                  </tr>	
                 <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Develop Service Line Capability across the Group		</td>   
                    <td class="border" style="padding: 10px;">HSi/Clinical Leads	</td>   
                    <td class="border" style="padding: 10px;">August 31</td> 
                    <td class="border" style="padding: 10px;"></td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;">Workforce</td>   
                    <td class="border" style="padding: 10px;">Nursing Demand vs Current</td>   
                    <td class="border" style="padding: 10px;">HSi MG/RB/BN</td> 
                    <td class="border" style="padding: 10px;"></td>                                       
                  </tr>	
                  
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Consultant Projecting and Jobplans</td>   
                    <td class="border" style="padding: 10px;"></td>   
                    <td class="border" style="padding: 10px;"></td> 
                    <td class="border" style="padding: 10px;"></td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">HQ Centralisation</td>   
                    <td class="border" style="padding: 10px;">MG</td>   
                    <td class="border" style="padding: 10px;"></td> 
                    <td class="border" style="padding: 10px;"></td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Establish total Workforce Strategy including Recruitment, Training Needs, Upskilling, and Equalization</td>   
                    <td class="border" style="padding: 10px;">MG</td>   
                    <td class="border" style="padding: 10px;"></td> 
                    <td class="border" style="padding: 10px;"></td>                                       
                  </tr>	    
                </tbody>
              </table> 

            
            <div class="hello1">Stage 3 - System Improvement Implementation</div>

            <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Item</th>
                    <th class="border px-4 py-2">Key Strategic Area</th>
                    <th class="border px-4 py-2">Action</th>
                    <th class="border px-4 py-2">Responsible</th>
                    <th class="border px-4 py-2">Timeline</th>     
                    <th class="border px-4 py-2">Remarks</th>     
                  </tr>
                </thead>
                <tbody>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">1</td>
                    <td class="border" style="padding: 10px;">IT</td>                    
                    <td class="border" style="padding: 10px;">Integration Plan of Selected HIS EPR across all hospitals based on HOPE (HIS) and Developed EPR</td>   
                    <td class="border" style="padding: 10px;">DM/MH/MD/DM</td>   
                    <td class="border" style="padding: 10px;">(1)	October 2023
(2)	March 2023</td> 
                    <td class="border" style="padding: 10px;"></td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;">Finance - Costing</td>                    
                    <td class="border" style="padding: 10px;">Establishment of universal costing mechanism plan applicable across all system hospitals.</td>   
                    <td class="border" style="padding: 10px;">HSi RB/GB/MG/MD</td>   
                    <td class="border" style="padding: 10px;">30 June 2023</td> 
                    <td class="border" style="padding: 10px;"></td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">3</td>
                    <td class="border" style="padding: 10px;">Finance - Coding</td> 
                    <td class="border" style="padding: 10px;">Establishment of coding plan across all system hospitals and synchronised within the new Financial EPR</td>                    
                    <td class="border" style="padding: 10px;">HSi RB/GB/MG/MD</td>   
                    <td class="border" style="padding: 10px;">30 June 2023</td>   
                    <td class="border" style="padding: 10px;">Benchmarked with HRG/DRG and SCH plans</td> 
                                                          
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">4</td>
                    <td class="border" style="padding: 10px;">Operations</td>                    
                    <td class="border" style="padding: 10px;">Establish Workflow Plan for Operational Efficiency Improvements: Operating Rooms, Emergency Rooms, Bed Flow and establishment of SOPs for Operational Planning, Execution and Business as Usual Management</td>   
                    <td class="border" style="padding: 10px;"></td>   
                    <td class="border" style="padding: 10px;"></td> 
                    <td class="border" style="padding: 10px;"></td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;">Operations</td>                    
                    <td class="border" style="padding: 10px;">Plan full operational rollout of System Operations Command and Control Centre (SOCC)</td>   
                    <td class="border" style="padding: 10px;">HSi MG/RB/GB</td>   
                    <td class="border" style="padding: 10px;">Initial Set-up (Phase 1): 16 May 

(Phase 2): 30 June</td> 
                    <td class="border" style="padding: 10px;">Outline Document attached at Annex</td>                                       
                  </tr>	               
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Procurement</td>   
                    <td class="border" style="padding: 10px;"></td>   
                    <td class="border" style="padding: 10px;"></td> 
                    <td class="border" style="padding: 10px;">Outline Document attached at Annex</td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Demand Capacity Alignment</td>   
                    <td class="border" style="padding: 10px;"></td>   
                    <td class="border" style="padding: 10px;"></td> 
                    <td class="border" style="padding: 10px;">Summary Document attached at Annex</td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Operational Flow</td>   
                    <td class="border" style="padding: 10px;"></td>   
                    <td class="border" style="padding: 10px;"></td> 
                    <td class="border" style="padding: 10px;">Flow Protocols attached at Annex</td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Kidney Dialysis Unit (BDF)</td>   
                    <td class="border" style="padding: 10px;"></td>   
                    <td class="border" style="padding: 10px;"></td> 
                    <td class="border" style="padding: 10px;">Outline Plan attached at Annex </td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;">Workforce</td>                    
                    <td class="border" style="padding: 10px;">Nursing Demand vs Current</td>   
                    <td class="border" style="padding: 10px;">HSi MG/RB/BN</td>   
                    <td class="border" style="padding: 10px;"></td> 
                    <td class="border" style="padding: 10px;"></td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;">Consultant Projecting and Jobplans	</td>                    
                    <td class="border" style="padding: 10px;"></td>   
                    <td class="border" style="padding: 10px;"></td>   
                    <td class="border" style="padding: 10px;"></td> 
                    <td class="border" style="padding: 10px;"></td>                                       
                  </tr>	                  
                </tbody>
              </table> 
        </div>
      </div>
    </div>

    <div v-if="stageSummary == 'planexecution'">
      <div class="route-content">
        <div class="new_stat-cards">
          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">Immediate Focus</span>
            <span class="card-total3">Efficiency, ‘right-sizing,’ Integration, Integrated Workforce Strategy and Target Operating Model (Retention, Targeted Recruitment, Nurse Bank/Agency, Training – upskilling, supernumerary roles, balance workforce/demand/assets). Operational Efficiency, Establish ‘daily eyes-on’ </span>            
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">Strategic</span>
            <span class="card-total3">Insurance model, pricing, growth, income strategy, supply chain leadership, innovation …</span>            
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">Concept</span>
            <span class="card-total3">Stages 1 to 3 of this plan will focus on the evolved integration of the key platform components of the health system: namely Financial and Costing systems, IT and Data, Operations and Workforce.

Stage 4 will focus on the delivery of major strategic changes incorporating optimized workforce, income generation, growth of private health income generation, rationalisation of shared services and additional innovations where identified.
</span>            
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">Method</span>
            <span class="card-total3">Stages 1 to 3 will be delivered consecutively.  Urgent matters will be expedited as required.

Stage 4 elements can be delivered concurrently as dictated by the Commander RMS, and as dictated by strategic and operational risks and opportunities.
</span>            
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">Key Focus Area</span>
            <span class="card-total3">The main effort of this Strategic Business Plan will be the successful implementation of the integration of Financial, Costing and IT systems across the 4 hospital sites.
The concurrent execution of strategic integration and shared services plus operational improvement to deliver maximum efficiency and quality improvement.
</span>            
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">Secondary Focus Areas</span>
            <span class="card-total3">The Development of specific Hospital Business Plans before July 31 that will indicate their plans for the next 5 year period.  These will focus on the improvement of Patient Care and Quality, Financial Efficiency and Performance Management. (HSi will prepare Hospital Business Planning frameworks, including the integration at Clinical Specialty level across the Group).</span>            
          </div>

          <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Item</th>
                    <th class="border px-4 py-2">Action</th>
                    <th class="border px-4 py-2">Responsible</th>
                    <th class="border px-4 py-2">Timeline</th>     
                    <th class="border px-4 py-2">Remarks</th>     
                  </tr>
                </thead>
                <tbody>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">1</td>
                    <td class="border" style="padding: 10px;">Current Asset Demand and Capacity Benchmark  </td>                    
                    <td class="border" style="padding: 10px;">HSi (MG, RB)</td>   
                    <td class="border" style="padding: 10px;">30 April 2023</td> 
                    <td class="border" style="padding: 10px;">Completed April 26 
[Need MKCC data to update.]</td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;">Population Health Demand and Capacity projection (HSi Insight)</td>                    
                    <td class="border" style="padding: 10px;">HSi (MG, RB)</td>   
                    <td class="border" style="padding: 10px;">30 April 2023</td>  
                    <td class="border" style="padding: 10px;">Completed April 18</td>                                        
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">3</td>
                    <td class="border" style="padding: 10px;">Current Financial Budget Analysis</td>                    
                    <td class="border" style="padding: 10px;">HSi (GB, GB)</td>   
                    <td class="border" style="padding: 10px;">04 May 2023</td>                                        
                    <td class="border" style="padding: 10px;">Completed April 24 </td>  
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">4</td>
                    <td class="border" style="padding: 10px;">Project Management System Set-up</td>                    
                    <td class="border" style="padding: 10px;">HSi (MG, RB)</td>   
                    <td class="border" style="padding: 10px;">18 May 2023</td>   
                    <td class="border" style="padding: 10px;">Completed April 23</td>                                       
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;" colspan="5">Burning Platforns</td>
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;">Immediate IT Paper</td>                    
                    <td class="border" style="padding: 10px;">HSi (DM, MG)</td>   
                    <td class="border" style="padding: 10px;">11 April 2023</td>  
                    <td class="border" style="padding: 10px;">Annex A</td>                                        
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">6</td>
                    <td class="border" style="padding: 10px;">KHUH OPD Alignment to enable BOC services</td>                    
                    <td class="border" style="padding: 10px;">HSi (MG)</td>   
                    <td class="border" style="padding: 10px;">10 May 2023</td> 
                    <td class="border" style="padding: 10px;">Plan altered 03 May and ongoing</td>                                         
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">7</td>
                    <td class="border" style="padding: 10px;">KHUH Car Parking Re-Planning</td>                    
                    <td class="border" style="padding: 10px;">HSi (MG, RB)</td>   
                    <td class="border" style="padding: 10px;">18 May 2023</td>  
                    <td class="border" style="padding: 10px;">Ongoing new information</td>                                        
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">8</td>
                    <td class="border" style="padding: 10px;">MKCC Income</td>                    
                    <td class="border" style="padding: 10px;">HSi (GB, RB)</td>   
                    <td class="border" style="padding: 10px;">05 May</td>   
                    <td class="border" style="padding: 10px;">Options for recovering uncovered STEMI and other non-elective patients not covered by insurance.</td>                                       
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">9</td>
                    <td class="border" style="padding: 10px;">Confirmation of Financial Operating System in EPR</td>                    
                    <td class="border" style="padding: 10px;">HSi (GB/MG)</td>   
                    <td class="border" style="padding: 10px;">11 May 2023</td>
                    <td class="border" style="padding: 10px;">Confirmed AlCare to be modified</td>                                          
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">10</td>
                    <td class="border" style="padding: 10px;">Plans for System HQ Integration and Rationalisation</td>                    
                    <td class="border" style="padding: 10px;">HSi (MG/RB)</td>   
                    <td class="border" style="padding: 10px;">11 May 2023</td>                
                    <td class="border" style="padding: 10px;">Outline potential HQ structuring as part of the Stage 2 System Planning</td>                          
                  </tr>                                                                                       
                </tbody>
              </table> 
        </div>
      </div>
    </div>
   
    <div v-if="stageSummary == 'stage'">
      <div class="route-content">
        <div class="new_stat-cards">
          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">1.1</span>
            <span class="card-total3">Transformation of the BDF Royal Medical Services has commenced at the strategic and operational levels.  The first stage completes one week ahead of schedule.</span>            
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">1.2</span>
            <span class="card-total3">The following areas of impact have been identified and immediate actions carried out prior to the finalisation of the long-term transformation planning in Stage 2 and System Improvement in Stage 3:</span>            
          </div>

          <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Item</th>
                    <th class="border px-4 py-2">Impact</th>
                    <th class="border px-4 py-2">Action</th>
                    <th class="border px-4 py-2">Remarks</th>
                    <th class="border px-4 py-2">Income Growth/Cost Reduction</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">1</td>
                    <td class="border" style="padding: 10px;">Identification of Budget Rationalisation through Strategic Demand Capacity Plan</td>      
                    <td class="border" style="padding: 10px;">Full Group Health Demand modelled against Required Capacity</td>  
                    <td class="border" style="padding: 10px;">Targetted efficiency areas for planning and implementation to improve Quality and reduce costs, increasing income where possible</td>  
                    <td class="border" style="padding: 10px;">Yes, to Confirm Stage 2</td>
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;">IT Integration Plan</td>                    
                    <td class="border" style="padding: 10px;">Plan established of full IT Systems integration.</td>   
                    <td class="border" style="padding: 10px;">Future Costs Reduced immediately by minimum c. $5M per annum.
Future Cost Reduction based on minimal licensing fees.
Future Cost Reduction based on reduced levels staffing due to Centralisation plan.
Security incorporated due to use of BDF RMS internal built systems.</td>   
                    <td class="border" style="padding: 10px;">Yes, to Confirm Stage 2</td>                  
                  </tr>
                 
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">3</td>
                    <td class="border" style="padding: 10px;">Operational Improvement</td>
                    <td class="border" style="padding: 10px;">Plan established to improve the Quality and Efficiency of Clinical and Non-Clinical Operations.</td>
                    <td class="border" style="padding: 10px;">Plan to improve efficiency and cost reduction in the improvement of Operational Planning and Execution of Operating Rooms, Emergency, OPD.</td>                       
                    <td class="border" style="padding: 10px;">Yes, to Confirm Stage 2</td>
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">4</td>
                    <td class="border" style="padding: 10px;">Workforce Rationalisation</td>
                    <td class="border" style="padding: 10px;">Intensive Population Health Modelling confirms workforce imbalance in certain areas and specialisations.</td>
                    <td class="border" style="padding: 10px;">Identified areas to ‘Equalise’ resources.
Identified improvement to Recruitment based on detailed knowledge of Demand Vs Capacity.Confirmed ability to reduce Locum and Agency Costs.</td>                       
                    <td class="border" style="padding: 10px;">Yes, to Confirm Stage 2</td>
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;">Group Headquarters rationalisation</td>
                    <td class="border" style="padding: 10px;">Established Outline Plan for Centralised Group HQ</td>
                    <td class="border" style="padding: 10px;">Shared HQ services for HR, Finance, </td>                       
                    <td class="border" style="padding: 10px;">Yes, to Confirm Stage 2</td>
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">6</td>
                    <td class="border" style="padding: 10px;">Shared Services rationalisation</td>
                    <td class="border" style="padding: 10px;">Outline Shared Services plan established</td>
                    <td class="border" style="padding: 10px;">Shared services for Laboratories, Facilities Management, Pharmacy, Supplies and Procurement, IT, Quality Management.</td>                       
                    <td class="border" style="padding: 10px;">Yes, to Confirm Stage 2</td>
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">7</td>
                    <td class="border" style="padding: 10px;">Integrated Group Command Control and Communications Daily Quality Control</td>
                    <td class="border" style="padding: 10px;">Established Integrated C3 Daily System Action Network</td>
                    <td class="border" style="padding: 10px;">Operational Grip plan to lead improvement of Hospital operations through C3 (Command, Control and Communications) Daily Dashboard and Quality Control network.</td>                       
                    <td class="border" style="padding: 10px;">Quality</td>
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">8</td>
                    <td class="border" style="padding: 10px;">Identification of Income Growth Opportunities</td>
                    <td class="border" style="padding: 10px;">Capacity Modelling indicates spare capacity available</td>
                    <td class="border" style="padding: 10px;">Plans to encourage increased income: Private market new services, new Products (e.g., Wellness checks, international Surgery operators, health tourism in-shoring, reduced self-pay plans
Confirm the Awali Support Services Commercial Plan.
</td>                       
                    <td class="border" style="padding: 10px;">Income</td>
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">9</td>
                    <td class="border" style="padding: 10px;">Identification of Cost Reduction Opportunities</td>
                    <td class="border" style="padding: 10px;">Capacity Modelling indicates efficiency targets</td>
                    <td class="border" style="padding: 10px;">Reduction of costs of non-funded programs e.g., STEMI,
FM Contracts,
Workforce – Nursing, Medical, Agency, Locums
</td>                       
                    <td class="border" style="padding: 10px;">Yes, to Confirm Stage 2</td>
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">10</td>
                    <td class="border" style="padding: 10px;">Identification of National Strategy Leadership</td>
                    <td class="border" style="padding: 10px;">Insurance – Travellers Insurance and SEHATI</td>
                    <td class="border" style="padding: 10px;">Capability to immediately propose BDFRMS lead Traveller Health package has been created based on all Health Insurance modelling and capacity need.  
Follow-up to deliver full Social Health Insurance with pricing and beneficiary capitation rates has been established at Phase 1 level, and ready to rapidly mature for public purchase as complete health insurance packages by Phase 2.
</td>                       
                    <td class="border" style="padding: 10px;">Income</td>
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">11</td>
                    <td class="border" style="padding: 10px;">Procedure Pricing, Costing and coding</td>
                    <td class="border" style="padding: 10px;">Full review and rationalisation of all costing and coding (Phase 1) carried out.</td>
                    <td class="border" style="padding: 10px;">Will enable competitive self-pay pricing.
Improved pricing with Insurance companies where previously undercharging expensive procedures.
Capability to move to DRG code classifications in the new IT system.</td>                       
                    <td class="border" style="padding: 10px;">Income</td>
                  </tr>
                </tbody>
              </table>          
    </div>
    </div>
    </div>

    <div v-if="stageSummary == 'sysstrat'">
      <div class="route-content">

        <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">2.1</span>
            <span class="card-total3">The following strategic factors are included in the basis of analyses in preparation of this Business Plan:</span>            
          </div>


          <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Item</th>
                    <th class="border px-4 py-2">Factor</th>
                    <th class="border px-4 py-2">Consideration</th>
                    <th class="border px-4 py-2">Deduction</th>
                    <th class="border px-4 py-2">So What?</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">1</td>
                    <td class="border" style="padding: 10px;">Beneficiary Population and Demand</td>      
                    <td class="border" style="padding: 10px;">The population covered by each hospital varies.  </td>  
                    <td class="border" style="padding: 10px;">MKCC is tertiary coverage over all of Bahrain population.  STEMI is provided for even those not entitled.</td>  
                    <td class="border" style="padding: 10px;">Tertiary coverage without tertiary funding.</td>
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;"></td>   
                    <td class="border" style="padding: 10px;">Bahrain Oncology Centre (BOC) is tertiary.</td>   
                    <td class="border" style="padding: 10px;">Full levels of demand should be totally understood, and resources re-evaluated to enable full status as a Tertiary Oncology Centre.</td>                  
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;"></td>   
                    <td class="border" style="padding: 10px;">BDF Military Hospital covers BDF employees plus Bahraini population.</td>   
                    <td class="border" style="padding: 10px;">There is a risk to BDF due to uncontrolled demand.</td>                  
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;"></td>   
                    <td class="border" style="padding: 10px;">King Hamad University Hospital covers </td>   
                    <td class="border" style="padding: 10px;"></td>                  
                  </tr>                
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;">Acute and Tertiary Clinical Strategy</td>                    
                    <td class="border" style="padding: 10px;">Acute and Tertiary services are clearly outlined for most areas.</td>   
                    <td class="border" style="padding: 10px;">Therefore, no major re-structuring of hospital services is required.</td>   
                    <td class="border" style="padding: 10px;"></td>                  
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Certain clinical specialties have unnecessary duplication of resources and workforce</td>   
                    <td class="border" style="padding: 10px;">Specialty level plans across the organization will be required and linked to workforce demand.</td>   
                    <td class="border" style="padding: 10px;">Identification of duplication will be carried out, and plans for unification/integration or reduction will be recommended within Stage 2 System Planning.</td>                  
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">3</td>
                    <td class="border" style="padding: 10px;">Financial Efficiency</td>                    
                    <td class="border" style="padding: 10px;">Key areas of financial efficiency outlined</td>   
                    <td class="border" style="padding: 10px;">Workforce rationalisation and equalisation
Medical Consumables,
Pharmaceuticals,
Asset Utilisation
Contracting
</td>   
                    <td class="border" style="padding: 10px;">Immediate targeting of financial efficiency Stage 2</td>                  
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">4</td>
                    <td class="border" style="padding: 10px;">Workforce</td>                    
                    <td class="border" style="padding: 10px;">The Workforce cost % is over 80% of total spend.  This is excessive.  </td>   
                    <td class="border" style="padding: 10px;">Workforce levels must be right-sized at all levels: Medical, Nursing, AHP, Administration and Management</td>   
                    <td class="border" style="padding: 10px;">A coherent Workforce strategy based entirely on Demand factors – i.e. population beneficiaries must be evolved.  This will require the understanding of the ‘optimized’ levels of staffing required across the entire organization.</td>                  
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;"></td>   
                    <td class="border" style="padding: 10px;">There should be no requirement for locums or part-time employees unless essential.</td>   
                    <td class="border" style="padding: 10px;">Immediate cessation of Locum and Agency hires.</td>                  
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;">Future National Strategic Plans: Social Health Insurance</td>                    
                    <td class="border" style="padding: 10px;">BDF are uniquely placed to lead the development of the national strategy for social health insurance, particularly immediately on travel health insurance</td>   
                    <td class="border" style="padding: 10px;">RMS has the best IT solutions to enable advanced health insurance handling as demonstrated by its low level dispute CCRCM.
RMS has the best layout of acute and tertiary hospitals to deliver complete services to the Bahraini population (and it currently does through the most complex services of Oncology and Cardiothoracic) for Tertiary and full Trauma at BDF and KHUH.
</td>   
                    <td class="border" style="padding: 10px;">Establish the plans for BDF delivery of travel insurance for expats/visitors, as well as long term SEHATI.</td>                  
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">6</td>
                    <td class="border" style="padding: 10px;">Asset Volume and Efficiency</td>                    
                    <td class="border" style="padding: 10px;">The Asset Benchmark Modelling conducted identified the potential for more capacity to generate more income and see more patients</td>   
                    <td class="border" style="padding: 10px;">The option is to either reduce excess Capacity available by becoming more efficient and using less, 
Or Growing services to utilise more capacity.
</td>   
                    <td class="border" style="padding: 10px;">1.	Boost patient growth to generate utilise more capacity or
2.	Close assets where not utilised fully and save cash.
</td>                  
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">7</td>
                    <td class="border" style="padding: 10px;">Financial Risks</td>                    
                    <td class="border" style="padding: 10px;">MKCC asset management and maintenance costs</td>   
                    <td class="border" style="padding: 10px;">Warranty expiration will lead to large increase of maintenance costs for the new asset</td>   
                    <td class="border" style="padding: 10px;">To include in the forward-projected budgeting for MKCC</td>                  
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Consumables and Pharmaceuticals excess costs</td>   
                    <td class="border" style="padding: 10px;">Extremely high costs for consumables and pharmaceuticals.</td>   
                    <td class="border" style="padding: 10px;">Pharmaceutical Committee established to tackle high costs and attempt to negotiate changes with authorities.</td>                  
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Excess Workforce costs through duplication</td>   
                    <td class="border" style="padding: 10px;">Established detailed Workforce plan in Stage 2.</td>   
                    <td class="border" style="padding: 10px;">Stage 2 prepare plan to reduce duplicated services across the sites.</td>                  
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Excess Agency and Locum costs</td>   
                    <td class="border" style="padding: 10px;">Workforce numbers are high and in excess of demand apart from specific specialist areas.</td>   
                    <td class="border" style="padding: 10px;">Stop all Locum and Agency unless directly approved by the RMS Commander with demonstration of exactly why the additional staffing required.</td>                  
                  </tr>      
                  
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Excess Medical Doctor costs without demand</td>   
                    <td class="border" style="padding: 10px;">Align Demand Model with Medical Workforce</td>   
                    <td class="border" style="padding: 10px;">Reduce medical workforce where not required</td>                  
                  </tr>    
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Excess Delayed Discharges and increased Length of Stay (LOS)</td>   
                    <td class="border" style="padding: 10px;">C3 Daily Control.  Confirm Ward Rounds carried out. </td>   
                    <td class="border" style="padding: 10px;">Immediate escalation procedure to ensure all patients reviewed early morning.</td>                  
                  </tr>    
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;"></td>   
                    <td class="border" style="padding: 10px;">Confirm patient plans for discharge. </td>   
                    <td class="border" style="padding: 10px;">Establish Discharge Lounges.
Pre-prepare TTO.
</td>                  
                  </tr>    
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                    
                    <td class="border" style="padding: 10px;">Excess Workforce costs through blind recruitment for Doctors and Consultants and excess Nursing staff.</td>   
                    <td class="border" style="padding: 10px;">Align to Demand Model.</td>   
                    <td class="border" style="padding: 10px;">Establish in Daily C3 meeting – confirm staffing numbers versus Demand.</td>                  
                  </tr>    
                </tbody>
              </table>     
      </div>   
    </div>

    <div v-if="stageSummary == 'strat'">
      <div class="route-content">    
          <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Item</th>
                    <th class="border px-4 py-2">Principle</th>
                    <th class="border px-4 py-2">Consideration</th>
                    <th class="border px-4 py-2">Action for Stage 2</th>          
                  </tr>
                </thead>
                <tbody>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">1</td>
                    <td class="border" style="padding: 10px;">Flexible Workforce</td>                    
                    <td class="border" style="padding: 10px;">Interoperable Workforce Target Operating Model</td>   
                    <td class="border" style="padding: 10px;">Establish for Execution:
1.	TOM Workforce for all specialties
2.	Complete Medical and Nursing Target Operating Models
3.	Training and Skills Needs Analysis for all Nursing Levels
4.	Equalization plans based on Activity Demand benchmarks
5.	Establishment of Activity-based Rotas for Nursing and stop all rota planning based on bed or theatre volumes
6.	Reduce Nursing in OPD</td>                                        
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;">Integration</td>                    
                    <td class="border" style="padding: 10px;">Integrated Financial, Administrative, RCM and Management Systems (HIS – EPR)</td>   
                    <td class="border" style="padding: 10px;">Complete integration planning for:
1.	Financial System (AlCare selected)
2.	RCM (AlCare System)
3.	Admin and workforce (AlCare)
4.	Procurement and Supply Chain (AlCare)</td>                                        
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">3</td>
                    <td class="border" style="padding: 10px;">Shared Services</td>                    
                    <td class="border" style="padding: 10px;">INSERT SHARED SERVICE PLAN AND DIAGRAM</td>   
                    <td class="border" style="padding: 10px;">Laboratory Plan – Stage 2
FM Plan – Stage 2
Procurement – Stage 2
Transport – Stage 2
IT – Paper in Progress Stage 2.
Finance – Stage 2
CSSD – Stage 2</td>                                        
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">4</td>
                    <td class="border" style="padding: 10px;">Supply Chain Integrity</td>                    
                    <td class="border" style="padding: 10px;">Confirm with Ayesha the plan from BDF Hospital (then insert)</td>   
                    <td class="border" style="padding: 10px;">Establish Centralised and ‘Virtual’ Supply Chain</td>                                        
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;">Knowledge Transfer</td>                    
                    <td class="border" style="padding: 10px;">Establishment of a knowledge framework to build up leadership and management knowledge of healthcare systems</td>   
                    <td class="border" style="padding: 10px;">Establish Management Training Program at various modules.
(Stage 4 but can commence in June).</td>                                        
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">6</td>
                    <td class="border" style="padding: 10px;">Strategic Impact – national level leadership (leading the way)</td>                    
                    <td class="border" style="padding: 10px;">Insurance Program.

Propose to carve out additional services and budget from MoH.  For example can establish a National Stroke Program based in BDF Hospital, and a National Trauma Centre.  Budgets transferred from MoH, and services established on optimised pathways.</td>   
                    <td class="border" style="padding: 10px;">Initial Paper established.

Population Health Demand models completed and ready for Stage 2 to identify the services, costs, workforce capacity, beds and assets – so can prepare immediate plans for all.</td>                                        
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">7</td>
                    <td class="border" style="padding: 10px;">Internal Capability
		
</td>                    
                    <td class="border" style="padding: 10px;">Development of Planning and Leadership capability.</td>   
                    <td class="border" style="padding: 10px;">Provision of management system linked to live data.  
(Stage 4)</td>                                        
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">8</td>
                    <td class="border" style="padding: 10px;">Service Quality</td>                    
                    <td class="border" style="padding: 10px;">Confirm Quality System to drive entire Group. Link to daily C3 management programme.</td>   
                    <td class="border" style="padding: 10px;">Confirm Quality Management program with the Nursing Directors.</td>                                        
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">9</td>
                    <td class="border" style="padding: 10px;">Income Generation</td>                    
                    <td class="border" style="padding: 10px;">Multiple options for increased income generation within the RMS Group:
Training, 
Marketing Private Practice
Health Check portfolio
Drive Private Health Insurance and lead for the Kingdom
</td>   
                    <td class="border" style="padding: 10px;">Consolidated plan Stage 2</td>                                        
                  </tr>	
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">10</td>
                    <td class="border" style="padding: 10px;">Value for Money (VFM) Efficiency</td>                    
                    <td class="border" style="padding: 10px;">Confirm VFM areas:
Workforce,
</td>   
                    <td class="border" style="padding: 10px;">Appoint VFM Lead
Supported by HSi
Align with HSi Insight modelling
Demand 
</td>                                        
                  </tr>
                 
                
                   
                  
                  
                </tbody>
              </table>     


      </div>   
    </div>

    <div v-if="stageSummary == 'clinical'">
      <div class="route-content-speciality">
        <div class="route-speciality items-center secondary">
          <div class="route-speciality--box">
            <div class="box">
              <span class="box-header">Clinical Sector</span>
              <span class="box-label">
                <v-select
                  v-model="selectedSpeciality"
                  label="label"
                  :clearable="false"
                  :options="$store.state.prototypedata.clinical"
                />
              </span>
            </div>
          </div>
        </div>

        

        <!-- now check to see if any of the specialities areas have changed -->
       

        <div v-if="specialityType == 'primary'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Attendance Rate</th>
                    <th class="border px-4 py-2">Increase Treatment NCDs</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">Primary Care BP to focus on increased attendances for diabtetes, obesity, COPD, HPT to reduce acute attendances by 2026</td>
                    <td class="border" style="padding: 10px;">Increased Primary Care Telemedicine</td>                    
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'acute'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stroke Multi-Centre</th>
                    <th class="border px-4 py-2">Diagnostics Bottleneck</th>
                    <th class="border px-4 py-2">Winter Plan</th>
                    <th class="border px-4 py-2">Total System Discharge Plan</th>
                    <th class="border px-4 py-2">ER Plan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">Establish Acute Clinical Strategy focussing on Elective Delivery, Expanded OR hours,Ringfenced Trauma, 6 Week Elective Planning Routine</td>
                    <td class="border" style="padding: 10px;">Activity Demand for additional CT, MR and US</td>      
                    <td class="border" style="padding: 10px;">Forward-Planning for increased Day Surgery Winter Period</td>  
                    <td class="border" style="padding: 10px;">Establish Home Healthcare Service with additional Nursing and Physio Staff, Hire drivers, lease vehicles, additional Pharmacy hours</td>  
                    <td class="border" style="padding: 10px;">Virtual Triage, Upskill Operational Management Team, Additional Specialist Consultant Attendance ER - Cardiology, Elderly Care, Orthopaedics, Internal Medicine</td>  
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">
                        <ul style="list-style-type: none">
                          <li>1. Elective Care Recovery Plan to reduce by October 23</li>
                          <li>2. Establish Elective 6-Week Advanced Planner by April 23</li>
                          <li>3. Establish Winter Plan 23 by June 23.</li>
                        </ul>
                    </td>
                    <td class="border" style="padding: 10px;">
                        <ul style="list-style-type: none">
                          <li>4. Confirm Purchase of additional [x] CT, [y] MRI, [z] US.</li>
                          <li>5. Source capacity in private sector at reduced price discount for block purchase</li>                
                        </ul>
                    </td>                    
                    <td class="border" style="padding: 10px;">
                        <ul style="list-style-type: none">
                          <li>6. Establish Day Surgery Planning and Capacity at minimum 30% levels of elective surgery referrals by August 23</li>                     
                        </ul>
                    </td>   
                    <td class="border" style="padding: 10px;">
                        <ul style="list-style-type: none">
                          <li>7.  Commence HHC service by July 23 utilising existing excess staffing from Nursing, AHP, Admin, Drivers</li>                  
                        </ul>
                    </td>   
                    <td class="border" style="padding: 10px;">
                      <ul style="list-style-type: none">
                          <li>9.  Confirm Consultant Job-Planning/Bed Management Pas to include ER attendance 1 x Hour per Bed Management PA</li>
                       </ul>
                    </td>                  
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">Higher asset utilisation = increased maintenance and property costs</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                       
                    <td class="border" style="padding: 10px;"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'tertiary'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Referrals for Cardiac, Neuro</th>
                    <th class="border px-4 py-2">Limited Tertiary inflow</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">Identify efficiency in current costs for tertiary patients.</td>
                    <td class="border" style="padding: 10px;"></td>      
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">
                        <ul style="list-style-type: none">
                          <li>10.  Reduce Tertiary Referral Costs by 15% by renegotiation of contracts, or outsourcing to private providers</li>                          
                        </ul>
                    </td>
                    <td class="border" style="padding: 10px;"></td>                                                
                  </tr>                  
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'mental'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                <tr>
                  <th class="border px-4 py-2">Mental Health OPD</th>
                  <th class="border px-4 py-2">Non-Elective Care - Out of Hours service</th>
                </tr>
             </thead>
             <tbody>
               <tr>
                  <td class="border">
                    <div>Increase levels of Mental Health provision. Telemedical Appointments increase</div>
                  </td>
                  <td class="border">
                    <div>Move Mental Health practitioners directly into Acute ER</div>
                  </td>
                </tr>

                <tr>
                  <td class="border">
                    <div>11.  Increase Out of Hours MH Support to reduce ER blockage</div>
                  </td>
                  <td class="border"></td>
                </tr>
             </tbody>
              </table>
            </div>
          </div>
        </div>


        <div v-if="specialityType == 'virt'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                <tr>
                  <th class="border px-4 py-2">Virtual OPD  for benign follow-ups</th>                
                </tr>
             </thead>
             <tbody>               
                <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                </tr>                
             </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'long'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                <tr>
                  <th class="border px-4 py-2">Capacity to convert multiple beds into LTC facilities.</th>
                  <th class="border px-4 py-2">Reduced medical staff</th>
                  <th class="border px-4 py-2">Enable discharge process into LTC facility</th>
                  <th class="border px-4 py-2">Lowered costs</th>
                </tr>
             </thead>
             <tbody>
              <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">Establish [300] Long Term Care Beds</td>
                    <td class="border" style="padding: 10px;">Establish 80 LTC Mental Health Beds</td>      
                    <td class="border" style="padding: 10px;">Establish 40 LTC Paediatric Mental Health Beds</td>
                    <td class="border" style="padding: 10px;"></td>
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">
                        <ul style="list-style-type: none">
                          <li>12.  Establish LTC beds by October 23 through rationalisation of existing assets and workforce.  (Cost Neutral)</li>                          
                        </ul>
                    </td>
                    <td class="border" style="padding: 10px;"></td>    
                    <td class="border" style="padding: 10px;"></td> 
                    <td class="border" style="padding: 10px;"></td>                                             
                  </tr>     
             </tbody>
              </table>
            </div>
          </div>
        </div>



       
      </div>
    </div>

   
 </div>
</template>
<script>


export default {
  data() {
    return {
      stageSummary: "stage",
      selectedSpeciality: "Acute",
      apiPayload: { speciality: "", apiString: "" },
      activeSummary: "",
      selectedSpecialityArea: "Patient Episodes",
      specialityType: "acute",
      specialityApi: "ahpstwo-collection",
      firstUpdate: false,    
    };
  },
  updated() {
   
  },
  beforeMount() {
    console.log("before mounted");
  },
  mounted() {
    console.log("mounted");
    this.activeSummary = "workforceCost";
  },
  watch: {
    selectedSpeciality: {
      handler: function (val) {
        console.log("selectedSpeciality: label: ", val.label);
        console.log("selectedSpeciality: string: ", val.string);
      
        this.editSpecialityType(val.string);
        //console.log("speciality: ", this.specialityApi);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      },
      deep: true,
    },
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },
    specialityName() {
      return this.$store.state.prototypedata.specialityName;
    },
    specialityData() {
      return true;
    },  
  },
  methods: {  
    
   h1(text) {
    return `<h1>${text} </h1>`
   },
    specialityHeader(header) {
      return header;
    },
    editActiveStageSummary(activeSummary) {
      this.activeSummary = activeSummary;
    },
    editSpecialityType(specialityTypeName) {

      console.log ("specialityTypeName: ", specialityTypeName);

      if (specialityTypeName == "primary") {
        this.specialityType = "primary";
      }
      if (specialityTypeName == "acute") {
        this.specialityType = "acute";
      }
      if (specialityTypeName == "tertiary") {
        this.specialityType = "tertiary";
      }
      if (specialityTypeName == "mental") {
        this.specialityType = "mental";
      }
      if (specialityTypeName == "virt") {
        this.specialityType = "virt";
      }
      if (specialityTypeName == "long") {
        this.specialityType = "long";
      }

      console.log(
        "editSpecialityType (",
        specialityTypeName,
        ")=",
        this.specialityType
      );
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    editStageSummary(stageSummary) {
      this.stageSummary = stageSummary;

      if (this.stageSummary == "stage") {
        //this.apiPayload.apiString = "grandsummarytwo-collection";

        //console.log("stage: getData");

        //this.getMongoDBData();
        //this.fillData("workforceCost");
      }

      if (this.stageSummary == "buildingsFM") {
        //this.apiPayload.apiString = "fm_build2_collection";
        //this.getMongoDBData();
      }

      if (this.stageSummary == "specialities") {
        console.log(
          " this.selectedSpecialityArea.selected",
          this.selectedSpecialityArea
        );
      }
    },
  },
};
</script>
<style>
.chartcontainer {
  border: 1px solid;
  border-color: #e5e9f2;
  height: 400px;
  display: inline-block;
  width: 350px; /* was 26 rem */
  margin-right: 20px;
  padding-bottom: 5px;
}
.title_text {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.hello1 {
color: #000000;
font-family: arial, sans-serif;
font-size: 16px;
font-weight: bold;
margin-top: 20px;
margin-bottom: 30px;
}
</style>
