<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/logo.svg" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">
          AI Transformation Platform <span class="text-xl ml-2">(Stage 3)</span>
        </h1>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Qasim</span>
        </div>
      </div>
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Total Population</span>
          <span class="box-label">1,403,267</span>
        </div>
      </div>
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Export Data</span>
          <button class="btn btn-primary btn-outline btn-small mr-4">
            CSV
          </button>
          <button class="btn btn-primary btn-outline btn-small mr-4">
            PDF
          </button>
          <button class="btn btn-primary btn-outline btn-small">XML</button>
        </div>
      </div>
    </div>

    <div class="route-content" v-if="activeSummary == 'annualTotals'">
      <h1 class="text-3xl my-6 active">Year Overview</h1>

      <table class="table-primary border-fix" v-if="specialityData !== null">
        <thead>
          <tr>
            <th class="border px-4 py-2"></th>
            <th class="border px-4 py-2">Year 1</th>
            <th class="border px-4 py-2">Year 2</th>
            <th class="border px-4 py-2">Year 3</th>
            <th class="border px-4 py-2">Year 4</th>
            <th class="border px-4 py-2">Year 5</th>
            <th class="border px-4 py-2">Year 6</th>
            <th class="border px-4 py-2">Year 7</th>
            <th class="border px-4 py-2">Year 8</th>
          </tr>

          <tr>
            <th
              class="border"
              v-for="(
                servedPopulation, index
              ) in annualTotalsServedPopulationFilter"
              :key="index"
            >
              {{ servedPopulation }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              class="border"
              v-for="(costbase, index) in annualTotalsCostbaseFilter"
              :key="index"
            >
              <div>{{ costbase }}</div>
            </td>
          </tr>

          <tr>
            <td
              class="border"
              v-for="(
                incomeRequirements, index
              ) in annualTotalsIncomeRequirementFilter"
              :key="index"
            >
              <div>{{ incomeRequirements }}</div>
            </td>
          </tr>

          <tr>
            <td>
              <div>&nbsp;</div>
            </td>
          </tr>

          <tr>
            <td
              class="border"
              v-for="(medicalStaff, index) in annualTotalsMedicalStaffFilter"
              :key="index"
            >
              <div>{{ medicalStaff }}</div>
            </td>
          </tr>

          <tr>
            <td
              class="border"
              v-for="(
                nursingAndMidwife, index
              ) in annualTotalsNursingAndMidwifeFilter"
              :key="index"
            >
              <div>{{ nursingAndMidwife }}</div>
            </td>
          </tr>

          <tr>
            <td
              class="border"
              v-for="(
                totalWorkforce, index
              ) in annualTotalsTotalWorkforceFilter"
              :key="index"
            >
              <div>{{ totalWorkforce }}</div>
            </td>
          </tr>

          <tr>
            <td>
              <div>&nbsp;</div>
            </td>
          </tr>

          <tr>
            <td
              class="border"
              v-for="(
                primaryCarePresentations, index
              ) in annualTotalsPrimaryCarePresentationsFilter"
              :key="index"
            >
              <div>{{ primaryCarePresentations }}</div>
            </td>
          </tr>
          <tr>
            <td
              class="border"
              v-for="(
                erPresentations, index
              ) in annualTotalsErPresentationsFilter"
              :key="index"
            >
              <div>{{ erPresentations }}</div>
            </td>
          </tr>
          <tr>
            <td
              class="border"
              v-for="(
                opdPresentations, index
              ) in annualTotalsOpdPresentationsFilter"
              :key="index"
            >
              <div>{{ opdPresentations }}</div>
            </td>
          </tr>
          <tr>
            <td
              class="border"
              v-for="(orEpisodes, index) in annualTotalsOrEpisodesFilter"
              :key="index"
            >
              <div>{{ orEpisodes }}</div>
            </td>
          </tr>
          <tr>
            <td
              class="border"
              v-for="(bedNights, index) in annualTotalsBedNightsFilter"
              :key="index"
            >
              <div>{{ bedNights }}</div>
            </td>
          </tr>
          <tr>
            <td
              class="border"
              v-for="(
                radiologyEpisodes, index
              ) in annualTotalsRadiologyEpisodesFilter"
              :key="index"
            >
              <div>{{ radiologyEpisodes }}</div>
            </td>
          </tr>
          <tr>
            <td
              class="border"
              v-for="(
                laboratoryEpisodes, index
              ) in annualTotalsLaboratoryEpisodesFilter"
              :key="index"
            >
              <div>{{ laboratoryEpisodes }}</div>
            </td>
          </tr>

          <tr>
            <td>
              <div>&nbsp;</div>
            </td>
          </tr>

          <tr>
            <td
              class="border"
              v-for="(totalAssets, index) in annualTotalsTotalAssetsFilter"
              :key="index"
            >
              <div>{{ totalAssets }}</div>
            </td>
          </tr>
          <tr>
            <td
              class="border"
              v-for="(
                lifeCycleCosts, index
              ) in annualTotalsLifeCycleCostsFilter"
              :key="index"
            >
              <div>{{ lifeCycleCosts }}</div>
            </td>
          </tr>

          <tr>
            <td
              class="border"
              v-for="(
                maintenanceCosts, index
              ) in annualTotalsMaintenanceCostsFilter"
              :key="index"
            >
              <div>{{ maintenanceCosts }}</div>
            </td>
          </tr>

          <tr>
            <td>
              <div>&nbsp;</div>
            </td>
          </tr>

          <tr>
            <td
              class="border"
              v-for="(
                propertyLifeCycle, index
              ) in annualTotalsPropertyLifeCycleFilter"
              :key="index"
            >
              <div>{{ propertyLifeCycle }}</div>
            </td>
          </tr>
          <tr>
            <td
              class="border"
              v-for="(
                propertyFmCosts, index
              ) in annualTotalsPropertyFmCostsFilter"
              :key="index"
            >
              <div>{{ propertyFmCosts }}</div>
            </td>
          </tr>
          <tr>
            <td
              class="border"
              v-for="(
                capexRequirements, index
              ) in annualTotalsCapexRequirementFilter"
              :key="index"
            >
              <div>{{ capexRequirements }}</div>
            </td>
          </tr>
        </tbody>
      </table>

    <div class="mt-10" v-if="activeSummary == 'annualTotals'">
      <h1 class="text-3xl my-6 active">Key Strategic Drivers</h1>
      <button class="btn btn-primary btn-outline mr-4 mb-4" :class="year1To4Table ? 'active' : ''" @click="showYear1To4Table">Year 1 to 4</button>
      <button class="btn btn-primary btn-outline mr-4 mb-4" :class="year5To8Table ? 'active' : ''" @click="showYear5To8Table">Year 5 to 8</button>

      <table
        v-if="year1To4Table && specialityData !== null"
        class="table-primary border-fix"
      >
        <thead>
          <tr>
            <th class="border px-4 py-2">Objectives</th>
            <th class="border px-4 py-2">Year 1</th>
            <th class="border px-4 py-2">Year 2</th>
            <th class="border px-4 py-2">Year 3</th>
            <th class="border px-4 py-2">Year 4</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-workforce-black.svg" alt="Workforce" /> Workforce</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Specialty Target Operating Models</li>
                  <li>Contract</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Nurse Rota Compliance Activity-Based</li>
                  <li>Activity-based Staff Planning</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>Consultant Job Planning</div>
            </td>
            <td class="border">
              <div>Nurse training</div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> Finance</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Immediate Cost Reduction</li>
                  <li>Contracts review and efficiency</li>
                  <li>Life Cycle Planning</li>
                  <li>Capex</li>
                  <li>Asset Rationalisation</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Efficiency FMS Procurement</li>
                  <li>Life Cycle integration</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>FM Procurement Contracting</div>
            </td>
            <td class="border">
              <div>CAPEX Planning</div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-healthcare.svg" alt="Healthcare Delivery" /> Healthcare Delivery</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Specialty Pathway models</li>
                  <li>Establish service-line management</li>
                  <li>Clinical Session Templates</li>
                  <li>Activity-based control TOMs</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>Synchroniised Pathways Primary Care to Acute</div>
            </td>
            <td class="border">
              <div>OR Utilisation Plans</div>
            </td>
            <td class="border">
              <div>Population Health Demand</div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-clinical.svg" alt="Clinical Excellence" /> Clinical Excellence</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Establish clinical audit</li>
                  <li>Establish clinical session Process</li>
                  <li>Medical Succession Planning</li>
                  <li>Cluster Regional Medical Cover</li>
                  <li>Critical Chromic Disease Process</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>System-Wide Clinical Audit</div>
            </td>
            <td class="border">
              <div>Cluster Clinical Audit</div>
            </td>
            <td class="border">
              <div>Integrated Diagnostics Plans</div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Digital" /> Digital IT/E Health</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Establish Cluster EPR systems</li>
                  <li>PHC data to support PMH</li>
                  <li>Interoperability Improvement PHCs</li>
                  <li>Digitised Management Process</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>HIS Rollout</div>
            </td>
            <td class="border">
              <div>EMR Primary Care</div>
            </td>
            <td class="border">
              <div>FM CAFM Procurement</div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-assets-infrastructure.svg" alt="Assets and Infrastucture" /> Assets and Infrastructure</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>PHC Asset Infrastructure</li>
                  <li>Urgent/Megacenter Planning</li>
                  <li>Hospital Risk Management</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>Capex Plan 5 Years</div>
            </td>
            <td class="border">
              <div>PHC Infrastructure Procurement</div>
            </td>
            <td class="border">
              <div>PHC Infrastrucure</div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-supplychain.svg" alt="Supply Chain" /> Supply Chain</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Specialty Activity based ordering</li>
                  <li>Universal Stock System</li>
                  <li>NUPCO Interoperability</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>NUPCO Consolidation</div>
            </td>
            <td class="border">
              <div>NUPCO Rolout</div>
            </td>
            <td class="border">
              <div>Pharma Rationalisation</div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-fms.svg" alt="FMS" /> FMS</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>FM Contracts Review</li>
                  <li>FM Benchmark</li>
                  <li>Establish FM Procurement</li>
                  <li>In-House Central Management</li>
                  <li>Payment Mechanism</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>Outsourced Procurement</div>
            </td>
            <td class="border">
              <div>Contracts Management</div>
            </td>
            <td class="border">
              <div>Re-Procurement</div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-population.svg" alt="Population Health Management" /> Population Health Management</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Establish Data Collection Plan</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>Confirm Patient Risk Stratification</div>
            </td>
            <td class="border">
              <div>Focus Care Group Plans on High Risk</div>
            </td>
            <td class="border">
              <div></div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-primary-care.svg" alt="Primary Care" /> Primary Care</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Workforce Equalisation</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>Workforce Upskilling Planning</div>
            </td>
            <td class="border">
              <div>PHC Digitization</div>
            </td>
            <td class="border">
              <div>Supply Chain</div>
            </td>
          </tr>
        </tbody>
      </table>

      <table
        class="table-primary border-fix"
        v-if="year5To8Table && specialityData !== null"
      >
        <thead>
          <tr>
            <th class="border px-4 py-2">Objectives</th>
            <th class="border px-4 py-2">Year 5</th>
            <th class="border px-4 py-2">Year 6</th>
            <th class="border px-4 py-2">Year 7</th>
            <th class="border px-4 py-2">Year 8</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-workforce-black.svg" alt="Workforce" /> Workforce</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Saudi Nursing 95%</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>ICU Nursing 100% Saudi</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>Primary Care Medical Staffing</div>
            </td>
            <td class="border">
              <div>Primary Care Nurse Training</div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> Finance</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Medical Equipment Life Cycle</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Revenue Cycle Management</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>Pricing Review Benchmark</div>
            </td>
            <td class="border">
              <div>Cost Optimization Program</div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-healthcare.svg" alt="Healthcare Delivery" /> Healthcare Delivery</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Clinical Specialty Integration</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>Laboratories Rationalisation</div>
            </td>
            <td class="border">
              <div></div>
            </td>
            <td class="border">
              <div></div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-clinical.svg" alt="Clinical Excellence" /> Clinical Excellence</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Clinical Pathway Standardization</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>Specialty Standardization</div>
            </td>
            <td class="border">
              <div></div>
            </td>
            <td class="border">
              <div></div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Digital IT/E Health" /> Digital IT/E Health</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>FM Contracts Procurement</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>FM Contracts Procurement</div>
            </td>
            <td class="border">
              <div></div>
            </td>
            <td class="border">
              <div></div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-assets-infrastructure.svg" alt="Assets and Infrastructure" /> Assets and Infrastructure</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>PHC Mobilisation</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>Acute Care Rationalisation</div>
            </td>
            <td class="border">
              <div>Rehabilitation Plan</div>
            </td>
            <td class="border">
              <div></div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-supplychain.svg" alt="Supply Chain" /> Supply Chain</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Pharma Rationalisation</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div>Pharma Rationalisation</div>
            </td>
            <td class="border">
              <div></div>
            </td>
            <td class="border">
              <div></div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-fms.svg" alt="FMS" /> FMS</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div></div>
            </td>
            <td class="border">
              <div></div>
            </td>
            <td class="border">
              <div></div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-population.svg" alt="Population Health Management" /> Population Health Management</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div></div>
            </td>
            <td class="border">
              <div></div>
            </td>
            <td class="border">
              <div></div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-primary-care.svg" alt="Primary Care" /> Primary Care</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>FM Contracts</li>
                </ul>
              </div>
            </td>
            <td class="border">
              <div></div>
            </td>
            <td class="border">
              <div></div>
            </td>
            <td class="border">
              <div></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeSummary: "annualTotals",
      year1To4Table: true,
      year5To8Table: false,
    };
  },
  mounted() {
    this.fetchAnnualTotals();
  },
  computed: {
    annualTotalsData() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsDataOne;
      }
      return null;
    },
    annualTotalsServedPopulationFilter() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsDataOne[0]
          .served_population;
      }
      return null;
    },
    annualTotalsCostbaseFilter() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsDataOne[0].costbase;
      }
      return null;
    },
    annualTotalsIncomeRequirementFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .income_requirement;
      }
      return null;
    },
    annualTotalsMedicalStaffFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .medical_staff_fte;
      }
      return null;
    },
    annualTotalsNursingAndMidwifeFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .nursing_and_midwife_fte;
      }
      return null;
    },
    annualTotalsTotalWorkforceFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .total_workforce_fte;
      }
      return null;
    },
    annualTotalsPrimaryCarePresentationsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .primary_care_presentations;
      }
      return null;
    },
    annualTotalsErPresentationsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .er_presentations;
      }
      return null;
    },
    annualTotalsOpdPresentationsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .opd_presentations;
      }
      return null;
    },
    annualTotalsOrEpisodesFilter() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsDataOne[0]
          .total_or_episodes;
      }
      return null;
    },
    annualTotalsBedNightsFilter() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsDataOne[0]
          .total_bed_nights;
      }
      return null;
    },
    annualTotalsRadiologyEpisodesFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .total_radiology_episodes;
      }
      return null;
    },
    annualTotalsLaboratoryEpisodesFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .total_laboratory_episodes;
      }
      return null;
    },
    annualTotalsTotalAssetsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0].total_assets;
      }
      return null;
    },
    annualTotalsLifeCycleCostsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .life_cycle_costs;
      }
      return null;
    },
    annualTotalsMaintenanceCostsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .maintenance_costs;
      }
      return null;
    },
    annualTotalsPropertyLifeCycleFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .property_life_cycle;
      }
      return null;
    },
    annualTotalsPropertyFmCostsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .property_fm_costs;
      }
      return null;
    },
    annualTotalsCapexRequirementFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .capex_requirement;
      }
      return null;
    },
  },
  methods: {
    fetchAnnualTotals() {
      this.$store.dispatch("attemptFetchAnnualTotals").then((data) => {
        console.log(data);
      });
    },
    showYear1To4Table() {
      this.year1To4Table = true;
      this.year5To8Table = false;
    },
    showYear5To8Table() {
      this.year1To4Table = false;
      this.year5To8Table = true;
    },
  },
};
</script>
