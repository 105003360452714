<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">
          Business Plan
        </h1>
      </div>

      <div class="validation-cards--item cert no-hover">
        <span class="card-header">Certification Status</span>
        <div class="card-icon" margin-top="0px">
          <img
            src="@/assets/img/circular-label-with-certified-stamp.png"
            alt=""
          />
          <span class="card-total">100% Validation</span>
        </div>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Surrey - United Kingdom</span>
        </div>
      </div>
    </div>

    <nav class="route-nav">
      <ul>
        <li
          @click="editStageSummary('stage')"
          :class="stageSummary == 'stage' ? 'active' : ''"
        >
          <span>Business Plan 2023</span>
        </li>
        <li
          @click="editStageSummary('sysstrat')"
          :class="stageSummary == 'sysstrat' ? 'active' : ''"
        >
          <span>System Strategy</span>
        </li>
        <li
          @click="editStageSummary('clinical')"
          :class="stageSummary == 'clinical' ? 'active' : ''"
        >
          <span>Clinical Sector</span>
        </li>
      </ul>
    </nav>

    <!-- //!-- hello  :class="activeSummary === 'patientEpisode' ? 'active' : ''" @click="fillData('patientEpisode')"> ---> 
    <!-- :class="activeSummary === 'assets' ? 'active' : ''" @click="fillData('assets')">-->
    <!-- :class="activeSummary === 'workforceCost' ? 'active' : ''" @click="fillData('workforceCost')"> -->
    <!-- :class="activeSummary === 'finance' ? 'active' : ''" @click="fillData('finance')"> -->
    <!-- :class="activeSummary === 'workforceCost1' ? 'active' : ''" @click="fillData('workforceCost1')">-->
    <div v-if="stageSummary == 'stage'">
      <div class="route-content">
        <div class="new_stat-cards">
          <div class="new_stat-cards--item single no-hover">
            <span class="card-header">Immediate Actionable Insight</span>            
            <span class="card-total">Productivity Per Asset</span>
            <span class="card-info"></span>
          </div>

          <div class="new_stat-cards--item single no-hover">
            <span class="card-header">Immediate Actionable Insight</span>
            <span class="card-total">Workforce Reduction</span>
            <span class="card-info"></span>
          </div>

          <div class="new_stat-cards--item single no-hover"> 
            <span class="card-header">Immediate Actionable Insight</span>
            <span class="card-total">Provide Leadership Restructure</span>
            <span class="card-info"></span>
          </div>

          <div class="new_stat-cards--item single no-hover"> 
            <span class="card-header">Immediate Actionable Insight</span>
            <span class="card-total">Tertiary Cost Reduction</span>
            <span class="card-info"></span>
          </div>

          <div class="new_stat-cards--item single no-hover">
            <span class="card-header">Immediate Actionable Insight</span>
            <span class="card-total">In-House Flow Strategy</span>
            <span class="card-info"></span>
          </div>

          <div class="new_stat-cards--item single no-hover">
            <span class="card-header">Strategic Executive Summary</span>
            <span class="card-total2">Asset Productivity Increased 30% (Improved Operational Planning)</span>           
          </div>

          <div class="new_stat-cards--item single no-hover">
            <span class="card-header">Strategic Executive Summary</span>
            <span class="card-total2">Workforce Reduction 400 FTEs per annum (Administration)</span>           
          </div>

          <div class="new_stat-cards--item single no-hover">
            <span class="card-header">Strategic Executive Summary</span>
            <span class="card-total2">Acute Leadership Strategy - Reduction of Management Overheads</span>           
          </div>

          <div class="new_stat-cards--item single no-hover">
            <span class="card-header">Strategic Executive Summary</span>
            <span class="card-total2">Cost Reduction Tertiary Referrals by 15%</span>            
          </div>

          <div class="new_stat-cards--item single no-hover">
            <span class="card-header">Strategic Executive Summary</span>
            <span class="card-total2">LTC Strategy - Reduced LOS and Improved Discharge Strategy</span>           
          </div>

          <div class="new_stat-cards--item single no-hover">
            <span class="card-header">System Objectives</span>
            <span class="card-total3">Standardised Productivity Performance Score per Asset</span>            
          </div>

          <div class="new_stat-cards--item single no-hover">
            <span class="card-header">System Objectives</span>           
            <span class="card-total3">Reduce Administration Cost - full automation of Digitised Systems</span>
          </div>

          <div class="new_stat-cards--item single no-hover"> 
            <span class="card-header">System Objectives</span>
            <span class="card-total3">Establishment of System Executive Team with Shared Service Back Office Administration, Finance, Workforce/HR, Quality, Procurement, Comms</span>            
          </div>

          <div class="new_stat-cards--item single no-hover">
            <span class="card-header">System Objectives</span>
            <span class="card-total3">Reduced Tertiary Referral rate</span>
          </div>

          <div class="new_stat-cards--item single no-hover">
            <span class="card-header">System Objectives</span>
            <span class="card-total3">Reduced Occupancy to (85%)</span>
          </div>
    </div>
    </div>
    </div>

    <div v-if="stageSummary == 'sysstrat'">
      <div class="route-content">
        <button class="btn btn-primary btn-outline mr-4 mb-4" :class="button1 ? 'active' : ''" @click="showButton1">Excess Administration FTEs</button>
        <button class="btn btn-primary btn-outline mr-4 mb-4" :class="button2 ? 'active' : ''" @click="showButton2">Excess Budget versus Demand</button>
        <button class="btn btn-primary btn-outline mr-4 mb-4" :class="button3 ? 'active' : ''" @click="showButton3">Low level Productivity: Asset and Productivity: Workforce</button>
        <button class="btn btn-primary btn-outline mr-4 mb-4" :class="button4 ? 'active' : ''" @click="showButton4">Duplication of System Management</button>
        <button class="btn btn-primary btn-outline mr-4 mb-4" :class="button5 ? 'active' : ''" @click="showButton5">Insufficient Diagnostic Modalities</button>

        <div v-if="button1">
          <div class="new_new_stat-cards">
            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Excess Administration FTEs</span>           
              <span class="card-total3">Establish Long-term plan to reduce Administrator workforce rationalising versus digital care capacity</span>
            </div>

            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Excess Administration FTEs</span>           
              <span class="card-total3">Balance Workforce directly with Activity Demand</span>
            </div>
          </div>

          <table class="table-primary border-fix">
        <thead>
          <tr>
            <th class="border px-4 py-2">Summary</th>
            <th class="border px-4 py-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-assets-infrastructure.svg" alt="Assets and Infrastructure" /> Assets and Infrastructure</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Reduced Offices and associated costs</li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-workforce-black.svg" alt="Workforce" /> Workforce</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Sucession Planning</li>
                  <li>Recruitment Strategy</li>
                  <li>Administration Reduction by natural wastage/Nil Recruitment</li>
                  <li>Increased GPs</li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> Financial OPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Cost Reduction Impact: £145M by 2027</li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> CAPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-supplychain.svg" alt="Supply Chain" /> Supply Chain</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-fms.svg" alt="FMS" /> FMS</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>


          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Digital IT/E Health" /> Digital IT/E Health</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Media and Communications" /> Media and Communications</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>          
        </tbody>
      </table>
    </div>
        

    <div v-if="button2">
      <div class="new_new_stat-cards">
            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Excess Budget versus Demand</span>           
              <span class="card-total3">Reduced Workforce based on Activity Demand to reduce cost budget</span>
            </div>

            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Excess Budget versus Demand</span>           
              <span class="card-total3">Balance Budget to Productivity </span>
            </div>
          </div>

          <table class="table-primary border-fix">
        <thead>
          <tr>
            <th class="border px-4 py-2">Summary</th>
            <th class="border px-4 py-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-assets-infrastructure.svg" alt="Assets and Infrastructure" /> Assets and Infrastructure</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>


          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-workforce-black.svg" alt="Workforce" /> Workforce</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>FTE Reduction in Administration by 3,500 FTEs by 2027</li>                
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> Financial OPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> CAPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-supplychain.svg" alt="Supply Chain" /> Supply Chain</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-fms.svg" alt="FMS" /> FMS</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>


          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Digital IT/E Health" /> Digital IT/E Health</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Media and Communications" /> Media and Communications</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>          
        </tbody>
      </table>
    </div>

        <div v-if="button3">
          <div class="new_new_stat-cards">
            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Low level Productivity:Asset and Productivity: Workforce</span>           
              <span class="card-total3">Establish minimum productivity levels per Asset.   Establish minimum productivity levels per Workforce Planning.</span>
            </div>

            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Low level Productivity:Asset and Productivity: Workforce</span>           
              <span class="card-total3">Reduce % Workforce:Total Income</span>
            </div>
          </div>

          <table class="table-primary border-fix">
        <thead>
          <tr>
            <th class="border px-4 py-2">Summary</th>
            <th class="border px-4 py-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-assets-infrastructure.svg" alt="Assets and Infrastructure" /> Assets and Infrastructure</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Minimum levels of activity per asset</li>
                </ul>
              </div>
            </td>
          </tr>


          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-workforce-black.svg" alt="Workforce" /> Workforce</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Activity-Linked Job-Planning for Medical and Nursing Staff</li>                
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> Financial OPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> CAPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-supplychain.svg" alt="Supply Chain" /> Supply Chain</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-fms.svg" alt="FMS" /> FMS</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>


          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Digital IT/E Health" /> Digital IT/E Health</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Media and Communications" /> Media and Communications</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>          
        </tbody>
      </table>
        </div>

        <div v-if="button4">
          <div class="new_new_stat-cards">
            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Duplication of System Management</span>           
              <span class="card-total3">Establish new System Executive Team structure based on Activity Management at clinical specialty levels. Remove per Facility Infrastructure Management, e.g. CEO per hospital etc.</span>
            </div>

            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Duplication of System Management</span>           
              <span class="card-total3">Establish Executive Structure based on Service Lines.  Reduced Executive management. </span>
            </div>
          </div>

          <table class="table-primary border-fix">
        <thead>
          <tr>
            <th class="border px-4 py-2">Summary</th>
            <th class="border px-4 py-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-assets-infrastructure.svg" alt="Assets and Infrastructure" /> Assets and Infrastructure</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Reduced Asset/office costs</li>
                </ul>
              </div>
            </td>
          </tr>


          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-workforce-black.svg" alt="Workforce" /> Workforce</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Restructure Executive Management Team.  Service Lines Focussed.</li>                
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> Financial OPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> CAPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-supplychain.svg" alt="Supply Chain" /> Supply Chain</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-fms.svg" alt="FMS" /> FMS</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>


          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Digital IT/E Health" /> Digital IT/E Health</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Media and Communications" /> Media and Communications</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>          
        </tbody>
      </table>
        </div>

        <div v-if="button5">
          <div class="new_new_stat-cards">
            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Insufficient Diagnostic Modalities</span>           
              <span class="card-total3">Establish immediate demand-driven plan for increased diagnostics including expanded out of hours timing.  Option 2: identify full demand requirement and price for outsourcing to local private sector providers at set pricing.  Block purchase for 3 year period with discounted rate</span>
            </div>

            <div class="new_new_stat-cards--item jessica no-hover">
              <span class="card-header">Insufficient Diagnostic Modalities</span>           
              <span class="card-total3">Increased Diagnositcs Throughput in Labs and Radiology by 15%</span>
            </div>
          </div>

          <table class="table-primary border-fix">
        <thead>
          <tr>
            <th class="border px-4 py-2">Summary</th>
            <th class="border px-4 py-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-assets-infrastructure.svg" alt="Assets and Infrastructure" /> Assets and Infrastructure</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Cost of new Infrastructure - £14M, and Assets - £9.5M</li>
                </ul>
              </div>
            </td>
          </tr>


          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-workforce-black.svg" alt="Workforce" /> Workforce</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Increased Technicians for Radiology: 19 Radiograhpers and 3 Radiologists for increased reporting demand</li>                
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> Financial OPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Increased caseload = Increased Income by £5.5m per annum</li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-finance-black.svg" alt="Finance" /> CAPEX</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Immediate Capex: £23.5M</li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-supplychain.svg" alt="Supply Chain" /> Supply Chain</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Minimal</li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-fms.svg" alt="FMS" /> FMS</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Increase FM coverage of £200k</li>
                </ul>
              </div>
            </td>
          </tr>


          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Digital IT/E Health" /> Digital IT/E Health</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li>Increae current licenses</li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td class="border">
              <div class="icon-header"><img src="@/assets/img/icons/icon-digital.svg" alt="Media and Communications" /> Media and Communications</div>
            </td>
            <td class="border">
              <div>
                <ul style="list-style-type: none">
                  <li></li>
                </ul>
              </div>
            </td>
          </tr>          
        </tbody>
      </table>
        </div>


      </div>   
    </div>

    <div v-if="stageSummary == 'clinical'">
      <div class="route-content-speciality">
        <div class="route-speciality items-center secondary">
          <div class="route-speciality--box">
            <div class="box">
              <span class="box-header">Clinical Sector</span>
              <span class="box-label">
                <v-select
                  v-model="selectedSpeciality"
                  label="label"
                  :clearable="false"
                  :options="$store.state.prototypedata.clinical"
                />
              </span>
            </div>
          </div>
        </div>

        

        <!-- now check to see if any of the specialities areas have changed -->
       

        <div v-if="specialityType == 'primary'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Attendance Rate</th>
                    <th class="border px-4 py-2">Increase Treatment NCDs</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">Primary Care BP to focus on increased attendances for diabtetes, obesity, COPD, HPT to reduce acute attendances by 2026</td>
                    <td class="border" style="padding: 10px;">Increased Primary Care Telemedicine</td>                    
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'acute'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stroke Multi-Centre</th>
                    <th class="border px-4 py-2">Diagnostics Bottleneck</th>
                    <th class="border px-4 py-2">Winter Plan</th>
                    <th class="border px-4 py-2">Total System Discharge Plan</th>
                    <th class="border px-4 py-2">ER Plan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">Establish Acute Clinical Strategy focussing on Elective Delivery, Expanded OR hours,Ringfenced Trauma, 6 Week Elective Planning Routine</td>
                    <td class="border" style="padding: 10px;">Activity Demand for additional CT, MR and US</td>      
                    <td class="border" style="padding: 10px;">Forward-Planning for increased Day Surgery Winter Period</td>  
                    <td class="border" style="padding: 10px;">Establish Home Healthcare Service with additional Nursing and Physio Staff, Hire drivers, lease vehicles, additional Pharmacy hours</td>  
                    <td class="border" style="padding: 10px;">Virtual Triage, Upskill Operational Management Team, Additional Specialist Consultant Attendance ER - Cardiology, Elderly Care, Orthopaedics, Internal Medicine</td>  
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">
                        <ul style="list-style-type: none">
                          <li>1. Elective Care Recovery Plan to reduce by October 23</li>
                          <li>2. Establish Elective 6-Week Advanced Planner by April 23</li>
                          <li>3. Establish Winter Plan 23 by June 23.</li>
                        </ul>
                    </td>
                    <td class="border" style="padding: 10px;">
                        <ul style="list-style-type: none">
                          <li>4. Confirm Purchase of additional [x] CT, [y] MRI, [z] US.</li>
                          <li>5. Source capacity in private sector at reduced price discount for block purchase</li>                
                        </ul>
                    </td>                    
                    <td class="border" style="padding: 10px;">
                        <ul style="list-style-type: none">
                          <li>6. Establish Day Surgery Planning and Capacity at minimum 30% levels of elective surgery referrals by August 23</li>                     
                        </ul>
                    </td>   
                    <td class="border" style="padding: 10px;">
                        <ul style="list-style-type: none">
                          <li>7.  Commence HHC service by July 23 utilising existing excess staffing from Nursing, AHP, Admin, Drivers</li>                  
                        </ul>
                    </td>   
                    <td class="border" style="padding: 10px;">
                      <ul style="list-style-type: none">
                          <li>9.  Confirm Consultant Job-Planning/Bed Management Pas to include ER attendance 1 x Hour per Bed Management PA</li>
                       </ul>
                    </td>                  
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">Higher asset utilisation = increased maintenance and property costs</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"></td>                       
                    <td class="border" style="padding: 10px;"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'tertiary'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Referrals for Cardiac, Neuro</th>
                    <th class="border px-4 py-2">Limited Tertiary inflow</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">Identify efficiency in current costs for tertiary patients.</td>
                    <td class="border" style="padding: 10px;"></td>      
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">
                        <ul style="list-style-type: none">
                          <li>10.  Reduce Tertiary Referral Costs by 15% by renegotiation of contracts, or outsourcing to private providers</li>                          
                        </ul>
                    </td>
                    <td class="border" style="padding: 10px;"></td>                                                
                  </tr>                  
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'mental'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                <tr>
                  <th class="border px-4 py-2">Mental Health OPD</th>
                  <th class="border px-4 py-2">Non-Elective Care - Out of Hours service</th>
                </tr>
             </thead>
             <tbody>
               <tr>
                  <td class="border">
                    <div>Increase levels of Mental Health provision. Telemedical Appointments increase</div>
                  </td>
                  <td class="border">
                    <div>Move Mental Health practitioners directly into Acute ER</div>
                  </td>
                </tr>

                <tr>
                  <td class="border">
                    <div>11.  Increase Out of Hours MH Support to reduce ER blockage</div>
                  </td>
                  <td class="border"></td>
                </tr>
             </tbody>
              </table>
            </div>
          </div>
        </div>


        <div v-if="specialityType == 'virt'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                <tr>
                  <th class="border px-4 py-2">Virtual OPD  for benign follow-ups</th>                
                </tr>
             </thead>
             <tbody>               
                <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;"></td>
                </tr>                
             </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'long'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                <tr>
                  <th class="border px-4 py-2">Capacity to convert multiple beds into LTC facilities.</th>
                  <th class="border px-4 py-2">Reduced medical staff</th>
                  <th class="border px-4 py-2">Enable discharge process into LTC facility</th>
                  <th class="border px-4 py-2">Lowered costs</th>
                </tr>
             </thead>
             <tbody>
              <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">Establish [300] Long Term Care Beds</td>
                    <td class="border" style="padding: 10px;">Establish 80 LTC Mental Health Beds</td>      
                    <td class="border" style="padding: 10px;">Establish 40 LTC Paediatric Mental Health Beds</td>
                    <td class="border" style="padding: 10px;"></td>
                  </tr>
                  <tr style="height: 0; padding: 10px;">
                    <td class="border" style="padding: 10px;">
                        <ul style="list-style-type: none">
                          <li>12.  Establish LTC beds by October 23 through rationalisation of existing assets and workforce.  (Cost Neutral)</li>                          
                        </ul>
                    </td>
                    <td class="border" style="padding: 10px;"></td>    
                    <td class="border" style="padding: 10px;"></td> 
                    <td class="border" style="padding: 10px;"></td>                                             
                  </tr>     
             </tbody>
              </table>
            </div>
          </div>
        </div>



       
      </div>
    </div>

   
 </div>
</template>
<script>


export default {
  data() {
    return {
      stageSummary: "stage",
      selectedSpeciality: "Acute",
      apiPayload: { speciality: "", apiString: "" },
      activeSummary: "",
      selectedSpecialityArea: "Patient Episodes",
      specialityType: "acute",
      specialityApi: "ahpstwo-collection",
      firstUpdate: false,
      button1: true,
      button2: false,
      button3: false,
      button4: false,
      button5: false, 
    };
  },
  updated() {
   
  },
  beforeMount() {
    console.log("before mounted");
  },
  mounted() {
    console.log("mounted");
    this.activeSummary = "workforceCost";
  },
  watch: {
    selectedSpeciality: {
      handler: function (val) {
        console.log("selectedSpeciality: label: ", val.label);
        console.log("selectedSpeciality: string: ", val.string);
      
        this.editSpecialityType(val.string);
        //console.log("speciality: ", this.specialityApi);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      },
      deep: true,
    },
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },
    specialityName() {
      return this.$store.state.prototypedata.specialityName;
    },
    specialityData() {
      return true;
    },  
  },
  methods: {  
    specialityHeader(header) {
      return header;
    },
    editActiveStageSummary(activeSummary) {
      this.activeSummary = activeSummary;
    },
    editSpecialityType(specialityTypeName) {

      console.log ("specialityTypeName: ", specialityTypeName);

      if (specialityTypeName == "primary") {
        this.specialityType = "primary";
      }
      if (specialityTypeName == "acute") {
        this.specialityType = "acute";
      }
      if (specialityTypeName == "tertiary") {
        this.specialityType = "tertiary";
      }
      if (specialityTypeName == "mental") {
        this.specialityType = "mental";
      }
      if (specialityTypeName == "virt") {
        this.specialityType = "virt";
      }
      if (specialityTypeName == "long") {
        this.specialityType = "long";
      }

      console.log(
        "editSpecialityType (",
        specialityTypeName,
        ")=",
        this.specialityType
      );
    },
    showButton1() {
      this.button1 = true;
      this.button2 = false;
      this.button3 = false;
      this.button4 = false;
      this.button5 = false;
    },
    showButton2() {
      this.button1 = false;
      this.button2 = true;
      this.button3 = false;
      this.button4 = false;
      this.button5 = false;
    },
    showButton3() {
      this.button1 = false;
      this.button2 = false;
      this.button3 = true;
      this.button4 = false;
      this.button5 = false;
    },
    showButton4() {
      this.button1 = false;
      this.button2 = false;
      this.button3 = false;
      this.button4 = true;
      this.button5 = false;
    },
    showButton5() {
      this.button1 = false;
      this.button2 = false;
      this.button3 = false;
      this.button4 = false;
      this.button5 = true;
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    editStageSummary(stageSummary) {
      this.stageSummary = stageSummary;

      if (this.stageSummary == "stage") {
        //this.apiPayload.apiString = "grandsummarytwo-collection";

        //console.log("stage: getData");

        //this.getMongoDBData();
        //this.fillData("workforceCost");
      }

      if (this.stageSummary == "buildingsFM") {
        //this.apiPayload.apiString = "fm_build2_collection";
        //this.getMongoDBData();
      }

      if (this.stageSummary == "specialities") {
        console.log(
          " this.selectedSpecialityArea.selected",
          this.selectedSpecialityArea
        );
      }
    },
  },
};
</script>
<style>
.chartcontainer {
  border: 1px solid;
  border-color: #e5e9f2;
  height: 400px;
  display: inline-block;
  width: 350px; /* was 26 rem */
  margin-right: 20px;
  padding-bottom: 5px;
}
.title_text {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
</style>
