<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">Benchmark Data</h1>
        <!-- <span class="text-xl ml-2">(Stage 2)</span></h1> -->
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Surrey - United Kingdom</span>
        </div>
      </div>
      <!-- div class="route-header--box">
        <div class="box">
          <span class="box-header">Country</span>
          <span class="box-label">
            <v-select
              v-model="selectedCountry"
              label="label"
              :clearable="false"
              :options="$store.state.prototypedata.countryList"
            />
          </span>
        </div>
      </div -->
      <!--div class="route-header--box">
        <div class="box">
          <span class="box-header">Total Population</span>
          <span class="box-label">1,199,870</span>
        </div>
      </div-->
    </div>

    <nav class="route-nav">
      <ul>
        <li @click="editSummary('workforce')"
          :class="activeSummary == 'workforce' ? 'active' : ''">
          <span>Workforce</span>
        </li>
        <li @click="editSummary('facilities')"
          :class="activeSummary == 'facilities' ? 'active' : ''">
          <span>Facilities</span>
        </li>
        <li @click="editSummary('episodes')"
          :class="activeSummary == 'episodes' ? 'active' : ''">
          <span>Episodes</span>
        </li>
      </ul>
    </nav>

    <div class="route-content">
      <div v-if="activeSummary == 'workforce'">
        <table class="table-primary">
 <thead>
  <tr>
    <th class="border px-4 py-2">Country</th>
    <th class="border px-4 py-2">GPs</th>
    <th class="border px-4 py-2">Paediatricians</th>
    <th class="border px-4 py-2">Gynaecologist/Obs</th>
    <th class="border px-4 py-2">Psychiatrists</th>
    <th class="border px-4 py-2">Consultant Physicians</th>
    <th class="border px-4 py-2">Consultant Surgeons</th>
    <th class="border px-4 py-2">Nurses</th>
    <th class="border px-4 py-2">Midwives</th>
    <th class="border px-4 py-2">Pharmacists</th>
    <th class="border px-4 py-2">Physios</th>
    <th class="border px-4 py-2">Total Hospital Employees</th>
  </tr>
 </thead>
 <tbody>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Australia</td>
      <td class="border" style="padding: 10px;">1.63</td>
      <td class="border" style="padding: 10px;">0.1</td>
      <td class="border" style="padding: 10px;">0.09</td>
      <td class="border" style="padding: 10px;">0.17</td>
      <td class="border" style="padding: 10px;">0.58</td>
      <td class="border" style="padding: 10px;">0.67</td>
      <td class="border" style="padding: 10px;">11.92</td>
      <td class="border" style="padding: 10px;">0.85</td>
      <td class="border" style="padding: 10px;">0.88</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Austria</td>
      <td class="border" style="padding: 10px;">1.7</td>
      <td class="border" style="padding: 10px;">0.15</td>
      <td class="border" style="padding: 10px;">0.21</td>
      <td class="border" style="padding: 10px;">0.18</td>
      <td class="border" style="padding: 10px;">1.1</td>
      <td class="border" style="padding: 10px;">0.98</td>
      <td class="border" style="padding: 10px;">6.87</td>
      <td class="border" style="padding: 10px;">0.26</td>
      <td class="border" style="padding: 10px;">0.72</td>
      <td class="border" style="padding: 10px;">0.44</td>
      <td class="border" style="padding: 10px;">13.42</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Belgium</td>
      <td class="border" style="padding: 10px;">1.15</td>
      <td class="border" style="padding: 10px;">0.14</td>
      <td class="border" style="padding: 10px;">0.13</td>
      <td class="border" style="padding: 10px;">0.17</td>
      <td class="border" style="padding: 10px;">0.88</td>
      <td class="border" style="padding: 10px;">0.61</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">1.25</td>
      <td class="border" style="padding: 10px;">1.98</td>
      <td class="border" style="padding: 10px;">17.23</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Canada</td>
      <td class="border" style="padding: 10px;">1.32</td>
      <td class="border" style="padding: 10px;">0.1</td>
      <td class="border" style="padding: 10px;">0.08</td>
      <td class="border" style="padding: 10px;">0.17</td>
      <td class="border" style="padding: 10px;">0.66</td>
      <td class="border" style="padding: 10px;">0.42</td>
      <td class="border" style="padding: 10px;">9.95</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">1.03</td>
      <td class="border" style="padding: 10px;">0.63</td>
      <td class="border" style="padding: 10px;">17.33</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Chile</td>
      <td class="border" style="padding: 10px;">1.34</td>
      <td class="border" style="padding: 10px;">0.1</td>
      <td class="border" style="padding: 10px;">0.11</td>
      <td class="border" style="padding: 10px;">0.1</td>
      <td class="border" style="padding: 10px;">0.4</td>
      <td class="border" style="padding: 10px;">0.45</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">1.52</td>
      <td class="border" style="padding: 10px;">6.9</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Colombia</td>
      <td class="border" style="padding: 10px;">1.65</td>
      <td class="border" style="padding: 10px;">0.13</td>
      <td class="border" style="padding: 10px;">0.29</td>
      <td class="border" style="padding: 10px;">0.15</td>
      <td class="border" style="padding: 10px;">1.55</td>
      <td class="border" style="padding: 10px;">1.1</td>
      <td class="border" style="padding: 10px;">1.33</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Czech Republic</td>
      <td class="border" style="padding: 10px;">0.66</td>
      <td class="border" style="padding: 10px;">0.08</td>
      <td class="border" style="padding: 10px;">0.11</td>
      <td class="border" style="padding: 10px;">0.19</td>
      <td class="border" style="padding: 10px;">0.77</td>
      <td class="border" style="padding: 10px;">0.64</td>
      <td class="border" style="padding: 10px;">8.07</td>
      <td class="border" style="padding: 10px;">0.39</td>
      <td class="border" style="padding: 10px;">0.69</td>
      <td class="border" style="padding: 10px;">0.87</td>
      <td class="border" style="padding: 10px;">14.84</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Denmark</td>
      <td class="border" style="padding: 10px;">0.8</td>
      <td class="border" style="padding: 10px;">0.12</td>
      <td class="border" style="padding: 10px;">0.23</td>
      <td class="border" style="padding: 10px;">0.19</td>
      <td class="border" style="padding: 10px;">1.27</td>
      <td class="border" style="padding: 10px;">0.84</td>
      <td class="border" style="padding: 10px;">10.1</td>
      <td class="border" style="padding: 10px;">0.36</td>
      <td class="border" style="padding: 10px;">0.54</td>
      <td class="border" style="padding: 10px;">1.72</td>
      <td class="border" style="padding: 10px;">20.59</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Estonia</td>
      <td class="border" style="padding: 10px;">0.83</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">6.29</td>
      <td class="border" style="padding: 10px;">0.34</td>
      <td class="border" style="padding: 10px;">0.72</td>
      <td class="border" style="padding: 10px;">0.38</td>
      <td class="border" style="padding: 10px;">12.29</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Finland</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">0.12</td>
      <td class="border" style="padding: 10px;">0.12</td>
      <td class="border" style="padding: 10px;">0.23</td>
      <td class="border" style="padding: 10px;">0.77</td>
      <td class="border" style="padding: 10px;">0.48</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">France</td>
      <td class="border" style="padding: 10px;">1.42</td>
      <td class="border" style="padding: 10px;">0.17</td>
      <td class="border" style="padding: 10px;">0.26</td>
      <td class="border" style="padding: 10px;">0.27</td>
      <td class="border" style="padding: 10px;">1.39</td>
      <td class="border" style="padding: 10px;">1.2</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0.34</td>
      <td class="border" style="padding: 10px;">1.03</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">19.67</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Germany</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">0.4</td>
      <td class="border" style="padding: 10px;">0.32</td>
      <td class="border" style="padding: 10px;">0.26</td>
      <td class="border" style="padding: 10px;">2.68</td>
      <td class="border" style="padding: 10px;">1.44</td>
      <td class="border" style="padding: 10px;">13.22</td>
      <td class="border" style="padding: 10px;">0.29</td>
      <td class="border" style="padding: 10px;">0.66</td>
      <td class="border" style="padding: 10px;">2.29</td>
      <td class="border" style="padding: 10px;">..</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Greece</td>
      <td class="border" style="padding: 10px;">0.41</td>
      <td class="border" style="padding: 10px;">0.24</td>
      <td class="border" style="padding: 10px;">0.15</td>
      <td class="border" style="padding: 10px;">0.15</td>
      <td class="border" style="padding: 10px;">1.3</td>
      <td class="border" style="padding: 10px;">0.7</td>
      <td class="border" style="padding: 10px;">3.37</td>
      <td class="border" style="padding: 10px;">0.26</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0.79</td>
      <td class="border" style="padding: 10px;">9.38</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Hungary</td>
      <td class="border" style="padding: 10px;">0.73</td>
      <td class="border" style="padding: 10px;">0.04</td>
      <td class="border" style="padding: 10px;">0.15</td>
      <td class="border" style="padding: 10px;">0.24</td>
      <td class="border" style="padding: 10px;">1.11</td>
      <td class="border" style="padding: 10px;">0.73</td>
      <td class="border" style="padding: 10px;">6.62</td>
      <td class="border" style="padding: 10px;">0.25</td>
      <td class="border" style="padding: 10px;">0.8</td>
      <td class="border" style="padding: 10px;">0.54</td>
      <td class="border" style="padding: 10px;">10.87</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Iceland</td>
      <td class="border" style="padding: 10px;">0.62</td>
      <td class="border" style="padding: 10px;">0.1</td>
      <td class="border" style="padding: 10px;">0.08</td>
      <td class="border" style="padding: 10px;">0.17</td>
      <td class="border" style="padding: 10px;">0.61</td>
      <td class="border" style="padding: 10px;">0.47</td>
      <td class="border" style="padding: 10px;">14.67</td>
      <td class="border" style="padding: 10px;">0.81</td>
      <td class="border" style="padding: 10px;">0.52</td>
      <td class="border" style="padding: 10px;">1.73</td>
      <td class="border" style="padding: 10px;">20.99</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Ireland</td>
      <td class="border" style="padding: 10px;">1.84</td>
      <td class="border" style="padding: 10px;">0.23</td>
      <td class="border" style="padding: 10px;">0.21</td>
      <td class="border" style="padding: 10px;">0.16</td>
      <td class="border" style="padding: 10px;">1.08</td>
      <td class="border" style="padding: 10px;">0.65</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">0.97</td>
      <td class="border" style="padding: 10px;">13.49</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Israel</td>
      <td class="border" style="padding: 10px;">0.99</td>
      <td class="border" style="padding: 10px;">0.28</td>
      <td class="border" style="padding: 10px;">0.2</td>
      <td class="border" style="padding: 10px;">0.17</td>
      <td class="border" style="padding: 10px;">1.45</td>
      <td class="border" style="padding: 10px;">0.99</td>
      <td class="border" style="padding: 10px;">5.03</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0.87</td>
      <td class="border" style="padding: 10px;">1.33</td>
      <td class="border" style="padding: 10px;">11.11</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Italy</td>
      <td class="border" style="padding: 10px;">0.88</td>
      <td class="border" style="padding: 10px;">0.14</td>
      <td class="border" style="padding: 10px;">0.1</td>
      <td class="border" style="padding: 10px;">0.13</td>
      <td class="border" style="padding: 10px;">0.65</td>
      <td class="border" style="padding: 10px;">0.66</td>
      <td class="border" style="padding: 10px;">5.74</td>
      <td class="border" style="padding: 10px;">0.28</td>
      <td class="border" style="padding: 10px;">1.19</td>
      <td class="border" style="padding: 10px;">1.02</td>
      <td class="border" style="padding: 10px;">10.47</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Korea</td>
      <td class="border" style="padding: 10px;">0.65</td>
      <td class="border" style="padding: 10px;">0.13</td>
      <td class="border" style="padding: 10px;">0.12</td>
      <td class="border" style="padding: 10px;">0.08</td>
      <td class="border" style="padding: 10px;">0.96</td>
      <td class="border" style="padding: 10px;">0.72</td>
      <td class="border" style="padding: 10px;">7.24</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0.73</td>
      <td class="border" style="padding: 10px;">0.74</td>
      <td class="border" style="padding: 10px;">7.79</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Latvia</td>
      <td class="border" style="padding: 10px;">0.73</td>
      <td class="border" style="padding: 10px;">0.13</td>
      <td class="border" style="padding: 10px;">0.2</td>
      <td class="border" style="padding: 10px;">0.16</td>
      <td class="border" style="padding: 10px;">1.65</td>
      <td class="border" style="padding: 10px;">1.11</td>
      <td class="border" style="padding: 10px;">4.35</td>
      <td class="border" style="padding: 10px;">0.21</td>
      <td class="border" style="padding: 10px;">0.86</td>
      <td class="border" style="padding: 10px;">0.42</td>
      <td class="border" style="padding: 10px;">11.08</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Lithuania</td>
      <td class="border" style="padding: 10px;">1.03</td>
      <td class="border" style="padding: 10px;">0.25</td>
      <td class="border" style="padding: 10px;">0.24</td>
      <td class="border" style="padding: 10px;">0.23</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">7.78</td>
      <td class="border" style="padding: 10px;">0.32</td>
      <td class="border" style="padding: 10px;">1.03</td>
      <td class="border" style="padding: 10px;">1.34</td>
      <td class="border" style="padding: 10px;">15.6</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Luxembourg</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">0.84</td>
      <td class="border" style="padding: 10px;">0.16</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Mexico</td>
      <td class="border" style="padding: 10px;">0.86</td>
      <td class="border" style="padding: 10px;">0.16</td>
      <td class="border" style="padding: 10px;">0.19</td>
      <td class="border" style="padding: 10px;">0.01</td>
      <td class="border" style="padding: 10px;">0.92</td>
      <td class="border" style="padding: 10px;">0.42</td>
      <td class="border" style="padding: 10px;">2.87</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">6.95</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Netherlands</td>
      <td class="border" style="padding: 10px;">1.67</td>
      <td class="border" style="padding: 10px;">0.11</td>
      <td class="border" style="padding: 10px;">0.1</td>
      <td class="border" style="padding: 10px;">0.24</td>
      <td class="border" style="padding: 10px;">0.73</td>
      <td class="border" style="padding: 10px;">0.72</td>
      <td class="border" style="padding: 10px;">11.13</td>
      <td class="border" style="padding: 10px;">0.24</td>
      <td class="border" style="padding: 10px;">0.21</td>
      <td class="border" style="padding: 10px;">1.99</td>
      <td class="border" style="padding: 10px;">16.05</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">New Zealand</td>
      <td class="border" style="padding: 10px;">1.39</td>
      <td class="border" style="padding: 10px;">0.13</td>
      <td class="border" style="padding: 10px;">0.1</td>
      <td class="border" style="padding: 10px;">0.19</td>
      <td class="border" style="padding: 10px;">0.85</td>
      <td class="border" style="padding: 10px;">0.57</td>
      <td class="border" style="padding: 10px;">10.34</td>
      <td class="border" style="padding: 10px;">0.56</td>
      <td class="border" style="padding: 10px;">0.7</td>
      <td class="border" style="padding: 10px;">1.12</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Norway</td>
      <td class="border" style="padding: 10px;">0.88</td>
      <td class="border" style="padding: 10px;">0.17</td>
      <td class="border" style="padding: 10px;">0.12</td>
      <td class="border" style="padding: 10px;">0.26</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">17.74</td>
      <td class="border" style="padding: 10px;">0.55</td>
      <td class="border" style="padding: 10px;">0.83</td>
      <td class="border" style="padding: 10px;">2.46</td>
      <td class="border" style="padding: 10px;">21.86</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Poland</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">1.24</td>
      <td class="border" style="padding: 10px;">0.76</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Portugal</td>
      <td class="border" style="padding: 10px;">2.69</td>
      <td class="border" style="padding: 10px;">0.21</td>
      <td class="border" style="padding: 10px;">0.18</td>
      <td class="border" style="padding: 10px;">0.13</td>
      <td class="border" style="padding: 10px;">1.08</td>
      <td class="border" style="padding: 10px;">0.67</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0.91</td>
      <td class="border" style="padding: 10px;">0.14</td>
      <td class="border" style="padding: 10px;">13.37</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Slovenia</td>
      <td class="border" style="padding: 10px;">0.68</td>
      <td class="border" style="padding: 10px;">0.32</td>
      <td class="border" style="padding: 10px;">0.18</td>
      <td class="border" style="padding: 10px;">0.15</td>
      <td class="border" style="padding: 10px;">1.1</td>
      <td class="border" style="padding: 10px;">0.98</td>
      <td class="border" style="padding: 10px;">10.14</td>
      <td class="border" style="padding: 10px;">0.11</td>
      <td class="border" style="padding: 10px;">0.71</td>
      <td class="border" style="padding: 10px;">0.36</td>
      <td class="border" style="padding: 10px;">12.61</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Spain</td>
      <td class="border" style="padding: 10px;">0.76</td>
      <td class="border" style="padding: 10px;">0.27</td>
      <td class="border" style="padding: 10px;">0.12</td>
      <td class="border" style="padding: 10px;">0.11</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">5.87</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">1.19</td>
      <td class="border" style="padding: 10px;">0.68</td>
      <td class="border" style="padding: 10px;">12.59</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Sweden</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">0.79</td>
      <td class="border" style="padding: 10px;">0.83</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">1.16</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Switzerland</td>
      <td class="border" style="padding: 10px;">1.14</td>
      <td class="border" style="padding: 10px;">0.22</td>
      <td class="border" style="padding: 10px;">0.22</td>
      <td class="border" style="padding: 10px;">0.52</td>
      <td class="border" style="padding: 10px;">0.54</td>
      <td class="border" style="padding: 10px;">0.44</td>
      <td class="border" style="padding: 10px;">17.59</td>
      <td class="border" style="padding: 10px;">0.33</td>
      <td class="border" style="padding: 10px;">0.69</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">25.45</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Turkey</td>
      <td class="border" style="padding: 10px;">0.6</td>
      <td class="border" style="padding: 10px;">0.1</td>
      <td class="border" style="padding: 10px;">0.1</td>
      <td class="border" style="padding: 10px;">0.05</td>
      <td class="border" style="padding: 10px;">0.72</td>
      <td class="border" style="padding: 10px;">0.82</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0.07</td>
      <td class="border" style="padding: 10px;">9.89</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">United Kingdom</td>
      <td class="border" style="padding: 10px;">0.75</td>
      <td class="border" style="padding: 10px;">0.16</td>
      <td class="border" style="padding: 10px;">0.12</td>
      <td class="border" style="padding: 10px;">0.18</td>
      <td class="border" style="padding: 10px;">0.75</td>
      <td class="border" style="padding: 10px;">0.39</td>
      <td class="border" style="padding: 10px;">7.78</td>
      <td class="border" style="padding: 10px;">0.47</td>
      <td class="border" style="padding: 10px;">0.9</td>
      <td class="border" style="padding: 10px;">0.44</td>
      <td class="border" style="padding: 10px;">22.48</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">United States</td>
      <td class="border" style="padding: 10px;">0.3</td>
      <td class="border" style="padding: 10px;">0.26</td>
      <td class="border" style="padding: 10px;">0.13</td>
      <td class="border" style="padding: 10px;">0.14</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0.7</td>
      <td class="border" style="padding: 10px;">..</td>
   </tr>
  </tbody>
 </table>
  
        
     

      </div>

      <div v-if="activeSummary == 'facilities'">
        <!-- h1 class="text-md active">Facilities</h1 -->    

        <table class="table-primary">
 <thead>
  <tr>
    <th class="border px-4 py-2">Country</th>
    <th class="border px-4 py-2">Beds</th>
    <th class="border px-4 py-2">Nurse to bed ratio</th>
  </tr>
 </thead>
 <tbody>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Australia</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Austria</td>
      <td class="border" style="padding: 10px;">7.27</td>
      <td class="border" style="padding: 10px;">0.8</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Belgium</td>
      <td class="border" style="padding: 10px;">5.62</td>
      <td class="border" style="padding: 10px;">0.97</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Canada</td>
      <td class="border" style="padding: 10px;">2.55</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Chile</td>
      <td class="border" style="padding: 10px;">2.06</td>
      <td class="border" style="padding: 10px;">0.46</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Colombia</td>
      <td class="border" style="padding: 10px;">1.71</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Czech Republic</td>
      <td class="border" style="padding: 10px;">6.62</td>
      <td class="border" style="padding: 10px;">0.75</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Denmark</td>
      <td class="border" style="padding: 10px;">2.43</td>
      <td class="border" style="padding: 10px;">2.63</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Estonia</td>
      <td class="border" style="padding: 10px;">4.57</td>
      <td class="border" style="padding: 10px;">0.81</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Finland</td>
      <td class="border" style="padding: 10px;">3.61</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">France</td>
      <td class="border" style="padding: 10px;">5.91</td>
      <td class="border" style="padding: 10px;">0.9</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Germany</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Greece</td>
      <td class="border" style="padding: 10px;">4.2</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Hungary</td>
      <td class="border" style="padding: 10px;">7.01</td>
      <td class="border" style="padding: 10px;">0.5</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Iceland</td>
      <td class="border" style="padding: 10px;">2.87</td>
      <td class="border" style="padding: 10px;">2.1</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Ireland</td>
      <td class="border" style="padding: 10px;">2.97</td>
      <td class="border" style="padding: 10px;">1.55</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Israel</td>
      <td class="border" style="padding: 10px;">2.98</td>
      <td class="border" style="padding: 10px;">1</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Italy</td>
      <td class="border" style="padding: 10px;">3.14</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Japan</td>
      <td class="border" style="padding: 10px;">12.98</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Korea</td>
      <td class="border" style="padding: 10px;">12.43</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Latvia</td>
      <td class="border" style="padding: 10px;">5.49</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Lithuania</td>
      <td class="border" style="padding: 10px;">6.43</td>
      <td class="border" style="padding: 10px;">0.86</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Luxembourg</td>
      <td class="border" style="padding: 10px;">4.51</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Mexico</td>
      <td class="border" style="padding: 10px;">0.98</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Netherlands</td>
      <td class="border" style="padding: 10px;">3.17</td>
      <td class="border" style="padding: 10px;">1.11</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">New Zealand</td>
      <td class="border" style="padding: 10px;">2.62</td>
      <td class="border" style="padding: 10px;">1.88</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Norway</td>
      <td class="border" style="padding: 10px;">3.53</td>
      <td class="border" style="padding: 10px;">2.27</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Poland</td>
      <td class="border" style="padding: 10px;">6.54</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Portugal</td>
      <td class="border" style="padding: 10px;">3.45</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Slovenia</td>
      <td class="border" style="padding: 10px;">4.43</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Spain</td>
      <td class="border" style="padding: 10px;">2.97</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Sweden</td>
      <td class="border" style="padding: 10px;">2.14</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Switzerland</td>
      <td class="border" style="padding: 10px;">4.63</td>
      <td class="border" style="padding: 10px;">1.48</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Turkey</td>
      <td class="border" style="padding: 10px;">2.85</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">United Kingdom</td>
      <td class="border" style="padding: 10px;">2.5</td>
      <td class="border" style="padding: 10px;">2.63</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">United States</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
  </tbody>
 </table>

      </div>

      <div v-if="activeSummary == 'episodes'">
        <!-- h1 class="text-md active">Episodes</h1 -->    

        <table class="table-primary">
 <thead>
  <tr>
    <th class="border px-4 py-2">Country</th>
    <th class="border px-4 py-2">Outpatient Consultations</th>
    <th class="border px-4 py-2">CT</th>
    <th class="border px-4 py-2">MRI</th>
    <th class="border px-4 py-2">PET</th>
    <th class="border px-4 py-2">Inpatient Procedures</th>
    <th class="border px-4 py-2">Day Case Procedures</th>
    <th class="border px-4 py-2">Outpatient Procedures</th>
  </tr>
 </thead>
 <tbody>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Australia</td>
      <td class="border" style="padding: 10px;">7.8</td>
      <td class="border" style="padding: 10px;">134.6</td>
      <td class="border" style="padding: 10px;">48.1</td>
      <td class="border" style="padding: 10px;">3.5</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Austria</td>
      <td class="border" style="padding: 10px;">6.6</td>
      <td class="border" style="padding: 10px;">183.6</td>
      <td class="border" style="padding: 10px;">141.4</td>
      <td class="border" style="padding: 10px;">4.8</td>
      <td class="border" style="padding: 10px;">183.8</td>
      <td class="border" style="padding: 10px;">1175.8</td>
      <td class="border" style="padding: 10px;">0.6</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Belgium</td>
      <td class="border" style="padding: 10px;">7.2</td>
      <td class="border" style="padding: 10px;">201.9</td>
      <td class="border" style="padding: 10px;">95.4</td>
      <td class="border" style="padding: 10px;">9.2</td>
      <td class="border" style="padding: 10px;">41.7</td>
      <td class="border" style="padding: 10px;">1052.6</td>
      <td class="border" style="padding: 10px;">..</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Canada</td>
      <td class="border" style="padding: 10px;">6.7</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">2.2</td>
      <td class="border" style="padding: 10px;">1125.6</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Chile</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">18.7</td>
      <td class="border" style="padding: 10px;">264.2</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Colombia</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Czech Republic</td>
      <td class="border" style="padding: 10px;">8.2</td>
      <td class="border" style="padding: 10px;">110.9</td>
      <td class="border" style="padding: 10px;">54.7</td>
      <td class="border" style="padding: 10px;">5.2</td>
      <td class="border" style="padding: 10px;">28.1</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">1212.4</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Denmark</td>
      <td class="border" style="padding: 10px;">3.8</td>
      <td class="border" style="padding: 10px;">184.6</td>
      <td class="border" style="padding: 10px;">89.1</td>
      <td class="border" style="padding: 10px;">10.2</td>
      <td class="border" style="padding: 10px;">7</td>
      <td class="border" style="padding: 10px;">986.9</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Estonia</td>
      <td class="border" style="padding: 10px;">5.6</td>
      <td class="border" style="padding: 10px;">133.5</td>
      <td class="border" style="padding: 10px;">50.3</td>
      <td class="border" style="padding: 10px;">1.1</td>
      <td class="border" style="padding: 10px;">10.6</td>
      <td class="border" style="padding: 10px;">1375.7</td>
      <td class="border" style="padding: 10px;">5.1</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Finland</td>
      <td class="border" style="padding: 10px;">4.4</td>
      <td class="border" style="padding: 10px;">57.5</td>
      <td class="border" style="padding: 10px;">49.5</td>
      <td class="border" style="padding: 10px;">0.9</td>
      <td class="border" style="padding: 10px;">12.1</td>
      <td class="border" style="padding: 10px;">825</td>
      <td class="border" style="padding: 10px;">300.9</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">France</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">195.7</td>
      <td class="border" style="padding: 10px;">119.6</td>
      <td class="border" style="padding: 10px;">9.1</td>
      <td class="border" style="padding: 10px;">71.6</td>
      <td class="border" style="padding: 10px;">1270.5</td>
      <td class="border" style="padding: 10px;">0</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Germany</td>
      <td class="border" style="padding: 10px;">9.9</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">1.8</td>
      <td class="border" style="padding: 10px;">181.6</td>
      <td class="border" style="padding: 10px;">2.9</td>
      <td class="border" style="padding: 10px;">864.9</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Greece</td>
      <td class="border" style="padding: 10px;">3.3</td>
      <td class="border" style="padding: 10px;">213.9</td>
      <td class="border" style="padding: 10px;">83.4</td>
      <td class="border" style="padding: 10px;">2.3</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Hungary</td>
      <td class="border" style="padding: 10px;">10.7</td>
      <td class="border" style="padding: 10px;">131.7</td>
      <td class="border" style="padding: 10px;">45.4</td>
      <td class="border" style="padding: 10px;">1.9</td>
      <td class="border" style="padding: 10px;">408.4</td>
      <td class="border" style="padding: 10px;">562.6</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Iceland</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">227.3</td>
      <td class="border" style="padding: 10px;">102.8</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">2.6</td>
      <td class="border" style="padding: 10px;">904.7</td>
      <td class="border" style="padding: 10px;">..</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Ireland</td>
      <td class="border" style="padding: 10px;">5</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">11.7</td>
      <td class="border" style="padding: 10px;">255.7</td>
      <td class="border" style="padding: 10px;">0</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Israel</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">146.6</td>
      <td class="border" style="padding: 10px;">46.6</td>
      <td class="border" style="padding: 10px;">8.7</td>
      <td class="border" style="padding: 10px;">44.3</td>
      <td class="border" style="padding: 10px;">351.8</td>
      <td class="border" style="padding: 10px;">337.3</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Italy</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">93.6</td>
      <td class="border" style="padding: 10px;">73.7</td>
      <td class="border" style="padding: 10px;">5.6</td>
      <td class="border" style="padding: 10px;">26.5</td>
      <td class="border" style="padding: 10px;">60.4</td>
      <td class="border" style="padding: 10px;">829.6</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Japan</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Korea</td>
      <td class="border" style="padding: 10px;">16.9</td>
      <td class="border" style="padding: 10px;">228.1</td>
      <td class="border" style="padding: 10px;">49</td>
      <td class="border" style="padding: 10px;">3.7</td>
      <td class="border" style="padding: 10px;">73.9</td>
      <td class="border" style="padding: 10px;">1073</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Latvia</td>
      <td class="border" style="padding: 10px;">6</td>
      <td class="border" style="padding: 10px;">180.8</td>
      <td class="border" style="padding: 10px;">64.6</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">62.2</td>
      <td class="border" style="padding: 10px;">1498.7</td>
      <td class="border" style="padding: 10px;">95.1</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Lithuania</td>
      <td class="border" style="padding: 10px;">9.9</td>
      <td class="border" style="padding: 10px;">114.2</td>
      <td class="border" style="padding: 10px;">57.5</td>
      <td class="border" style="padding: 10px;">0.6</td>
      <td class="border" style="padding: 10px;">456.9</td>
      <td class="border" style="padding: 10px;">162.3</td>
      <td class="border" style="padding: 10px;">262.7</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Luxembourg</td>
      <td class="border" style="padding: 10px;">5.8</td>
      <td class="border" style="padding: 10px;">218.5</td>
      <td class="border" style="padding: 10px;">83</td>
      <td class="border" style="padding: 10px;">5.5</td>
      <td class="border" style="padding: 10px;">85</td>
      <td class="border" style="padding: 10px;">935.1</td>
      <td class="border" style="padding: 10px;">302.8</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Mexico</td>
      <td class="border" style="padding: 10px;">2.8</td>
      <td class="border" style="padding: 10px;">94.8</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">28.2</td>
      <td class="border" style="padding: 10px;">48.3</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Netherlands</td>
      <td class="border" style="padding: 10px;">9</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">52.2</td>
      <td class="border" style="padding: 10px;">6.5</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">New Zealand</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Norway</td>
      <td class="border" style="padding: 10px;">4.5</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">14.5</td>
      <td class="border" style="padding: 10px;">316.3</td>
      <td class="border" style="padding: 10px;">51.3</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Poland</td>
      <td class="border" style="padding: 10px;">7.6</td>
      <td class="border" style="padding: 10px;">85.4</td>
      <td class="border" style="padding: 10px;">37.2</td>
      <td class="border" style="padding: 10px;">1.6</td>
      <td class="border" style="padding: 10px;">333.6</td>
      <td class="border" style="padding: 10px;">317.1</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Portugal</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Slovak Republic</td>
      <td class="border" style="padding: 10px;">10.9</td>
      <td class="border" style="padding: 10px;">155.2</td>
      <td class="border" style="padding: 10px;">69.5</td>
      <td class="border" style="padding: 10px;">2.3</td>
      <td class="border" style="padding: 10px;">89.8</td>
      <td class="border" style="padding: 10px;">675.4</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Slovenia</td>
      <td class="border" style="padding: 10px;">6.6</td>
      <td class="border" style="padding: 10px;">76</td>
      <td class="border" style="padding: 10px;">69.7</td>
      <td class="border" style="padding: 10px;">0.2</td>
      <td class="border" style="padding: 10px;">16</td>
      <td class="border" style="padding: 10px;">0.4</td>
      <td class="border" style="padding: 10px;">988.9</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Spain</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">118.8</td>
      <td class="border" style="padding: 10px;">92.4</td>
      <td class="border" style="padding: 10px;">4.2</td>
      <td class="border" style="padding: 10px;">9.7</td>
      <td class="border" style="padding: 10px;">819.1</td>
      <td class="border" style="padding: 10px;">0</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Sweden</td>
      <td class="border" style="padding: 10px;">2.7</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">17.8</td>
      <td class="border" style="padding: 10px;">1105.5</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Switzerland</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">50.4</td>
      <td class="border" style="padding: 10px;">354.7</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Turkey</td>
      <td class="border" style="padding: 10px;">9.5</td>
      <td class="border" style="padding: 10px;">225.1</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">3.7</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">United Kingdom</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">7.9</td>
      <td class="border" style="padding: 10px;">761.8</td>
      <td class="border" style="padding: 10px;">11.7</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">United States</td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;">271.5</td>
      <td class="border" style="padding: 10px;">119.4</td>
      <td class="border" style="padding: 10px;">6.4</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">..</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
  </tbody>
 </table>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeSummary: 'workforce',
      //selectedCountry: 'Korea',
    };
  },
  methods:
  {
    editSummary(summary) {
      this.activeSummary = summary;
      }
    },
};

</script>
<style>
.v-text-field.v-text-field--solo .v-input__control{
    min-height: 100px;
}

</style>
