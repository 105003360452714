<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">Specialities - Stage 3</h1>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">United Kingdom</span>
        </div>
      </div>
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Speciality</span>
          <span class="box-label">
            <v-select
              v-model="selectedSpeciality"
              label="speciality"
              :clearable="false"
              @input="fetchYear"
              :options="$store.state.prototypedata.specialities"
            />
          </span>
        </div>
        <div class="box">
          <span class="box-header">Year</span>
          <span class="box-label">
            <v-select
              v-model="selectedYear"
              label="year"
              :clearable="false"
              @input="fetchYear"
              :options="$store.state.prototypedata.years"
            />
          </span>
        </div>
      </div>
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Total Population</span>
          <span class="box-label">1,199,870</span>
        </div>
      </div>
    </div>

    <nav class="route-nav">
      <ul>
        <li
          @click="editActiveSummary('patientEpisodes')"
          :class="activeSummary == 'patientEpisodes' ? 'active' : ''"
        >
          <span>Patient Episodes</span>
        </li>
        <li
          @click="editActiveSummary('spaceProperty')"
          :class="activeSummary == 'spaceProperty' ? 'active' : ''"
        >
          <span>Space & Property</span>
        </li>
        <li
          @click="editActiveSummary('finance')"
          :class="activeSummary == 'finance' ? 'active' : ''"
        >
          <span>Finance</span>
        </li>
        <li
          @click="editActiveSummary('workforce')"
          :class="activeSummary == 'workforce' ? 'active' : ''"
        >
          <span>Workforce</span>
        </li>
      </ul>
    </nav>

    <div class="route-content">
      <div v-if="activeSummary == 'patientEpisodes'">
        <h1 class="text-3xl my-6 active">Patient Episodes</h1>

        <table class="table-primary" v-if="specialityData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Patient Episodes</th>
              <th class="border px-4 py-2">Patient Episodes Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(
                    patientEpisodes, index
                  ) in specialityPatientEpisodesFilter"
                  :key="index"
                >
                  {{ patientEpisodes }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(
                    patientEpisodesQuantity, index
                  ) in specialityPatientEpisodesTotalFilter"
                  :key="index"
                >
                  {{ patientEpisodesQuantity | numeral }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="activeSummary == 'spaceProperty'">
        <h1 class="text-3xl my-6 active">Space & Property</h1>

        <table class="table-primary" v-if="specialityData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Space & Property</th>
              <th class="border px-4 py-2">Quantity</th>
              <th class="border px-4 py-2">Capital Cost Per Item</th>
              <th class="border px-4 py-2">Total Capital Cost</th>
              <th class="border px-4 py-2">Life Cycle</th>
              <th class="border px-4 py-2">Annualised Cost</th>
              <th class="border px-4 py-2">Annualised Maintenance Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(
                    spaceAndProperty, index
                  ) in specialitySpaceAndPropertyFilter"
                  :key="index"
                >
                  {{ spaceAndProperty }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(
                    spaceAndPropertyQuantity, index
                  ) in specialitySpaceAndPropertyQuantityFilter"
                  :key="index"
                >
                  {{ spaceAndPropertyQuantity | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(
                    capitalCostPerItem, index
                  ) in specialityCapitalCostPerItemFilter"
                  :key="index"
                >
                  {{ capitalCostPerItem | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(
                    totalCapitalCost, index
                  ) in specialityTotalCapitalCostFilter"
                  :key="index"
                >
                  {{ totalCapitalCost | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(lifeCycle, index) in specialityLifeCycleFilter"
                  :key="index"
                >
                  {{ lifeCycle }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(
                    annualisedCost, index
                  ) in specialityAnnualisedCostFilter"
                  :key="index"
                >
                  {{ annualisedCost | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(
                    annualisedMaintenanceCost, index
                  ) in specialityAnnualisedMaintenanceCostFilter"
                  :key="index"
                >
                  {{ annualisedMaintenanceCost | numeral }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="activeSummary == 'finance'">
        <h1 class="text-3xl my-6 active">Finance</h1>
        <table class="table-primary" v-if="specialityData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Finance</th>
              <th class="border px-4 py-2">Finance Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(
                    patientEpisodes, index
                  ) in specialityFinanceNameFilter"
                  :key="index"
                >
                  {{ patientEpisodes }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(financeName, index) in specialityFinanceTotalFilter"
                  :key="index"
                >
                  {{ financeName | numeral }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="activeSummary == 'workforce'">
        <h1 class="text-3xl my-6 active">Workforce</h1>
        <table class="table-primary" v-if="specialityData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Workforce</th>
              <th class="border px-4 py-2">Workforce Quantity</th>
              <th class="border px-4 py-2">Cost Per Staff</th>
              <th class="border px-4 py-2">Total Workforce Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(
                    workforceName, index
                  ) in specialityWorkforceNameFilter"
                  :key="index"
                >
                  {{ workforceName }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(
                    financeName, index
                  ) in specialityWorkforceQuantityFilter"
                  :key="index"
                >
                  {{ financeName | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(
                    workforceCostPerStaff, index
                  ) in specialityWorkforceCostPerStaffFilter"
                  :key="index"
                >
                  {{ workforceCostPerStaff | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(
                    workforceTotalCost, index
                  ) in specialityWorkforceTotalWorkforceCostFilter"
                  :key="index"
                >
                  {{ workforceTotalCost | numeral }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeSummary: "patientEpisodes",
      selectedSpeciality: "AHPs",
      selectedYear: "2023",
      activeSpeciality: "",
      activeYear: "",
      apiPayload: { speciality: "", year: "" },
    };
  },
  mounted() {
    console.log("SpecialitiesMain.Vue: mounted");
    var apiPayload = { speciality: "AHPs", year: "2023" };
    this.fetchYear(apiPayload);
  },
  computed: {
    //console.log ("SpecialitiesMain.Vue: computed");
    store() {
      return this.$store.state.prototypedata;
    },
    specialityData() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return true;
      }
      return null;
    },
    getWorkforceIndex() {
      var i = 0;
      var index = 0;

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if ("workforce" in this.$store.state.prototypedata.specialityData[i]) {
          index = i;
        }
      }

      return index;
    },
    getPatientIndex() {
      var i = 0;
      var index = 0;

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "patient_episodes" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getSpaceIndex() {
      var i = 0;
      var index = 0;

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "space_and_property" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getFinanceIndex() {
      var i = 0;
      var index = 0;

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if ("finance" in this.$store.state.prototypedata.specialityData[i]) {
          index = i;
        }
      }

      return index;
    },
    specialityWorkforceCostPerStaffFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex
        ].cost_per_staff;
      }
      return null;
    },
    specialityWorkforceTotalWorkforceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex
        ].total_workforce_cost;
      }
      return null;
    },
    specialityWorkforceNameFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex
        ].workforce;
      }
      return null;
    },
    specialityWorkforceQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex
        ].workforce_quantity;
      }
      return null;
    },
    specialitySpaceAndPropertyFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].space_and_property;
      }
      return null;
    },
    specialitySpaceAndPropertyQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].space_and_property_quantity;
      }
      return null;
    },
    specialityCapitalCostPerItemFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].capital_cost_per_item;
      }
      return null;
    },
    specialityTotalCapitalCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].total_capital_cost;
      }
      return null;
    },
    specialityLifeCycleFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].life_cycle;
      }
      return null;
    },
    specialityAnnualisedCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].annualised_cost;
      }
      return null;
    },
    specialityAnnualisedMaintenanceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].annualised_maintenance_cost;
      }
      return null;
    },
    specialityPatientEpisodesFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getPatientIndex
        ].patient_episodes;
      }
      return null;
    },
    specialityPatientEpisodesTotalFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getPatientIndex
        ].patient_episodes_total;
      }
      return null;
    },
    specialityFinanceNameFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getFinanceIndex
        ].finance;
      }
      return null;
    },
    specialityFinanceTotalFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getFinanceIndex
        ].finance_total;
      }
      return null;
    },
  },
  methods: {
    fetchYear() {
      console.log("fetchYear (", this.selectedYear.year, ")");

      this.specialityYear(this.selectedYear.year);

      this.apiPayload.year = this.selectedYear.year;
      this.apiPayload.speciality = this.selectedSpeciality.speciality;

      console.log("api = ", this.apiPayload.speciality, this.apiPayload.year);

      this.$store
        .dispatch("GetSpecialityDataByYear", this.apiPayload)
        .then((data) => {
          console.log("data", data);
        });
    },
    //fetchData(speciality) {
    //console.log("SpecialitiesMain.Vue: fetchData (", speciality);

    //console.log("fetchData (", this.selectedSpeciality.speciality, ")");

    //this.specialityName(this.selectedSpeciality.speciality);
    //this.$store
    //  .dispatch("attemptFetchSpecialityData", speciality)
    //  .then((data) => {
    //    console.log("data", data);
    //  });
    //},
    specialityHeader(header) {
      return header;
    },
    editActiveSummary(activeSummary) {
      this.activeSummary = activeSummary;
    },
    specialityName(specialityName) {
      this.activeSpeciality = specialityName;
    },
    specialityYear(specialityYear) {
      this.activeYear = specialityYear;
    },
  },
};
</script>
