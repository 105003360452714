import Shell from '../../../layouts/Shell.vue'
import ecr from '../views/ecr.vue'
 
const routes = [
    {
        path: '/',
        name: 'LayoutModule',
        component: Shell,
        children: [
        {
            path: '/ecr',
            name: 'ecr',
            component: ecr,
            meta: {
                requiresAuth: true
            }
        }]
    }
]

export default routes
