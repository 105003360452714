<template>
  <div>
     <header class="route-header">
       <div class="route-header--left">
         <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
       </div>
       <div class="route-header--right">
         <h1 class="text-3xl">Total Population Health Demand - Stage 1</h1>
       </div>
       <div class="route-header--box">
          <div class="box">
           <img src="@/assets/img/logo-full-blue.png" alt="" style="width: 15vw; min-width: 130px; height: 80px;" />
          </div>
       </div>
     </header>
 
     <div class="route-header items-center secondary">
       <div class="route-header--box">
         <div class="box">
           <span class="box-header">Location</span>
           <span class="box-label">United Kingdom</span>
         </div>
       </div>
       <div class="route-header--box">
         <div class="box">
           <span class="box-header">Total Population</span>
           <span class="box-label">1,199,870</span>
         </div>
       </div>
     </div>
 
     <nav class="route-nav">
       <ul>
         <li class="active">
           <a href="#">Surrey</a>
           <jam-close />
         </li>
       </ul>
     </nav>
 
         
            
 
   <div class="stat-cards--item single3"> <!-- 2 open -->
           <span class="card-header">Workforce Summary</span>
           <div class="card-icon"> <!-- 3 open -->
             <div class="flex flex-col"> <!-- 4 open -->
               <div class="overflow-x-auto sm:-mx-6 lg:-mx-8"> <!-- 5 open -->
                 <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8"> <!-- 6 open -->
                   <div class="overflow-hidden"> <!-- 7 open -->
                     <table class="min-w-full">
                       <thead>
                       </thead>
                       <tbody>
                         <Bar
                             :chart-options="chartOptions"
                             :chart-data="chartData"
                             :chart-id="chartId"
                             :dataset-id-key="datasetIdKey"
                             :plugins="plugins"
                             :css-classes="cssClasses"
                             :styles="styles"
                             :width=300
                             :height="height"
                           />
                       </tbody>
                     </table>
                   </div> <!-- 7 close -->
                 </div> <!--6 close -->
               </div> <!--5 close -->
             </div>  <!--4 close -->
           </div> <!--3 close-->
           <span class="card-info"></span>
         </div> <!-- 2 close -->
       <!-- /div --> <!-- 1 close -->        
       
       <div class="stat-cards--item single3"> <!-- 2 open -->
           <span class="card-header">XYZ Summary</span>
           <div class="card-icon"> <!-- 3 open -->
             <div class="flex flex-col"> <!-- 4 open -->
               <div class="overflow-x-auto sm:-mx-6 lg:-mx-8"> <!-- 5 open -->
                 <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8"> <!-- 6 open -->
                   <div class="overflow-hidden"> <!-- 7 open -->
                     <table class="min-w-full">
                       <thead>
                       </thead>
                       <tbody>
                         <Bar
                             :chart-options="chartOptions"
                             :chart-data="chartData"
                             :chart-id="chartId"
                             :dataset-id-key="datasetIdKey"
                             :plugins="plugins"
                             :css-classes="cssClasses"
                             :styles="styles"
                             :width=300
                             :height="height"
                           />
                       </tbody>
                     </table>
                   </div> <!-- 7 close -->
                 </div> <!--6 close -->
               </div> <!--5 close -->
             </div>  <!--4 close -->
           </div> <!--3 close-->
           <span class="card-info"></span>
         </div> <!-- 2 close -->
       </div> <!-- 1 close -->        
         
 
     
 
    
 
   
  
 
 </template>
 
 <script>
 import { Bar } from 'vue-chartjs/legacy'
 //import { Bubble } from 'vue-chartjs/legacy'
 
 import {
   Chart as ChartJS,
   Title,
   Tooltip,
   Legend,
   BarElement,
   CategoryScale,
   LinearScale
 } from 'chart.js'
 
 ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
 
 export default {
   name: 'bar',
   components: {
     Bar
     //Bubble
   },
   props: {
     chartId: {
       type: String,
       default: 'bar-chart'
     },
     datasetIdKey: {
       type: String,
       default: 'label'
     },
     width: {
       type: Number,
       default: 400
     },
     height: {
       type: Number,
       default: 400
     },
     cssClasses: {
       default: '',
       type: String
     },
     styles: {
       type: Object,
       default: () => {}
     },
     plugins: {
       type: Array,
       default: () => []
     }
   },
   data()
   {
     return {
       chartData: {
         labels: [
           'January',
           'February',
           'March',
           'April',
           'May',
           'June',
           'July',
           'August',
           'September',
           'October',
           'November',
           'December'
         ],
         datasets: [
           {
             label: 'Data One',
             backgroundColor: '#f87979',
             data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
           }
         ]
       },
       chartOptions: {
         responsive: true,
         maintainAspectRatio: true
       }
     }
   }
 }
 </script>
