<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/logo.svg" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">Test Page</h1>
      </div>
      <div class="route-header--box">
         <div class="box">
          <img src="@/assets/img/logo-full.svg" alt="" style="width: 55vw; min-width: 330px;" />
         </div>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">United Kingdom</span>
        </div>
      </div>
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Total Population</span>
          <span class="box-label">1,199,870</span>
        </div>
      </div>
     <!-- had the export stuff here -->
    </div>

    <nav class="route-nav">
      <ul>
        <li class="active">
          <a href="#">Surrey</a>
          <jam-close />
        </li>
      </ul>
    </nav>

    

    <div class="route-content">

      <h1 class="text-3xl my-6 active">Summary Total System Demand</h1> 
      
      <div class="stat-cards" v-if="workforceData !== null">
                    
      <!-- div class="stat-cards" -->
        


       
        
        

        <div v-if="activeSummary == 'workforceCost'">
        <h1 class="text-3xl my-6 active">Workforce Summary</h1>

        <table class="table-primary" v-if="workForceData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Workforce</th>
              <th class="border px-4 py-2">Quantity</th>
              <th class="border px-4 py-2">Staff Cost</th>
              <th class="border px-4 py-2">Total Workforce Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(workforce, index) in summaryWorkforceFilter1"
                  :key="index"
                >
                  {{ workforce }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(quantity, index) in summaryQuantityFilter1"
                  :key="index"
                >
                  {{ quantity | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(costPerStaff, index) in summaryCostPerStaffFilter1"
                  :key="index"
                >
                  {{ costPerStaff | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(totalWorkforceCost,
                  index) in summaryTotalWorkforceCostFilter1"
                  :key="index"
                >
                  {{ totalWorkforceCost | numeral }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="activeSummary == 'annualTotals'">
        <table class="table-primary" v-if="workforceData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Year Overview</th>
              <th class="border px-4 py-2">Year 1</th>
              <th class="border px-4 py-2">Year 2</th>
              <th class="border px-4 py-2">Year 3</th>
              <th class="border px-4 py-2">Year 4</th>
              <th class="border px-4 py-2">Year 5</th>
              <th class="border px-4 py-2">Year 6</th>
              <th class="border px-4 py-2">Year 7</th>
              <th class="border px-4 py-2">Year 8</th>
            </tr>

            <tr>
              <th
                class="border"
                v-for="(servedPopulation,
                index) in annualTotalsServedPopulationFilter1"
                :key="index"
              >
                {{ servedPopulation }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                class="border"
                v-for="(costbase, index) in annualTotalsCostbaseFilter1"
                :key="index"
              >
                {{ costbase }}
              </td>
            </tr>
            <tr>
              <td
                class="border"
                v-for="(incomeRequirements,
                index) in annualTotalsIncomeRequirementFilter1"
                :key="index"
              >
                {{ incomeRequirements }}
              </td>
            </tr>

            <tr>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
            </tr>

            <tr>
              <td
                class="border"
                v-for="(medicalStaff, index) in annualTotalsMedicalStaffFilter1"
                :key="index"
              >
                {{ medicalStaff }}
              </td>
            </tr>
            <tr>
              <td
                class="border"
                v-for="(nursingAndMidwife,
                index) in annualTotalsNursingAndMidwifeFilter1"
                :key="index"
              >
                {{ nursingAndMidwife }}
              </td>
            </tr>
            <tr>
              <td
                class="border"
                v-for="(totalWorkforce,
                index) in annualTotalsTotalWorkforceFilter1"
                :key="index"
              >
                {{ totalWorkforce }}
              </td>
            </tr>
            <tr>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
            </tr>

            <tr>
              <td
                class="border"
                v-for="(primaryCarePresentations,
                index) in annualTotalsPrimaryCarePresentationsFilter1"
                :key="index"
              >
                {{ primaryCarePresentations }}
              </td>
            </tr>
            <tr>
              <td
                class="border"
                v-for="(erPresentations,
                index) in annualTotalsErPresentationsFilter1"
                :key="index"
              >
                {{ erPresentations }}
              </td>
            </tr>
            <tr>
              <td
                class="border"
                v-for="(opdPresentations,
                index) in annualTotalsOpdPresentationsFilter1"
                :key="index"
              >
                {{ opdPresentations }}
              </td>
            </tr>
            <tr>
              <td
                class="border"
                v-for="(orEpisodes, index) in annualTotalsOrEpisodesFilter1"
                :key="index"
              >
                {{ orEpisodes }}
              </td>
            </tr>
            <tr>
              <td
                class="border"
                v-for="(bedNights, index) in annualTotalsBedNightsFilter1"
                :key="index"
              >
                {{ bedNights }}
              </td>
            </tr>
            <tr>
              <td
                class="border"
                v-for="(radiologyEpisodes,
                index) in annualTotalsRadiologyEpisodesFilter1"
                :key="index"
              >
                {{ radiologyEpisodes }}
              </td>
            </tr>
            <tr>
              <td
                class="border"
                v-for="(laboratoryEpisodes,
                index) in annualTotalsLaboratoryEpisodesFilter1"
                :key="index"
              >
                {{ laboratoryEpisodes }}
              </td>
            </tr>
            <tr>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
            </tr>

            <tr>
              <td
                class="border"
                v-for="(totalAssets, index) in annualTotalsTotalAssetsFilter1"
                :key="index"
              >
                {{ totalAssets }}
              </td>
            </tr>
            <tr>
              <td
                class="border"
                v-for="(lifeCycleCosts,
                index) in annualTotalsLifeCycleCostsFilter1"
                :key="index"
              >
                {{ lifeCycleCosts }}
              </td>
            </tr>
            <tr>
              <td
                class="border"
                v-for="(maintenanceCosts,
                index) in annualTotalsMaintenanceCostsFilter1"
                :key="index"
              >
                {{ maintenanceCosts }}
              </td>
            </tr>
            <tr>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
            </tr>

            <tr>
              <td
                class="border"
                v-for="(propertyLifeCycle,
                index) in annualTotalsPropertyLifeCycleFilter1"
                :key="index"
              >
                {{ propertyLifeCycle }}
              </td>
            </tr>
            <tr>
              <td
                class="border"
                v-for="(propertyFmCosts,
                index) in annualTotalsPropertyFmCostsFilter1"
                :key="index"
              >
                {{ propertyFmCosts }}
              </td>
            </tr>
            <tr>
              <td
                class="border"
                v-for="(capexRequirements,
                index) in annualTotalsCapexRequirementFilter1"
                :key="index"
              >
                {{ capexRequirements }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="activeSummary == 'patientEpisode'">
        <h1 class="text-3xl my-6 active">Patient Episodes</h1>

        <table class="table-primary" v-if="specialityData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Patient Episodes</th>
              <th class="border px-4 py-2">Patient Episodes Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(patientEpisodes,
                  index) in specialityPatientEpisodesFilter1"
                  :key="index"
                >
                  {{ patientEpisodes }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(patientEpisodesQuantity,
                  index) in specialityPatientEpisodesTotalFilter1"
                  :key="index"
                >
                  {{ patientEpisodesQuantity | numeral }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="activeSummary == 'finance'">
        <h1 class="text-3xl my-6 active">Finance</h1>
        <table class="table-primary" v-if="specialityData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Finance</th>
              <th class="border px-4 py-2">Finance Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(patientEpisodes,
                  index) in specialityFinanceNameFilter1"
                  :key="index"
                >
                  {{ patientEpisodes }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(financeName, index) in specialityFinanceTotalFilter1"
                  :key="index"
                >
                  {{ financeName | numeral }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="activeSummary == 'accountableCare'">
        <h1 class="text-3xl my-6 active">Accountable Care Organisation</h1>
        <table class="table-primary" v-if="specialityData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">
                Accountable Care Organisation Summary
              </th>
              <th class="border px-4 py-2">Total Costbase</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(accountableCare,
                  index) in accountableCareSummaryFilter1"
                  :key="index"
                >
                  {{ accountableCare }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(accountableCareTotalCostbase,
                  index) in accountableCareTotalCostbaseFilter1"
                  :key="index"
                >
                  {{ accountableCareTotalCostbase | numeral }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="activeSummary == 'propertyFacilities'">
        <h1 class="text-3xl my-6 active">Property & Facilities</h1>
        <table class="table-primary" v-if="specialityData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Property And Facility Management</th>
              <th class="border px-4 py-2">Property Size Cost</th>
              <th class="border px-4 py-2">Property Size Total</th>
              <th class="border px-4 py-2">New Build Cost</th>
              <th class="border px-4 py-2">
                Annualised Property Life Cycle Cost
              </th>
              <th class="border px-4 py-2">Cleaning Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(propertyAndFacilty,
                  index) in propertyAndFacilityFilter1"
                  :key="index"
                >
                  {{ propertyAndFacilty }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(propertySizeCost, index) in propertySizeCostFilter1"
                  :key="index"
                >
                  {{ propertySizeCost | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(propertySizeTotal, index) in propertySizeTotalFilter1"
                  :key="index"
                >
                  {{ propertySizeTotal | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(propertyNewBuildCost,
                  index) in propertyNewBuildCostFilter1"
                  :key="index"
                >
                  {{ propertyNewBuildCost | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(propertyAnnualLifeCycleCost,
                  index) in propertyAnnualLifeCycleFilter1"
                  :key="index"
                >
                  {{ propertyAnnualLifeCycleCost | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(propertyCleaningCost,
                  index) in propertyCleaningCostFilter1"
                  :key="index"
                >
                  {{ propertyCleaningCost | numeral }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="activeSummary == 'assets'">
        <h1 class="text-3xl my-6 active">Assets Summary</h1>

        <table class="table-primary" v-if="specialityData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Assets</th>
              <th class="border px-4 py-2">Quantity</th>
              <th class="border px-4 py-2">Capital Cost Per Item</th>
              <th class="border px-4 py-2">Total Capital Cost</th>
              <th class="border px-4 py-2">Life Cycle</th>
              <th class="border px-4 py-2">Annual Cost</th>
              <th class="border px-4 py-2">Annual Maintenance Cost</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td class="border">
                <div v-for="(assets, index) in assetsFilter1" :key="index">
                  {{ assets }}
                </div>
              </td>
              <td class="border">
                <div
                  v-for="(assetsQuantity, index) in assetsQuantityFilter1"
                  :key="index"
                >
                  {{ assetsQuantity | numeral }}
                </div>
              </td>
              <td class="border">
                <div
                  v-for="(capitalCostPerItem,
                  index) in assetsCapitalCostPerItemFilter1"
                  :key="index"
                >
                  {{ capitalCostPerItem | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(totalCapitalCost,
                  index) in assetsTotalCapitalCostFilter1"
                  :key="index"
                >
                  {{ totalCapitalCost | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(lifeCycle, index) in assetsLifeCycleFilter1"
                  :key="index"
                >
                  {{ lifeCycle }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(annualisedCost, index) in assetsAnnualisedCostFilter1"
                  :key="index"
                >
                  {{ annualisedCost | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(annualisedMaintenanceCost,
                  index) in assetsAnnualisedMaintenanceCostFilter1"
                  :key="index"
                >
                  {{ annualisedMaintenanceCost | numeral }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() 
  {
    console.log ("UserMain.vue: data();");
    return {
      activeSummary: "workforceCost",
      statusItemName: "exportdefault",
    };
  },
  mounted() {
    console.log ("(4) UserMain.vue: mounted();")
     const speciality = {
      speciality: "Grand Summary",
      apiString: "grandsummaryone-collection",
    };
    
    this.fetchData(speciality);
    this.fetchAnnualTotals();
  },
  beforeMount() {
    console.log ("(3) UserMain.vue: beforeMount();");
  },
  beforeCreate() {
    console.log ("(1) UserMain.vue: beforeCreate();");
  },
  created() { 
    console.log ("(2) UserMain.vue: created();");
  },
  beforeUpdate() {
    console.log ("(5) UserMain.vue: beforeUpdate();");
  },
  updated () {
    console.log ("(6) UserMain.vue: updated(); ");
  },
  beforeUnmount () {
    console.log("(7) UserMain.vue: beforeUnmount();");
  },
  unmounted () {
    console.log ("(8) UserMain.vue: unmounted();");
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },
    workForceDataOne() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return true;
      }
      return null;
    },
    locationName() {
      if (this.$store.state.prototypedata.test.length > 0) {
        return this.$store.state.prototypedata.test;   
      }
      return null;
    },
    assetsFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[1].assets;
      }
      return null;
    },
    assetsQuantityFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[1]
          .assets_quantity;
      }
      return null;
    },
    assetsCapitalCostPerItemFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[1]
          .capital_cost_per_item;
      }
      return null;
    },
    assetsTotalCapitalCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[1]
          .total_capital_cost;
      }
      return null;
    },
    assetsLifeCycleFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[1].life_cycle;
      }
      return null;
    },
    assetsAnnualisedCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[1]
          .annualised_cost;
      }
      return null;
    },
    assetsAnnualisedMaintenanceCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[1]
          .annualised_maintenance_cost;
      }
      return null;
    },
    assetsTotalCapitalCostOverallFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateAssetsTotalCapitalCost(
          this.$store.state.prototypedata.specialityData[1].total_capital_cost
        );
      }
      return null;
    },
    assetsAnnualisedCostOverallFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateAssetsAnnualisedCost(
          this.$store.state.prototypedata.specialityData[1].annualised_cost
        );
      }
      return null;
    },
    assetsAnnualisedMaintenanceCostOverallFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateAssetsAnnualisedMaintenanceCost(
          this.$store.state.prototypedata.specialityData[1]
            .annualised_maintenance_cost
        );
      }
      return null;
    },
    summaryWorkforceFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[0].workforce;
      }
      return null;
    },
    summaryQuantityFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[0]
          .workforce_quantity;
      }
      return null;
    },
    summaryCostPerStaffFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[0].cost_per_staff;
      }
      return null;
    },
    summaryTotalWorkforceCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[0]
          .total_workforce_cost;
      }
      return null;
    },
    summaryTotalQuantityFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateTotalQuantity(
          this.$store.state.prototypedata.specialityData[0].workforce_quantity
        );
      }
      return null;
    },
    summaryTotalCostPerStaffFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateTotalCostPerStaff(
          this.$store.state.prototypedata.specialityData[0].cost_per_staff
        );
      }
      return null;
    },
    summaryTotalCapitalWorkforceCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateTotalCapitalWorkforceCost(
          this.$store.state.prototypedata.specialityData[0].total_workforce_cost
        );
      }
      return null;
    },
    accountableCareOrganisationCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[5]
          .total_income_requirement[0];
      }
      return null;
    },
    patientEpisodeProductivityFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculatePatientEpisodeProductivityCost(
          this.$store.state.prototypedata.specialityData[2]
            .patient_episodes_total
        );
      }
      return null;
    },
    financeCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[3]
          .finance_total[2];
      }
      return null;
    },
    propertyAndFacilitiesCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculatePropertyAndFacilitiesCost(
          this.$store.state.prototypedata.specialityData[4]
            .annualised_propery_life_cycle_cost
        );
      }
      return null;
    },
    specialityPatientEpisodesFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[2]
          .patient_episodes;
      }
      return null;
    },
    specialityPatientEpisodesTotalFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[2]
          .patient_episodes_total;
      }
      return null;
    },
    specialityFinanceNameFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[3].finance;
      }
      return null;
    },
    specialityFinanceTotalFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[3].finance_total;
      }
      return null;
    },
    accountableCareSummaryFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[5]
          .accountable_care_organisation_commerical_summary;
      }
      return null;
    },
    accountableCareTotalCostbaseFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[5].total_costbase;
      }
      return null;
    },
    propertyAndFacilityFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[4]
          .property_and_facility_management_costs;
      }
      return null;
    },
    propertySizeCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[4]
          .property_size_cost;
      }
      return null;
    },
    propertySizeTotalFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[4]
          .property_size_total;
      }
      return null;
    },
    propertyNewBuildCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[4].new_build_cost;
      }
      return null;
    },
    propertyAnnualLifeCycleFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[4]
          .annualised_propery_life_cycle_cost;
      }
      return null;
    },
    propertyCleaningCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[4].cleaning_cost;
      }
      return null;
    },
    annualTotalsData() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData;
      }
      return null;
    },
    annualTotalsServedPopulationFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .served_population;
      }
      return null;
    },
    annualTotalsCostbaseFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0].costbase;
      }
      return null;
    },
    annualTotalsIncomeRequirementFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .income_requirement;
      }
      return null;
    },
    annualTotalsMedicalStaffFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .medical_staff_fte;
      }
      return null;
    },
    annualTotalsNursingAndMidwifeFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .nursing_and_midwife_fte;
      }
      return null;
    },
    annualTotalsTotalWorkforceFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .total_workforce_fte;
      }
      return null;
    },
    annualTotalsPrimaryCarePresentationsFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .primary_care_presentations;
      }
      return null;
    },
    annualTotalsErPresentationsFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .er_presentations;
      }
      return null;
    },
    annualTotalsOpdPresentationsFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .opd_presentations;
      }
      return null;
    },
    annualTotalsOrEpisodesFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .total_or_episodes;
      }
      return null;
    },
    annualTotalsBedNightsFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .total_bed_nights;
      }
      return null;
    },
    annualTotalsRadiologyEpisodesFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .total_radiology_episodes;
      }
      return null;
    },
    annualTotalsLaboratoryEpisodesFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .total_laboratory_episodes;
      }
      return null;
    },
    annualTotalsTotalAssetsFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0].total_assets;
      }
      return null;
    },
    annualTotalsLifeCycleCostsFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .life_cycle_costs;
      }
      return null;
    },
    annualTotalsMaintenanceCostsFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .maintenance_costs;
      }
      return null;
    },
    annualTotalsPropertyLifeCycleFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .property_life_cycle;
      }
      return null;
    },
    annualTotalsPropertyFmCostsFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .property_fm_costs;
      }
      return null;
    },
    annualTotalsCapexRequirementFilter1() {
      if (this.$store.state.prototypedata.annualTotalsDataOne.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .capex_requirement;
      }
      return null;
    },
  },
  methods: {
    fetchData(speciality) {
      this.$store
        .dispatch("attemptFetchSpecialityData", speciality)
        .then(() => {
        });
    },
    fetchAnnualTotals() {
      this.$store.dispatch("attemptFetchAnnualTotalsOne").then((data) => {
        console.log ("UserMain.vue: fetchAnnualTotals",data)
      });
    },
    calculateTotalQuantity(workforceQuantityArray) {
      let workForceQuantityFloatArray = new Array();
      for (var i = 0; i < workforceQuantityArray.length; i++) {
        workForceQuantityFloatArray.push(parseFloat(workforceQuantityArray[i]));
      }

      const total = workForceQuantityFloatArray.reduce(function (a, b) {
        return a + b;
      });
      return total;
    },
    calculateTotalCostPerStaff(totalCostPerStaffArray) {
      let totalCostPerStaffFloatArray = new Array();
      for (var i = 0; i < totalCostPerStaffArray.length; i++) {
        totalCostPerStaffFloatArray.push(parseFloat(totalCostPerStaffArray[i]));
      }

      const total = totalCostPerStaffFloatArray.reduce(function (a, b) {
        return a + b;
      });
      return total;
    },
    calculateTotalCapitalWorkforceCost(totalCapitalWorkforceCostArray) {
      let totalCapitalWorkforceCostFloatArray = new Array();
      for (var i = 0; i < totalCapitalWorkforceCostArray.length; i++) {
        totalCapitalWorkforceCostFloatArray.push(
          parseFloat(totalCapitalWorkforceCostArray[i])
        );
      }

      const total = totalCapitalWorkforceCostFloatArray.reduce(function (a, b) {
        return a + b;
      });
      return total;
    },
    calculateAccountableCareOrganisationCost(
      accountableCareOrganisationCostArray
    ) {
      let accountableCareOrganisationCostFloatArray = new Array();
      for (var i = 0; i < accountableCareOrganisationCostArray.length; i++) {
        accountableCareOrganisationCostFloatArray.push(
          parseFloat(accountableCareOrganisationCostArray[i])
        );
      }

      const total = accountableCareOrganisationCostFloatArray.reduce(function (
        a,
        b
      ) {
        return a + b;
      });
      return total;
    },
    calculatePatientEpisodeProductivityCost(
      patientEpisodeProductivityCostArray
    ) {
      let patientEpisodeProductivityCostFloatArray = new Array();
      for (var i = 0; i < patientEpisodeProductivityCostArray.length; i++) {
        patientEpisodeProductivityCostFloatArray.push(
          parseFloat(patientEpisodeProductivityCostArray[i])
        );
      }

      const total = patientEpisodeProductivityCostFloatArray.reduce(function (
        a,
        b
      ) {
        return a + b;
      });
      return total;
    },
    calculateAssetsTotalCapitalCost(assetsTotalCapitalCostArray) {
      let assetsTotalCapitalCostFloatArray = new Array();
      for (var i = 0; i < assetsTotalCapitalCostArray.length; i++) {
        assetsTotalCapitalCostFloatArray.push(
          parseFloat(assetsTotalCapitalCostArray[i])
        );
      }

      const total = assetsTotalCapitalCostFloatArray.reduce(function (a, b) {
        return a + b;
      });
      return total;
    },
    calculateAssetsAnnualisedCost(assetsAnnualisedCostArray) {
      let assetsAnnualisedCostFloatArray = new Array();
      for (var i = 0; i < assetsAnnualisedCostArray.length; i++) {
        assetsAnnualisedCostFloatArray.push(
          parseFloat(assetsAnnualisedCostArray[i])
        );
      }

      const total = assetsAnnualisedCostFloatArray.reduce(function (a, b) {
        return a + b;
      });
      return total;
    },
    calculateAssetsAnnualisedMaintenanceCost(
      assetsAnnualisedMaintenanceCostArray
    ) {
      let assetsAnnualisedMaintenanceCostFloatArray = new Array();
      for (var i = 0; i < assetsAnnualisedMaintenanceCostArray.length; i++) {
        assetsAnnualisedMaintenanceCostFloatArray.push(
          parseFloat(assetsAnnualisedMaintenanceCostArray[i])
        );
      }

      const total = assetsAnnualisedMaintenanceCostFloatArray.reduce(function (
        a,
        b
      ) {
        return a + b;
      });
      return total;
    },
    calculatePropertyAndFacilitiesCost(assetsPropertyAndFacilitiesCostArray) {
      let assetsPropertyAndFacilitiesCostFloatArray = new Array();
      for (var i = 0; i < assetsPropertyAndFacilitiesCostArray.length; i++) {
        assetsPropertyAndFacilitiesCostFloatArray.push(
          parseFloat(assetsPropertyAndFacilitiesCostArray[i])
        );
      }

      const total = assetsPropertyAndFacilitiesCostFloatArray.reduce(function (
        a,
        b
      ) {
        return a + b;
      });
      return total;
    },
  },
};
</script>
