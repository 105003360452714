<template>
  <h2 style="color: #000">Forgot Password Screen</h2>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    console.log("sadsadsad");
    this.checkLoggedIn();
  },
  methods: {
    doLogin() {
      this.isLoading = true;
    },
    checkLoggedIn() {
      if (window.localStorage.getItem("token")) {
        this.$router.push("/summary");
      }
    }
  },
};
</script>
