<template>
  <!-- 1. Sort out the issue with changing hospital and no specialities appearing --->
  <!-- 2. The issue with grand summary front page --> <!-- fixed-->
  <!-- 3. The issues with op improvements - needs updating --> 
  <!-- -->
  <!-- New Issues -->
  <!-- Graph data not appearing on page refresh -->
  <!-- Hospital Grans summary for RMS  - need to show -->
  <!-- sort out data retrieval - do it too many times for the tiles -->
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">
          {{  currentOverallTitle }}
        </h1>
      </div>

      <div class="validation-cards--item cert no-hover">
        <span class="card-header">Certification Status</span>
        <div class="card-icon" margin-top="0px">
          <img
            src="@/assets/img/circular-label-with-certified-stamp.png"
            alt=""
          />
          <span class="card-total">100% Validation</span>
        </div>
      </div>
    </header>

    <!-- div class="route-header items-center secondary">             
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Bahrain</span>
        </div>
      </div>   
    </div -->

    <div class="route-header items-center secondary">
    <div class="route-speciality--box">
        <div class="box">
            <span class="box-header">Stage</span>
            <span class="box-label">
            <v-select
                v-model="selectedStage"
                label="label"
                :clearable="false"
                @input="setStage"
                :options="$store.state.prototypedata.stage456"
            />
            </span>
        </div>
    </div>
    </div>

    <div v-if="currentOverallStage == 'Stage 4'">

    <nav class="route-nav">
      <ul>
        <li
          @click="editStageSummary('stage')"
          :class="stageSummary == 'stage' ? 'active' : ''"
        >
          <span>Stage 4</span>
        </li>
        <li
          @click="editStageSummary('specialities')"
          :class="stageSummary == 'specialities' ? 'active' : ''"
        >
          <span>Specialities</span>
        </li>
        <li
          @click="editStageSummary('buildingsFM')"
          :class="stageSummary == 'buildingsFM' ? 'active' : ''"
        >
          <span>Buildings and FM</span>
        </li>
        <li
          @click="editStageSummary('opImprov')"
          :class="stageSummary == 'opImprov' ? 'active' : ''"
        >
          <span>Operational Improvement</span>
        </li>  
        <li
          @click="editStageSummary('metrics')"
          :class="stageSummary == 'metrics' ? 'active' : ''"
        >
          <span>Population Demand Capacity Metrics</span>
        </li>     
      </ul>
    </nav>
    </div>

    <div v-if="currentOverallStage == 'Stage 5'">
    <nav class="route-nav">
      <ul>
        <li
          @click="editSummary('Strategic')"
          :class="Summary == 'Strategic' ? 'active' : ''"
        >
          <span>Strategic Analytics Insights</span>
        </li>
        <li
          @click="editSummary('Episodes')"
          :class="Summary == 'Episodes' ? 'active' : ''"
        >
          <span>Episodes</span>
        </li>
        <li
          @click="editSummary('Assets and Infrastructure')"
          :class="Summary == 'Assets and Infrastructure' ? 'active' : ''"
        >
          <span>Assets and Infrastructure</span>
        </li>
        <li
          @click="editSummary('Workforce')"
          :class="Summary == 'Workforce' ? 'active' : ''"
        >
          <span>Workforce</span>
        </li>
        <li
          @click="editSummary('Finance')"
          :class="Summary == 'Finance' ? 'active' : ''"
        >
          <span>Finance</span>
        </li>
      </ul>
    </nav>
    </div>
   
    <div v-if="currentOverallStage == 'Stage 4'">

    <div v-if="stageSummary == 'stage'">
      <div class="route-content">

        <!-- button class="btn btn-primary btn-outline mr-4 mb-4" :class="year1To4Table ? 'active' : ''" @click="displayData">Display Data</button -->

      <div class="stat-cards">
    
          <div class="stat-cards--item single no-hover" style="width:300px;">
            <span class="card-header">Population ({{ this.yeardata[0] }})</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
              <span class="card-total"> {{ this.getPopulationTotal(this.yeardata[0]) }}</span>
            </div>
          </div>

          <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'patientEpisodeYear5' ? 'active' : ''"  @click="fillData4('patientEpisodeYear5',yeardata[0])">
            <span class="card-header">Patient Episode Productivity ({{ this.yeardata[0] }})</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-patient.svg" alt="Patient Episodes"/>
              <span class="card-total">{{ getTileData1(0) }}</span>
            </div>
            <span class="card-info">Total patient episodes</span>
          </div>

          <div class="stat-cards--item single" style="width:300px" :class="activeSummary === 'assetsYear5' ? 'active' : ''" @click="fillData4('assetsYear5',yeardata[0])">
            <span class="card-header">Assets ({{ this.yeardata[0] }})</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <span class="card-total">{{ getTileData1(1) }}</span>
            </div>
            <span class="card-info">Total Assets</span>
          </div>

          <div class="stat-cards--item single" style="width:300px" :class="activeSummary === 'workforceCostYear5' ? 'active' : ''" @click="fillData4('workforceCostYear5',yeardata[0])">
            <span class="card-header">Workforce ({{ this.yeardata[0] }})</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
              <span class="card-total">{{ getTileData1(2) }}</span>
            </div>
            <span class="card-info">Total Workforce Full Time Equivalent (FTE)</span>
          </div>

          <div class="stat-cards--item single" style="width:300px" :class="activeSummary === 'financeYear5' ? 'active' : ''" @click="fillData4('financeYear5', yeardata[0] )">
            <span class="card-header">Finance ({{ this.yeardata[0] }})</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <span class="card-total">{{ getTileData1(3) }}</span>
            </div>
            <span class="card-info">Budget Requirement</span>
          </div>

        <!-- line 2 -->
        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Population ({{ this.yeardata[1] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total"> {{ this.getPopulationTotal(this.yeardata[1])}} </span>
          </div>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'patientEpisodeYear1' ? 'active' : ''"  @click="fillData4('patientEpisodeYear1',yeardata[1])">
          <span class="card-header">Patient Episode Productivity ({{this.yeardata[1]}})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total">{{ getTileData1(4) }}</span>
          </div>
        </div>

        <div class="stat-cards--item single" style="width:300px;"  :class="activeSummary === 'assetsYear1' ? 'active' : ''" @click="fillData4('assetsYear1',yeardata[1])">
          <span class="card-header">Assets ({{this.yeardata[1]}})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
            <span class="card-total">{{ getTileData1(5) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'workforceCostYear1' ? 'active' : ''" @click="fillData4('workforceCostYear1',yeardata[1])">
          <span class="card-header">Workforce ({{this.yeardata[1]}})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
            <span class="card-total">{{ getTileData1(6) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'financeYear1' ? 'active' : ''" @click="fillData4('financeYear1',yeardata[1])">
          <span class="card-header">Finance ({{this.yeardata[1]}})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
            <span class="card-total">{{ getTileData1(7) }}</span>
          </div>
          <span class="card-info"></span>
        </div>
            
        <!-- line 3 -->
        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Population ({{this.yeardata[2]}})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total"> {{ this.getPopulationTotal(this.yeardata[2])}}</span>
          </div>
        </div>

        <div class="stat-cards--item single" style="width:300px; " :class="activeSummary === 'patientEpisodeYear2' ? 'active' : ''"  @click="fillData4('patientEpisodeYear2',yeardata[2])">
          <span class="card-header">Patient Episode Productivity ({{ this.yeardata[2] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total">{{ getTileData1(8) }}</span>
          </div>
        </div>

        <div class="stat-cards--item single" style="width:300px;"  :class="activeSummary === 'assetsYear2' ? 'active' : ''" @click="fillData4('assetsYear2',yeardata[2])">
          <span class="card-header">Assets ({{ this.yeardata[2] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
            <span class="card-total">{{ getTileData1(9) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'workforceCostYear2' ? 'active' : ''" @click="fillData4('workforceCostYear2',yeardata[2])">
          <span class="card-header">Workforce ({{ this.yeardata[2] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
            <span class="card-total">{{ getTileData1(10) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'financeYear2' ? 'active' : ''" @click="fillData4('financeYear2',yeardata[2])">
          <span class="card-header">Finance ({{this.yeardata[2]}})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
            <span class="card-total">{{ getTileData1(11) }}</span>
          </div>
          <span class="card-info"></span>
        </div>      

        <!-- line 4 -->
        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Population ({{ this.yeardata[3] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total"> {{ this.getPopulationTotal(this.yeardata[3]) }}</span>
          </div>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'patientEpisodeYear3' ? 'active' : ''"  @click="fillData4('patientEpisodeYear3',yeardata[3])">
          <span class="card-header">Patient Episode Productivity ({{this.yeardata[3]}})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total">{{ getTileData1(12) }}</span>
          </div>
        </div>

        <div class="stat-cards--item single" style="width:300px;"  :class="activeSummary === 'assetsYear3' ? 'active' : ''" @click="fillData4('assetsYear3',yeardata[3])">
          <span class="card-header">Assets ({{ this.yeardata[3] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
            <span class="card-total">{{ getTileData1(13) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'workforceCostYear3' ? 'active' : ''" @click="fillData4('workforceCostYear3',yeardata[3])">
          <span class="card-header">Workforce ({{ this.yeardata[3] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
            <span class="card-total">{{ getTileData1(14) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'financeYear3' ? 'active' : ''" @click="fillData4('financeYear3',yeardata[3])">
          <span class="card-header">Finance ({{ this.yeardata[3] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
            <span class="card-total">{{ getTileData1(15) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <!-- line 5 -->
        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Population ({{ this.yeardata[4] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total"> {{ this.getPopulationTotal(this.yeardata[4]) }}</span>
          </div>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'patientEpisodeYear4' ? 'active' : ''"  @click="fillData4('patientEpisodeYear4',yeardata[4])">
          <span class="card-header">Patient Episode Productivity ({{ this.yeardata[4] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total">{{ getTileData1(16) }}</span>
          </div>
        </div>

        <div class="stat-cards--item single" style="width:300px;"  :class="activeSummary === 'assetsYear4' ? 'active' : ''" @click="fillData4('assetsYear4',yeardata[4]) ">
          <span class="card-header">Assets ({{ this.yeardata[4] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
            <span class="card-total">{{ getTileData1(17) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'workforceCostYear4' ? 'active' : ''" @click="fillData4('workforceCostYear4',yeardata[4])">
          <span class="card-header">Workforce ({{this.yeardata[4] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
            <span class="card-total">{{ getTileData1(18) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'financeYear4' ? 'active' : ''" @click="fillData4('financeYear4',yeardata[4])">
          <span class="card-header">Finance ({{this.yeardata[4]}})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
            <span class="card-total">{{ getTileData1(19) }}</span>
          </div>
          <span class="card-info"></span>
        </div>
      </div>

        <div v-if="activeSummary == 'workforceCostYear1' || activeSummary == 'workforceCostYear2' || activeSummary == 'workforceCostYear3' || activeSummary == 'workforceCostYear4' || activeSummary == 'workforceCostYear5'">
          <h1 class="text-3xl my-6 active">Workforce Summary</h1>

          <div class="chartcontainer3">
            <div class="title_text3">WORKFORCE</div>
            <div clas="chart_view3">
            <Bar
              :chart-options="chartOptions"
              :chart-data="datacollection"
              :chart-id="chartBarId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
            </div>
          </div>

          <div>
            <h1><br /></h1>
          </div>

          <table class="table-primary">
            <thead>
              <tr>
                <th class="border px-4 py-2">Workforce</th>
                <th class="border px-4 py-2">Quantity</th>
                <th class="border px-4 py-2">Staff Cost</th>
                <th class="border px-4 py-2">Total Workforce Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div
                    v-for="(workforce, index) in workforceSummaryTableWorkforceFilter"
                    :key="index"
                  >
                    {{ workforce  }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(quantity, index) in workforceSummaryTableQuantityFilter"
                    :key="index"
                  >
                    {{ quantity | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(costPerStaff, index) in workforceSummaryTableCostPerStaffFilter"
                    :key="index"
                  >
                    {{ costPerStaff | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      totalWorkforceCost, index
                    ) in workforceSummaryTableTotalWorkforceCostFilter"
                    :key="index"
                  >
                    {{ totalWorkforceCost | numeral }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="activeSummary == 'assetsYear1' || activeSummary == 'assetsYear2' || activeSummary == 'assetsYear3' || activeSummary == 'assetsYear4' || activeSummary == 'assetsYear5'">
          <!-- start -->
          <h1 class="text-3xl my-6 active">Assets Summary</h1>

          <div class="chartcontainer3">
            <div class="title_text3">ASSETS</div>
            <div class="chart_view3">
            <Bar
              :chart-options="chartOptions"
              :chart-data="datacollection"
              :chart-id="chartBarId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
            </div>
          </div>

          <div>
            <h1><br /></h1>
          </div>

          <table class="table-primary">
            <thead>
              <tr>
                <th class="border px-4 py-2">Assets</th>
                <th class="border px-4 py-2">Quantity</th>
                <th class="border px-4 py-2">Capital Cost Per Item</th>
                <th class="border px-4 py-2">Total Capital Cost</th>
                <th class="border px-4 py-2">Life Cycle</th>
                <th class="border px-4 py-2">Annual Cost</th>
                <th class="border px-4 py-2">Annual Maintenance Cost</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td class="border">
                  <div v-for="(assets, index) in assetsFilter" :key="index">
                    {{ assets }}
                  </div>
                </td>
                <td class="border">
                  <div
                    v-for="(assetsQuantity, index) in assetsQuantityFilter"
                    :key="index"
                  >
                    {{ assetsQuantity | numeral }}
                  </div>
                </td>
                <td class="border">
                  <div
                    v-for="(
                      capitalCostPerItem, index
                    ) in assetsCapitalCostPerItemFilter"
                    :key="index"
                  >
                    {{ capitalCostPerItem | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      totalCapitalCost, index
                    ) in assetsTotalCapitalCostFilter"
                    :key="index"
                  >
                    {{ totalCapitalCost | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(lifeCycle, index) in assetsLifeCycleFilter"
                    :key="index"
                  >
                    {{ lifeCycle }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      annualisedCost, index
                    ) in assetsAnnualisedCostFilter"
                    :key="index"
                  >
                    {{ annualisedCost | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      annualisedMaintenanceCost, index
                    ) in assetsAnnualisedMaintenanceCostFilter"
                    :key="index"
                  >
                    {{ annualisedMaintenanceCost | numeral }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- finish -->

        <div v-if="activeSummary == 'patientEpisodeYear1' || activeSummary == 'patientEpisodeYear2' || activeSummary == 'patientEpisodeYear3' || activeSummary == 'patientEpisodeYear4' || activeSummary == 'patientEpisodeYear5'">
          <h1 class="text-3xl my-6 active">Patient Episodes</h1>
          <!-- start -->
          <div class="chartcontainer3">
            <div class="title_text3">PATIENT EPISODES</div>
            <div class="chart_view3">
            <Bar
              :chart-options="chartOptions"
              :chart-data="datacollection"
              :chart-id="chartBarId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
            </div>
          </div>

          <div>
            <h1><br /></h1>
          </div>

          <table class="table-primary">
            <thead>
              <tr>
                <th class="border px-4 py-2">Patient Episodes</th>
                <th class="border px-4 py-2">Patient Episodes Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div
                    v-for="(
                      patientEpisodes, index
                    ) in specialityPatientEpisodesFilter"
                    :key="index"
                  >
                    {{ patientEpisodes }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      patientEpisodesQuantity, index
                    ) in specialityPatientEpisodesTotalFilter"
                    :key="index"
                  >
                    {{ patientEpisodesQuantity | numeral }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /div -->

        <div v-if="activeSummary == 'financeYear1' || activeSummary == 'financeYear2' || activeSummary == 'financeYear3' || activeSummary == 'financeYear4' || activeSummary == 'financeYear5'">
          <h1 class="text-3xl my-6 active">Finance</h1>

          <!-- start -->

          <div class="chartcontainer3">
            <div class="title_text3">FINANCE</div>
            <div class="chart_view3">
            <Bar
              :chart-options="chartOptions"
              :chart-data="datacollection"
              :chart-id="chartBarId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
          </div>
          </div>

          <div>
            <h1><br /></h1>
          </div>

          <table class="table-primary">
            <thead>
              <tr>
                <th class="border px-4 py-2">Finance</th>
                <th class="border px-4 py-2">Finance Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div
                    v-for="(
                      patientEpisodes, index
                    ) in specialityFinanceNameFilter"
                    :key="index"
                  >
                    {{ patientEpisodes }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(financeName, index) in specialityFinanceTotalFilter"
                    :key="index"
                  >
                    {{ financeName | numeral }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- finish -->
      </div>
    </div>

      <div v-if="stageSummary == 'metrics'">
        <div class="route-content-speciality">
        <div class="route-speciality items-center secondary">
                    <!-- used to be :options=getOptions-->
          <div class="route-speciality--box">
          <div class="box">
          <span class="box-header">Metrics Year</span>
          <span class="box-label">
            <v-select
              v-model="metricYear"
              label="year"
              :clearable="false"
              @input="setMetricYear"
              :options="$store.state.prototypedata.yearsStage4"
            />
          </span>
        </div>
        </div>          
        </div>

        <div v-if="metricUpdate">
          <div class="route-content">
          <!-- 1 open -->
          <table class="table-primary">            
            <tbody>
              <tr>
                <td class="border">
                  <div v-for="(jobPlan, index) in metricActivityFilter" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanPatients, index) in metricBeneficiaryFilter" :key="index">
                    <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanPatients }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanperSessions, index) in metricMetricsFilter" :key="index">
                    <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanperSessions }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanannSessions, index) in metricHSIBenchmarkFilter" :key="index">
                    <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanannSessions }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanweekSessions, index) in metricInternationalBenchmarkFilter" :key="index">
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanweekSessions }}</span> 
                  </div>
                </td>                
              </tr>
            </tbody>
          </table>
        </div>
        </div>       
      </div>
    </div>

    <div v-if="stageSummary == 'opImprov'">
      <div class="route-content-speciality">
        <div class="route-speciality items-center secondary">
          

          <div class="route-speciality--box">
            <div class="box">
              <span class="box-header">Improvement Area</span>
              <span class="box-label">
                <v-select
                  v-model="improvementSpecialityArea"
                  label="label"
                  :clearable="false"
                  :options="$store.state.prototypedata.dataTypes2"
                />
              </span>
            </div>
          </div>
        </div>

        <!-- now check to see if any of the specialities areas have changed -->
        <div v-if="improvementType == 'diagnostics'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 4</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Daily Diagnostics Coordination</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Daily Diagnostics Coordination <div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: by Modality</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: by Modality<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm General Management oversight of daily lists</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm General Management oversight of daily lists<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Daily Reporting of Actual versus TOM per clinical specialty for IP and DC</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Reporting of Actual versus TOM per clinical specialty for IP and DC<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Commence uplift of Day-Case Activity - reduction of IP and ALOS</td>
                    <td class="border" style="padding: 10px;">20</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Commence uplift of Day-Case Activity - reduction of IP and ALOS<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Trauma Diagnostics 24/7 capacity, including day-case over-bookings</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Trauma Diagnostics 24/7 capacity, including day-case over-bookings<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                </tbody>
              </table>          
            <!-- /div -->
          </div>
        </div>

           <!-- now check to see if any of the specialities areas have changed -->
           <div v-if="improvementType == 'OPD'">
            <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 4</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Daily OPD Coordination</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Daily OPD Coordination <div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: by Pre-Ops</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: by Pre-Ops<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">OPD Coordination and Activity linked to TOM</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">OPD Coordination and Activity linked to TOM<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm General Management oversight of daily lists</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm General Management oversight of daily lists<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Daily Reporting of Actual versus TOM per clinical specialty</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Reporting of Actual versus TOM per clinical specialty<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                </tbody>
              </table>
            <!-- /div -->
          </div>
        </div>


        <div v-if="improvementType == 'OR'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 4</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>                                   
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Daily OR Coordination </td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Daily OR Coordination<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Pre-Ops</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Pre-Ops<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Pre-Session Theatre Briefing (WHO)</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Pre-Session Theatre Briefing (WHO)<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Early Knife-Skin</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Early Knife-Skin<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Reception - OR - Recovery Turnaround Timing</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Reception - OR - Recovery Turnaround Timing<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Ward - OR - Ward</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Ward - OR - Ward<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">OR Coordination and Activity linked to TOM</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">OR Coordination and Activity linked to TOM<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm General Management oversight of daily lists</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm General Management oversight of daily lists<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Daily Reporting of Actual versus TOM per clinical specialty for IP and DC</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Reporting of Actual versus TOM per clinical specialty for IP and DC<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Commence uplift of Day-Case Activity - reduction of IP and ALOS</td>
                    <td class="border" style="padding: 10px;">20</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Commence uplift of Day-Case Activity - reduction of IP and ALOS<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Trauma OR 24/7 capacity, including day-case over-bookings</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Trauma OR 24/7 capacity, including day-case over-bookings<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  
                </tbody>
              </table>
            <!-- /div -->
          </div>
        </div>

        <div v-if="improvementType == 'ER'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 4</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Daily ER Coordination</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Daily ER Coordination <div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Minor Escalation</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Minor Escalation<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Major Escalation</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Major Escalation<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">ER Coordination and Activity linked to TOM</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">ER Coordination and Activity linked to TOM<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">Confirm General Management oversight of daily lists</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm General Management oversight of daily lists<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">Daily Reporting of Actual versus TOM per clinical specialty  for Trauma Surgery</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Reporting of Actual versus TOM per clinical specialty  for Trauma Surgery<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>                                                                     
                </tbody>
              </table>
            <!-- /div -->
          </div>
        </div>

        <div v-if="improvementType == 'bedflow'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 2</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Daily Ward-Based Discharge Planning Process pre-Ward Rounds </td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Daily Ward-Based Discharge Planning Process pre-Ward Rounds<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Operational BedFLow SOPs</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Operational BedFLow SOPs<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">Confirm Multi-disciplinary Specialist Consultant ER attendance</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Multi-disciplinary Specialist Consultant ER attendance<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">Confirm Consultant Coverage per Specialty per Bed</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Consultant Coverageg per Specialty per Bed<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">ER Coordination and Activity linked to TOM</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">ER Coordination and Activity linked to TOM<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Consultant of the Week or similar cover per Bed, 7 days per week</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Consultant of the Week or similar cover per Bed, 7 days per week<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm General Management oversight of daily lists</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm General Management oversight of daily lists<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>    <tr>
                    <td class="border" style="padding: 10px;">Daily Reporting of Actual versus TOM per clinical specialty  for Trauma Surgery</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Reporting of Actual versus TOM per clinical specialty  for Trauma Surgery<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
      
                </tbody>
              </table>
            <!-- /div -->
          </div>  
        </div>
      </div>
    </div>

    <!-- :options="$store.state.prototypedata.specialities2" -->
    <div v-if="stageSummary == 'specialities'">
      <div class="route-content-speciality">
        <div class="route-speciality items-center secondary">
         
          <!-- used to be :options=getOptions-->
          <div class="route-speciality--box">
            <div class="box">
              <span class="box-header">Speciality Discipline</span>
              <span class="box-label">
                <v-select
                  v-model="selectedSpeciality"
                  label="speciality"
                  :clearable="false"
                  @input="setSpeciality"
                  :options="$store.state.prototypedata.specialities2"
                />
              </span>
            </div>
          </div>

          <div class="route-speciality--box">
          <div class="box">
          <span class="box-header">Speciality Year</span>
          <span class="box-label">
            <v-select
              v-model="selectedYear"
              label="year"
              :clearable="false"
              @input="setYear"
              :options="$store.state.prototypedata.yearsStage4"
            />
          </span>
        </div>
        </div>

          <div class="route-speciality--box">
            <div class="box">
              <span class="box-header">Speciality Area</span>
              <span class="box-label">
                <v-select
                  v-model="selectedSpecialityArea"
                  label="label"
                  :clearable="false"
                  :options="$store.state.prototypedata.dataTypes"
                />
              </span>
            </div>
          </div>
        </div>

        <!-- now check to see if any of the specialities areas have changed -->
        <div v-if="specialityType == 'patientEpisodes'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Patient Episodes</th>
                    <th class="border px-4 py-2">Patient Episodes Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          patientEpisodes, index
                        ) in specialitiesPatientEpisodesFilter"
                        :key="index"
                      >
                        {{ patientEpisodes }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          patientEpisodesQuantity, index
                        ) in specialitiesPatientEpisodesTotalFilter"
                        :key="index"
                      >
                        {{ patientEpisodesQuantity | numeral }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'finance'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Finance</th>
                    <th class="border px-4 py-2">Finance Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          patientEpisodes, index
                        ) in specialitiesFinanceNameFilter"
                        :key="index"
                      >
                        {{ patientEpisodes }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          financeName, index
                        ) in specialitiesFinanceTotalFilter"
                        :key="index"
                      >
                        {{ financeName | numeral }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'space'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Space & Property</th>
                    <th class="border px-4 py-2">Quantity</th>
                    <th class="border px-4 py-2">Capital Cost Per Item</th>
                    <th class="border px-4 py-2">Total Capital Cost</th>
                    <th class="border px-4 py-2">Life Cycle</th>
                    <th class="border px-4 py-2">Annualised Cost</th>
                    <th class="border px-4 py-2">
                      Annualised Maintenance Cost
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          spaceAndProperty, index
                        ) in specialitiesSpaceAndPropertyFilter"
                        :key="index"
                      >
                        {{ spaceAndProperty }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          spaceAndPropertyQuantity, index
                        ) in specialitiesSpaceAndPropertyQuantityFilter"
                        :key="index"
                      >
                        {{ spaceAndPropertyQuantity | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          capitalCostPerItem, index
                        ) in specialitiesCapitalCostPerItemFilter"
                        :key="index"
                      >
                        {{ capitalCostPerItem | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          totalCapitalCost, index
                        ) in specialitiesTotalCapitalCostFilter"
                        :key="index"
                      >
                        {{ totalCapitalCost | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(lifeCycle, index) in specialitiesLifeCycleFilter"
                        :key="index"
                      >
                        {{ lifeCycle }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          annualisedCost, index
                        ) in specialitiesAnnualisedCostFilter"
                        :key="index"
                      >
                        {{ annualisedCost | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          annualisedMaintenanceCost, index
                        ) in specialitiesAnnualisedMaintenanceCostFilter"
                        :key="index"
                      >
                        {{ annualisedMaintenanceCost | numeral }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'workforce'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Workforce</th>
                    <th class="border px-4 py-2">Workforce Quantity</th>
                    <th class="border px-4 py-2">Cost Per Staff</th>
                    <th class="border px-4 py-2">Total Workforce Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          workforceName, index
                        ) in specialitiesWorkforceNameFilter"
                        :key="index"
                      >
                        {{ workforceName }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          financeName, index
                        ) in specialitiesWorkforceQuantityFilter"
                        :key="index"
                      >
                        {{ financeName | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          workforceCostPerStaff, index
                        ) in specialitiesWorkforceCostPerStaffFilter"
                        :key="index"
                      >
                        {{ workforceCostPerStaff | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          workforceTotalCost, index
                        ) in specialitiesWorkforceTotalWorkforceCostFilter"
                        :key="index"
                      >
                        {{ workforceTotalCost | numeral }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="stageSummary == 'buildingsFM'">

<div class="route-content-speciality">
  <div class="route-speciality items-center secondary">
              <!-- used to be :options=getOptions-->
    <div class="route-speciality--box">
    <div class="box">
    <span class="box-header">Buildings Year</span>
    <span class="box-label">
      <v-select
        v-model="buildingsYear"
        label="year"
        :clearable="false"
        @input="setBuildingsYear"
        :options="$store.state.prototypedata.yearsStage4"
      />
    </span>
  </div>
  </div>          
  </div>

  <div v-if="buildUpdate">
    <div class="route-content">
    <!-- 1 open -->
    <table class="table-primary">
      <thead>
        <tr>
          <th class="border px-4 py-2">Area</th>
          <th class="border px-4 py-2">Total Floor Space</th>
          <th class="border px-4 py-2">Full Development Cost</th>
          <th class="border px-4 py-2">Net Build Cost</th>
          <th class="border px-4 py-2">Avg FM Cost</th>         
          <th class="border px-4 py-2">FM Cost</th>        
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="border">
            <div v-for="(jobPlan, index) in areaAreaFilter" :key="index">
              {{ jobPlan }}
            </div>
          </td>

          <td class="border">
            <div
              v-for="(jobPlanPatients, index) in areaTFSFilter"
              :key="index"
            >
              {{ jobPlanPatients }}
            </div>
          </td>

          <td class="border">
            <div
              v-for="(jobPlanperSessions, index) in areaNBCFilter"
              :key="index"
            >
              {{ jobPlanperSessions }}
            </div>
          </td>

          <td class="border">
            <div
              v-for="(jobPlanannSessions, index) in areaFDCFilter"
              :key="index"
            >
              {{ jobPlanannSessions }}
            </div>
          </td>

          <td class="border">
            <div
              v-for="(jobPlanweekSessions, index) in areaAFMCostFilter"
              :key="index"
            >
              {{ jobPlanweekSessions }}
            </div>
          </td>

          <td class="border">
            <div
              v-for="(jobPlanweekSessions, index) in areaFMCostFilter"
              :key="index"
            >
              {{ jobPlanweekSessions }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  </div>  
</div>
    </div>

    <div v-if="stageSummary == 'buildingsFM1'">
      <div class="route-content">
        <div v-if="specialityData != null">
          <!-- 1 open -->
          <table class="table-primary">
            <thead>
              <tr>
                <th class="border px-4 py-2">Area</th>
                <th class="border px-4 py-2">Total Floor Space</th>
                <th class="border px-4 py-2">Full Development Cost</th>
                <th class="border px-4 py-2">Net Build Cost</th>
                <th class="border px-4 py-2">Avg FM Cost</th>
                <th class="border px-4 py-2">FM Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div v-for="(jobPlan, index) in areaAreaFilter" :key="index">
                    {{ jobPlan }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanPatients, index) in areaTFSFilter"
                    :key="index"
                  >
                    {{ jobPlanPatients }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanperSessions, index) in areaNBCFilter"
                    :key="index"
                  >
                    {{ jobPlanperSessions }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanannSessions, index) in areaFDCFilter"
                    :key="index"
                  >
                    {{ jobPlanannSessions }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in areaAFMCostFilter"
                    :key="index"
                  >
                    {{ jobPlanweekSessions }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in areaFMCostFilter"
                    :key="index"
                  >
                    {{ jobPlanweekSessions }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>

    <div v-if="currentOverallStage == 'Stage 5'">
        <div class="route-content-speciality">             
          <div class="route-content">
          <!-- 1 open -->
          <div v-if="stageFive != null">
          <table class="table-primary">
            <thead>
              <tr>
                <th class="border px-4 py-2"></th>
                <th class="border px-4 py-2">Population</th>
                <th class="border px-4 py-2">Episode Productivity</th>
                <th class="border px-4 py-2">Assets</th>
                <th class="border px-4 py-2">Workforce</th>
                <th class="border px-4 py-2">Finance Cost Budget</th>                
              </tr>
            </thead>
            <tbody>
              <tr>          
                <td class="border">
                  <div v-for="(jobPlan, index) in StageSummary" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlan, index) in StagePopulation" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanPatients, index) in StageEpisodeProductivity" :key="index">
                    <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanPatients }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanperSessions, index) in StageAssets" :key="index">
                    <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanperSessions }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanannSessions, index) in StageWorkforce" :key="index">
                    <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanannSessions }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanweekSessions, index) in StageFinance" :key="index">
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanweekSessions }}</span> 
                  </div>
                </td>                
              </tr>              
              <tr style="border-bottom-style: hidden; border-top-style: hidden;">
                <td class="border" colspan="2" style="border-left-style: hidden;">
                  <div>
                    <span><div v-html="message"/></span>                    
                  </div>
                </td>
                
                <td class="border" >
                  <div>
                    <span> {{ current_optimised.episode_productivity }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div>
                    <span>{{ current_optimised.assets }}</span>    
                  </div>
                </td>

                <td class="border">
                  <div>
                    <span> {{ current_optimised.workforce }}</span> 
                  </div>
                </td>

                <td class="border">
                <div>
                  <span> {{ current_optimised.finance_cost_budget }}</span> 
                </div>
                </td>                                                 
              </tr>

              <tr>
                <td class="border" colspan="6"/>                               
              </tr>
              
              <tr style="border-bottom-style: hidden; border-top-style: hidden;">
              <td class="border" colspan="2">
                  <div>% Excess</div>                    
                </td>
                
                <td class="border">
                <div>
                  <span> {{ percent_excess.episode_productivity }}</span> 
                </div>
                </td>

                <td class="border">
                  <div>
                    <span>{{ percent_excess.assets }}</span>    
                  </div>
                </td>

                <td class="border">
                <div>
                  <span> {{ percent_excess.workforce }}</span> 
                </div>
                </td>

                <td class="border">
                <div>
                  <span> {{ percent_excess.finance_cost_budget }}</span> 
                </div>
                </td>                                                 
              </tr>

              <!----td class="border" style="padding: 10px;">7</td> -->
              
              <tr  style="border-bottom-style: hidden; border-top-style: hidden;">
                <td class="border" colspan="2">
                  <div>Optimised</div>                    
                </td>
                
                <td :class="getDataColour(this.percent_excess.episode_productivity)">
                <div>
                  <span><div v-html="message"/></span> 
                </div>
                </td>

                <td :class="getDataColour(this.percent_excess.assets)">
                  <span><div v-html="message"/></span>                    
                </td>

                <td :class="getDataColour(this.percent_excess.workforce)">
                <div>
                  <span><div v-html="message"/></span> 
                </div>
                </td>

                <td :class="getDataColour(this.percent_excess.finance_cost_budget)">
                <div>
                  <span><div v-html="message"/></span> 
                </div>
                </td>                                                 
              </tr>
              
            </tbody>
          </table>  
          
         

      

          <div>
            <h1><br /></h1>
          </div>

          <div class="chartcontainer5">
            <div class="title_text5">Episodes</div>
            <Bar @on-receive="updateEpisodes"
              :chart-options="chartOptions1"
              :chart-data="dataCollectionEpisodes"
              :chart-id="chartBarId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
          </div>

          <div class="chartcontainer5">
            <div class="title_text5">Assets</div>
            <Bar
              :chart-options="chartOptions1"
              :chart-data="dataCollectionAssets"
              :chart-id="chartBarId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
          </div>

          <div class="chartcontainer5">
            <div class="title_text5">Workforce</div>
            <Bar
              :chart-options="chartOptions1"
              :chart-data="dataCollectionWorkforce"
              :chart-id="chartBarId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
          </div>

          <div class="chartcontainer5">
            <div class="title_text5">Finance</div>
            <Bar
              :chart-options="chartOptions1"
              :chart-data="dataCollectionFinance"
              :chart-id="chartBarId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
          </div>

          <div>
            <h1><br /></h1>
          </div>


          <div v-if="Summary == 'Episodes'">         
          <!-- 1 open -->
          <div v-if="stageFive != null">
          <table class="table-primary">
            <thead>
              <tr>                  
                <th class="border px-4 py-2" colspan="2">Episode Productivity</th>
              </tr>
            </thead>
            <tbody>
              <tr>          
                <td class="border">
                  <div v-for="(jobPlan, index) in EpisodeSummary" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlan, index) in EpisodeProductivity" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>
              </tr>
            </tbody>
          </table>  
    
    </div>
    </div>

    <div v-if="Summary == 'Strategic'">         
          <!-- 1 open -->
          <div v-if="stageFive != null">
          <table class="table-primary">
            <thead>
              <tr>                  
                <th class="border px-4 py-2" colspan="1">Strategic Analytic Insights</th>
              </tr>
            </thead>
            <tbody>
              <tr>          
                <td class="border">
                  <div v-for="(jobPlan, index) in StrategicInsight" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>
              </tr>
            </tbody>
          </table>  
    
    </div>
    </div>

    <div v-if="Summary == 'Assets and Infrastructure'">         
          <!-- 1 open -->
          <div v-if="stageFive != null">
          <table class="table-primary">
            <thead>
              <tr>                  
                <th class="border px-4 py-2" colspan="2">Assets and Infrastructure</th>
              </tr>
            </thead>
            <tbody>
              <tr>          
                <td class="border">
                  <div v-for="(jobPlan, index) in AssetsSummary" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlan, index) in AssetsProductivity" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>
              </tr>
            </tbody>
          </table>  
    
    </div>
    </div>

    <div v-if="Summary == 'Workforce'">         
          <!-- 1 open -->
          <div v-if="stageFive != null">
          <table class="table-primary">
            <thead>
              <tr>                  
                <th class="border px-4 py-2" colspan="2">Workforce</th>
              </tr>
            </thead>
            <tbody>
              <tr>          
                <td class="border">
                  <div v-for="(jobPlan, index) in WorkforceSummary" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlan, index) in WorkforceProductivity" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>
              </tr>
            </tbody>
          </table>  
    
    </div>
    </div>

    <div v-if="Summary == 'Finance'">         
          <!-- 1 open -->
          <div v-if="stageFive != null">
          <table class="table-primary">
            <thead>
              <tr>                  
                <th class="border px-4 py-2" colspan="2">Finance</th>
              </tr>
            </thead>
            <tbody>
              <tr>          
                <td class="border">
                  <div v-for="(jobPlan, index) in FinanceSummary" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlan, index) in FinanceProductivity" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>
              </tr>
            </tbody>
          </table>  
    
    </div>
    </div>
          
        
          
        
        </div>
        </div>
        </div>   
    </div>

    <div v-if="currentOverallStage == 'Stage 6'">
        <div class="route-content">
        <div class="stat-cards" v-if="workForceDataTwo !== null">
          <div
            class="stat-cards--item single"
            :class="activeSummary === 'patientEpisode60month' ? 'active' : ''"
            @click="fillData6('patientEpisode60month')"
          >
            <span class="card-header">Patient Episode Productivity</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-patient.svg"
                alt="Patient Episodes"
              />
              <span class="card-total">{{ getTileData6(0) }}</span>
            </div>
            <span class="card-info"></span>
          </div>
  
          <div
            class="stat-cards--item single"
            :class="activeSummary === 'assets60month' ? 'active' : ''"
            @click="fillData6('assets60month')"
          >
            <span class="card-header">Assets</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <span class="card-total">{{ getTileData6(1) }}</span>
            </div>
            <span class="card-info"></span>
          </div>
  
          <div
            class="stat-cards--item single"
            :class="activeSummary === 'workforce60month' ? 'active' : ''"
            @click="fillData6('workforce60month')"
          >
            <span class="card-header">Workforce</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
              <span class="card-total">{{ getTileData6(2) }}</span>
            </div>
            <span class="card-info"
              ></span
            >
          </div>
  
          <div
            class="stat-cards--item single"
            :class="activeSummary === 'finance60month' ? 'active' : ''"
            @click="fillData6('finance60month')"
          >
            <span class="card-header">Finance</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <span class="card-total">{{ getTileData6(3) }}</span>
            </div>
            <span class="card-info"></span>
          </div>      
     </div>

     <div v-if="activeSummary == 'finance60month'">

<div class="route-content-speciality">             
<div class="route-content">
<!-- 1 open -->
<div v-if="stageSix != null">

  <table class="table-primary">
  <thead>
    <tr>
      <th class="border px-4 py-2">Current</th>        
      <th class="border px-4 py-2">2024</th>
      <th class="border px-4 py-2">2025</th>
      <th class="border px-4 py-2">2026</th>
      <th class="border px-4 py-2">2027</th>
      <th class="border px-4 py-2">2028</th>
      <th class="border px-4 py-2">2029</th>

    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border">
        <div v-for="(jobPlan, index) in financeProductivityFilter" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlan, index) in getFinance(1, 'cu')" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanPatients, index) in getFinance(2, 'cu')" :key="index">
          <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanPatients }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanperSessions, index) in getFinance(3,'cu')" :key="index">
          <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanperSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanannSessions, index) in getFinance(4, 'cu')" :key="index">
          <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanannSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getFinance(5,'cu')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>   
      
      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getFinance(6,'cu')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>    
    </tr>
  </tbody>
</table> 

<div class="hello1"><BR/></div>

<table class="table-primary">
  <thead>
    <tr>
      <th class="border px-4 py-2">Optimized</th>        
      <th class="border px-4 py-2">2024</th>
      <th class="border px-4 py-2">2025</th>
      <th class="border px-4 py-2">2026</th>
      <th class="border px-4 py-2">2027</th>
      <th class="border px-4 py-2">2028</th>
      <th class="border px-4 py-2">2029</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border">
        <div v-for="(jobPlan, index) in financeProductivityFilter" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlan, index) in getFinance(1,'op')" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanPatients, index) in getFinance(2,'op')" :key="index">
          <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanPatients }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanperSessions, index) in getFinance(3,'op')" :key="index">
          <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanperSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanannSessions, index) in getFinance(4,'op')" :key="index">
          <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanannSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getFinance(5,'op')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>    
      
      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getFinance(6,'op')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>     
    </tr>
  </tbody>
</table>   

<div>
  <h1><br /></h1>
</div>             
</div>
<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">COSTBASE</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="financeCostbase"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">SUPPLY CHAIN</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="financeSupplyChain"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">BENEFICIARY</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="financeBeneficiary"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">COST PER EPISODE</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="financeCostPerEpisode"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>     

<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">COST PER ASSET</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="financeCostPerAsset"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>     

<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">COST PER WORKFORCE</div>
  <LineChartGenerator :chart-options="chartOptions" :chart-data="financeCostPerWorkforce" :chart-id="chartLineId" :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="500" :height="300">
</div> 

<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">COST PER POPULATION</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="financeCostPerPopulation"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>     

</div>
</div>  
      </div>
      <div v-if="activeSummary == 'workforce60month'">
        <div class="route-content-speciality">             
<div class="route-content">
<!-- 1 open -->
<div v-if="stageSix != null">

  <table class="table-primary">
  <thead>
    <tr>
      <th class="border px-4 py-2">Current</th>        
      <th class="border px-4 py-2">2024</th>
      <th class="border px-4 py-2">2025</th>
      <th class="border px-4 py-2">2026</th>
      <th class="border px-4 py-2">2027</th>
      <th class="border px-4 py-2">2028</th>
      <th class="border px-4 py-2">2029</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border">
        <div v-for="(jobPlan, index) in workforceProductivityFilter" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlan, index) in getWorkforce(1, 'cu')" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanPatients, index) in getWorkforce(2, 'cu')" :key="index">
          <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanPatients }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanperSessions, index) in getWorkforce(3,'cu')" :key="index">
          <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanperSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanannSessions, index) in getWorkforce(4, 'cu')" :key="index">
          <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanannSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getWorkforce(5,'cu')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>   
      
      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getWorkforce(6,'cu')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>    
    </tr>
  </tbody>
</table> 

<div class="hello1"><BR/></div>

<table class="table-primary">
  <thead>
    <tr>
      <th class="border px-4 py-2">Optimized</th>        
      <th class="border px-4 py-2">2024</th>
      <th class="border px-4 py-2">2025</th>
      <th class="border px-4 py-2">2026</th>
      <th class="border px-4 py-2">2027</th>
      <th class="border px-4 py-2">2028</th>
      <th class="border px-4 py-2">2029</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border">
        <div v-for="(jobPlan, index) in workforceProductivityFilter" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlan, index) in getWorkforce(1,'op')" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanPatients, index) in getWorkforce(2,'op')" :key="index">
          <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanPatients }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanperSessions, index) in getWorkforce(3,'op')" :key="index">
          <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanperSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanannSessions, index) in getWorkforce(4,'op')" :key="index">
          <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanannSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getWorkforce(5,'op')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>    
      
      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getWorkforce(6,'op')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>      
    </tr>
  </tbody>
</table> 

<div>
  <h1><br /></h1>
</div>           
</div>
<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">TOTAL WORKFORCE</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="workforceTotalWorkforce"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">MEDICAL</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="workforceMedical"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">NURSING</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="workforceNursing"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">ADMINISTRATION</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="workforceAdministration"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">AHPS</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="workforceAHPs"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">TECHNICIANS</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="workforceTechnicians"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>    


</div>
</div>  
      </div>
      <div v-if="activeSummary == 'assets60month'">

<div class="route-content-speciality">             
<div class="route-content">
<!-- 1 open -->
<div v-if="stageSix != null">
  <table class="table-primary">
  <thead>
    <tr>
      <th class="border px-4 py-2">Current</th>        
      <th class="border px-4 py-2">2024</th>
      <th class="border px-4 py-2">2025</th>
      <th class="border px-4 py-2">2026</th>
      <th class="border px-4 py-2">2027</th>
      <th class="border px-4 py-2">2028</th>
      <th class="border px-4 py-2">2029</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border">
        <div v-for="(jobPlan, index) in assetProductivityFilter" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlan, index) in getAssets(1, 'cu')" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanPatients, index) in getAssets(2, 'cu')" :key="index">
          <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanPatients }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanperSessions, index) in getAssets(3,'cu')" :key="index">
          <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanperSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanannSessions, index) in getAssets(4, 'cu')" :key="index">
          <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanannSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getAssets(5,'cu')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>   
      
      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getAssets(6,'cu')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>     
    </tr>
  </tbody>
</table> 

<div class="hello1"><BR/></div>

<table class="table-primary">
  <thead>
    <tr>
      <th class="border px-4 py-2">Optimized</th>        
      <th class="border px-4 py-2">2024</th>
      <th class="border px-4 py-2">2025</th>
      <th class="border px-4 py-2">2026</th>
      <th class="border px-4 py-2">2027</th>
      <th class="border px-4 py-2">2028</th>
      <th class="border px-4 py-2">2029</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border">
        <div v-for="(jobPlan, index) in assetProductivityFilter" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlan, index) in getAssets(1,'op')" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanPatients, index) in getAssets(2,'op')" :key="index">
          <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanPatients }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanperSessions, index) in getAssets(3,'op')" :key="index">
          <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanperSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanannSessions, index) in getAssets(4,'op')" :key="index">
          <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanannSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getAssets(5,'op')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>       
      
      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getAssets(6,'op')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>       
    </tr>
  </tbody>
</table> 

<div>
  <h1><br /></h1>
</div>

             
</div>

<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">TOTAL ASSETS</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="assetTotalAssets"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">TOTAL BEDS</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="assetTotalBeds"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">TOTAL INTERVENTION & DIALYSIS</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="assetTotalIntervention"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">TOTAL PRIMARY CARE</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="assetPrimaryCare"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

</div>
</div>  
      </div>

      <div v-if="activeSummary == 'patientEpisode60month'">

<div class="route-content-speciality">             
<div class="route-content">
<!-- 1 open -->
<div v-if="stageSix != null">
<table class="table-primary">
  <thead>
    <tr>
      <th class="border px-4 py-2">Current</th>        
      <th class="border px-4 py-2">2024</th>
      <th class="border px-4 py-2">2025</th>
      <th class="border px-4 py-2">2026</th>
      <th class="border px-4 py-2">2027</th>
      <th class="border px-4 py-2">2028</th>
      <th class="border px-4 py-2">2029</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border">
        <div v-for="(jobPlan, index) in episodeProductivityFilter" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlan, index) in getPatientEpisodes(1, 'cu')" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanPatients, index) in getPatientEpisodes(2, 'cu')" :key="index">
          <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanPatients }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanperSessions, index) in getPatientEpisodes(3,'cu')" :key="index">
          <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanperSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanannSessions, index) in getPatientEpisodes(4, 'cu')" :key="index">
          <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanannSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getPatientEpisodes(5,'cu')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td> 
      
      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getPatientEpisodes(6,'cu')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>       
    </tr>
  </tbody>
</table> 

<div class="hello1"><BR/></div>

<table class="table-primary">
  <thead>
    <tr>
      <th class="border px-4 py-2">Optimized</th>        
      <th class="border px-4 py-2">2024</th>
      <th class="border px-4 py-2">2025</th>
      <th class="border px-4 py-2">2026</th>
      <th class="border px-4 py-2">2027</th>
      <th class="border px-4 py-2">2028</th>
      <th class="border px-4 py-2">2029</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border">
        <div v-for="(jobPlan, index) in episodeProductivityFilter" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlan, index) in getPatientEpisodes(1,'op')" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanPatients, index) in getPatientEpisodes(2,'op')" :key="index">
          <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanPatients }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanperSessions, index) in getPatientEpisodes(3,'op')" :key="index">
          <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanperSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanannSessions, index) in getPatientEpisodes(4,'op')" :key="index">
          <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanannSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getPatientEpisodes(5,'op')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>     
      
      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getPatientEpisodes(6,'op')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>     
    </tr>
  </tbody>
</table> 

<div>
  <h1><br /></h1>
</div>             
</div>
<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">EPISODES</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="patientEpisodes"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">OPD</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="patientOPD"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">Intervention</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="patientIntervention"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer5" style="width: 410px; height:350px">
  <div class="title_text5">Diagnostics</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="patientDiagnostics"
    :chart-id="chartLineId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

</div>
</div>       
</div>           
    </div>
    </div>
  </div>
</template>
<script>
import { Bar } from "vue-chartjs/legacy";
import { Line as LineChartGenerator } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "bar",
  components: {
    Bar,
    LineChartGenerator,
  },
  props: {
    chartBarId: {
      type: String,
      default: "bar-chart",
    },
    chartLineId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stageSummary: "stage",
      selectedSpeciality: "AHPs",
      selectedYear: "2026",
      selectedStage: "Stage 4",
      currentOverallStage: "Stage 4",
      currentOverallTitle: "5 Year Optimization",
      metricYear: "2026",
      buildingsYear: "2025",
      //selectedNewHospital: "BOC",
      improvementSpecialityArea: "Diagnostics",
      //selectedHospital: "MH",
      Summary: "Episodes",
      selectedHospitalPrefix: "_new_bdf_4_",
      apiPayload: { reference: "", hospital: "", speciality: "", apiString: "", year: "", stage: "4", yearApi: "" },
      activeSummary: "workforceCostYear1",      
      improvementType: "Diagnostics",
      selectedSpecialityArea: "Patient Episodes",
      specialityType: "Patient Episodes",
      specialityApi: "ahps_surrey_stage_3_2023_collection",
      tileInformation: {},
      firstUpdate: true,  
      secondUpdate: false,
      stageFourData: [],
      activeStageYear: "2025",
      metricActivity: [],
      metricMetrics: [],
      metricBeneficiary: [],
      metricHsiBenchmark: [],
      metricInternationalBenchmark: [],
      metricUpdate: false,
      yeardata: [],
      buildArea: [],
      buildTFS: [],
      buildNBC: [],
      buildFDC: [],
      buildAFM: [],
      buildFM: [],
      buildUpdate: false,
      message: "<BR />",  
      current_optimised: { population: 0, episode_productivity: 0, assets: 0, workforce: 0, finance_cost_budget: 0},
      percent_excess: { population: 0, episode_productivity: 0, assets: 0, workforce: 0, finance_cost_budget: 0},
      optimised: { population: 0, episode_productivity: 0, assets: 0, workforce: 0, finance_cost_budget: 0 },
      dataCollectionPopulation: [],
      dataCollectionEpisodes: [],
      dataCollectionAssets: [],
      dataCollectionWorkforce: [],
      dataCollectionFinance: [],
      //message: "<BR />",    
      //chartOptions: {
      //  responsive: true
      //},
    };
  },
  updated () {
    //console.log ("updated: ")
    if (this.firstUpdate == true)
    {
      //console.log ("update: update only once");

       var fullAPIString = "get" + this.selectedHospitalPrefix;

       //console.log ("4. ", fullAPIString);

        this.fetchDataSummaries(fullAPIString);

        for (var i = 0; i< this.yeardata.length; i++)
        {
          this.getBarGraphDataValues(this.yeardata[0]);
        }


         this.fillData4(this.activeSummary, this.activeStageYear);

         

        this.firstUpdate = false;
    }

    if (this.secondUpdate == false) 
    {
      console.log ("updated!!!!");   

      this.dataCollectionEpisodes = {
        labels: this.getBarGraphLabels5(1, "episodes"),
        datasets: [
          {
            type: 'bar',
            label: this.getBarGraphDataLabels(0),
            data: this.getBarGraphDataValues2("episodes"),
            //data: [100, 100, 100],
            backgroundColor: ["rgba(75, 192, 192, 0.2)"],
            borderColor: ["rgb(75, 192, 192)"],
            borderWidth: 1,
          },
          {
            type: 'bar',
            label: this.getBarGraphDataLabels(1),
            data: this.getBarGraphDataValues3("episodes"),
            //data: [200, 200, 200],
            backgroundColor: ["rgba(54, 162, 235, 0.2)"],
            borderColor: ["rgb(54, 162, 235)"],
            borderWidth: 1,
          },
        ],
        //chartOptions: {
        //    responsive: true,
        //    maintainAspectRatio: false,
        //    onClick: this.handleCollectionEpisodes,
        //},
      };

      this.dataCollectionAssets = {
        labels: this.getBarGraphLabels5(2,"assets"),
        datasets: [
          {
            label: this.getBarGraphDataLabels(0),
            data: this.getBarGraphDataValues2("assets"),
            //data: [100, 100, 100],
            backgroundColor: ["rgba(75, 192, 192, 0.2)"],
            borderColor: ["rgb(75, 192, 192)"],
            borderWidth: 1,
          },
          {
            label: this.getBarGraphDataLabels(1),
            data: this.getBarGraphDataValues3("assets"),
            //data: [200, 200, 200],
            backgroundColor: ["rgba(54, 162, 235, 0.2)"],
            borderColor: ["rgb(54, 162, 235)"],
            borderWidth: 1,
          },
        ],
      };

      this.dataCollectionWorkforce = {
        labels: this.getBarGraphLabels5(3,"workforce"),
        datasets: [
          {
            label: this.getBarGraphDataLabels(0),
            data: this.getBarGraphDataValues2("workforce"),
            //data: [100, 100, 100],
            backgroundColor: ["rgba(75, 192, 192, 0.2)"],
            borderColor: ["rgb(75, 192, 192)"],
            borderWidth: 1,
          },
          {
            label: this.getBarGraphDataLabels(1),
            data: this.getBarGraphDataValues3("workforce"),
            //data: [200, 200, 200],
            backgroundColor: ["rgba(54, 162, 235, 0.2)"],
            borderColor: ["rgb(54, 162, 235)"],
            borderWidth: 1,
          },
        ],
      };

      this.dataCollectionFinance = {
        labels: this.getBarGraphLabels5(4, "finance"),
        datasets: [
          {
            label: this.getBarGraphDataLabels(0),
            data: this.getBarGraphDataValues2("finance"),
            //data: [100, 100, 100],
            backgroundColor: ["rgba(75, 192, 192, 0.2)"],
            borderColor: ["rgb(75, 192, 192)"],
            borderWidth: 1,
          },
          {
            label: this.getBarGraphDataLabels(1),
            data: this.getBarGraphDataValues3("finance"),
            //data: [200, 200, 200],
            backgroundColor: ["rgba(54, 162, 235, 0.2)"],
            borderColor: ["rgb(54, 162, 235)"],
            borderWidth: 1,
          },
        ],
      };

      this.secondUpdate = true;
    }
    
  },
  beforeMount() {
    //console.log("Summary3.vue before mounted");
    this.apiPayload.apiString = "grandsummarytwo-collection";
    this.apiPayload.reference = "1";
  
    var fullAPIString = "get" + this.selectedHospitalPrefix;

    //console.log ("1. ", fullAPIString);
    //console.log ("->1");
    this.fetchDataSummaries (fullAPIString);
    //console.log ("<-1");


  },
  mounted() {
    //const speciality = {
//      speciality: "Grand Summary 3",
  //    apiString: "grandsummarythreeyearone-collection",
    //};
    //console.log ("->2a");
    //this.fetchData(speciality);

    //console.log ("->2b");

    this.apiPayload.speciality = "AHPs";
    this.apiPayload.apiString = "grandsummarythreeyearone-collection";
    this.apiPayload.year = "2024";
    //this.apiPayload.hospital = "boc";
    this.apiPayload.reference = "2";

    this.datacollection = {};

    //console.log ("mounted - apiPayload", this.apiPayload);

    var fullAPIString = "get" + this.selectedHospitalPrefix;

    //console.log ("2 ", fullAPIString);

    //console.log ("->2c");

    this.fetchDataSummaries(fullAPIString);

    //console.log ("->2d");

    this.fillData4("assetsYear1", this.activeStageYear);
    //console.log ("->2e");

      console.log ("stuff: ", this.$store.state.prototypedata.yearsStage4);

      for (var i= 0; i < this.$store.state.prototypedata.yearsStage4.length; i++)
      {
        this.yeardata.push ( this.$store.state.prototypedata.yearsStage4[i].year);
        console.log ("year: ",this.$store.state.prototypedata.yearsStage4[i].year);
      }


    //this.getOptionsMethod();
  },
  watch: {
    selectedSpecialityArea: {
      handler: function (val) {
        //console.log(
        //  "selectedSpecialityArea: Value Change: ",
        //  val.label,
        //  val.string
        //);
        this.editSpecialityArea(val.label);
        //console.log("speciality: ", this.specialityApi);
        this.apiPayload.apiString = this.specialityApi;
        this.apiPayload.reference = "3";
        //this.getMongoDBData();
      },
      deep: true,
    },   
    improvementSpecialityArea: {
      handler: function (val) {
        //console.log(
         // "improvementArea: Value Change: ",
          //val.label,
          //val.string
       // );
        this.editImprovementType(val.label);
        //console.log("speciality: ", this.specialityApi);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      },
      deep: true,
    },
    activeSummary: {
      handler: function (newvalue) {
        //console.log("The new activeSummary value is ", newvalue);
        this.fillData4 (newvalue, this.activeStageYear);
      },
      deep: true,
    },
    stageSummary: {
      handler: function () {
        
      },
      deep: true,
    },
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },

    episodeProductivityFilter() 
    {
      var i = 0;

      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        i = this.getEpisodeItemIndex ();
        return this.$store.state.prototypedata.stageSix[i].episodeProductivity;
                                                            
      }
      return null;
    },
    

    financeProductivityFilter() 
    {
      var i = 0;

      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        i = this.getFinanceItemIndex ();
        return this.$store.state.prototypedata.stageSix[i].finance;
                                                            
      }
      return null;
    },
    assetProductivityFilter() 
    {
      var i = 0;

      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        i = this.getAssetItemIndex ();
        console.log ("assetProductivityFilter index = ", i);
        console.log ("data: ",this.$store.state.prototypedata.stageSix[i]);
        return this.$store.state.prototypedata.stageSix[i].assets;
                                                            
      }
      return null;
    },
    
    workforceProductivityFilter() 
    {
      var i = 0;

      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        i = this.getWorkforceItemIndex ();
        return this.$store.state.prototypedata.stageSix[i].workforce;
                                                            
      }
      return null;
    },

   


    stageFive() 
    {
      if (this.$store.state.prototypedata.stageFive.length > 0) {
        return true;
      }
      return null;
    },  
    specialityData() {
      //console.log(
//        "specialityData: ",
  //      this.$store.state.prototypedata.specialityData.length
    //  );
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return true;
      }
      return null;
    },

    StageSummary() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      if (this.$store.state.prototypedata.stageFive.length > 0) {
        //console.log ("StageSummary: this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFive[0].stage5summary;
      }
      return null;
    },
    EpisodeSummary() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      // stageFiveInsights

      var i = this.getDataIndex ("episode_productivity", this.$store.state.prototypedata.stageFiveInsights);

      console.log ("Episode Summary: ", i);

      if (this.$store.state.prototypedata.stageFiveInsights.length > 0) {
        //console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFiveInsights[i].episode_productivity;
      }
      return null;
    },
    EpisodeProductivity() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      // stageFiveInsights

      var i = this.getDataIndex ("episode_insight", this.$store.state.prototypedata.stageFiveInsights);

      console.log ("Episode Summary: ", i);

      if (this.$store.state.prototypedata.stageFiveInsights.length > 0) {
        //console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFiveInsights[i].episode_insight;
      }
      return null;
    },
    AssetsSummary() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      // stageFiveInsights

      var i = this.getDataIndex ("assets", this.$store.state.prototypedata.stageFiveInsights);

      console.log ("Episode Summary: ", i);

      if (this.$store.state.prototypedata.stageFiveInsights.length > 0) {
        //console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFiveInsights[i].assets;
      }
      return null;
    },
    StrategicInsight() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      // stageFiveInsights

      var i = this.getDataIndex ("strategic_analytics_insights", this.$store.state.prototypedata.stageFiveInsights);

      console.log ("Episode Summary: ", i);

      if (this.$store.state.prototypedata.stageFiveInsights.length > 0) {
        //console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFiveInsights[i].strategic_analytics_insights;
      }
      return null;
    },
    AssetsProductivity() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      // stageFiveInsights

      var i = this.getDataIndex ("assets_insight", this.$store.state.prototypedata.stageFiveInsights);

      console.log ("Episode Summary: ", i);

      if (this.$store.state.prototypedata.stageFiveInsights.length > 0) {
        //console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFiveInsights[i].assets_insight;
      }
      return null;
    },

    WorkforceSummary() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      // stageFiveInsights

      var i = this.getDataIndex ("workforce", this.$store.state.prototypedata.stageFiveInsights);

      console.log ("Episode Summary: ", i);

      if (this.$store.state.prototypedata.stageFiveInsights.length > 0) {
        //console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFiveInsights[i].workforce;
      }
      return null;
    },
    WorkforceProductivity() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      // stageFiveInsights

      var i = this.getDataIndex ("workforce_insight", this.$store.state.prototypedata.stageFiveInsights);

      console.log ("Episode Summary: ", i);

      if (this.$store.state.prototypedata.stageFiveInsights.length > 0) {
        //console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFiveInsights[i].workforce_insight;
      }
      return null;
    },
    FinanceSummary() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      // stageFiveInsights

      var i = this.getDataIndex ("finance", this.$store.state.prototypedata.stageFiveInsights);

      console.log ("Episode Summary: ", i);

      if (this.$store.state.prototypedata.stageFiveInsights.length > 0) {
        //console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFiveInsights[i].finance;
      }
      return null;
    },
    FinanceProductivity() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      // stageFiveInsights

      var i = this.getDataIndex ("finance_insight", this.$store.state.prototypedata.stageFiveInsights);

      console.log ("Episode Summary: ", i);

      if (this.$store.state.prototypedata.stageFiveInsights.length > 0) {
        //console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFiveInsights[i].finance_insight;
      }
      return null;
    },

    StagePopulation() {
      var data = new Array ();
      var str = "";

      if (this.$store.state.prototypedata.stageFive.length > 0) {
        for (var i = 0; i< this.$store.state.prototypedata.stageFive[0].population.length; i++)
        {
          str =  this.$store.state.prototypedata.stageFive[0].population[i];
          //console.log ("str: ", str);
          data.push (this.numberWithCommas (str));

          if (i == 1)
          {
            console.log ("data [0] : ", data[0]);
            console.log ("data [1] : ", data[1]);
            //this.current_optimised.population = this.numberWithCommas(data[0].replaceAll (",","") - data[1].replaceAll (",",""));
            //this.percent_excess.populated = this.numberWithPercent(data[0].replaceAll (",","") / data[1].replaceAll (",",""));

            //var num=25;
            //var s = Number(num/100).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2}); 
            //console.log(s);
          }


        }
    
        return data;
      }
      return null;
    },

    /* eslint-disable */
    StageAssets() {
      var data = new Array ();
      var str = "";

      if (this.$store.state.prototypedata.stageFive.length > 0) {
        for (var i = 0; i< this.$store.state.prototypedata.stageFive[0].assets.length; i++)
        {
          str =  this.$store.state.prototypedata.stageFive[0].assets[i];
          //console.log ("str: ", str);
          data.push (this.numberWithCommas (str));


          if (i == 1)
          {
            console.log ("data [0] : ", data[0]);
            console.log ("data [1] : ", data[1]);
            this.current_optimised.assets = this.numberWithCommas(data[0].replaceAll (",","") - data[i].replaceAll (",",""));
            this.percent_excess.assets = this.numberWithPercent(data[0].replaceAll (",","") / data[i].replaceAll (",","")).replaceAll ("%","");
          }
        }
    
        return data;
      }
      return null;
    },
    StageEpisodeProductivity() 
    {
      var data = new Array();
      var str ="";


      if (this.$store.state.prototypedata.stageFive.length > 0) {
        for (var i = 0; i< this.$store.state.prototypedata.stageFive[0].episode_productivity.length; i++)
        {
          str =  this.$store.state.prototypedata.stageFive[0].episode_productivity[i];
          //console.log ("str: ", str);
          data.push (this.numberWithCommas (str));

          if (i == 1)
          {
            console.log ("data [0] : ", data[0]);
            console.log ("data [1] : ", data[1]);
            this.current_optimised.episode_productivity = this.numberWithCommas(data[0].replaceAll (",","") - data[1].replaceAll (",",""));
            this.percent_excess.episode_productivity = this.numberWithPercent(data[0].replaceAll (",","") / data[1].replaceAll (",","")).replaceAll ("%","");
          }
        }
        return data;
      }
      return null;
    },
    StageWorkforce() {
      var data = new Array();
      var str = "";

      if (this.$store.state.prototypedata.stageFive.length > 0) {
        for (var i = 0; i< this.$store.state.prototypedata.stageFive[0].workforce.length; i++)
        {
          str =  this.$store.state.prototypedata.stageFive[0].workforce[i];
          //console.log ("str: ", str);
          data.push (this.numberWithCommas (str));

          if (i == 1)
          {
            console.log ("data [0] : ", data[0]);
            console.log ("data [1] : ", data[1]);
            this.current_optimised.workforce = this.numberWithCommas (data[0].replaceAll (",","") - data[1].replaceAll (",",""));
            this.percent_excess.workforce = this.numberWithPercent(data[0].replaceAll (",","") / data[1].replaceAll (",","")).replaceAll ("%","");
          }
        }
        return data;            
      }
      return null;
    },
    StageFinance() {
      var data = new Array();
      var str = "";

      if (this.$store.state.prototypedata.stageFive.length > 0) {
        for (var i = 0; i< this.$store.state.prototypedata.stageFive[0].finance_cost_budget.length; i++)
        {
          str =  this.$store.state.prototypedata.stageFive[0].finance_cost_budget[i];
          //console.log ("str: ", str);
          data.push (this.numberWithCommas (str));

          if (i == 1)
          {
            console.log ("data [0] : ", data[0]);
            console.log ("data [1] : ", data[1]);
            this.current_optimised.finance_cost_budget = this.numberWithCommas(data[0].replaceAll (",","") - data[1].replaceAll (",",""));
            this.percent_excess.finance_cost_budget = this.numberWithPercent(data[0].replaceAll (",","") / data[1].replaceAll (",","")).replaceAll ("%","");
          }
        }
        return data;            
      }
      return null;    
    },
    
    stageFourYearOne()
    {
      if (this.$store.state.prototypedata.stageFourYearOne.length > 0)
       {
        return true;
       }

       return null;
    },
    stageFourYearTwo()
    {
      if (this.$store.state.prototypedata.stageFourYearTwo.length > 0)
       {
        return true;
       }

       return null;
    },
    stageFourYearThree()
    {
      if (this.$store.state.prototypedata.stageFourYearThree.length > 0)
       {
        return true;
       }

       return null;
    },
    stageFourYearFour()
    {
      if (this.$store.state.prototypedata.stageFourYearFour.length > 0)
       {
        return true;
       }

       return null;
    },
    stageFourYearFive()
    {
      if (this.$store.state.prototypedata.stageFourYearFive.length > 0)
       {
        return true;
       }

       return null;
    },
    stageData()
    {
       if (this.$store.state.prototypedata.stageFourYearOne.length > 0)
       {
        return true;
       }

       return null;
    },
    getSpecialityAreaWorkforceIndex() {
      var i = 0;
      var index = 0;

      for (i = 0; i < this.$store.state.prototypedata.specialityData.length; i++) {
        if ("cost_per_staff" in this.$store.state.prototypedata.specialityData[i]) {
          index = i;
        }
      }

      return index;
    },
    getAreaIndex() {
      var i = 0;
      var index = 0;

      //console.log ("stageFour length: ", this.$store.state.prototypedata.stageFour.length);
      //console.log ("speciality length: ", this.$store.state.prototypedata.stageFour.length);

      for (i = 0; i < this.$store.state.prototypedata.stageFour.length; i++) {
        if ("total_floor_space" in this.$store.state.prototypedata.stageFour[i]) {
          index = i;
        }
      }

      return index;
    },
    
    
    getSpecialityAreaPatientIndex() {
      var i = 0;
      var index = 0;

      for (i = 0; i < this.$store.state.prototypedata.specialityData.length; i++) {
        if (
          "patient_episodes_total" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getSpecialityAreaSpaceIndex() {
      var i = 0;
      var index = 0;

      if (this.activeStageYear == this.yeardata[0])

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "space_and_property" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getAssetIndex() {
      var i = 0;
      var index = 0;

      for (i = 0; i < this.$store.state.prototypedata.stageFour.length; i++) {
        if (
          "annualised_cost" in this.$store.state.prototypedata.stageFour[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getSpecialityAreaFinanceIndex() {
      var i = 0;
      var index = 0;

      for (i = 0; i < this.$store.state.prototypedata.specialityData.length; i++) {
        if ("finance_total" in this.$store.state.prototypedata.specialityData[i]) {
          index = i;
        }
      }

      return index;
    },
 
    getPopulationIndex() {
      var i = 0;
      var index = 0;

      //console.log("getFinanceIndex");

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if ("population" in this.$store.state.prototypedata.specialityData[i]) {
          index = i;
        }
      }

      return index;
    },

    metricActivityFilter() {
      return this.metricActivity;
    },
    // metricBeneficiaryFilter
    metricBeneficiaryFilter() {        
        return this.metricBeneficiary;
      
    },
    metricMetricsFilter() {    
        return this.metricMetrics;
    },
    metricHSIBenchmarkFilter() {      
      return this.metricHsiBenchmark;
       

    },
    metricInternationalBenchmarkFilter() {      
        return  this.metricInternationalBenchmark;
    },
    isPatientData()
    {
      var exists = false;

      //console.log ("isPatientData: ", this.activeSummary);
      if (this.activeSummary == 'patientEpisodeYear1' || 
          this.activeSummary == 'patientEpisodeYear2' ||
          this.activeSummary == 'patientEpisodeYear3' ||
          this.activeSummary == 'patientEpisodeYear4' || 
          this.activeSummary == 'patientEpisodeYear5' 
          )

           {
            exists = true;
           }

      //console.log ("exists = ", exists);

       return exists;
    },
    getPopulation() {
      //var i = this.getPopulationIndex;

      //console.log ("population index ", i);
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getPopulationIndex
        ].population[0];
      }
      return null;
    },
    specialitiesWorkforceCostPerStaffFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaWorkforceIndex].cost_per_staff;
      }
      return null;
    },
    specialityWorkforceCostPerStaffFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex].cost_per_staff;
      }
      return null;
    },
    specialityWorkforceTotalWorkforceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex].total_workforce_cost;
      }
      return null;
    },
    specialitiesWorkforceTotalWorkforceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaWorkforceIndex].total_workforce_cost;
      }
      return null;
    },
    specialitiesWorkforceNameFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaWorkforceIndex].workforce;
      }
      return null;
    },
    // Workforce Summary Table -- Workforce column
    specialityWorkforceNameFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex].workforce;
      }
      return null;
    },
    specialitiesWorkforceQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaWorkforceIndex
        ].workforce_quantity;
      }
      return null;
    },
    // Workforce Table -- Quantity column
    specialityWorkforceQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex
        ].workforce_quantity;
      }
      return null;
    },
    workforceSummaryTableWorkforceFilter() {
      return this.getDataSlice (this.activeStageYear, "cost_per_staff", "workforce");
    },
    workforceSummaryTableQuantityFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceSummaryTableQuantityFilter)
      );

      return str;
    },
    assetsTotalCapitalCostFilter() 
    {
      return this.getDataSlice (this.activeStageYear, "annualised_cost", "total_capital_cost");
    },
    assetsCapitalCostPerItemFilter() {
      return this.getDataSlice (this.activeStageYear, "annualised_cost", "capital_cost_per_item");
    },
    workforceSummaryTableQuantityFilter() {
      return this.getDataSlice (this.activeStageYear, "cost_per_staff", "workforce_quantity");
    },
    workforceSummaryTableCostPerStaffFilter() {
      return this.getDataSlice (this.activeStageYear, "cost_per_staff", "cost_per_staff");
    },
    workforceSummaryTableCostPerStaffFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceSummaryTableCostPerStaffFilter)
      );

      return str;
    },
    workforceSummaryTableTotalWorkforceCostFilter() {
      return this.getDataSlice(this.activeStageYear, "cost_per_staff", "total_workforce_cost");
    },
    workforceSummaryTableTotalWorkforceCostFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceSummaryTableTotalWorkforceCostFilter)
      );

      return str;
    },
    specialityPatientEpisodesTotalFilter() {
      return this.getDataSlice(this.activeStageYear, "patient_episodes_total", "patient_episodes_total");
    },
    specialitiesSpaceAndPropertyFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaSpaceIndex
        ].space_and_property;
      }
      return null;
    },
    specialitiesSpaceAndPropertyQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaSpaceIndex
        ].space_and_property_quantity;
      }
      return null;
    },
    specialitiesCapitalCostPerItemFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaSpaceIndex
        ].capital_cost_per_item;
      }
      return null;
    },
    specialitiesTotalCapitalCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaSpaceIndex
        ].total_capital_cost;
      }
      return null;
    },
    specialitiesLifeCycleFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaSpaceIndex
        ].life_cycle;
      }
      return null;
    },
    specialitiesAnnualisedCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaSpaceIndex
        ].annualised_cost;
      }
      return null;
    },
    specialitiesAnnualisedMaintenanceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaSpaceIndex
        ].annualised_maintenance_cost;
      }
      return null;
    },
    areaAreaFilter() {
      return this.buildArea;
    },
    areaTFSFilter() {
      return this.buildTFS;
    },
    areaNBCFilter() {
      return this.buildNBC;
    },
    areaFDCFilter() {
      return this.buildFDC;
    },
    areaAFMCostFilter() {
      return this.buildAFM;
    },
    areaFMCostFilter() {
      return this.buildFM;
    },
    gfaFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getGFAIndex]
          .gfa;
      }
      return null;
    },    /** Finance - Specialities */
    specialitiesFinanceNameFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaFinanceIndex
        ].finance;
      }
      return null;      
    },
    specialityFinanceNameFilter() {
      return this.getDataSlice (this.activeStageYear, "finance_total", "finance");
    },
    specialitiesFinanceTotalFilter()
    {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaFinanceIndex
        ].finance_total;
       }
      return null;
    },
    specialityFinanceTotalFilter() {
      return this.getDataSlice (this.activeStageYear, "finance_total", "finance_total");
    },
    specialitiesPatientEpisodesFilter()
    {
      if (this.$store.state.prototypedata.specialityData.length > 0) 
      {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaPatientIndex].patient_episodes;
      }
      return null;
    },
    specialitiesPatientEpisodesTotalFilter()
    {
      if (this.$store.state.prototypedata.specialityData.length > 0) 
      {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaPatientIndex].patient_episodes_total;
      }
      return null;  
    },
    specialityPatientEpisodesFilter() {
      return this.getDataSlice(this.activeStageYear, "patient_episodes_total", "patient_episodes");      
    },

    specialityPatientEpisodesTotalFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.specialityPatientEpisodesTotalFilter)
      );

      return str;
    },
    assetsFilter() {
      return this.getDataSlice (this.activeStageYear, "annualised_cost", "assets");
    },
    assetsLifeCycleFilter() 
    {
      return this.getDataSlice (this.activeStageYear, "annualised_cost", "life_cycle");
    },
    assetsQuantityFilter() 
    {
      return this.getDataSlice (this.activeStageYear, "annualised_cost", "assets_quantity");   
    },
   
    assetsAnnualisedCostFilter() {
      return this.getDataSlice (this.activeStageYear, "annualised_cost", "annualised_cost");
    },
    assetsAnnualisedCostFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsAnnualisedCostFilter)
      );

      return str;
    },
    assetsAnnualisedMaintenanceCostFilter() {
      return this.getDataSlice (this.activeStageYear, "annualised_cost", "annualised_maintenance_cost");
    },
    //-----------------------------------------------------------------------------------
    // SUMMARY ASSET
    //-----------------------------------------------------------------------------------
    // Grand Summary
    // Summary Asset Table
    // Asset Filter
    summaryAssetFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 6) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_assets")
        ].summary_assets;
      }
      return null;
    },
    // Grand Summary
    // Summary Asset Table
    // Assets Quantity Filter
    summaryAssetQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 6) {
        var i = this.getDataSetIndex("summary_assets");

        if (i >= 0) {
          return this.$store.state.prototypedata.specialityData[i]
            .summary_assets_quantity;
        }
      }
      return null;
    },
    summaryAssetQuantityFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryAssetQuantityFilter)
      );

      return str;
    },
    // Grand Summary
    // Summary Asset Table
    // Asset Total Capital Cost Filter
    summaryAssetTotalCapitalCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 6) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_assets")
        ].summary_assets_total_capital_cost;
      }
      return null;
    },
    summaryAssetTotalCapitalCostFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryAssetTotalCapitalCostFilter)
      );

      return str;
    },

    // Grand Summary
    // Summary Patient Table
    // Patient Episodes Filter
    summaryPatientFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 7) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_patient_episodes")
        ].summary_patient_episodes;
      }
      return null;
    },
    // Grand Summary
    // Summary Patient Table
    // Patient Quantity Filter
    summaryPatientQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 7) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_patient_episodes")
        ].summary_patient_quantity;
      }
      return null;
    },
    summaryPatientQuantityFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryPatientQuantityFilter)
      );

      return str;
    },
    calculateAssetsTotalCapitalCost(assetsTotalCapitalCostArray) {
      let assetsTotalCapitalCostFloatArray = new Array();
      for (var i = 0; i < assetsTotalCapitalCostArray.length; i++) {
        assetsTotalCapitalCostFloatArray.push(
          parseFloat(assetsTotalCapitalCostArray[i])
        );
      }

      const total = assetsTotalCapitalCostFloatArray.reduce(function (a, b) {
        return a + b;
      });
      return total;
    },
  },
  methods: {   
    stageSix() 
    {
      if (this.$store.state.prototypedata.stageSix.length > 0) {
        return true;
      }
      return null;
    },
    getSixtyMonthLabels()
      {
        let data = new Array ();
  
        for (var i = 1; i<=72; i++)
        {
          data.push (i.toString());
        }    
        return data;  
      },
    fillData6(tileValue) 
      {
        this.activeSummary = tileValue;
  
        this.activeTitle = "Assets (6 Year) 72 Month Tracker";

        this.patientEpisodes = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Total Episode (Optimized)',
              data:  this.getData(1),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {            
              label: 'Total Episodes (Current)',
              data: this.getData(2),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.patientOPD = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'OPD (Optimized)',
              data:  this.getData(3),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'OPD (Current)',
              data: this.getData(4),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.patientIntervention = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Intervention (Optimized)',
              data:  this.getData(5),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'Intervention (Current)',
              data: this.getData(6),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.patientDiagnostics = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Diagnostics (Optimized)',
              data:  this.getData(7),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'Diagnostics (Current)',
              data: this.getData(8),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.assetTotalAssets = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Total Assets',
              data:  this.getData(9),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(10),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.assetTotalBeds = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Total Beds',
              data:  this.getData(11),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(12),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.assetTotalIntervention = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Total Intervention & Dialysis',
              data:  this.getData(13),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(14),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.assetPrimaryCare = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Total Primary Care',
              data:  this.getData(15),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(16),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.workforceTotalWorkforce = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Total Workforce',
              data:  this.getData(17),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(18),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.workforceMedical = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Medical',
              data:  this.getData(19),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(20),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.workforceNursing = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Nursing',
              data:  this.getData(21),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(22),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.workforceAdministration = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Administration',
              data:  this.getData(23),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(24),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.workforceAHPs = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'AHPs',
              data:  this.getData(25),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(26),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.workforceTechnicians = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Technicians',
              data:  this.getData(27),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(28),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.financeCostbase = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Costbase',
              data:  this.getData(29),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(30),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.financeSupplyChain = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Supply Chain',
              data:  this.getData(31),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(32),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.financeBeneficiary = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Beneficiary',
              data:  this.getData(33),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(34),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.financeCostPerEpisode = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Cost Per Episode',
              data:  this.getData(35),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(36),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.financeCostPerAsset = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Cost Per Asset',
              data:  this.getData(37),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(38),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.financeCostPerWorkforce = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Cost Per Workforce',
              data:  this.getData(39),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(40),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.financeCostPerPopulation = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Cost Per Population',
              data:  this.getData(41),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(42),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };
    },  
    isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    numberWithPercent(x)
    {
      return  Number(x).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2}); 
    },
    getBarGraphDataValues2(str) {
      var data = new Array();
      var newstr = "";

      //console.log("getBarGraphValues2");
      //console.log ("==> ", this.$store.state.prototypedata.stageFive[0].workforce);

      if (str == "workforce") 
      {
        newstr = this.$store.state.prototypedata.stageFive[0].workforce[0].replaceAll(",", "");
        data.push(newstr);
      }
      
       else if (str == "assets") { 
        newstr = this.$store.state.prototypedata.stageFive[0].assets[0].replaceAll(",", "");
        data.push(newstr);
      } else if (str == "finance") {
        newstr = this.$store.state.prototypedata.stageFive[0].finance_cost_budget[0].replaceAll(",", "");
        data.push(newstr);
      } else if (str == "episodes") {
        newstr = this.$store.state.prototypedata.stageFive[0].episode_productivity[0].replaceAll(",", "");
        data.push(newstr);
      }

      console.log ("getBarGraphDataValues2: ", newstr);

      return data;
    },
    getBarGraphDataValues3(str) {
      var data = new Array();
      var newstr = "";

      console.log("getBarGraphDataValues3: ", str);

      if (str == "workforce") 
      {
        newstr = this.$store.state.prototypedata.stageFive[0].workforce[1].replaceAll(",", "");
        data.push(newstr);
      }
       else if (str == "assets") {
        newstr = this.$store.state.prototypedata.stageFive[0].assets[1].replaceAll(",", "");
        data.push(newstr);
      } else if (str == "finance") {
        newstr = this.$store.state.prototypedata.stageFive[0].finance_cost_budget[1].replaceAll(",", "");
        data.push(newstr);
      } else if (str == "episodes") {
        newstr = this.$store.state.prototypedata.stageFive[0].episode_productivity[1].replaceAll(",", "");
        data.push(newstr);
      }
      
      console.log ("getBarGraphDataValues3: ", newstr);
      console.log ("data: ", data);
      return data;
    },
    getTrackerItemIndex() 
    {
      var i = 0;
      var index = -1;

     
      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        for (
          i = 0;
          i < this.$store.state.prototypedata.stageSix.length;
          i++
        ) {
          //console.log (" this.$store.state.prototypedata.stageSix[i]:", this.$store.state.prototypedata.stageSix[i]);
          if ( "trackerSixty" in this.$store.state.prototypedata.stageSix[i]) 
          {
            index = i;
//            console.log ("index = ", i);
          }
        }
      }

      //console.log ("here3");
      return index;
    },
    getAssets (year, value)
    {
      var data = new Array();

      year = year - 1;

          
        if (this.$store.state.prototypedata.stageSix.length > 0) 
        {
          //console.log ("getAssets", this.$store.state.prototypedata.stageSix[this.getAssetItemIndex()]);

          if (value == "cu")
          {  
            data.push (this.$store.state.prototypedata.stageSix[this.getAssetItemIndex()].total_assets_cu[year]);          
            data.push (this.$store.state.prototypedata.stageSix[this.getAssetItemIndex()].total_beds_cu[year]);           
            data.push (this.$store.state.prototypedata.stageSix[this.getAssetItemIndex()].total_intervention_cu[year]);            
            data.push (this.$store.state.prototypedata.stageSix[this.getAssetItemIndex()].total_primary_cu[year]);
          }
          else
          {
            data.push (this.$store.state.prototypedata.stageSix[this.getAssetItemIndex()].total_assets_op[year]);          
            data.push (this.$store.state.prototypedata.stageSix[this.getAssetItemIndex()].total_beds_op[year]);           
            data.push (this.$store.state.prototypedata.stageSix[this.getAssetItemIndex()].total_intervention_op[year]);            
            data.push (this.$store.state.prototypedata.stageSix[this.getAssetItemIndex()].total_primary_op[year]);

          }
        }
      return data;
    },
    getWorkforce (year, value)
    {
      var data = new Array();

      year = year - 1;

          
        if (this.$store.state.prototypedata.stageSix.length > 0) 
        {
          //console.log ("getAssets", this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()]);

          if (value == "cu")
          {  
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].total_workforce_cu[year]);          
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].medical_cu[year]);           
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].nursing_cu[year]);            
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].administration_cu[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].ahps_cu[year]);          
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].technicians_cu[year]);            
          }
          else
          {
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].total_workforce_op[year]);          
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].medical_op[year]);           
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].nursing_op[year]);            
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].administration_op[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].ahps_op[year]);          
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].technicians_op[year]);   
          }
        }


      return data;
    },
    getEpisodeItemIndex() 
    {
      var i = 0;
      var index = -1;

     
      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        for (
          i = 0;
          i < this.$store.state.prototypedata.stageSix.length;
          i++
        ) {
          //console.log (" this.$store.state.prototypedata.stageSix[i]:", this.$store.state.prototypedata.stageSix[i]);
          if ( "episodeProductivity" in this.$store.state.prototypedata.stageSix[i]) 
          {
            index = i;
            //console.log ("index = ", i);
          }
        }
      }

      //console.log ("here3");
      return index;
    },
    getWorkforceItemIndex() 
    {
      var i = 0;
      var index = -1;

     
      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        for (
          i = 0;
          i < this.$store.state.prototypedata.stageSix.length;
          i++
        ) {
          //console.log (" this.$store.state.prototypedata.stageSix[i]:", this.$store.state.prototypedata.stageSix[i]);
          if ( "total_workforce_cu" in this.$store.state.prototypedata.stageSix[i]) 
          {
            index = i;
            //console.log ("index = ", i);
          }
        }
      }

      //console.log ("here3");
      return index;
    },
   
    getFinanceItemIndex() 
    {
      var i = 0;
      var index = -1;

     
      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        for (
          i = 0;
          i < this.$store.state.prototypedata.stageSix.length;
          i++
        ) {
          //console.log (" this.$store.state.prototypedata.stageSix[i]:", this.$store.state.prototypedata.stageSix[i]);
          if ( "costbase_budget_cu" in this.$store.state.prototypedata.stageSix[i]) 
          {
            index = i;
            //console.log ("index = ", i);
          }
        }
      }

      //console.log ("here3");
      return index;
    },

    getFinance (year, value)
    {
      var data = new Array();

      year = year - 1;

          
        if (this.$store.state.prototypedata.stageSix.length > 0) 
        {
          if (value == "cu")
          {              
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].costbase_budget_cu[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].supply_chain_cu[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].beneficiary_cap_rate_cu[year]);
            //data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].infrastructure_build_cost_optimized[4]);
            //data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].annualised_property_cost_optimized[4]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].cost_per_episode_cu[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].cost_per_asset_cu[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].cost_per_workforce_cu[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].cost_per_population_cu[year]);
            //data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].episodes_per_asset_optimized[year]);
            //data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].episodes_per_workforce_optimized[year]);
          }
          else
          {
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].costbase_budget_op[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].supply_chain_op[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].beneficiary_cap_rate_op[year]);
            //data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].infrastructure_build_cost_optimized[4]);
            //data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].annualised_property_cost_optimized[4]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].cost_per_episode_op[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].cost_per_asset_op[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].cost_per_workforce_op[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].cost_per_population_op[year]);
            //data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].episodes_per_asset_optimized[year]);
            //data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].episodes_per_workforce_optimized[year]);    
          }
        }


      return data;
    },
    getAssetItemIndex() 
    {
      var i = 0;
      var index = -1;

      //console.log ("==> getAssetItemIndex()");

     
      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        for ( i = 0; i < this.$store.state.prototypedata.stageSix.length; i++) 
        {
          //console.log (" this.$store.state.prototypedata.stageSix[i]:", this.$store.state.prototypedata.stageSix[i]);
          if ( "total_assets_cu" in this.$store.state.prototypedata.stageSix[i]) 
          {
            index = i;
            //console.log ("getAssetItemIndex = ", i);
          }
        }
      }

      //console.log ("<== getAssetItemIndex()");

      //console.log ("here3");
      return index;
    },
    getData(index)  
     {
      let data = new Array ();
      let tempData = new Array ();
  
      let str =  "";
      
      //console.log ("> GetData: ", index);

      switch (index)
      {
        case 1:  tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_episodes_ot;                               
        break;
        case 2: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_episodes_ct;      
        break;
        case 3:  tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].opd_ot;
        break;
        case 4: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].opd_ct;  
        break;
        case 5:  tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].intervention_ot;
        break;
        case 6: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].intervention_ct;  
        break;
        case 7:  tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].diagnostics_ot;
        break;
        case 8: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].diagnostics_ct;
        break;

        case 9: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_assets_ot;
        break;
        case 10: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_assets_ct;
        break;
        case 11: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_beds_ot; 
        break;
        case 12: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_beds_ct; 
        break;          
        case 13: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_intervention_ot;     
        break;
        case 14: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_intervention_ct; 
        break;
        case 15: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_primary_ot; 
        break;
        case 16: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_primary_ct;     
        break;

        case 17: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_workforce_ot; 
        break;
        case 18: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_workforce_ct; 
        break;
        case 19: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].medical_ot; 
        break;
        case 20: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].medical_ct;  
        break;
        case 21: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].nursing_ot;  
        break;
        case 22: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].nursing_ct;   
        break;
        case 23: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].administration_ot;
        break;
        case 24: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].administration_ct;
        break;
        case 25: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].ahps_ot;    
        break;
        case 26: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].ahps_ct; 
        break;
        case 27: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].technicians_ot;    
        break;
        case 28: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].technicians_ct; 
        break;

        case 29:  tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].costbase_budget_ot;
        break;
        case 30: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].costbase_budget_ct; 
        break;  
        case 31: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].supply_chain_ot;
        break; 
        case 32: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].supply_chain_ct;  
        break;
        case 33:  tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].beneficiary_cap_rate_ot;
        break;
        case 34: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].beneficiary_cap_rate_ct;
        break; 
        case 35: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].cost_per_episode_ot;
        break;
        case 36: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].cost_per_episode_ct;
        break;
        case 37: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].cost_per_asset_ot;    
        break;
        case 38: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].cost_per_asset_ct;
        break;
        case 39: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].cost_per_workforce_ot;  
        break;  
        case 40: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].cost_per_workforce_ct;
        break; 
        case 41: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].cost_per_population_ot; 
        break; 
        case 42: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].cost_per_population_ct;
        break;
       
    }

    if (this.isEmpty(tempData) == false || typeof tempData !== "undefined") {
        //console.log("tempData", tempData);
        for (var i = 0; i < tempData.length; i++) {
          str = tempData[i].replaceAll(",", "");
          data.push(str);
        }
      }

//      console.log (">> GetData: ", index);
  //    console.log ("data: ", data);

      return data;
     },  
    getDataColour (value)
    {
      var colourString = "";

      if (value < 10.00)
      {
        colourString = "green-colour"; // red
      }
      else if (value > 10.01 && value < 20.01)
      {
        colourString = "amber-colour";
      }
      else if (value > 20.01)
      {
        colourString = "red-colour";
      }

      //console.log ("colourString: ", colourString)

      return colourString;

    },
    getDataIndex(itemToSearch, dataStruct) {
      var i = 0;
      var index = 0;

      for (
        i = 0;
        i < dataStruct.length;
        i++
      ) {
        if (
          itemToSearch in
          dataStruct[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getBarGraphLabels5(index, str) {
      var data = new Array();
      //console.log("getBarGraphLabels", index);      

      if (str == "workforce") 
      {
        data.push("Workforce");
      } else if (str == "assets") 
      {
        data.push("Assets");
      } 
      else if (str == "population") {
        data.push("Population");
      }
      else if (str == "finance") 
      {
        data.push("Finance");
      } else if (str == "episodes") 
      {
        data.push("Episode Productivity");
      }

      return data;
    },

    getBarGraphDataLabels(index)
    {
      var data = new Array();
      

      if (index == 0) 
      {
        data.push(this.$store.state.prototypedata.stageFive[0].stage5summary[0]);
      } else 
      {
        data.push(this.$store.state.prototypedata.stageFive[0].stage5summary[1]);
      } 

      return data;
    },
    editSummary(Summary) {
      this.Summary = Summary;
    },
    setMetricYearMethod()
    {
        this.metricUpdate = true;
        this.metricActivity = new Array ();
        this.metricMetrics = new Array ();
        this.metricBeneficiary = new Array ();
        this.metricHsiBenchmark = new Array ();
        this.metricInternationalBenchmark = new Array ();

        var currentYear = this.metricYear;

        if (typeof this.metricYear.year != "undefined")
        {
            currentYear = this.metricYear.year;
        }

        //console.log ("setMetricYear: ", currentYear);       

        var index = -1;

        if (currentYear ==  this.yeardata[0] )
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearOne,
          "metrics"
        );        

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageFourYearOne[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageFourYearOne[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageFourYearOne[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageFourYearOne[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageFourYearOne[index].international_benchmark;         
        }
      }

      if (currentYear == this.yeardata[1])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearTwo,
          "metrics"
        );    

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageFourYearTwo[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageFourYearTwo[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageFourYearTwo[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageFourYearTwo[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageFourYearTwo[index].international_benchmark;         
        }
      }

      if (currentYear == this.yeardata[2])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearThree,
          "metrics"
        );    

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageFourYearThree[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageFourYearThree[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageFourYearThree[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageFourYearThree[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageFourYearThree[index].international_benchmark;         
        }
      }

      if (currentYear == this.yeardata[3])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearFour,
          "metrics"
        );    

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageFourYearFour[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageFourYearFour[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageFourYearFour[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageFourYearFour[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageFourYearFour[index].international_benchmark;         
        }
      }

      if (currentYear == this.yeardata[4])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearFive,
          "metrics"
        );    

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageFourYearFive[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageFourYearFive[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageFourYearFive[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageFourYearFive[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageFourYearFive[index].international_benchmark;         
        }
      }
    },
    getEpisodeItemIndex() 
    {
      var i = 0;
      var index = -1;

     
      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        for (
          i = 0;
          i < this.$store.state.prototypedata.stageSix.length;
          i++
        ) {
          //console.log (" this.$store.state.prototypedata.stageSix[i]:", this.$store.state.prototypedata.stageSix[i]);
          if ( "episodeProductivity" in this.$store.state.prototypedata.stageSix[i]) 
          {
            index = i;
            //console.log ("index = ", i);
          }
        }
      }

      //console.log ("here3");
      return index;
    },

    setBuildingsYearMethod()
    {
        this.buildUpdate = true;
        this.buildArea = new Array ();
        this.buildTFS = new Array ();
        this.buildFDC = new Array ();
        this.buildNBC = new Array ();
        this.buildAFM = new Array ();
        this.buildFM = new Array ();

        var currentYear = this.buildingsYear;

        if (typeof this.buildingsYear.year != "undefined")
        {
            currentYear = this.buildingsYear.year;
        }

        //console.log ("setBuildingYear: ", currentYear);       

        var index = -1;

        if (currentYear == this.yeardata[0])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearOne,
          "total_floor_space"
        );        

        if (index > -1) 
        {
            this.buildArea = this.$store.state.prototypedata.stageFourYearOne[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageFourYearOne[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageFourYearOne[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageFourYearOne[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageFourYearOne[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageFourYearOne[index].fm_cost;         
        }
      }

      if (currentYear == this.yeardata[1])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearTwo,
          "total_floor_space"
        );    

        if (index > -1) 
        {
          this.buildArea = this.$store.state.prototypedata.stageFourYearTwo[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageFourYearTwo[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageFourYearTwo[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageFourYearTwo[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageFourYearTwo[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageFourYearTwo[index].fm_cost;      
        }
      }

      if (currentYear == this.yeardata[2])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearThree,
          "total_floor_space"
        );    

        if (index > -1) 
        {
          this.buildArea = this.$store.state.prototypedata.stageFourYearThree[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageFourYearThree[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageFourYearThree[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageFourYearThree[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageFourYearThree[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageFourYearThree[index].fm_cost;      
        }
      }

      if (currentYear == this.yeardata[3])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearFour,
          "total_floor_space"
        );    

        if (index > -1) 
        {
          this.buildArea = this.$store.state.prototypedata.stageFourYearFour[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageFourYearFour[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageFourYearFour[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageFourYearFour[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageFourYearFour[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageFourYearFour[index].fm_cost;      
        }
      }

      if (currentYear == this.yeardata[4])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearFive,
          "total_floor_space"
        );    

        if (index > -1) 
        {
          this.buildArea = this.$store.state.prototypedata.stageFourYearFive[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageFourYearFive[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageFourYearFive[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageFourYearFive[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageFourYearFive[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageFourYearFive[index].fm_cost;      
           }
      }


    },
    getPatientEpisodes (year, value)
    {
      var data = new Array();

      year = year - 1;

          
        if (this.$store.state.prototypedata.stageSix.length > 0) 
        {
          if (value == "cu")
          {  
            data.push (this.$store.state.prototypedata.stageSix[this.getEpisodeItemIndex()].total_episodes_cu[year]);          
            data.push (this.$store.state.prototypedata.stageSix[this.getEpisodeItemIndex()].opd_cu[year]);           
            data.push (this.$store.state.prototypedata.stageSix[this.getEpisodeItemIndex()].intervention_cu[year]);            
            data.push (this.$store.state.prototypedata.stageSix[this.getEpisodeItemIndex()].diagnostics_cu[year]);
          }
          else
          {
            data.push (this.$store.state.prototypedata.stageSix[this.getEpisodeItemIndex()].total_episodes_op[year]);          
            data.push (this.$store.state.prototypedata.stageSix[this.getEpisodeItemIndex()].opd_op[year]);           
            data.push (this.$store.state.prototypedata.stageSix[this.getEpisodeItemIndex()].intervention_op[year]);            
            data.push (this.$store.state.prototypedata.stageSix[this.getEpisodeItemIndex()].diagnostics_op[year]);
          }
        }

      return data;
    },
    setSpeciality ()
    {                
        //console.log ("selectedStage: ", this.selectedStage);

        if (typeof this.selectedYear.year != "undefined") 
        {
          this.apiPayload.year = this.selectedYear.year;
        }
        else if (typeof this.selectedYear != "undefined") 
        {
          this.apiPayload.year = this.selectedYear;
        }

        //console.log ("setSpeciality: this.apiPayload.year: ", this.apiPayload.year);

        if (typeof this.selectedSpeciality.speciality != "undefined") 
        {
          this.apiPayload.speciality = this.selectedSpeciality.speciality;
        }
        else if (typeof this.selectedSpeciality != "undefined") 
        {
          this.apiPayload.speciality = this.selectedSpeciality;
        }

        //console.log ("setSpeciality: this.apiPayload.speciality: ", this.apiPayload.speciality);

        this.apiPayload.apiString = "";
        this.apiPayload.reference = "5";

        this.fetchSpecialityData ();

    },
    getDataSlice (year, summary, dataIndex)
    {
      var index = -1;
   
      var newData = new Array ();

      if (year == this.yeardata[0]) 
      {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearOne,
          summary
        );

        //console.log ("index = ", index);

        if (index > -1) 
        {
           if (dataIndex == "finance") 
           {
            newData = this.$store.state.prototypedata.stageFourYearOne[index].finance;
           }
           if (dataIndex == "finance_total")
           {
            newData = this.$store.state.prototypedata.stageFourYearOne[index].finance_total;
           }

           if (dataIndex == "workforce")
           {
            newData = this.$store.state.prototypedata.stageFourYearOne[index].workforce;
           }

           if (dataIndex == "workforce_quantity")
           {
            newData = this.$store.state.prototypedata.stageFourYearOne[index].workforce_quantity;
           }

           if (dataIndex == "cost_per_staff")
           {
            newData = this.$store.state.prototypedata.stageFourYearOne[index].cost_per_staff;
           }

           if (dataIndex == "total_workforce_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearOne[index].total_workforce_cost;
           }

           if (dataIndex == "assets")
           {
            newData = this.$store.state.prototypedata.stageFourYearOne[index].assets;
           }

           if (dataIndex == "assets_quantity")
           {
            newData = this.$store.state.prototypedata.stageFourYearOne[index].assets_quantity;
           }

           if (dataIndex == "capital_cost_per_item")
           {
            newData = this.$store.state.prototypedata.stageFourYearOne[index].capital_cost_per_item;
           }

           if (dataIndex == "total_capital_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearOne[index].total_capital_cost;
           }

           if (dataIndex == "life_cycle")
           {
            newData = this.$store.state.prototypedata.stageFourYearOne[index].life_cycle;
           }

           if (dataIndex == "annualised_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearOne[index].annualised_cost;
           }

           if (dataIndex == "annualised_maintenance_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearOne[index].annualised_maintenance_cost;
           }

           if (dataIndex == "patient_episodes")
           {
            newData = this.$store.state.prototypedata.stageFourYearOne[index].patient_episodes;
           }

           if (dataIndex == "patient_episodes_total")
           {
            newData = this.$store.state.prototypedata.stageFourYearOne[index].patient_episodes_total;
           }
        }
      }

      if (year == this.yeardata[1]) 
      {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearTwo,
          summary
        );

        //console.log ("index = ", index);

        if (index > -1) 
        {
           if (dataIndex == "finance") 
           {
            newData = this.$store.state.prototypedata.stageFourYearTwo[index].finance;
           }
           if (dataIndex == "finance_total")
           {
            newData = this.$store.state.prototypedata.stageFourYearTwo[index].finance_total;
           }

           if (dataIndex == "workforce")
           {
            newData = this.$store.state.prototypedata.stageFourYearTwo[index].workforce;
           }

           if (dataIndex == "workforce_quantity")
           {
            newData = this.$store.state.prototypedata.stageFourYearTwo[index].workforce_quantity;
           }

           if (dataIndex == "cost_per_staff")
           {
            newData = this.$store.state.prototypedata.stageFourYearTwo[index].cost_per_staff;
           }

           if (dataIndex == "total_workforce_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearTwo[index].total_workforce_cost;
           }

           if (dataIndex == "assets")
           {
            newData = this.$store.state.prototypedata.stageFourYearTwo[index].assets;
           }

           if (dataIndex == "assets_quantity")
           {
            newData = this.$store.state.prototypedata.stageFourYearTwo[index].assets_quantity;
           }

           if (dataIndex == "capital_cost_per_item")
           {
            newData = this.$store.state.prototypedata.stageFourYearTwo[index].capital_cost_per_item;
           }

           if (dataIndex == "total_capital_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearTwo[index].total_capital_cost;
           }

           if (dataIndex == "life_cycle")
           {
            newData = this.$store.state.prototypedata.stageFourYearTwo[index].life_cycle;
           }

           if (dataIndex == "annualised_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearTwo[index].annualised_cost;
           }

           if (dataIndex == "annualised_maintenance_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearTwo[index].annualised_maintenance_cost;
           }

           if (dataIndex == "patient_episodes")
           {
            newData = this.$store.state.prototypedata.stageFourYearTwo[index].patient_episodes;
           }

           if (dataIndex == "patient_episodes_total")
           {
            newData = this.$store.state.prototypedata.stageFourYearTwo[index].patient_episodes_total;
           }
        }
      }

      if (year == this.yeardata[2]) 
      {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearThree,
          summary
        );

        //console.log ("index = ", index);

        if (index > -1) 
        {
           if (dataIndex == "finance") 
           {
            newData = this.$store.state.prototypedata.stageFourYearThree[index].finance;
           }
           if (dataIndex == "finance_total")
           {
            newData = this.$store.state.prototypedata.stageFourYearThree[index].finance_total;
           }

           if (dataIndex == "workforce")
           {
            newData = this.$store.state.prototypedata.stageFourYearThree[index].workforce;
           }

           if (dataIndex == "workforce_quantity")
           {
            newData = this.$store.state.prototypedata.stageFourYearThree[index].workforce_quantity;
           }

           if (dataIndex == "cost_per_staff")
           {
            newData = this.$store.state.prototypedata.stageFourYearThree[index].cost_per_staff;
           }

           if (dataIndex == "total_workforce_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearThree[index].total_workforce_cost;
           }

           if (dataIndex == "assets")
           {
            newData = this.$store.state.prototypedata.stageFourYearThree[index].assets;
           }

           if (dataIndex == "assets_quantity")
           {
            newData = this.$store.state.prototypedata.stageFourYearThree[index].assets_quantity;
           }

           if (dataIndex == "capital_cost_per_item")
           {
            newData = this.$store.state.prototypedata.stageFourYearThree[index].capital_cost_per_item;
           }

           if (dataIndex == "total_capital_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearThree[index].total_capital_cost;
           }

           if (dataIndex == "life_cycle")
           {
            newData = this.$store.state.prototypedata.stageFourYearThree[index].life_cycle;
           }

           if (dataIndex == "annualised_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearThree[index].annualised_cost;
           }

           if (dataIndex == "annualised_maintenance_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearThree[index].annualised_maintenance_cost;
           }

           if (dataIndex == "patient_episodes")
           {
            newData = this.$store.state.prototypedata.stageFourYearThree[index].patient_episodes;
           }

           if (dataIndex == "patient_episodes_total")
           {
            newData = this.$store.state.prototypedata.stageFourYearThree[index].patient_episodes_total;
           }
        }
      }

      if (year == this.yeardata[3]) 
      {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFour,
          summary
        );

        //console.log ("index = ", index);

        if (index > -1) 
        {
           if (dataIndex == "finance") 
           {
            newData = this.$store.state.prototypedata.stageFourYearFour[index].finance;
           }
           if (dataIndex == "finance_total")
           {
            newData = this.$store.state.prototypedata.stageFourYearFour[index].finance_total;
           }

           if (dataIndex == "workforce")
           {
            newData = this.$store.state.prototypedata.stageFourYearFour[index].workforce;
           }

           if (dataIndex == "workforce_quantity")
           {
            newData = this.$store.state.prototypedata.stageFourYearFour[index].workforce_quantity;
           }

           if (dataIndex == "cost_per_staff")
           {
            newData = this.$store.state.prototypedata.stageFourYearFour[index].cost_per_staff;
           }

           if (dataIndex == "total_workforce_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearFour[index].total_workforce_cost;
           }

           if (dataIndex == "assets")
           {
            newData = this.$store.state.prototypedata.stageFourYearFour[index].assets;
           }

           if (dataIndex == "assets_quantity")
           {
            newData = this.$store.state.prototypedata.stageFourYearFour[index].assets_quantity;
           }

           if (dataIndex == "capital_cost_per_item")
           {
            newData = this.$store.state.prototypedata.stageFourYearFour[index].capital_cost_per_item;
           }

           if (dataIndex == "total_capital_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearFour[index].total_capital_cost;
           }

           if (dataIndex == "life_cycle")
           {
            newData = this.$store.state.prototypedata.stageFourYearFour[index].life_cycle;
           }

           if (dataIndex == "annualised_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearFour[index].annualised_cost;
           }

           if (dataIndex == "annualised_maintenance_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearFour[index].annualised_maintenance_cost;
           }

           if (dataIndex == "patient_episodes")
           {
            newData = this.$store.state.prototypedata.stageFourYearFour[index].patient_episodes;
           }

           if (dataIndex == "patient_episodes_total")
           {
            newData = this.$store.state.prototypedata.stageFourYearFour[index].patient_episodes_total;
           }
        }
      }

      if (year == this.yeardata[4]) 
      {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFive,
          summary
        );

        //console.log ("index = ", index);

        if (index > -1) 
        {
           if (dataIndex == "finance") 
           {
            newData = this.$store.state.prototypedata.stageFourYearFive[index].finance;
           }
           if (dataIndex == "finance_total")
           {
            newData = this.$store.state.prototypedata.stageFourYearFive[index].finance_total;
           }

           if (dataIndex == "workforce")
           {
            newData = this.$store.state.prototypedata.stageFourYearFive[index].workforce;
           }

           if (dataIndex == "workforce_quantity")
           {
            newData = this.$store.state.prototypedata.stageFourYearFive[index].workforce_quantity;
           }

           if (dataIndex == "cost_per_staff")
           {
            newData = this.$store.state.prototypedata.stageFourYearFive[index].cost_per_staff;
           }

           if (dataIndex == "total_workforce_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearFive[index].total_workforce_cost;
           }

           if (dataIndex == "assets")
           {
            newData = this.$store.state.prototypedata.stageFourYearFive[index].assets;
           }

           if (dataIndex == "assets_quantity")
           {
            newData = this.$store.state.prototypedata.stageFourYearFive[index].assets_quantity;
           }

           if (dataIndex == "capital_cost_per_item")
           {
            newData = this.$store.state.prototypedata.stageFourYearFive[index].capital_cost_per_item;
           }

           if (dataIndex == "total_capital_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearFive[index].total_capital_cost;
           }

           if (dataIndex == "life_cycle")
           {
            newData = this.$store.state.prototypedata.stageFourYearFive[index].life_cycle;
           }

           if (dataIndex == "annualised_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearFive[index].annualised_cost;
           }

           if (dataIndex == "annualised_maintenance_cost")
           {
            newData = this.$store.state.prototypedata.stageFourYearFive[index].annualised_maintenance_cost;
           }

           if (dataIndex == "patient_episodes")
           {
            newData = this.$store.state.prototypedata.stageFourYearFive[index].patient_episodes;
           }

           if (dataIndex == "patient_episodes_total")
           {
            newData = this.$store.state.prototypedata.stageFourYearFive[index].patient_episodes_total;
           }
        }
      }
      
       

      return newData;
    },
    getSpecialityAPI ()
    {
      var newString = "";

      newString = newString + this.selectedSpeciality.toString().toLowerCase();
      newString = newString + "_new_bdf_stage_4_";

      newString = newString + this.selectedYear.toString().toLowerCase();

      //console.log ("getSpecialityAPI: ", newString )

      return newString;
    },
    displayData ()
    {
      console.log ("displayData");
      console.log ("");
      
      console.log ("specialityData length: ", this.$store.state.prototypedata.specialityData.length);
      console.log ("stageFourYearOne length: ", this.$store.state.prototypedata.stageFourYearOne.length);
      console.log (" ", this.$store.state.prototypedata.stageFourYearOne);
      console.log ("stageFourYearTwo length: ", this.$store.state.prototypedata.stageFourYearTwo.length);
      console.log (" ", this.$store.state.prototypedata.stageFourYearTwo);
      console.log ("stageFourYearThree length: ", this.$store.state.prototypedata.stageFourYearThree.length);
      console.log (" ", this.$store.state.prototypedata.stageFourYearThree);
      console.log ("stageFourYearFour length: ", this.$store.state.prototypedata.stageFourYearFour.length);
      console.log (" ", this.$store.state.prototypedata.stageFourYearFour);
      console.log ("stageFourYearFive length: ", this.$store.state.prototypedata.stageFourYearFive.length);
      console.log (" ", this.$store.state.prototypedata.stageFourYearFive);

      console.log ("");

      console.log ("stageFour length :", this.$store.state.prototypedata.stageFour.length);
      console.log ("stageFour:", this.$store.state.prototypedata.stageFour);  

      console.log ("stageFourYearOne: ", this.$store.state.prototypedata.stageFourYearOne[0].patient_episodes);
      console.log ("stageFourYearOne: ", this.$store.state.prototypedata.stageFourYearOne[0].patient_episodes_total);
    },
    getTileData6(tileNumber)
     {
      var Str = ""; 

      if (tileNumber == "0")        Str = "72 Month Tracker";
      if (tileNumber == "1")        Str = "72 Month Tracker";
      if (tileNumber == "2")        Str = "72 Month Tracker";
      if (tileNumber == "3")        Str = "72 Month Tracker";
      if (tileNumber == "4")        Str = "12 Month Tracker";
      if (tileNumber == "5")        Str = "12 Month Tracker";
      if (tileNumber == "6")        Str = "12 Month Tracker";
      if (tileNumber == "7")        Str = "12 Month Tracker";
      if (tileNumber == "8")        Str = "Optimization 1 Month";
      if (tileNumber == "9")        Str = "Optimization 1 Month";
      if (tileNumber == "10")        Str = "Optimization 1 Month";
      if (tileNumber == "11")        Str = "Optimization 1 Month";
    
      return Str;
     },
    getTileData1(tileNumber) {
      //console.log("--------------------------------------");
      //console.log("SummaryMain3: getTileData1", tileNumber);

      let defaultValues = new Array();
      let index = -1;

      var debugValue = false;

      let currentvalue = "";

      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[0],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[0],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[0],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[0],debugValue)));


      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[1],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[1],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[1],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[1],debugValue)));

      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[2], debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[2], debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[2],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[2],debugValue)));

      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[3],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[3],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[3],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[3],debugValue)));
      
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[4],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[4],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[4],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[4],debugValue)));

      
      index = this.getDataSetIndex("summary_overview");

      //console.log("GetTileData1 - getDataSetIndex", index);

      currentvalue = defaultValues[tileNumber];

    

      if (index >= 0) {
        //console.log("index > 0");
        if (
          tileNumber <=
          this.$store.state.prototypedata.stageFour[index].summary_overview
            .length
        ) {
          //console.log("tileNumber");
          if (
            this.$store.state.prototypedata.stageFour[index].summary_overview[
              tileNumber
            ] == null ||
            this.$store.state.prototypedata.stageFour[index].summary_overview[
              tileNumber
            ].length == 0
          ) {
            currentvalue = defaultValues[tileNumber];
          } else {
            currentvalue =
              this.$store.state.prototypedata.stageFour[index]
                .summary_overview[tileNumber];
          }
        }
      }

      //console.log("currentvalue ", currentvalue);
      //console.log("--------------------------------------");
      return currentvalue;
    },
    setBuildingsYear()
    {
        this.buildUpdate = true;
        this.buildArea = new Array ();
        this.buildTFS = new Array ();
        this.buildFDC = new Array ();
        this.buildNBC = new Array ();
        this.buildAFM = new Array ();
        this.buildFM = new Array ();

        var currentYear = this.buildingsYear;

        if (typeof this.buildingsYear.year != "undefined")
        {
            currentYear = this.buildingsYear.year;
        }

        //console.log ("setBuildingYear: ", currentYear);       

        var index = -1;

        if (currentYear == this.yeardata[0])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearOne,
          "total_floor_space"
        );        

        if (index > -1) 
        {
            this.buildArea = this.$store.state.prototypedata.stageFourYearOne[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageFourYearOne[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageFourYearOne[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageFourYearOne[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageFourYearOne[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageFourYearOne[index].fm_cost;         
        }
      }

      if (currentYear == this.yeardata[1])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearTwo,
          "total_floor_space"
        );    

        if (index > -1) 
        {
          this.buildArea = this.$store.state.prototypedata.stageFourYearTwo[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageFourYearTwo[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageFourYearTwo[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageFourYearTwo[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageFourYearTwo[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageFourYearTwo[index].fm_cost;      
        }
      }

      if (currentYear == this.yeardata[2])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearThree,
          "total_floor_space"
        );    

        if (index > -1) 
        {
          this.buildArea = this.$store.state.prototypedata.stageFourYearThree[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageFourYearThree[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageFourYearThree[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageFourYearThree[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageFourYearThree[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageFourYearThree[index].fm_cost;      
        }
      }

      if (currentYear == this.yeardata[3])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearFour,
          "total_floor_space"
        );    

        if (index > -1) 
        {
          this.buildArea = this.$store.state.prototypedata.stageFourYearFour[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageFourYearFour[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageFourYearFour[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageFourYearFour[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageFourYearFour[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageFourYearFour[index].fm_cost;      
        }
      }

      if (currentYear == this.yeardata[4])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearFive,
          "total_floor_space"
        );    

        if (index > -1) 
        {
          this.buildArea = this.$store.state.prototypedata.stageFourYearFive[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageFourYearFive[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageFourYearFive[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageFourYearFive[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageFourYearFive[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageFourYearFive[index].fm_cost;      
           }
      }


    },

    setMetricYear()
    {
        this.metricUpdate = true;
        this.metricActivity = new Array ();
        this.metricMetrics = new Array ();
        this.metricBeneficiary = new Array ();
        this.metricHsiBenchmark = new Array ();
        this.metricInternationalBenchmark = new Array ();

        //console.log ("setMetricYear: ", this.metricYear.year);       

        var index = -1;

        if (this.metricYear.year == this.yeardata[0])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearOne,
          "metrics"
        );        

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageFourYearOne[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageFourYearOne[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageFourYearOne[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageFourYearOne[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageFourYearOne[index].international_benchmark;         
        }
      }

      if (this.metricYear.year == this.yeardata[1])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearTwo,
          "metrics"
        );    

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageFourYearTwo[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageFourYearTwo[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageFourYearTwo[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageFourYearTwo[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageFourYearTwo[index].international_benchmark;         
        }
      }

      if (this.metricYear.year == this.yeardata[2])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearThree,
          "metrics"
        );    

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageFourYearThree[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageFourYearThree[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageFourYearThree[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageFourYearThree[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageFourYearThree[index].international_benchmark;         
        }
      }

      if (this.metricYear.year == this.yeardata[3])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearFour,
          "metrics"
        );    

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageFourYearFour[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageFourYearFour[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageFourYearFour[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageFourYearFour[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageFourYearFour[index].international_benchmark;         
        }
      }

      if (this.metricYear.year == this.yeardata[4])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageFourYearFive,
          "metrics"
        );    

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageFourYearFive[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageFourYearFive[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageFourYearFive[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageFourYearFive[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageFourYearFive[index].international_benchmark;         
        }
      }


    },
    setYear()
    {
        

        if (typeof this.selectedYear.year != "undefined") 
        {
          this.apiPayload.year = this.selectedYear.year;
        }
        else if (typeof this.selectedYear != "undefined") 
        {
          this.apiPayload.year = this.selectedYear;
        }

        //console.log ("setYear: this.apiPayload.year: ", this.apiPayload.year);

        if (typeof this.selectedSpeciality.speciality != "undefined") 
        {
          this.apiPayload.speciality = this.selectedSpeciality.speciality;
        }
        else if (typeof this.selectedSpeciality != "undefined") 
        {
          this.apiPayload.speciality = this.selectedSpeciality;
        }

        //console.log ("setYear: this.apiPayload.speciality: ", this.apiPayload.speciality);




       this.apiPayload.apiString = "";
       this.apiPayload.reference = "4";

       this.fetchSpecialityData();

    },
    getSpecialityPulldownOptions()
    {
       //console.log ("setYear: ", this.selectedYear.year);       

      

        //console.log ("setYear: this.apiPayload.hospital: ", this.apiPayload.hospital);

        if (typeof this.selectedYear.year != "undefined") 
        {
          this.apiPayload.year = this.selectedYear.year;
        }
        else if (typeof this.selectedYear != "undefined") 
        {
          this.apiPayload.year = this.selectedYear;
        }

        //console.log ("setYear: this.apiPayload.year: ", this.apiPayload.year);

        if (typeof this.selectedSpeciality.speciality != "undefined") 
        {
          this.apiPayload.speciality = this.selectedSpeciality.speciality;
        }
        else if (typeof this.selectedSpeciality != "undefined") 
        {
          this.apiPayload.speciality = this.selectedSpeciality;
        }

        //console.log ("setYear: this.apiPayload.speciality: ", this.apiPayload.speciality);




       this.apiPayload.apiString = "";
       this.apiPayload.reference = "4";

       this.fetchSpecialityData();

    },
    setStage ()
    {
        //console.log ("setStage: ", this.selectedStage);

        if (typeof this.selectedStage != "undefined")
        {
            //console.log ("this.selectedStage.label:  ", this.selectedStage.label);
            //console.log ("this.selectedStage.string: ", this.selectedStage.string);

            this.currentOverallStage = this.selectedStage.string;
        }

        if (this.currentOverallStage == "Stage 4")
        {
            this.currentOverallTitle = "5 Year Optimization";
        }

        if (this.currentOverallStage == "Stage 5")
        {
            this.currentOverallTitle = "Population Health Benchmark";
        }

        if (this.currentOverallStage == "Stage 6")
        {
            this.currentOverallTitle = " Live AI Tracker Optimization";
        }




        //console.log ("currentOverallStage: ", this.currentOverallStage);
    },
    setSpeciality ()
    {        
     

        //console.log ("setSpeciality: this.apiPayload.hospital: ", this.apiPayload.hospital);

        if (typeof this.selectedYear.year != "undefined") 
        {
          this.apiPayload.year = this.selectedYear.year;
        }
        else if (typeof this.selectedYear != "undefined") 
        {
          this.apiPayload.year = this.selectedYear;
        }

        //console.log ("setSpeciality: this.apiPayload.year: ", this.apiPayload.year);

        if (typeof this.selectedSpeciality.speciality != "undefined") 
        {
          this.apiPayload.speciality = this.selectedSpeciality.speciality;
        }
        else if (typeof this.selectedSpeciality != "undefined") 
        {
          this.apiPayload.speciality = this.selectedSpeciality;
        }

        //console.log ("setSpeciality: this.apiPayload.speciality: ", this.apiPayload.speciality);

        this.apiPayload.apiString = "";
        this.apiPayload.reference = "5";

        this.fetchSpecialityData ();

    },
    setHospital ()
    {
        //this.specialityHospital (this.selectedNewHospital.speciality);

        this.apiPayload.year = this.selectedYear.year;
        this.apiPayload.speciality = this.selectedSpeciality.speciality;
        
        this.apiPayload.apiString = "";
        this.apiPayload.reference = "6";

        this.fetchSpecialityData ();

    },           
    fetchSpecialityData() {
      //console.log ("api ==================================");
      //console.log("api.speciality = ", this.apiPayload.speciality);
      //console.log("api.year = ", this.apiPayload.year);
      //console.log("api.hospital = ", this.apiPayload.hospital);
      //console.log("api.apiString = ", this.apiPayload.apiString);
      
      //console.log("api.apiString =", this.apiPayload.apiString);

      this.apiPayload.yearApi = "";

      if (this.apiPayload.year == this.yeardata[0])
      {
        this.apiPayload.yearApi = "Year1";
      }

      if (this.apiPayload.year == this.yeardata[1])
      {
        this.apiPayload.yearApi = "Year2";
      }

      if (this.apiPayload.year == this.yeardata[2])
      {
        this.apiPayload.yearApi = "Year3";
      }

      if (this.apiPayload.year == this.yeardata[3])
      {
        this.apiPayload.yearApi = "Year4";
      }

      if (this.apiPayload.year == this.yeardata[4])
      {
        this.apiPayload.yearApi = "Year5";
      }


      this.$store
        .dispatch("GetSpecialityDataByYearNew", this.apiPayload)
        .then((data) => {
          console.log("data", data);

          this.logData ();
        });

    
    
    },
    retrieveTileInformation ()
    {
        var debugVal = true;
        this.tileInformation = new Array ();

        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[0], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[0], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[0], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[0], debugVal)));
      

        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[1], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[1], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[1], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[1], debugVal)));
      
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[2], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[2], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[2], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[2], debugVal)));

        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[3], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[3], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[3], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[3], debugVal)));

        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[4], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[4], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[4], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[4], debugVal)));       

        //console.log ("this.tileinformation: ", this.tileInformation);

    },  
    logData() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        console.log(
          "logData: length: ",
          this.$store.state.prototypedata.specialityData.length
        );
        for (
          var i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          console.log(
            "item[",
            i,
            "]=",
            this.$store.state.prototypedata.specialityData[i]
          );
        }
      }
    },   
    fetchDataSummaries(option) {
      //console.log ("==> fetchDataSummaries");
      //console.log ("option = ", option);
      
      this.$store.dispatch("getBDFStageFourSummaries", option).then((data) => {
        if (typeof data != "undefined") 
        {
          console.log ("data: ", data);
        }

        this.fillData4(this.activeSummary, this.activeStageYear);
      });

      this.$store.dispatch("getBDFStageFiveSummaries").then((data) => {
        console.log("data", data);
      });

      this.$store.dispatch("getBDFStageFiveInsights").then((data) => {
        console.log("data", data);
      });

      this.$store.dispatch("getBDFStageSixSummaries").then((data) => {
        console.log("data", data);
      });
      //console.log ("<== fetchDataSummaries");
    },
    getMongoDBData() {
      //console.log ("SummaryMain3.vue: getMongoDBData: this.apiPayload: ", this.apiPayload);
      this.$store.dispatch("GetData", this.apiPayload).then((data) => {
        console.log("data", data);
      });
    },
    specialityHeader(header) {
      return header;
    },
    editActiveStageSummary(activeSummary) {
      this.activeSummary = activeSummary;
    },
    editSpecialityArea(specialityTypeName) {
      if (specialityTypeName == "Patient Episodes") {
        this.specialityType = "patientEpisodes";
      }
      if (specialityTypeName == "Finance") {
        this.specialityType = "finance";
      }
      if (specialityTypeName == "Workforce") {
        this.specialityType = "workforce";
      }
      if (specialityTypeName == "Space & Property") {
        this.specialityType = "space";
      }      
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    getIndex(dataStruct, item) {
      var id = -1;
      var i = 0;

    

      if (typeof dataStruct != "undefined") {
        //console.log("datastruct", dataStruct);
        if (dataStruct.length > 0) {
          for (i = 0; i < dataStruct.length; i++) {
            if (item in dataStruct[i]) {
              id = i;
            }
          }
        }
      } else {
        //console.log("GetIndex: dataStruct is undefined");
      }

      return id;
    },
    searchIndex(dataStruct, item) {
      var id = -1;
      var i = 0;

      if (typeof dataStruct != "undefined") {
        //console.log("datastruct", dataStruct);
        if (dataStruct.length > 0) {
          for (i = 0; i < dataStruct.length; i++) {
            //console.log("dataStruc[i]", dataStruct[i]);
            if (item == dataStruct[i]) {
              //console.log("item == dataStruct!");
              id = i;
            }
          }
        }
      } else {
        //console.log("GetIndex: dataStruct is undefined");
      }

      return id;
    },
   
    fillData4(tileValue, year) {
      //console.log ("fillData: (activeSummary): tileValue", tileValue);
      //console.log ("fillData: (year): ",year);
      this.activeSummary = tileValue;
      this.activeStageYear = year;

      //console.log ("activeSummary: ", this.activeSummary);

      //this.chartOptions = {
      //  responsive : true
      //}

      this.datacollection = {
        labels: this.getBarGraphLabels(),
        datasets: [
          {
            label: this.yeardata[0],
            data: this.getBarGraphDataValues(this.yeardata[0]),
            backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
            borderColor: ["rgb(255, 99, 132)"],
            borderWidth: 1,
          },
          {
            label: this.yeardata[1],
            data: this.getBarGraphDataValues(this.yeardata[1]),
            backgroundColor: ["rgba(75, 192, 192, 0.2)"], // line 4
            borderColor: ["rgb(75, 192, 192)"],
            borderWidth: 1,
          },
          {
            label: this.yeardata[2],
            data: this.getBarGraphDataValues(this.yeardata[2]),
            backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 5
            borderColor: ["rgb(54, 162, 235)"],
            borderWidth: 1,
          },
          {
            label: this.yeardata[3],
            data: this.getBarGraphDataValues(this.yeardata[3]),
            backgroundColor: ["rgba(255, 159, 64, 0.22)"], // line 2
            borderColor: ["rgb(255, 159, 64)"],
            borderWidth: 1,
          },
          {
            label: this.yeardata[4],
            data: this.getBarGraphDataValues(this.yeardata[4]),
            backgroundColor: ["rgba(255, 205, 86, 0.2)"],
            borderColor: ["rgb(255, 205, 86)"], // line 3
            borderWidth: 1,
          },
        ],
      };

      //console.log ("this.datacollection: ", this.datacollection);
    },
    
    getBarGraphDataValues(year) {
      var data = new Array();
      var debugVal = false;

      if (this.activeSummary == 'workforceCostYear1' || 
          this.activeSummary == 'workforceCostYear2' || 
          this.activeSummary == 'workforceCostYear3' || 
          this.activeSummary == 'workforceCostYear4' || 
          this.activeSummary == 'workforceCostYear5') {
        data.push(this.calculateSummaryValue("workforce", year,debugVal));
      } else if (this.activeSummary == 'assetsYear1' || 
                 this.activeSummary == 'assetsYear2' || 
                 this.activeSummary == 'assetsYear3' || 
                 this.activeSummary == 'assetsYear4' || 
                 this.activeSummary == 'assetsYear5') {
        data.push(this.calculateSummaryValue("assets", year,debugVal));
      } else if (this.activeSummary == 'financeYear1' || 
                 this.activeSummary == 'financeYear2' || 
                 this.activeSummary == 'financeYear3' || 
                 this.activeSummary == 'financeYear4' || 
                 this.activeSummary == 'financeYear5') {
        data.push(this.calculateSummaryValue("finance", year,debugVal));
      } else if (this.activeSummary == 'patientEpisodeYear1' || 
                 this.activeSummary == 'patientEpisodeYear2' || 
                 this.activeSummary == 'patientEpisodeYear3' || 
                 this.activeSummary == 'patientEpisodeYear4' || 
                 this.activeSummary == 'patientEpisodeYear5') {
        data.push(this.calculateSummaryValue("patientEpisodes", year,debugVal));
      }

      if (data.length == 0)
      {
        data.push ("0");
      }

      //console.log ("data 24: ", data);

      return data;
    },

    getBarGraphLabels() {
      var data = new Array();

      if (this.activeSummary == 'workforceCostYear1' || 
          this.activeSummary == 'workforceCostYear2' || 
          this.activeSummary == 'workforceCostYear3' || 
          this.activeSummary == 'workforceCostYear4' || 
          this.activeSummary == 'workforceCostYear5') {
        data.push("Workforce");
      } else if (this.activeSummary == 'assetsYear1' || 
                 this.activeSummary == 'assetsYear2' || 
                 this.activeSummary == 'assetsYear3' || 
                 this.activeSummary == 'assetsYear4' || 
                 this.activeSummary == 'assetsYear5') {
        data.push("Assets");
      } else if (this.activeSummary == 'financeYear1' || 
                 this.activeSummary == 'financeYear2' || 
                 this.activeSummary == 'financeYear3' || 
                 this.activeSummary == 'financeYear4' || 
                 this.activeSummary == 'financeYear5') {
        data.push("Finance");
      } else if (this.activeSummary == 'patientEpisodeYear1' || 
                 this.activeSummary == 'patientEpisodeYear2' || 
                 this.activeSummary == 'patientEpisodeYear3' || 
                 this.activeSummary == 'patientEpisodeYear4' || 
                 this.activeSummary == 'patientEpisodeYear5') {
        data.push("Patient Episodes");
      }

      return data;
    },
    getGraphTitle() {
      var str;

      if (this.activeSummary == 'workforceCostYear1' || 
          this.activeSummary == 'workforceCostYear2' || 
          this.activeSummary == 'workforceCostYear3' || 
          this.activeSummary == 'workforceCostYear4' || 
          this.activeSummary == 'workforceCostYear5') {
        str = "Workforce Quantity";
      } else if (this.activeSummary == 'assetsYear1' || 
          this.activeSummary == 'assetsYear2' || 
          this.activeSummary == 'assetsYear3' || 
          this.activeSummary == 'assetsYear4' || 
          this.activeSummary == 'assetsYear5') {
        str = "Asset Quantity";
      } else if (this.activeSummary == 'financeYear1' || 
                 this.activeSummary == 'financeYear2' || 
                 this.activeSummary == 'financeYear3' || 
                 this.activeSummary == 'financeYear4' || 
                 this.activeSummary == 'financeYear5') {
        str = "Finance Quantity";
      } else if (this.activeSummary == 'patientEpisodeYear1' || 
                 this.activeSummary == 'patientEpisodeYear2' || 
                 this.activeSummary == 'patientEpisodeYear3' || 
                 this.activeSummary == 'patientEpisodeYear4' || 
                 this.activeSummary == 'patientEpisodeYear5') {
        str = "Patient Episodes Quantity";
      }
      return str;
    },
    calculateSummaryValue(summary, year, debug) {
      if (debug)
      {
         console.log("calculateSummaryValue :", summary + " " + year);
      }
      
      let summaryValues = new Array();
      let index = -1;
      let totalValue = "";

      if (summary == "population" && year == this.yeardata[0]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearOne,
          "population"
        );

        //console.log ("index = ", index );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearOne[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearTwo,
          "population"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearTwo[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearThree,
          "population"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearThree[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFour,
          "population"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearFour[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFive,
          "population"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearFive[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "workforce" && year == this.yeardata[0]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearOne,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearOne[index]
              .summary_workforce_quantity;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "workforce" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearTwo,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearTwo[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforced yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "workforce" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearThree,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearThree[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "workforce" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFour,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearFour[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
              console.log("workforce yr4 = ", totalValue);
            }
          }
        }
      }

      if (summary == "workforce" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFive,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearFive[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
             console.log("workforce yr5 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[0]) {
        //console.log ("this.$store.state.prototypedata.stageFourYearOne: ", this.$store.state.prototypedata.stageFourYearOne);
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearOne,
          "summary_assets"
        );

        //console.log ("index = ", index);

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearOne[index]
              .summary_assets_quantity;

          //console.log ("summaryValues: ", summaryValues);

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr1 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearTwo,
          "summary_assets"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearTwo[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearThree,
          "summary_assets"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearThree[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFour,
          "summary_assets"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearFour[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr4 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFive,
          "summary_assets"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearFive[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr5 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[0]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearOne,
          "summary_patient_quantity"
        );

        //console.log ("index = ", index);

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearOne[index]
              .summary_patient_quantity;

           //console.log ("summaryValues : ", summaryValues);


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr1 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearTwo,
          "summary_patient_quantity"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearTwo[index]
              .summary_patient_quantity;

        if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearThree,
          "summary_patient_quantity"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearThree[index]
              .summary_patient_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFour,
          "summary_patient_quantity"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearFour[index]
              .summary_patient_quantity;

       
          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr4 = ", totalValue);
            }
       
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFive,
          "summary_patient_quantity"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearFive[index]
              .summary_patient_quantity;

         
          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr5 = ", totalValue);
            }
          
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[0]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearOne,
          "finance_total"
        );

        if (index > -1) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageFourYearOne[index].finance,
            "Income Requirement"
          );

          temp_val =
            this.$store.state.prototypedata.stageFourYearOne[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance yr1 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearTwo,
          "finance_total"
        );

        if (index > -1) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageFourYearTwo[index].finance,
            "Income Requirement"
          );

          temp_val =
            this.$store.state.prototypedata.stageFourYearTwo[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearThree,
          "finance_total"
        );

        if (index > -1) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageFourYearThree[index].finance,
            "Income Requirement"
          );

          temp_val =
            this.$store.state.prototypedata.stageFourYearThree[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFour,
          "finance_total"
        );

        if (index > -1) {
          var temp_val = 0;
          var finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageFourYearFour[index].finance,
            "Income Requirement"
          );


          temp_val =
            this.$store.state.prototypedata.stageFourYearFour[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

         if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr4 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFive,
          "finance_total"
        );

        if (index > -1) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageFourYearFive[index].finance,
            "Income Requirement"
          );

         temp_val =
            this.$store.state.prototypedata.stageFourYearFive[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);
   
          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr5 = ", totalValue);
            }
            }
        }
      }

      //console.log ("return totalValue ", totalValue);

      return totalValue;
    },
    getDataValues(summary, year, debug) {
      if (debug)
      {
          console.log("calculateSummaryValue :", summary + " " + year);
      }
      
      let summaryValues = new Array();
      let index = -1;
      let totalValue = "";

      if (summary == "population" && year == this.yeardata[0]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearOne,
          "population"
        );

        //console.log ("index = ", index );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearOne[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearTwo,
          "population"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearTwo[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearThree,
          "population"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearThree[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFour,
          "population"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearFour[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFive,
          "population"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearFive[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "workforce" && year == this.yeardata[0]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearOne,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearOne[index]
              .summary_workforce_quantity;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "workforce" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearTwo,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearTwo[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforced yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "workforce" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearThree,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearThree[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "workforce" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFour,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearFour[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
              console.log("workforce yr4 = ", totalValue);
            }
          }
        }
      }

      if (summary == "workforce" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFive,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearFive[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr5 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[0]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearOne,
          "summary_assets"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearOne[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr1 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearTwo,
          "summary_assets"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearTwo[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearThree,
          "summary_assets"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearThree[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFour,
          "summary_assets"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearFour[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr4 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFive,
          "summary_assets"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearFive[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr5 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[0]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearOne,
          "summary_patient_quantity"
        );

        //console.log ("index = ", index);

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearOne[index]
              .summary_patient_quantity;

           //console.log ("summaryValues : ", summaryValues);


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr1 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearTwo,
          "summary_patient_quantity"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearTwo[index]
              .summary_patient_quantity;

        if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearThree,
          "summary_patient_quantity"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearThree[index]
              .summary_patient_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFour,
          "summary_patient_quantity"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearFour[index]
              .summary_patient_quantity;

       
          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr4 = ", totalValue);
            }
       
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFive,
          "summary_patient_quantity"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageFourYearFive[index]
              .summary_patient_quantity;

         
          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr5 = ", totalValue);
            }
          
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[0]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearOne,
          "finance_total"
        );

        if (index > -1) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageFourYearOne[index].finance,
            "Income Requirement"
          );

          temp_val =
            this.$store.state.prototypedata.stageFourYearOne[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance yr1 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearTwo,
          "finance_total"
        );

        if (index > -1) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageFourYearTwo[index].finance,
            "Income Requirement"
          );

          temp_val =
            this.$store.state.prototypedata.stageFourYearTwo[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearThree,
          "finance_total"
        );

        if (index > -1) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageFourYearThree[index].finance,
            "Income Requirement"
          );

          temp_val =
            this.$store.state.prototypedata.stageFourYearThree[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFour,
          "finance_total"
        );

        if (index > -1) {
          var temp_val = 0;
          var finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageFourYearFour[index].finance,
            "Income Requirement"
          );


          temp_val =
            this.$store.state.prototypedata.stageFourYearFour[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

         if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr4 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageFourYearFive,
          "finance_total"
        );

        if (index > -1) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageFourYearFive[index].finance,
            "Income Requirement"
          );

         temp_val =
            this.$store.state.prototypedata.stageFourYearFive[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);
   
          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr5 = ", totalValue);
            }
            }
        }
      }

      return totalValue;
    },
    
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    calculateArray(data) {
      let arrayValuesFloatArray = new Array();
      let str = "";
      let parse = false;

      if (typeof data != "undefined") {
        str = "";
        parse = false;

        for (var i = 0; i < data.length; i++) 
        {
          str = String(data[i]);
          str = str.replaceAll(",", "");
          str = str.replaceAll("-", "");

          if (str.includes(".")) {
            parse = true;
          }

          if (str.length > 0)
          {
            arrayValuesFloatArray.push(parseFloat(str));
          }
        }

        //console.log ("arrayValuesFloatArray: ", arrayValuesFloatArray );
      }

      if (arrayValuesFloatArray.length > 0)
      {

          const total = arrayValuesFloatArray.reduce(function (a, b) 
          {
              return a + b;
          });
      if (parse == true) {
        return total.toFixed(2);
      } else {
        return total;
      }
    }
    else
    {
      return 0;
    }
    },
    getDataSetIndex(item) {
      var i = 0;
      var id = -1;

      if (this.$store.state.prototypedata.stageFour.length > 0) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.stageFour.length;
          i++
        ) {
          if (item in this.$store.state.prototypedata.stageFour[i]) {
            id = i;
          }
        }
      }

      //console.log("getDataSetIndex", item, id);

      return id;
    },
    getPopulationTotal (year)    
    {
      var item = "";
      item = this.numberWithCommas(this.calculateSummaryValue("population", year, false));

      //console.log ("population item:", item);

      return item;
    },
    // Summary Overview
    // Controls the information on the boxes within the Stage
    specialityOverviewFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_overview")
        ].summary_overview;
      }
      return null;
    },
    // 
    setSpecialityName(specialityName) {
      this.activeSpeciality = specialityName;
    },
    //
    editImprovementType(specialityTypeName) {
      //console.log ("editImprovement: ", specialityTypeName);
      if (specialityTypeName == "Diagnostics") {
        this.improvementType = "diagnostics";
      }
      if (specialityTypeName == "Outpatient Clinics") {
        this.improvementType = "OPD";
      }
      if (specialityTypeName == "OR") {
        this.improvementType = "OR";
      }
      if (specialityTypeName == "Emergency Room (ER)") {
        this.improvementType = "ER";
      }

      if (specialityTypeName == "Bed Flow") {
        this.improvementType = "bedflow";
      }

      //console.log(
//        "editImprovementType (",
  //      specialityTypeName,
    //    ")=",
      //  this.improvementType
      //);
    },
    editStageSummary(stageSummary) {
      this.stageSummary = stageSummary;

      if (this.stageSummary == "stage") {
        //console.log ("SummaryMain3.vue: Stage");

        var fullAPIString = "get" + this.selectedHospitalPrefix;

        //console.log ("4. ", fullAPIString);

        this.fetchDataSummaries(fullAPIString);
        this.retrieveTileInformation ();
        //console.log ("tileInformation: ", this.tileInformation);
        this.apiPayload.apiString = "grandsummarytwo-collection";
        this.apiPayload.reference = "7";

        //console.log("stage: getData");

        this.getMongoDBData();
        this.fillData4("workforceCostYear1", this.activeStageYear);
      }

      if (this.stageSummary == "buildingsFM") {
        //this.apiPayload.apiString = "fm_build3_collection";
        //this.apiPayload.reference = "8";
        //this.getMongoDBData();
        this.setBuildingsYearMethod();
      }

      if (this.stageSummary == "specialities") {
        //console.log(
        //  " this.selectedSpecialityArea.selected",
        //  this.selectedSpecialityArea
       // );

        //this.editSpeciality
        this.editSpecialityArea(this.selectedSpecialityArea);
        //this.specialityApi = this.getSpecialityAPI();        
        //this.apiPayload.apiString = this.specialityApi;


        this.getSpecialityPulldownOptions ();



        this.apiPayload.reference = "9";
        //this.getMongoDBData();
      }

      if (this.stageSummary == "metrics")
      {
        this.setMetricYearMethod ();
      }

      if (this.stageSummary == "opImprov") {
       // console.log(
       //   " this.improvementSpecialityAra.selected",
       //   this.improvementSpecialityArea
      //  );

        this.editImprovementType(this.improvementSpecialityArea);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      }
    },
  },
};
</script>
<style>
.chartcontainer3 {
  border: 1px solid;
  border-color: #e5e9f2;
  height: 450px;
  display: inline-block;
  width: 600px; /* was 26 rem */
  margin-right: 20px;
}
.chartcontainer {
  border: 1px solid;
  border-color: #e5e9f2;
  height: 450px;
  display: inline-block;
  width: 600px; /* was 26 rem */
  margin-right: 20px;
}
.title_text3 {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.title_text {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.chart_view3 {
  width: 600px;
  height: 400px;
  /*border: solid #5b6dcd 10px;*/
  box-sizing: content-box;
}
.chartcontainer5 {
  border: 1px solid;
  border-color: #e5e9f2;
  height: 300px;
  display: inline-block;
  width: 350px; /* was 26 rem */
  margin-right: 20px;
  padding-bottom: 5px;
}
.title_text5 {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
</style>
