<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/logo.svg" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">Locations</h1>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Country</span>
          <span class="box-label">Kingdom of Bahrain</span>
        </div>
      </div>
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Total Population</span>
          <span class="box-label">1,472,204</span>
        </div>
      </div>
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Export Data</span>
          <button class="btn btn-primary btn-outline btn-small mr-4">
            CSV
          </button>
          <button class="btn btn-primary btn-outline btn-small mr-4">
            PDF
          </button>
          <button class="btn btn-primary btn-outline btn-small">XML</button>
        </div>
      </div>
    </div>
 
    <div class="route-content">
      <h1 class="text-3xl my-6 active">Kingdom of Bahrain</h1>
      <table class="table-primary">
        <thead>
          <tr>
            <th class="border px-4 py-2">Location</th>
            <th class="border px-4 py-2">Location Population</th>
            <th class="border px-4 py-2">Additional Information</th>
            <th class="border px-4 py-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border">
              <div>Manama</div>
            </td>
            <td class="border">
              <div>436,000</div>
            </td>
            <td class="border">
              <div> </div>
            </td>
            <td class="border">
              <div>Actions</div>
            </td>
          </tr>
          <tr>
            <td class="border">
              <div>Riffa</div>
            </td>
            <td class="border">
              <div>195,606</div>
            </td>
            <td class="border">
              <div> </div>
            </td>
            <td class="border">
              <div>Actions</div>
            </td>
          </tr>
          <tr>
            <td class="border">
              <div>Muharraq</div>
            </td>
            <td class="border">
              <div>176,583</div>
            </td>
            <td class="border">
              <div></div>
            </td>
            <td class="border">
              <div>Actions</div>
            </td>
          </tr>
          <tr>
            <td class="border">
              <div>Hamad Town</div>
            </td>
            <td class="border">
              <div>57,000</div>
            </td>
            <td class="border">
              <div></div>
            </td>
            <td class="border">
              <div>Actions</div>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <button class="btn btn-primary btn-large">Add new cluster</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeSummary: "annualTotals",
    };
  },
  mounted() {
    this.fetchAnnualTotals();
  },
  computed: {
    annualTotalsData() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData;
      }
      return null;
    },
    annualTotalsServedPopulationFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .served_population;
      }
      return null;
    },
    annualTotalsCostbaseFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0].costbase;
      }
      return null;
    },
    annualTotalsIncomeRequirementFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .income_requirement;
      }
      return null;
    },
    annualTotalsMedicalStaffFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .medical_staff_fte;
      }
      return null;
    },
    annualTotalsNursingAndMidwifeFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .nursing_and_midwife_fte;
      }
      return null;
    },
    annualTotalsTotalWorkforceFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .total_workforce_fte;
      }
      return null;
    },
    annualTotalsPrimaryCarePresentationsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .primary_care_presentations;
      }
      return null;
    },
    annualTotalsErPresentationsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .er_presentations;
      }
      return null;
    },
    annualTotalsOpdPresentationsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .opd_presentations;
      }
      return null;
    },
    annualTotalsOrEpisodesFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .total_or_episodes;
      }
      return null;
    },
    annualTotalsBedNightsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .total_bed_nights;
      }
      return null;
    },
    annualTotalsRadiologyEpisodesFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .total_radiology_episodes;
      }
      return null;
    },
    annualTotalsLaboratoryEpisodesFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .total_laboratory_episodes;
      }
      return null;
    },
    annualTotalsTotalAssetsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0].total_assets;
      }
      return null;
    },
    annualTotalsLifeCycleCostsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .life_cycle_costs;
      }
      return null;
    },
    annualTotalsMaintenanceCostsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .maintenance_costs;
      }
      return null;
    },
    annualTotalsPropertyLifeCycleFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .property_life_cycle;
      }
      return null;
    },
    annualTotalsPropertyFmCostsFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .property_fm_costs;
      }
      return null;
    },
    annualTotalsCapexRequirementFilter() {
      if (this.$store.state.prototypedata.annualTotalsData.length > 0) {
        return this.$store.state.prototypedata.annualTotalsData[0]
          .capex_requirement;
      }
      return null;
    },
  },
  methods: {
    fetchAnnualTotals() {
      this.$store.dispatch("attemptFetchAnnualTotals").then((data) => {
        console.log(data);
      });
    },
  },
};
</script>
