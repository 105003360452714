import Shell from '../../../layouts/Shell.vue'
import UserMain from '../views/UserMain.vue'
 
const routes = [
    {
        path: '/',
        name: 'LayoutModule',
        component: Shell,
        children: [
        {
            path: '/user',
            name: 'UserMain',
            component: UserMain,
            meta: {
                requiresAuth: true
            }
        }]
    }
]

export default routes
