import Shell from "../../../layouts/Shell.vue";
import SpecialitiesMain3 from "../views/SpecialitiesMain3.vue";

const routes = [
  {
    path: "/",
    name: "LayoutModule",
    component: Shell,
    children: [
      {
        path: "/specialities3",
        name: "SpecialitiesMain3",
        component: SpecialitiesMain3,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
