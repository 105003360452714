import Shell from '../../../layouts/Shell.vue'
import cipp from '../views/cipp.vue'
 
const routes = [
    {
        path: '/',
        name: 'LayoutModule',
        component: Shell,
        children: [
        {
            path: '/cipp',
            name: 'cipp',
            component: cipp,
            meta: {
                requiresAuth: true
            }
        }]
    }
]

export default routes
