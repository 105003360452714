<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/logo.svg" alt="">
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">Management Toolkit</h1>
      </div>
    </header>

    <nav class="route-nav">
      <ul>
        <li class="active">
          <a href="#">Resources</a>
        </li>
        <li>
          <a href="#">Downloadables</a>
        </li>
        <li>
          <a href="#">Videos</a>
        </li>
      </ul>
    </nav>

    <div class="route-content">
      <img src="@/assets/img/management-toolkit.svg" alt="" style="width:100%; margin-bottom: 2rem;" />
    </div>
  </div>
</template>
<script>
export default {
};
</script>
