import Shell from '../../../layouts/AuthShell.vue'
import Login from '../views/Login.vue'
import ForgotPassword from '../views/ForgotPassword.vue'

const routes = [
{
    path: '/',
    name: 'LoginModule',
    component: Shell,
    children: [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
            requiresAuth: false
        }
    }
    ]
}]

export default routes
