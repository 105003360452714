import Shell from '../../../layouts/Shell.vue'
import BenchmarkingMain from '../views/BenchmarkingMain.vue'
 
const routes = [
    {
        path: '/',
        name: 'LayoutModule',
        component: Shell,
        children: [
        {
            path: '/benchmarking',
            name: 'BenchmarkingMain',
            component: BenchmarkingMain,
            meta: {
                requiresAuth: true
            }
        }]
    }
]

export default routes