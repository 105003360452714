import Shell from '../../../layouts/Shell.vue'
import JobPlans from '../views/JobPlans.vue'
 
const routes = [
    {
        path: '/',
        name: 'LayoutModule',
        component: Shell,
        children: [
        {
            path: '/jobplans',
            name: 'JobPlans',
            component: JobPlans,
            meta: {
                requiresAuth: true
            }
        }]
    }
]

export default routes
