import Shell from "../../../layouts/Shell.vue";
import SummaryMain11 from "../views/SummaryMain11.vue";

const routes = [
  {
    path: "/",
    name: "LayoutModule",
    component: Shell,
    children: [
      {
        path: "/summary11",
        name: "SummaryMain11",
        component: SummaryMain11,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
