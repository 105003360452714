<template>
  <div>
      <header class="route-header">
        <div class="route-header--left">
          <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
        </div>
        <div class="route-header--right">
          <h1 class="text-3xl">Projected Optimization Tracker - Public Beneficiary Population</h1>
        </div>
  
        <div class="validation-cards--item cert no-hover">
          <span class="card-header">Certification Status</span>
          <div class="card-icon" margin-top="0px">
            <img
              src="@/assets/img/circular-label-with-certified-stamp.png"
              alt=""
            />
            <span class="card-total">100% Validation</span>
          </div>
        </div>
      </header>               
  
      <div class="route-content">
        <div class="stat-cards" v-if="workForceDataTwo !== null">
          <div
            class="stat-cards--item single"
            :class="activeSummary === 'patientEpisode60month' ? 'active' : ''"
            @click="fillData('patientEpisode60month')"
          >
            <span class="card-header">Patient Episode Productivity</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-patient.svg"
                alt="Patient Episodes"
              />
              <span class="card-total">{{ getTileData(0) }}</span>
            </div>
            <span class="card-info"></span>
          </div>
  
          <div
            class="stat-cards--item single"
            :class="activeSummary === 'assets60month' ? 'active' : ''"
            @click="fillData('assets60month')"
          >
            <span class="card-header">Assets</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <span class="card-total">{{ getTileData(1) }}</span>
            </div>
            <span class="card-info"></span>
          </div>
  
          <div
            class="stat-cards--item single"
            :class="activeSummary === 'workforce60month' ? 'active' : ''"
            @click="fillData('workforce60month')"
          >
            <span class="card-header">Workforce</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
              <span class="card-total">{{ getTileData(2) }}</span>
            </div>
            <span class="card-info"
              ></span
            >
          </div>
  
          <div
            class="stat-cards--item single"
            :class="activeSummary === 'finance60month' ? 'active' : ''"
            @click="fillData('finance60month')"
          >
            <span class="card-header">Finance</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <span class="card-total">{{ getTileData(3) }}</span>
            </div>
            <span class="card-info"></span>
          </div>      
     </div>

     <div v-if="activeSummary == 'finance60month'">

<div class="route-content-speciality">             
<div class="route-content">
<!-- 1 open -->
<div v-if="stageSix != null">

  <table class="table-primary">
  <thead>
    <tr>
      <th class="border px-4 py-2">Current</th>        
      <th class="border px-4 py-2">2024</th>
      <th class="border px-4 py-2">2025</th>
      <th class="border px-4 py-2">2026</th>
      <th class="border px-4 py-2">2027</th>
      <th class="border px-4 py-2">2028</th>
      <th class="border px-4 py-2">2029</th>

    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border">
        <div v-for="(jobPlan, index) in financeProductivityFilter" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlan, index) in getFinance(1, 'cu')" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanPatients, index) in getFinance(2, 'cu')" :key="index">
          <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanPatients }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanperSessions, index) in getFinance(3,'cu')" :key="index">
          <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanperSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanannSessions, index) in getFinance(4, 'cu')" :key="index">
          <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanannSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getFinance(5,'cu')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>   
      
      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getFinance(6,'cu')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>    
    </tr>
  </tbody>
</table> 

<div class="hello1"><BR/></div>

<table class="table-primary">
  <thead>
    <tr>
      <th class="border px-4 py-2">Optimized</th>        
      <th class="border px-4 py-2">2024</th>
      <th class="border px-4 py-2">2025</th>
      <th class="border px-4 py-2">2026</th>
      <th class="border px-4 py-2">2027</th>
      <th class="border px-4 py-2">2028</th>
      <th class="border px-4 py-2">2029</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border">
        <div v-for="(jobPlan, index) in financeProductivityFilter" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlan, index) in getFinance(1,'op')" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanPatients, index) in getFinance(2,'op')" :key="index">
          <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanPatients }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanperSessions, index) in getFinance(3,'op')" :key="index">
          <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanperSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanannSessions, index) in getFinance(4,'op')" :key="index">
          <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanannSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getFinance(5,'op')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>    
      
      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getFinance(6,'op')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>     
    </tr>
  </tbody>
</table>   

<div>
  <h1><br /></h1>
</div>             
</div>
<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">COSTBASE</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="financeCostbase"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">SUPPLY CHAIN</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="financeSupplyChain"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">BENEFICIARY</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="financeBeneficiary"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">COST PER EPISODE</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="financeCostPerEpisode"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>     

<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">COST PER ASSET</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="financeCostPerAsset"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>     

<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">COST PER WORKFORCE</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="financeCostPerWorkforce"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div> 

<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">COST PER POPULATION</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="financeCostPerPopulation"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>     

</div>
</div>  
      </div>
      <div v-if="activeSummary == 'workforce60month'">
        <div class="route-content-speciality">             
<div class="route-content">
<!-- 1 open -->
<div v-if="stageSix != null">

  <table class="table-primary">
  <thead>
    <tr>
      <th class="border px-4 py-2">Current</th>        
      <th class="border px-4 py-2">2024</th>
      <th class="border px-4 py-2">2025</th>
      <th class="border px-4 py-2">2026</th>
      <th class="border px-4 py-2">2027</th>
      <th class="border px-4 py-2">2028</th>
      <th class="border px-4 py-2">2029</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border">
        <div v-for="(jobPlan, index) in workforceProductivityFilter" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlan, index) in getWorkforce(1, 'cu')" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanPatients, index) in getWorkforce(2, 'cu')" :key="index">
          <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanPatients }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanperSessions, index) in getWorkforce(3,'cu')" :key="index">
          <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanperSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanannSessions, index) in getWorkforce(4, 'cu')" :key="index">
          <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanannSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getWorkforce(5,'cu')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>   
      
      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getWorkforce(6,'cu')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>    
    </tr>
  </tbody>
</table> 

<div class="hello1"><BR/></div>

<table class="table-primary">
  <thead>
    <tr>
      <th class="border px-4 py-2">Optimized</th>        
      <th class="border px-4 py-2">2024</th>
      <th class="border px-4 py-2">2025</th>
      <th class="border px-4 py-2">2026</th>
      <th class="border px-4 py-2">2027</th>
      <th class="border px-4 py-2">2028</th>
      <th class="border px-4 py-2">2029</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border">
        <div v-for="(jobPlan, index) in workforceProductivityFilter" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlan, index) in getWorkforce(1,'op')" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanPatients, index) in getWorkforce(2,'op')" :key="index">
          <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanPatients }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanperSessions, index) in getWorkforce(3,'op')" :key="index">
          <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanperSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanannSessions, index) in getWorkforce(4,'op')" :key="index">
          <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanannSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getWorkforce(5,'op')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>    
      
      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getWorkforce(6,'op')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>      
    </tr>
  </tbody>
</table> 

<div>
  <h1><br /></h1>
</div>           
</div>
<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">TOTAL WORKFORCE</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="workforceTotalWorkforce"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">MEDICAL</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="workforceMedical"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">NURSING</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="workforceNursing"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">ADMINISTRATION</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="workforceAdministration"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">AHPS</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="workforceAHPs"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">TECHNICIANS</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="workforceTechnicians"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>    


</div>
</div>  
      </div>
      <div v-if="activeSummary == 'assets60month'">

<div class="route-content-speciality">             
<div class="route-content">
<!-- 1 open -->
<div v-if="stageSix != null">
  <table class="table-primary">
  <thead>
    <tr>
      <th class="border px-4 py-2">Current</th>        
      <th class="border px-4 py-2">2024</th>
      <th class="border px-4 py-2">2025</th>
      <th class="border px-4 py-2">2026</th>
      <th class="border px-4 py-2">2027</th>
      <th class="border px-4 py-2">2028</th>
      <th class="border px-4 py-2">2029</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border">
        <div v-for="(jobPlan, index) in assetProductivityFilter" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlan, index) in getAssets(1, 'cu')" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanPatients, index) in getAssets(2, 'cu')" :key="index">
          <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanPatients }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanperSessions, index) in getAssets(3,'cu')" :key="index">
          <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanperSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanannSessions, index) in getAssets(4, 'cu')" :key="index">
          <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanannSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getAssets(5,'cu')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>   
      
      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getAssets(6,'cu')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>     
    </tr>
  </tbody>
</table> 

<div class="hello1"><BR/></div>

<table class="table-primary">
  <thead>
    <tr>
      <th class="border px-4 py-2">Optimized</th>        
      <th class="border px-4 py-2">2024</th>
      <th class="border px-4 py-2">2025</th>
      <th class="border px-4 py-2">2026</th>
      <th class="border px-4 py-2">2027</th>
      <th class="border px-4 py-2">2028</th>
      <th class="border px-4 py-2">2029</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border">
        <div v-for="(jobPlan, index) in assetProductivityFilter" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlan, index) in getAssets(1,'op')" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanPatients, index) in getAssets(2,'op')" :key="index">
          <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanPatients }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanperSessions, index) in getAssets(3,'op')" :key="index">
          <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanperSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanannSessions, index) in getAssets(4,'op')" :key="index">
          <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanannSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getAssets(5,'op')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>       
      
      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getAssets(6,'op')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>       
    </tr>
  </tbody>
</table> 

<div>
  <h1><br /></h1>
</div>

             
</div>

<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">TOTAL ASSETS</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="assetTotalAssets"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">TOTAL BEDS</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="assetTotalBeds"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">TOTAL INTERVENTION & DIALYSIS</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="assetTotalIntervention"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">TOTAL PRIMARY CARE</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="assetPrimaryCare"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

</div>
</div>  
      </div>

      <div v-if="activeSummary == 'patientEpisode60month'">

<div class="route-content-speciality">             
<div class="route-content">
<!-- 1 open -->
<div v-if="stageSix != null">
<table class="table-primary">
  <thead>
    <tr>
      <th class="border px-4 py-2">Current</th>        
      <th class="border px-4 py-2">2024</th>
      <th class="border px-4 py-2">2025</th>
      <th class="border px-4 py-2">2026</th>
      <th class="border px-4 py-2">2027</th>
      <th class="border px-4 py-2">2028</th>
      <th class="border px-4 py-2">2029</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border">
        <div v-for="(jobPlan, index) in episodeProductivityFilter" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlan, index) in getPatientEpisodes(1, 'cu')" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanPatients, index) in getPatientEpisodes(2, 'cu')" :key="index">
          <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanPatients }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanperSessions, index) in getPatientEpisodes(3,'cu')" :key="index">
          <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanperSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanannSessions, index) in getPatientEpisodes(4, 'cu')" :key="index">
          <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanannSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getPatientEpisodes(5,'cu')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td> 
      
      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getPatientEpisodes(6,'cu')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>       
    </tr>
  </tbody>
</table> 

<div class="hello1"><BR/></div>

<table class="table-primary">
  <thead>
    <tr>
      <th class="border px-4 py-2">Optimized</th>        
      <th class="border px-4 py-2">2024</th>
      <th class="border px-4 py-2">2025</th>
      <th class="border px-4 py-2">2026</th>
      <th class="border px-4 py-2">2027</th>
      <th class="border px-4 py-2">2028</th>
      <th class="border px-4 py-2">2029</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border">
        <div v-for="(jobPlan, index) in episodeProductivityFilter" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlan, index) in getPatientEpisodes(1,'op')" :key="index">
          <span v-if="jobPlan == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlan }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanPatients, index) in getPatientEpisodes(2,'op')" :key="index">
          <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanPatients }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanperSessions, index) in getPatientEpisodes(3,'op')" :key="index">
          <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanperSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanannSessions, index) in getPatientEpisodes(4,'op')" :key="index">
          <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanannSessions }}</span> 
        </div>
      </td>

      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getPatientEpisodes(5,'op')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>     
      
      <td class="border">
        <div v-for="(jobPlanweekSessions, index) in getPatientEpisodes(6,'op')" :key="index">
          <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
          <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
          <span v-else> {{  jobPlanweekSessions }}</span> 
        </div>
      </td>     
    </tr>
  </tbody>
</table> 

<div>
  <h1><br /></h1>
</div>             
</div>
<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">EPISODES</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="patientEpisodes"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">OPD</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="patientOPD"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">INTERVENTION</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="patientIntervention"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

<div class="chartcontainer" style="width: 410px; height:350px">
  <div class="title_text">DIAGNOSTICS</div>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="patientDiagnostics"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="500"
    :height="300"
  >
</div>      

</div>
</div>       
</div>           
    </div>
  </div>
  </template>
  <script>
  import { Line as LineChartGenerator } from "vue-chartjs/legacy";
  //import { Bar  } from "vue-chartjs/legacy";
  //import { Bubble } from "vue-chartjs/legacy";
  //import { Doughnut } from "vue-chartjs/legacy";
  //import { Pie } from 'vue-chartjs/legacy';
  //import { PolarArea } from 'vue-chartjs/legacy';
  //import { Radar } from 'vue-chartjs/legacy'
  //import { Scatter } from 'vue-chartjs/legacy'
  
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    ArcElement,
    RadialLinearScale
  } from "chart.js";
  
  
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    ArcElement,
    RadialLinearScale
  );
  
  
  
  export default {
    
    components: {
      LineChartGenerator,
      //Bar,
      //Bubble,
      //Doughnut,
      //Pie,
      //PolarArea,
      //Radar,
      //Scatter
  },
    props: {
      chartLineId:   {   type: String, default: "line-chart",},
      chartBarId:    {   type: String, default: "bar",},
      chartBubbleId: {   type: String, default: "bubble-chart",},
      chartDoughnutId: { type: String, default: "doughnut-chart",},
      chartRadarId: { type: String, default : "radar-chart",},
      chartPolarId:  { type: String, default: "polar-chart",},
      chartScatterId: { type: String, default: "scatter-chart",},
      chartPieId:    { type: String, default: "pie-chart",},
      datasetIdKey: {   type: String, default: "label",},
      width:        {   type: Number, default: 300,},   // 200
      height:       {   type: Number, default: 300, },  // 200
      cssClasses:   {   type: String, default: "", },
      styles:       {   type: Object, default: () => {},},
      plugins:      {   type: Array,  default: () => [], },
    },
    data() {
      return {
        activeSummary: "patientEpisodes",
        //activeSummary: "finance60month",
        selectedSpeciality: "AHPs",
        dataLinecollection: "", 
        dataBarCollection: "",
        dataBubbleCollection: "",
        dataDoughnutCollection: "",
        chartDataPie: "",
        chartData: "",
        chartDataRadar: "",
        chartDataScatter: "",
        /*chartsS6:  { episodeProductivity: 0, total_episodes_op : 1, total_episodes_cu: 2, opd_op: 3, opd_cu: 4, intervention_cu: 5,
                           intervention_op: 6, diagnostics_cu: 7, diagnostics_op: 8, assets: 9, total_assets_op: 10, total_assets_cu: 11,
                           total_beds_op: 12, total_beds_cu: 13, total_intervention_op: 14, total_intervention_cu: 15, total_primary_op: 16,
                           total_primary_cu: 17, workforce: 18, total_workforce_op: 19, total_workforce_cu: 20, medical_op: 21, medical_cu: 22,
                           nursing_op: 23, nursing_cu: 24, administration_op: 25, administration_cu: 26, ahps_op: 27, ahps_cu: 28, technicians_op: 29,
                           technicians_cu: 30, finance: 31, costbase_budget_cu: 32, costbase_budget_op: 33, supply_chain_cu: 34, supply_chain_op: 35,
                           beneficiary_cap_rate_cu: 36, beneficiary_cap_rate_op: 37, cost_per_episode_cu: 38, cost_per_episode_op: 39, cost_per_asset_cu: 40,
                           cost_per_asset_op: 41, cost_per_workforce_cu: 42, cost_per_workforce_op: 43, cost_per_population_cu: 44, cost_per_population_op: 45,
                           trackerSixty: 46, total_episodes_ot: 47, opd_ot: 48, intervention_ot: 49, diagnostics_ot: 50, total_assets_ot: 51, total_beds_ot: 52,
                           total_intervention_ot: 53, total_primary_ot: 54, total_workforce_ot: 55, medical_ot: 56, nursing_ot: 57, administration_ot: 58,
                           ahps_ot: 59, technicians_ot: 60, costbase_budget_ot: 61, supply_chain_ot: 62, beneficiary_cap_rate_ot: 63, cost_per_episode_ot: 64,
                           cost_per_asset_ot: 65, cost_per_workforce_ot: 66, cost_per_population_ot: 67, total_episodes_ct: 68, opd_ct: 69, intervention_ct: 70,
                           diagnostics_ct: 71, total_assets_ct: 72, total_beds_ct: 73, total_intervention_ct: 74, total_primary_ct: 75, total_workforce_ct: 76,
                           medical_ct: 77, nursing_ct: 78, administration_ct: 79, ahps_ct: 80, technicians_ct: 81, costbase_budget_ct: 82, supply_chain_ct: 83,
                           beneficiary_cap_rate_ct: 84, cost_per_episode_ct: 85, cost_per_asset_ct: 86, cost_per_workforce_ct: 87, cost_per_population_ct: 88 },*/
 
        };
    },
    mounted() {  
    console.log("mounted");

    this.getMongoDBData();
    },
    computed: 
    {
      store() {
        return this.$store.state.prototypedata;
      },

     
      
    stageSix() 
    {
      if (this.$store.state.prototypedata.stageSix.length > 0) {
        return true;
      }
      return null;
    },  
   
    episodeProductivityFilter() 
    {
      var i = 0;

      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        i = this.getEpisodeItemIndex ();
        return this.$store.state.prototypedata.stageSix[i].episodeProductivity;
                                                            
      }
      return null;
    },
    

    financeProductivityFilter() 
    {
      var i = 0;

      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        i = this.getFinanceItemIndex ();
        return this.$store.state.prototypedata.stageSix[i].finance;
                                                            
      }
      return null;
    },
    assetProductivityFilter() 
    {
      var i = 0;

      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        i = this.getAssetItemIndex ();
        console.log ("assetProductivityFilter index = ", i);
        console.log ("data: ",this.$store.state.prototypedata.stageSix[i]);
        return this.$store.state.prototypedata.stageSix[i].assets;
                                                            
      }
      return null;
    },
    
    workforceProductivityFilter() 
    {
      var i = 0;

      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        i = this.getWorkforceItemIndex ();
        return this.$store.state.prototypedata.stageSix[i].workforce;
                                                            
      }
      return null;
    },
    },
    methods: {


    getPatientEpisodes (year, value)
    {
      var data = new Array();

      year = year - 1;

          
        if (this.$store.state.prototypedata.stageSix.length > 0) 
        {
          if (value == "cu")
          {  
            data.push (this.$store.state.prototypedata.stageSix[this.getEpisodeItemIndex()].total_episodes_cu[year]);          
            data.push (this.$store.state.prototypedata.stageSix[this.getEpisodeItemIndex()].opd_cu[year]);           
            data.push (this.$store.state.prototypedata.stageSix[this.getEpisodeItemIndex()].intervention_cu[year]);            
            data.push (this.$store.state.prototypedata.stageSix[this.getEpisodeItemIndex()].diagnostics_cu[year]);
          }
          else
          {
            data.push (this.$store.state.prototypedata.stageSix[this.getEpisodeItemIndex()].total_episodes_op[year]);          
            data.push (this.$store.state.prototypedata.stageSix[this.getEpisodeItemIndex()].opd_op[year]);           
            data.push (this.$store.state.prototypedata.stageSix[this.getEpisodeItemIndex()].intervention_op[year]);            
            data.push (this.$store.state.prototypedata.stageSix[this.getEpisodeItemIndex()].diagnostics_op[year]);
          }
        }

      return data;
    },
    getAssets (year, value)
    {
      var data = new Array();

      year = year - 1;

          
        if (this.$store.state.prototypedata.stageSix.length > 0) 
        {
          console.log ("getAssets", this.$store.state.prototypedata.stageSix[this.getAssetItemIndex()]);

          if (value == "cu")
          {  
            data.push (this.$store.state.prototypedata.stageSix[this.getAssetItemIndex()].total_assets_cu[year]);          
            data.push (this.$store.state.prototypedata.stageSix[this.getAssetItemIndex()].total_beds_cu[year]);           
            data.push (this.$store.state.prototypedata.stageSix[this.getAssetItemIndex()].total_intervention_cu[year]);            
            data.push (this.$store.state.prototypedata.stageSix[this.getAssetItemIndex()].total_primary_cu[year]);
          }
          else
          {
            data.push (this.$store.state.prototypedata.stageSix[this.getAssetItemIndex()].total_assets_op[year]);          
            data.push (this.$store.state.prototypedata.stageSix[this.getAssetItemIndex()].total_beds_op[year]);           
            data.push (this.$store.state.prototypedata.stageSix[this.getAssetItemIndex()].total_intervention_op[year]);            
            data.push (this.$store.state.prototypedata.stageSix[this.getAssetItemIndex()].total_primary_op[year]);

          }
        }
      return data;
    },
    getWorkforce (year, value)
    {
      var data = new Array();

      year = year - 1;

          
        if (this.$store.state.prototypedata.stageSix.length > 0) 
        {
          console.log ("getAssets", this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()]);

          if (value == "cu")
          {  
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].total_workforce_cu[year]);          
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].medical_cu[year]);           
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].nursing_cu[year]);            
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].administration_cu[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].ahps_cu[year]);          
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].technicians_cu[year]);            
          }
          else
          {
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].total_workforce_op[year]);          
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].medical_op[year]);           
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].nursing_op[year]);            
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].administration_op[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].ahps_op[year]);          
            data.push (this.$store.state.prototypedata.stageSix[this.getWorkforceItemIndex()].technicians_op[year]);   
          }
        }


      return data;
    },
    getFinance (year, value)
    {
      var data = new Array();

      year = year - 1;

          
        if (this.$store.state.prototypedata.stageSix.length > 0) 
        {
          if (value == "cu")
          {              
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].costbase_budget_cu[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].supply_chain_cu[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].beneficiary_cap_rate_cu[year]);
            //data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].infrastructure_build_cost_optimized[4]);
            //data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].annualised_property_cost_optimized[4]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].cost_per_episode_cu[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].cost_per_asset_cu[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].cost_per_workforce_cu[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].cost_per_population_cu[year]);
            //data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].episodes_per_asset_optimized[year]);
            //data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].episodes_per_workforce_optimized[year]);
          }
          else
          {
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].costbase_budget_op[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].supply_chain_op[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].beneficiary_cap_rate_op[year]);
            //data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].infrastructure_build_cost_optimized[4]);
            //data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].annualised_property_cost_optimized[4]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].cost_per_episode_op[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].cost_per_asset_op[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].cost_per_workforce_op[year]);
            data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].cost_per_population_op[year]);
            //data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].episodes_per_asset_optimized[year]);
            //data.push (this.$store.state.prototypedata.stageSix[this.getFinanceItemIndex()].episodes_per_workforce_optimized[year]);    
          }
        }


      return data;
    },
    getTrackerItemIndex() 
    {
      var i = 0;
      var index = -1;

     
      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        for (
          i = 0;
          i < this.$store.state.prototypedata.stageSix.length;
          i++
        ) {
          //console.log (" this.$store.state.prototypedata.stageSix[i]:", this.$store.state.prototypedata.stageSix[i]);
          if ( "trackerSixty" in this.$store.state.prototypedata.stageSix[i]) 
          {
            index = i;
//            console.log ("index = ", i);
          }
        }
      }

      //console.log ("here3");
      return index;
    },
    getEpisodeItemIndex() 
    {
      var i = 0;
      var index = -1;

     
      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        for (
          i = 0;
          i < this.$store.state.prototypedata.stageSix.length;
          i++
        ) {
          //console.log (" this.$store.state.prototypedata.stageSix[i]:", this.$store.state.prototypedata.stageSix[i]);
          if ( "episodeProductivity" in this.$store.state.prototypedata.stageSix[i]) 
          {
            index = i;
            //console.log ("index = ", i);
          }
        }
      }

      //console.log ("here3");
      return index;
    },
   
    getFinanceItemIndex() 
    {
      var i = 0;
      var index = -1;

     
      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        for (
          i = 0;
          i < this.$store.state.prototypedata.stageSix.length;
          i++
        ) {
          //console.log (" this.$store.state.prototypedata.stageSix[i]:", this.$store.state.prototypedata.stageSix[i]);
          if ( "costbase_budget_cu" in this.$store.state.prototypedata.stageSix[i]) 
          {
            index = i;
            //console.log ("index = ", i);
          }
        }
      }

      //console.log ("here3");
      return index;
    },

    getAssetItemIndex() 
    {
      var i = 0;
      var index = -1;

      console.log ("==> getAssetItemIndex()");

     
      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        for ( i = 0; i < this.$store.state.prototypedata.stageSix.length; i++) 
        {
          //console.log (" this.$store.state.prototypedata.stageSix[i]:", this.$store.state.prototypedata.stageSix[i]);
          if ( "total_assets_cu" in this.$store.state.prototypedata.stageSix[i]) 
          {
            index = i;
            console.log ("getAssetItemIndex = ", i);
          }
        }
      }

      console.log ("<== getAssetItemIndex()");

      //console.log ("here3");
      return index;
    },

    getWorkforceItemIndex() 
    {
      var i = 0;
      var index = -1;

     
      if (this.$store.state.prototypedata.stageSix.length > 0) 
      {
        for (
          i = 0;
          i < this.$store.state.prototypedata.stageSix.length;
          i++
        ) {
          //console.log (" this.$store.state.prototypedata.stageSix[i]:", this.$store.state.prototypedata.stageSix[i]);
          if ( "total_workforce_cu" in this.$store.state.prototypedata.stageSix[i]) 
          {
            index = i;
            console.log ("index = ", i);
          }
        }
      }

      //console.log ("here3");
      return index;
    },
    getMongoDBData() 
    {
      this.$store.dispatch("getBDFStageSixSummaries").then((data) => {
        console.log("data", data);
      });
    },
     getTileData(tileNumber)
     {
      var Str = ""; 

      if (tileNumber == "0")        Str = "60 Month Tracker";
      if (tileNumber == "1")        Str = "60 Month Tracker";
      if (tileNumber == "2")        Str = "60 Month Tracker";
      if (tileNumber == "3")        Str = "60 Month Tracker";
      if (tileNumber == "4")        Str = "12 Month Tracker";
      if (tileNumber == "5")        Str = "12 Month Tracker";
      if (tileNumber == "6")        Str = "12 Month Tracker";
      if (tileNumber == "7")        Str = "12 Month Tracker";
      if (tileNumber == "8")        Str = "Optimization 1 Month";
      if (tileNumber == "9")        Str = "Optimization 1 Month";
      if (tileNumber == "10")        Str = "Optimization 1 Month";
      if (tileNumber == "11")        Str = "Optimization 1 Month";
    
      return Str;
     },
     getData(index)  
     {
      let data = new Array ();
      let tempData = new Array ();
  
      let str =  "";
      
      console.log ("> GetData: ", index);

      switch (index)
      {
        case 1:  tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_episodes_ot;                               
        break;
        case 2: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_episodes_ct;      
        break;
        case 3:  tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].opd_ot;
        break;
        case 4: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].opd_ct;  
        break;
        case 5:  tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].intervention_ot;
        break;
        case 6: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].intervention_ct;  
        break;
        case 7:  tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].diagnostics_ot;
        break;
        case 8: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].diagnostics_ct;
        break;

        case 9: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_assets_ot;
        break;
        case 10: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_assets_ct;
        break;
        case 11: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_beds_ot; 
        break;
        case 12: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_beds_ct; 
        break;          
        case 13: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_intervention_ot;     
        break;
        case 14: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_intervention_ct; 
        break;
        case 15: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_primary_ot; 
        break;
        case 16: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_primary_ct;     
        break;

        case 17: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_workforce_ot; 
        break;
        case 18: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].total_workforce_ct; 
        break;
        case 19: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].medical_ot; 
        break;
        case 20: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].medical_ct;  
        break;
        case 21: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].nursing_ot;  
        break;
        case 22: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].nursing_ct;   
        break;
        case 23: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].administration_ot;
        break;
        case 24: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].administration_ct;
        break;
        case 25: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].ahps_ot;    
        break;
        case 26: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].ahps_ct; 
        break;
        case 27: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].technicians_ot;    
        break;
        case 28: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].technicians_ct; 
        break;

        case 29:  tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].costbase_budget_ot;
        break;
        case 30: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].costbase_budget_ct; 
        break;  
        case 31: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].supply_chain_ot;
        break; 
        case 32: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].supply_chain_ct;  
        break;
        case 33:  tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].beneficiary_cap_rate_ot;
        break;
        case 34: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].beneficiary_cap_rate_ct;
        break; 
        case 35: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].cost_per_episode_ot;
        break;
        case 36: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].cost_per_episode_ct;
        break;
        case 37: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].cost_per_asset_ot;    
        break;
        case 38: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].cost_per_asset_ct;
        break;
        case 39: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].cost_per_workforce_ot;  
        break;  
        case 40: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].cost_per_workforce_ct;
        break; 
        case 41: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].cost_per_population_ot; 
        break; 
        case 42: tempData = this.$store.state.prototypedata.stageSix[this.getTrackerItemIndex()].cost_per_population_ct;
        break;
       
    }

    if (this.isEmpty(tempData) == false || typeof tempData !== "undefined") {
        console.log("tempData", tempData);
        for (var i = 0; i < tempData.length; i++) {
          str = tempData[i].replaceAll(",", "");
          data.push(str);
        }
      }

      console.log (">> GetData: ", index);
      console.log ("data: ", data);

      return data;
     },    
     isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
     getSixtyMonthLabels()
      {
        let data = new Array ();
  
        for (var i = 1; i<=60; i++)
        {
          data.push (i.toString());
        }    
        return data;  
      },    
      fillData(tileValue) 
      {
        this.activeSummary = tileValue;
  
        this.activeTitle = "Assets (5 Year) 60 Month Tracker";

        this.patientEpisodes = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Total Episode (Optimized)',
              data:  this.getData(1),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'Total Episodes (Current)',
              data: this.getData(2),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.patientOPD = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'OPD (Optimized)',
              data:  this.getData(3),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'OPD (Current)',
              data: this.getData(4),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.patientIntervention = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Intervention (Optimized)',
              data:  this.getData(5),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'Intervention (Current)',
              data: this.getData(6),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.patientDiagnostics = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Diagnostics (Optimized)',
              data:  this.getData(7),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'Diagnostics (Current)',
              data: this.getData(8),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.assetTotalAssets = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Total Assets',
              data:  this.getData(9),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(10),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.assetTotalBeds = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Total Beds',
              data:  this.getData(11),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(12),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.assetTotalIntervention = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Total Intervention & Dialysis',
              data:  this.getData(13),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(14),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.assetPrimaryCare = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Total Primary Care',
              data:  this.getData(15),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(16),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.workforceTotalWorkforce = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Total Workforce',
              data:  this.getData(17),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(18),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.workforceMedical = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Medical',
              data:  this.getData(19),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(20),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.workforceNursing = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Nursing',
              data:  this.getData(21),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(22),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.workforceAdministration = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Administration',
              data:  this.getData(23),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(24),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.workforceAHPs = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'AHPs',
              data:  this.getData(25),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(26),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.workforceTechnicians = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Technicians',
              data:  this.getData(27),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(28),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.financeCostbase = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Costbase',
              data:  this.getData(29),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(30),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.financeSupplyChain = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Supply Chain',
              data:  this.getData(31),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(32),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.financeBeneficiary = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Beneficiary',
              data:  this.getData(33),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(34),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.financeCostPerEpisode = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Cost Per Episode',
              data:  this.getData(35),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(36),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.financeCostPerAsset = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Cost Per Asset',
              data:  this.getData(37),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(38),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.financeCostPerWorkforce = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Cost Per Workforce',
              data:  this.getData(39),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(40),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };

        this.financeCostPerPopulation = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Cost Per Population',
              data:  this.getData(41),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: '',
              data: this.getData(42),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
          ],
        };
    },
      fetchData(speciality) {
        console.log("SpecialitiesMain.Vue: fetchData (", speciality);
         }
    },
  };
  </script>
  <style scoped>
  .chartcontainer9 {
    border: 1px solid;
    border-color: #e5e9f2;
    height: 400px;
    display: block;
    width: 300px; /* was 26 rem */
    margin-right: 20px;
    padding-bottom: 5px;
    padding-top: 0px;
    margin-bottom: 20px;
  }
  .hello1 {
color: #000000;
font-family: arial, sans-serif;
font-size: 16px;
font-weight: bold;
margin-top: 20px;
margin-bottom: 30px;
}
  .title_text9 {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  </style>
