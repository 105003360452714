import axios from "axios";
import router from "../router";


// how to check what this is in the console - DM
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(
  config => {
    config.headers["X-Requested-With"] = "XMLHttpRequest";
    if (window.localStorage.getItem("token"))
      config.headers["Authorization"] =
        "Bearer " + window.localStorage.getItem("token");
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  response => {
    if (response.headers.authorization) {
      let match = response.headers.authorization.match(/Bearer (.+)/);
      if (match) window.localStorage.setItem("token", match[1]);
    }
    return response.data;
  },
  err => {
    if (err.response.status === 401) {
      window.localStorage.removeItem("token");
      router.push("/login");
    }
    return Promise.reject(err);
  }
);

export default axios;
