import axios from "../../plugins/axios";

export default {
  state: {
    namespaced: true
  },
  getters: {
  },
  mutations: {
    SET_LOGGED_IN(state, token) {
      window.localStorage.setItem("token", token);
    },
    SET_LOGGED_OUT() {
      window.localStorage.removeItem("token");
    },
  },
  actions: {
    attemptLogin({ commit }, { email, password }) {
      return axios
        .post(`/login`, { email, password })
        .then(auth => {
          commit("SET_LOGGED_IN", auth.token);
        })
        .catch((error) => Promise.reject(error))
    },
    attemptLogout({ commit }) {
      commit("SET_LOGGED_OUT");
    }
  }
};
