import Shell from '../../../layouts/Shell.vue'
import businessplan from '../views/businessplan.vue'
 
const routes = [
    {
        path: '/',
        name: 'LayoutModule',
        component: Shell,
        children: [
        {
            path: '/businessplan',
            name: 'businessplan',
            component: businessplan,
            meta: {
                requiresAuth: true
            }
        }]
    }
]

export default routes
