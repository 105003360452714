<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">Strategic Insight Analysis</h1>
      </div>

      <div class="validation-cards--item cert no-hover">
        <span class="card-header">Certification Status</span>
        <div class="card-icon" margin-top="0px">
          <img
            src="@/assets/img/circular-label-with-certified-stamp.png"
            alt=""
          />
          <span class="card-total">100% Validation</span>
        </div>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Bahrain</span>
        </div>
      </div>      
    </div>

    <nav class="route-nav">
<ul>
<li
@click="editStageSummary('strategic outline')"
:class="stageSummary == 'strategic outline' ? 'active' : ''">
<span>Strategic Outline</span>
</li>
<li
@click="editStageSummary('aim')"
:class="stageSummary == 'aim' ? 'active' : ''">
<span>Aim</span>
</li>
<li
@click="editStageSummary('key strategic objectives')"
:class="stageSummary == 'key strategic objectives' ? 'active' : ''">
<span>Key Strategic Objectives</span>
</li>
<li
@click="editStageSummary('strategic recommendations')"
:class="stageSummary == 'strategic recommendations' ? 'active' : ''">
<span>Strategic Recommendations</span>
</li>
<li
@click="editStageSummary('financial plan & recommendation')"
:class="stageSummary == 'financial plan & recommendation' ? 'active' : ''">
<span>Financial Plan & Recommendation</span>
</li>
<li
@click="editStageSummary('workforce')"
:class="stageSummary == 'workforce' ? 'active' : ''">
<span>Workforce</span>
</li>
<li
@click="editStageSummary('operational improvement')"
:class="stageSummary == 'operational improvement' ? 'active' : ''">
<span>Operational Improvement</span>
</li>
<li
@click="editStageSummary('ccrcm')"
:class="stageSummary == 'ccrcm' ? 'active' : ''">
<span>CCRCM</span>
</li>
</ul>
</nav>



<div v-if="stageSummary == 'strategic outline'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->

<div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">1.1</span>
            <span class="card-total3">Bahrain Defence Force Royal Medical Services – ‘BDFRMS’ - was established under one leadership of Commander Royal Medical Services in April 2023.
</span>            
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">1.2</span>
            <span class="card-total3">Health System Intelligence (HSi) Limited – ‘HSi’ – was contracted to enable transformation and integration of the 4 RMS hospitals across 5 main disciplines in an initial 3-stage
</span>            
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">1.3</span>
            <span class="card-total3">HSi have completed the first 3 stages ahead of schedule.  This document is a summary of all delivery conducted, and recommendations of the strategic direction and accompanying actions required.
</span>            
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">1.4</span>
            <span class="card-total3">BDF RMS is now in the position to effectively lead healthcare provision across Bahrain through a coherent strategic leadership approach to optimising service quality, whilst delivering value for money (VFM).
</span>            
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">1.5</span>
            <span class="card-total3"> To deliver strategic change, BDF RMS must now coordinate its key functions of Finance, IT, and Workforce planning, supported by restructuring the balance between its Group and Hospital leadership functions to implement long-term clinical service transformation to deliver sustainable and leading healthcare services.
</span>            
          </div>



</div>
</div>
</div>





<div v-if="stageSummary == 'aim'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->

<div class="hello1">AIM</div>

<div class="new_stat-cards--item single no-hover" style="width: 100%">        
            <span class="card-total3">BDF RMS intends to conduct full integration of Clinical and non-Clinical services to ensure the optimised care delivery for its entitled population and to serve the strategic interests of the Kingdom of Bahrain to deliver value for money provision of healthcare outputs in a high-quality clinical environment.</span>            
          </div>




</div>
</div>
</div>





<div v-if="stageSummary == 'key strategic objectives'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->
<div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">3.1</span>
            <span class="card-total3">The following are indicated by all background analyses and integration across all 4 RMS hospitals as being key objectives to achieve in order to deliver the RMS mission.</span>            
          </div>

          <table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Task</th>
          <th class="border px-4 py-2">Strategic Objectives</th>
          <th class="border px-4 py-2">Rationale</th>
          <th class="border px-4 py-2">Strategic Actions</th>
          <th class="border px-4 py-2">Interdependencies</th>
          <th class="border px-4 py-2">Stage 4 Readiness</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Financial IT Integration (Controls and Oversight)</td>
          <td class="border" style="padding: 10px; ">Financial systems in place to provide complete insight and oversight capability, plus full controls over spending and income generation</td>
          <td class="border" style="padding: 10px; ">A.  Implement prepared financial package.<BR/>
            B.  Establish financial standing instructions.<BR/>
            C.  Establish sound Creditors and Debtors listing with payment mechanisms,<BR/>
            D.  Confirm Management Accounts format.<BR/>
            E.  Confirm P&L, Balance Sheet, and Trial Balance formats.<BR/>
            * All the above are prepared and in place.<BR/>
          </td>
          <td class="border" style="padding: 10px; ">·      IT Integration<BR/>
            ·      Finance<BR/>
            ·      Procurement<BR/>
          </td>
          <td class="border" style="padding: 10px; ">Yes<BR/>
            Full financial package prepared Stages 2 and 3.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Value for Money</td>
          <td class="border" style="padding: 10px; ">Demonstrate financial position based on provision of high quality, efficient services</td>
          <td class="border" style="padding: 10px; ">A.    Workforce Target Operating Models link to Activity.<BR/>
            B.    Activity-based procurement<BR/>C.     Operational Efficiency – Planning<BR/>D.    Strategic Quality Plan</td>
          <td class="border" style="padding: 10px; ">·      Operational Improvement<BR/>
            ·      Workforce<BR/>·      Financial<BR/>·      IT Integration<BR/>·      Cost, Coding, Pricing</td>
          <td class="border" style="padding: 10px; ">Yes<BR/>Financial Projections prepared for 5-year period for:<BR/>·      RMS.<BR/>·      MKCC<BR/>·      KHUH<BR/>·      BDFH<BR/>·      BOC</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Optimised Care </td>
          <td class="border" style="padding: 10px; ">High Quality Clinical Environment</td>
          <td class="border" style="padding: 10px; ">Review and Improve Clinical pathways<BR/>Drive improvement of clinical service provision<BR/>Establish full M&M audits across RMS<BR/>Confirm Clinical Audit timetable per specialty</td>
          <td class="border" style="padding: 10px; ">·      Workforce<BR/>·      IT Integration</td>
          <td class="border" style="padding: 10px; ">Yes</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Workforce Integrity</td>
          <td class="border" style="padding: 10px; ">High Quality, trained workforce levels based on patient episode demand</td>
          <td class="border" style="padding: 10px; ">Utilise available Workforce benchmarking.to confirm optimised levels (100% prepared).<BR/>Training Plan<BR/>Control Recruitment – all levels<BR/>Commence creation of TOMs<BR/>Incentivization Policy Agreement</td>
          <td class="border" style="padding: 10px; ">·      Operational Improvement<BR/>·      Workforce<BR/>·      Finance</td>
          <td class="border" style="padding: 10px; ">Yes</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">5</td>
          <td class="border" style="padding: 10px; ">Operational Efficiency</td>
          <td class="border" style="padding: 10px; ">Core driver of financial efficiency<BR/>Balanced Workforce<BR/>Centralised Control over performance through Specialty Target Operating Models (TOMs)</td>
          <td class="border" style="padding: 10px; ">·      Drive and Measure Activity: Asset productivity levels.<BR/>·      Ensure weekly planning and daily operational controls drive:<BR/>-  Activity-based Nursing<BR/>-  Activity-based medical staffing</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Yes</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">6</td>
          <td class="border" style="padding: 10px; ">IT Integration Improvement</td>
          <td class="border" style="padding: 10px; ">Improvements to the HIS/ERP to support:<br/>·      Improved Clinical care.<BR/>·      Quality<BR/>·      Audit<BR/>·      Booking<BR/>·      Planning<BR/>·      Financial Controls<BR/>·      Procurement<BR/>·     Patient Access</td>
          <td class="border" style="padding: 10px; ">Programmed Phases of Refinement based on key priorities, namely:<BR/>·      Clinical<BR/>·      Financial<BR/>·     Patient Access<BR/>·      Audit</td>
          <td class="border" style="padding: 10px; ">·      Finance Package Integration</td>
          <td class="border" style="padding: 10px; ">Yes</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">7</td>
          <td class="border" style="padding: 10px; ">Centralised Group Structure</td>
          <td class="border" style="padding: 10px; ">·      Central Controls, Policy, Executive Team.<BR/>·      Reduce Duplication of Functional Administration and Staffing.<BR/>·      Minimise administration and bureaucracy.</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
</tr>
</tbody>
</table>





</div>
</div>
</div>





<div v-if="stageSummary == 'strategic recommendations'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->
<div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">4.1</span>
            <span class="card-total3">The following is the outline of the strategic recommendations.</span>            
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">4.2</span>
            <span class="card-total3">Summary details of key workstream recommendations are contained in Paragraph 5 for further information on specific areas.</span>            
          </div>

          <table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Task</th>
          <th class="border px-4 py-2">Strategic Recommendations</th>
          <th class="border px-4 py-2">Rationale</th>
          <th class="border px-4 py-2">Integrated Strategic Actions</th>
          <th class="border px-4 py-2">Stage 4 Readiness</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Group Business Plan (5 years)</td>
          <td class="border" style="padding: 10px; ">Confirm: Group Mission, Policies, Performance Aims, Financial Targets<BR/>Coordinate: Key Workstreams:<BR/>
          Finance, IT, Workforce, Operational Improvement<BR/>Integrate: Demand, Capacity, Productivity<BR/>Delegate:  TOMs to HCTs.</td>
          <td class="border" style="padding: 10px; ">1.     Strategic Risks and Opportunities<BR/>2.     Population Health Demand<BR/>3.     Workforce – TOMs<BR/>4.     Workforce – Restructured Functions (Central and Site)
            <BR/>5.     Workforce – Restructured Shared Services (Central)<BR/>6.     Asset Utilisation plan<BR/>7.     IT Centralised Control Functions<BR/>8.     IT Performance Oversight
            <BR/>9.     Finance – Cost Base Controls<BR/>10.  Finance – Income Generation Plan<BR/>11.  Finance – TPMs<BR/>12.  Reporting and PMS<BR/><BR/><BR/><BR/><BR/><BR/><BR/><BR/><BR/><BR/><BR/><BR/></td>
          <td class="border" style="padding: 10px; ">Commenced</td>
</tr>


<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Hospital Business Plans</td>
          <td class="border" style="padding: 10px; ">Delegate hospital leadership full autonomy for efficiency delivery</td>
          <td class="border" style="padding: 10px; ">Issue TOMs for:<BR/>a.     Finance (Budget)<BR/>b.     Finance (Income)<BR/>c.     Workforce (Admin/Shared)<BR/>d.     Workforce (Clinical)
          <BR/>e.     Quality Performance<BR/>f.      Operational Performance</td>
          <td class="border" style="padding: 10px; ">Commenced</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Model Hospital</td>
          <td class="border" style="padding: 10px; ">Establish KHUH as the model hospital for SOPs for rdr to imitate</td>
          <td class="border" style="padding: 10px; ">Establish full process for:<BR/>·      OT,<BR/>·      ER,<BR/>·      OPD,<BR/>·      Diagnostics<BR/>·      Bed Flow<BR/>·      Operational Management Process Daily Control Room Command functions</td>
          <td class="border" style="padding: 10px; ">Commenced</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Reporting Mechanism and Target Operating Models</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Daily/Weekly Group and Hospital Reporting Mechanisms</td>
          <td class="border" style="padding: 10px; ">Commenced</td>
</tr>
</tbody>
</table>

<div class="hello1">Additional Recommendations</div>


<table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Task</th>
          <th class="border px-4 py-2">Strategic Recommendations</th>
          <th class="border px-4 py-2">Rationale</th>
          <th class="border px-4 py-2">Remarks</th>
          <th class="border px-4 py-2">Stage 4 Readiness</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Long-Term Care Plan</td>
          <td class="border" style="padding: 10px; ">Reduce long-stay patients by c. 70 across BDF & KHUH<BR/>Reduce costs<BR/>Mitigate Medical and Nursing staffing</td>
          <td class="border" style="padding: 10px; ">Phases:<BR/>a.     Lease<BR/>b.     Design<BR/>c.     Build </td>
          <td class="border" style="padding: 10px; ">Plan prepared for 3 phases.<BR/>Operational plan and costings.  </td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">IT Deep Integration</td>
          <td class="border" style="padding: 10px; ">Continuous improvement.<BR/>Booking process.<BR/>Clinical Risk</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Acute Clinical Strategy</td>
          <td class="border" style="padding: 10px; ">Improve acute services</td>
          <td class="border" style="padding: 10px; ">Neurosciences – specialist Stroke Centre<BR/>Focus limited specialists<BR/>Urology </td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Influence National Procurement Strategy</td>
          <td class="border" style="padding: 10px; ">Medications and Surgical Consumables excessive</td>
          <td class="border" style="padding: 10px; ">Propose strategic solutions to Government</td>
          <td class="border" style="padding: 10px; ">Plans commenced</td>
</tr>
</tbody>
</table>







</div>
</div>
</div>





<div v-if="stageSummary == 'financial plan & recommendation'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->
<div class="hello1">Interdependencies: Operational Improvement, Workforce, CCRCM</div>

<table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Item</th>
          <th class="border px-4 py-2">Finance Area</th>
          <th class="border px-4 py-2">Overview Finding</th>
          <th class="border px-4 py-2">Additional Remarks</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">General Comment:</td>
          <td class="border" style="padding: 10px; ">RMS Group does not currently have financial and commercial systems that enable sound financial management of a group of hospitals.</td>
          <td class="border" style="padding: 10px; ">Complete finance package to inculcate into RMS/AlCare has been prepared</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Current Main Risks</td>
          <td class="border" style="padding: 10px; ">·      Lack of spending controls<BR/>·      Poor inventory and supply chain management in certain areas.<BR/>·      Lack of clarity on true debtor positions.<BR/>·      Lack of clarity on true profitability/loss based on unclear spend position month-month, or past debtors from previous years.<BR/>
            ·      Financial standing orders not universally applied<BR/></td>
          <td class="border" style="padding: 10px; ">Mitigations are provided by the fact that:<BR/>·      The IT integration is ensuring the AlCare/RMS ERP is applied across all hospitals.
          <BR/>·      Central supply chain procurement will improve controls and reduce expenditure.<BR/>·      Financial package prepared for AlCare
          </td>
</tr>
</tbody>
</table>

<div class="hello1"><BR/></div>

<div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">6.2 Finance Recommendations</span>
            <span class="card-total3">The following are the main recommendations for financial improvement:</span>                      
          </div>
      

          <table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Item</th>
          <th class="border px-4 py-2">Factor</th>
          <th class="border px-4 py-2">Recommendation</th>
          <th class="border px-4 py-2">Remarks</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Finance IT</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "> </td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Cost Efficiency</td>
          <td class="border" style="padding: 10px; ">Establish Cost Base per Hospital<BR/>Establish Cost Base per Group Function</td>
          <td class="border" style="padding: 10px; ">Cost Efficiency to be applied by:<BR/>·      Operational Improvement.<BR/>·      Workforce rationalisation to minimum 8%<BR/>
          ·      Shared Service and Function restructure<BR/>·      Procurement<BR/>Cost-Base Target Operating Model is prepared for Stage 4.  To update.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Finance Function</td>
          <td class="border" style="padding: 10px; ">Centralised finance function restructure</td>
          <td class="border" style="padding: 10px; ">Finance Structures prepared and presented 27 August 2023<BR/>Headcount reduction of unnecessary workforce<BR/>IT function improves Group capability</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Income Generation</td>
          <td class="border" style="padding: 10px; ">Confirm income generation by grouping, market segment and profitability</td>
          <td class="border" style="padding: 10px; ">Comprehensive Income Generation has been planned ahead for Stage 4 and will be included within the updated financial projections.<BR/>
          Income Generation potential to focus on:<BR/>·      Private Practice Growth based on forensic market plan on:<BR/>o   procedures<BR/>o   Demand-based<BR/>
        o   In-Bahrain – Target market (Health Insurance, Expat, Companies)<BR/>o   External Bahrain (GCC)<BR/>o   External Bahrain (UK/Europe)</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">5</td>
          <td class="border" style="padding: 10px; ">Financial Target</td>
          <td class="border" style="padding: 10px; ">Immediate confirmation of financial cost-base per hospital based on multiple TOM factors</td>
          <td class="border" style="padding: 10px; ">Detailed commercial and financial projections per hospital and for RMS for the next 5 years have been prepared and updated </td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">6</td>
          <td class="border" style="padding: 10px; ">Resource Equalization</td>
          <td class="border" style="padding: 10px; ">Current Cost budgets are not feasible.</td>
          <td class="border" style="padding: 10px; ">For example:<BR/>·      BOC currently pays for a significant proportion of the KHUH medical and nursing staff (on top of those who currently staff and manage BOC assets – ICU, OT, Medical OPD),<BR/>·      MKCC budget is way more than its true requirement based on every area of Demand modelling (e.g. Workforce – excessive in terms of background administrative functions support, as well as junior medical staffing),</td>
</tr>
</tbody>
</table>

<div class="hello1"><BR/></div>



<div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">6.3 Financial Outputs</span>
            <span class="card-total3">Effective controls of Cost-base, supported by an effective Group Business Plan that progressively builds targeted revenues generation according to HSi analysis of multiple expanded income streams will lead to minimum threshold of BD 35M per annum.</span>
</div>

</div>
</div>
</div>





<div v-if="stageSummary == 'workforce'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->

<div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">8.1</span>
            <span class="card-total3">The initial strategy for workforce was included at Stage 1 summary in May 2023 (Annex A).
</span>                      
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">8.2</span>
            <span class="card-total3">Intensive modelling has been conducted per hospital, and by clinical specialty by:
<BR/>(1) asset capacity,
<BR/>(2) actual asset utilisation, and<BR/>(3) Entitled/Beneficiary population health demand.</span>                      
          </div>
          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">8.3</span>
            <span class="card-total3">The summary Workforce strategy presented in September 2023 is at Annex D. The detailed workforce strategy was presented to the CEO’s meeting as well as direct to certain Hospital Commanders.
</span>                      
          </div>
          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">8.4</span>
            <span class="card-total3">When compared to total asset utilisation, workforce levels of Medical, Nursing and Administrative staffing are significantly higher by establishment, and by true current capacity employed at Group level, and at hospital level.
</span>                      
          </div>
          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">8.5</span>
            <span class="card-total3">HSi have insisted on various levels of staffing as being sufficient against Demand despite various requests for many more workforce levels.  
</span>                      
          </div>
          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">8.6</span>
            <span class="card-total3">Equalization of certain resources will be required as part of the draft stage 4 ‘right-sizing’ – for example increased levels of Junior Doctors into Bahrain Oncology Centre (BOC).
</span>                      
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">8.7</span>
            <span class="card-total3">The Operational Improvement workstream has established daily hospital and group Workforce and Activity reviews.  During Stages 2 and 3, there have been no reports of a lack of staffing.
</span>                      
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">8.8</span>
            <span class="card-total3">The detailed analysis supports detailed Target Operating Models required to support potential Stage 4 Financial Efficiency delivery.  These are detailed per clinical specialty, per hospital.
</span>                      
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">8.9</span>
            <span class="card-total3">Additional Information is required in areas of Administration, where insufficient current information has been supplied.  However, the optimisation Administration Target Operating Models are in place.
</span>                      
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">8.10</span>
            <span class="card-total3">Workforce is heavily interdependent on the development of Operational Improvement, and in particular ensuring all clinical pathways are efficient.  For example, high Nursing numbers in certain areas are caused by low levels of clinical productivity – for example in Operating Theatres, high levels of Follow-ups in OPD, or incomplete application of medical ward round processes.
</span>                      
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">8.11</span>
            <span class="card-total3">HSi have prepared a complete detailed plan for reducing the workforce at strategic and functional levels, as well as medical, nursing and admin staffing in each hospital.  This is based on what is actually required against optimization target operating models per clinical specialty and per hospital.
</span>                      
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">8.12</span>
            <span class="card-total3">General Commentary: Workforce</span>                      
          </div>


          <table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Item</th>
          <th class="border px-4 py-2">Factor</th>
          <th class="border px-4 py-2">Recommendation</th>
          <th class="border px-4 py-2">Remarks</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Workforce is a disproportionately high cost of overall revenues (more than 78%)</td>
          <td class="border" style="padding: 10px; ">Minimum reduction of easily 8% per year over 3 years</td>
          <td class="border" style="padding: 10px; ">Feasible as part of the draft ‘Right-sizing’ within the draft Stage 4 Financial Efficiency target operating strategy.  </td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Recruitment is not related to demand</td>
          <td class="border" style="padding: 10px; ">Immediate controls on workforce recruitment should be in place across all disciplines until measured against specific demand.<BR/>Establish long-term Workforce Plan to indicate specific demand</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Medical Target Operating Models</td>
          <td class="border" style="padding: 10px; ">Establish Activity-based TOMs per clinical speciality</td>
          <td class="border" style="padding: 10px; ">·      Included within Draft Group Business Planning.<BR/>·      Extracted into Draft Hospital Business Plans.<BR/>
          <BR/>·      Initial establishment of process: ‘Medical Staff Planning’ – weekly.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Training Plan</td>
          <td class="border" style="padding: 10px; ">Long-term training plan to be in place against specialty and sub-specialty demand</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">5</td>
          <td class="border" style="padding: 10px; ">Shared Services</td>
          <td class="border" style="padding: 10px; ">Shared service structure target operating models to be incorporated to reduce unnecessary excessive headcount.</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">6</td>
          <td class="border" style="padding: 10px; ">Administration</td>
          <td class="border" style="padding: 10px; ">Centralisation of Administration Functions (including Finance, IT, HR, Procurement) should be established as part of Stage 4 Financial Efficiency TOM.</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">7</td>
          <td class="border" style="padding: 10px; ">Management</td>
          <td class="border" style="padding: 10px; ">Operational Leadership for delivery of ongoing management expertise to be incorporated within the ongoing workforce strategy</td>
          <td class="border" style="padding: 10px; ">HSi have prepared a complete detailed plan for reducing the workforce at strategic and functional levels, as well as medical, nursing and admin staffing in each hospital.  This is based on what is actually required against optimization target operating models per clinical specialty and per hospital.</td>
</tr>
</tbody>
</table>


<div class="hello1"><BR/></div>

<div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">Workforce Summary</span>
            <span class="card-total3">1.     Excessive staffing in Medical, Nursing and Administration.<BR/>
2.     No coordination of actual demand with CPA reviews – major risk.<BR/>
3.     Nurse Staff Planning does not follow ratio policy of RMS.<BR/>
4.     Medical staff in certain specialties are major luxury mode.<BR/>
5.     Stage 4 Actions/Recommendations: Using HSi detailed Workforce modelling and presented on detailed AI platform to guide optimisation, the following should be tackled:<BR/>
a.     Implement Group HR Workforce Plan<BR/>
b.     Implement Group Management structure (Risk) to drive transformation.<BR/>
c.     Complete Group Functional and Hospital Structure<BR/>
d.     Establish shared service plan.<BR/>
e.     Establish ‘hub and spoke’ functional support across all sites.<BR/>
f.      Medical Planning based on activity.<BR/>
g.     Nurse Management restructured to adopt Group Structure<BR/>
h.     Reduce Nurse Management across all sites – excess overhead and doing not much.  Reduces quality of care.  Most experienced nurses = no nursing.<BR/>
i.      Reduce excess administration duplicated across each site performing similar functions.<BR/>
</span>                      
          </div>



</div>
</div>
</div>





<div v-if="stageSummary == 'operational improvement'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->

<div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">Recommendations for Operational Improvement</span>
            <span class="card-total3">Prior to commencing initial operational improvement phasing in Stage 2, and into Stage 3, multiple lectures and briefings were presented to all invited Medical and Nursing staffs in all of the hospital sites.
Failure to implement full operational improvement transition will:<BR/>
·      Negate the potential financial success and integrity of RMS.<BR/>
·      Maintain high costs.<BR/>
·      Reduce quality.<BR/>
·      Increase risk.<BR/>
Based on the lack of 100% cooperation by all Medical Staffing, the following are the main recommendations:<BR/>

</span>                      
          </div>

          <table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Item</th>
          <th class="border px-4 py-2">Factor</th>
          <th class="border px-4 py-2">Recommendation</th>
          <th class="border px-4 py-2">Remarks</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Productivity</td>
          <td class="border" style="padding: 10px; ">Establish TOMs per clinical service</td>
          <td class="border" style="padding: 10px; ">All TOMs are prepared for each clinical service.  To update.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Operational Grip</td>
          <td class="border" style="padding: 10px; ">Refine and Improve the Command, Control and Communications functions in each hospital</td>
          <td class="border" style="padding: 10px; ">All set up in Stage 3 for:<BR/>Hospital Daily Controls,<BR/>Group Daily Controls<BR/>Reports and Data issued daily.  To refine.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Medical Staff Planning</td>
          <td class="border" style="padding: 10px; ">Fully Implement Medical Staff Planning</td>
          <td class="border" style="padding: 10px; ">This will:<BR/>·      Question and Improve clinical pathway<BR/>·      Right-size medical staffing vs demand<BR/>·      Ensure correct allocation of assets and resources to demand</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Demand Management</td>
          <td class="border" style="padding: 10px; ">Improve booking systems</td>
          <td class="border" style="padding: 10px; ">Reduce DNAs and Cancellations<BR/>Stream patient demand to appropriate areas</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">5</td>
          <td class="border" style="padding: 10px; ">Clinical Services</td>
          <td class="border" style="padding: 10px; ">Integrate Clinical Services</td>
          <td class="border" style="padding: 10px; ">Appropriate services to integrate and rationalise, e.g.:<BR/>·      Radiology (in process)<BR/>·      Plastics <BR/>·      Laboratories (in process)<BR/>
           ·      Oral Maxillofacial<BR/>·      Vascular<BR/>·      Inter alia (To discuss CRMS in Stage 4).</td>
</tr>
</tbody>
</table>




</div>
</div>
</div>





<div v-if="stageSummary == 'ccrcm'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->

<div class="hello1">Interdepedencies: Finance, IT</div>

<div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">10.1</span>
            <span class="card-total3">The full detailed list of CPT codes per procedure was prepared and presented to the LPP meeting in August 2023.  
</span>                      
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">10.2</span>
            <span class="card-total3">The fully detailed list was issued in early September.
</span>                      
          </div>

          
          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">10.3</span>
            <span class="card-total3">This presented fully detailed prices unified across all sites, ensuring all procedures and prices including all cost factors, plus profit and overhead assumptions.
</span>                      
          </div>

          
          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">10.4</span>
            <span class="card-total3">A review should take place per 6 months to assess certain prices against the market, as well as to ensure self-payer growth is enabled.  A key area for regular review will be Cardiology pricing.
</span>                      
          </div>

          
          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">10.5</span>
            <span class="card-total3">General Commentary: Costing and Coding</span>                      
          </div>
      

          <table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Item</th>
          <th class="border px-4 py-2">CCP Area</th>
          <th class="border px-4 py-2">Overview Finding</th>
          <th class="border px-4 py-2">Additional Remarks</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">General Comment:</td>
          <td class="border" style="padding: 10px; ">Prices across RMS Group varied even for the same procedure.</td>
          <td class="border" style="padding: 10px; ">Methodology for pricing based on different factors.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Consultant Input</td>
          <td class="border" style="padding: 10px; ">Consultants dictating pricing levels despite their vested % interest.</td>
          <td class="border" style="padding: 10px; ">CRMS ceased this practice in April 2023.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Profitability</td>
          <td class="border" style="padding: 10px; "> Many procedures were being carried out at a loss to RMS Group </td>
          <td class="border" style="padding: 10px; ">Loss-making procedures common, notably when taking off the Surgeon and Anaesthetic % fees</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Revenue Cycle Management</td>
          <td class="border" style="padding: 10px; ">The RCM system in place in AlCare/RMS is the best we have seen</td>
          <td class="border" style="padding: 10px; ">The RCM system has limited Rejection claims by insurers</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">5</td>
          <td class="border" style="padding: 10px; ">Excess Pricing</td>
          <td class="border" style="padding: 10px; ">Certain procedures</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
</tbody>
</table>

<div class="hello1"><BR/></div>

          <div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">10.6</span>
            <span class="card-total3">Recommendations</span>                      
          </div>

          <table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Item</th>
          <th class="border px-4 py-2">Factor</th>
          <th class="border px-4 py-2">Recommendation</th>
          <th class="border px-4 py-2">Remarks</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Consultant Input</td>
          <td class="border" style="padding: 10px; ">Consultants should be removed from all pricing involvement</td>
          <td class="border" style="padding: 10px; ">All TOMs are prepared for each clinical service.  To update.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Upload onto RMS when new financial package is uploaded</td>
          <td class="border" style="padding: 10px; ">Immediate upload</td>
          <td class="border" style="padding: 10px; ">Unified pricing lists issued</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Review</td>
          <td class="border" style="padding: 10px; ">Review all pricing every 3 months </td>
          <td class="border" style="padding: 10px; ">To ensure all costs are appropriately included within price</td>
</tr>
</tbody>
</table>





</div>
</div>
</div>   
  </div>
</template>
<script>
import { Bar } from "vue-chartjs/legacy";
//import { Bubble } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "bar",
  components: {
    Bar,
    //Bubble,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stageSummary: "strategic outline",
      selectedSpeciality: "AHPs",
      apiPayload: { speciality: "", apiString: "" },
      activeSummary: "",
      population: "",
      selectedSpecialityArea: "Patient Episodes",
      improvementSpecialityArea: "Diagnostics",
      specialityType: "Patient Episodes",
      improvementType: "Diagnostics",
      specialityApi: "ahpstwo-collection",
      firstUpdate: false,
      message: "<BR />",
    };
  },
  updated() {
    if (this.firstUpdate == false) {
      console.log("updated");
      this.fillData("workforceCost");
      this.population = this.getPopulation();     
      this.firstUpdate = true;
    }
  },
  beforeMount() {
    console.log("before mounted");
    this.apiPayload.apiString = "grandsummarytwo-collection";
    this.getMongoDBData();
    this.fillData("workforceCost");
  },
  mounted() {
    console.log("mounted");
    this.activeSummary = "workforceCost";
    this.apiPayload.speciality = "Grand Summary";
    this.apiPayload.apiString = "grandsummarytwo-collection";

    this.getMongoDBData();
    this.fillData("workforceCost");
  },
  watch: {
    selectedSpecialityArea: {
      handler: function (val) {
        console.log(
          "selectedSpecialityArea: Value Change: ",
          val.label,
          val.string
        );
        this.editSpecialityType(val.label);
        //console.log("speciality: ", this.specialityApi);
        this.apiPayload.apiString = this.specialityApi;
        this.getMongoDBData();
      },
      deep: true,
    },
    improvementSpecialityArea: {
      handler: function (val) {
        console.log(
          "improvementArea: Value Change: ",
          val.label,
          val.string
        );
        this.editImprovementType(val.label);
        //console.log("speciality: ", this.specialityApi);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      },
      deep: true,
    },
    activeSummary: {
      handler: function (newvalue) {
        console.log("The new activeSummary value is ", newvalue);
        //this.fillData(newvalue);
        //console.log(
        //  "specialityOverviewFilter",
        //  this.specialityOverviewFilter()
        //);
      },
      deep: true,
    },
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },
    specialityName() {
      return this.$store.state.prototypedata.specialityName;
    },
    specialityData() {
      console.log(
        "specialityData: ",
        this.$store.state.prototypedata.specialityData.length
      );
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return true;
      }
      return null;
    },
    getAreaIndex() {
      var i = 0;
      var index = 0;

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "total_floor_space" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },

    getMetricsIndex() {
      var i = 0;
      var index = 0;

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "metrics" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },

    getWorkforceIndex() {
      var i = 0;
      var index = 0;

      //console.log("begin getWorkforceIndex");

      if (this.$store.state.prototypedata.specialityData.length > 0) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          if (
            "workforce" in this.$store.state.prototypedata.specialityData[i]
          ) {
            index = i;
          }
        }
      }

      //console.log("end getWorkforceIndex");

      return index;
    },    
    getTacticalIndex() 
    {
      var i = 0;
      var index = -1;

      if (this.$store.state.prototypedata.specialityData.length > 0) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          if (
            "tactical_assumptions_type" in this.$store.state.prototypedata.specialityData[i]
          ) {
            index = i;
          }
        }
      }

      //console.log("end getWorkforceIndex");

      return index;
    },
    getPatientIndex() {
      var i = 0;
      var index = 0;

      //console.log("begin getPatientIndex");

      if (this.$store.state.prototypedata.specialityData.length > 0) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          if (
            "patient_episodes" in
            this.$store.state.prototypedata.specialityData[i]
          ) {
            index = i;
          }
        }
      }

      //console.log("end getPatientIndex");

      return index;
    },
    getSpaceIndex() {
      var i = 0;
      var index = 0;

      //console.log("getSpaceIndex");

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "space_and_property" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getPropertyIndex() {
      var i = 0;
      var index = -1;

      //console.log("getSpaceIndex");

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "fm_cost" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getFinanceIndex() {
      var i = 0;
      var index = 0;

      //console.log("getFinanceIndex");

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if ("finance" in this.$store.state.prototypedata.specialityData[i]) {
          index = i;
        }
      }

      return index;
    },    

    getPopulationIndex() {
      var i = 0;
      var index = -1;

      //console.log("getFinanceIndex");

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if ("population" in this.$store.state.prototypedata.specialityData[i]) {
          index = i;
        }
      }

      return index;
    },
   
    tacticalAssumptionsFilter() 
    {
      var i = this.getTacticalIndex;

      console.log ("getTacticalIndex : ", i);
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getTacticalIndex
        ].tactical_assumptions;
      }
      return null;
    },
    tacticalAssumptionsTypeFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getTacticalIndex
        ].tactical_assumptions_type;
      }
      return null;
    },
    tacticalAssumptionsQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getTacticalIndex
        ].tactical_assumptions_quantity;
      }
      return null;
    },
    propertyFacilityManagementFMCost() {

      let data = new Array ();
      let newData = new Array ();
      let tempData = new Array ();

      var str = "";

      var fm_cost = "";

      // first get the array
      if (this.$store.state.prototypedata.specialityData.length > 0) 
      {
        data =  this.$store.state.prototypedata.specialityData[this.getPropertyIndex].fm_cost;

        for (var i = 0; i < data.length; i++) {
          str =  data[i].replaceAll(",", "");
          //console.log ("str: ", str);
          tempData.push(str);
        }

        //console.log ("fm_cost: ", tempData);

  

        for (i = 0; i< tempData.length; i++)
        {
          str = tempData[i];
          //console.log (str);
          if (this.isNumeric (str))
          {
            //console.log ("numeric: ", str);
            newData.push (str);
          }
        }

        //console.log ("newData", newData);

        if (newData.length > 0)  fm_cost = this.numberWithCommas(this.calculateArray (newData));

        //console.log ("fm_cost:", fm_cost);
      }
      return fm_cost;
    },
   
    specialityWorkforceCostPerStaffFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex
        ].cost_per_staff;
      }
      return null;
    },
    specialityWorkforceTotalWorkforceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex
        ].total_workforce_cost;
      }
      return null;
    },
    // Workforce Summary Table -- Workforce column
    specialityWorkforceNameFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex
        ].workforce;
      }
      return null;
    },
    // Workforce Table -- Quantity column
    specialityWorkforceQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex
        ].workforce_quantity;
      }
      return null;
    },
    specialitySpaceAndPropertyFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].space_and_property;
      }
      return null;
    },
    specialitySpaceAndPropertyQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].space_and_property_quantity;
      }
      return null;
    },
    specialityCapitalCostPerItemFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].capital_cost_per_item;
      }
      return null;
    },
    specialityTotalCapitalCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].total_capital_cost;
      }
      return null;
    },
    specialityLifeCycleFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].life_cycle;
      }
      return null;
    },
    specialityAnnualisedCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].annualised_cost;
      }
      return null;
    },
    specialityAnnualisedMaintenanceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].annualised_maintenance_cost;
      }
      return null;
    },
    specialityPatientEpisodesFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getPatientIndex
        ].patient_episodes;
      }
      return null;
    },
    specialityPatientEpisodesTotalFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getPatientIndex
        ].patient_episodes_total;
      }
      return null;
    },
    specialityFinanceNameFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getFinanceIndex
        ].finance;
      }
      return null;
    },
    specialityFinanceTotalFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getFinanceIndex
        ].finance_total;
      }
      return null;
    },
    areaAreaFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .area;
      }
      return null;
    },
    areaTFSFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .total_floor_space;
      }
      return null;
    },
    areaNBCFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .net_build_cost;
      }
      return null;
    },
    areaFDCFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .full_dev_cost;
      }
      return null;
    },
    areaAFMCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .avg_fm_cost;
      }
      return null;
    },
    areaFMCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .fm_cost;
      }
      return null;
    },
    gfaFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getGFAIndex]
          .gfa;
      }
      return null;
    },
    specialityFinanceNameFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("finance_total")
        ].finance;
      }
      return null;
    },
    specialityFinanceTotalFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("finance_total")
        ].finance_total;
      }
      return null;
    },
    specialityFinanceTotalCostBase() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("finance_total")
        ].finance_total[0];
      }
      return null;
    },
    specialityFinanceBeneficiaryCapitationRate() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("finance_total")
        ].finance_total[4];
      }
      return null;
    },
    specialityFinanceTotalFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.specialityFinanceTotalFilter1)
      );

      return str;
    },

    metricActivityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getMetricsIndex]
          .activity;
      }
      return null;
    },
    // metricBeneficiaryFilter
    metricBeneficiaryFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getMetricsIndex]
          .beneficiary;
      }
      return null;
    },
    metricMetricsFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getMetricsIndex]
          .metrics;
      }
      return null;
    },
    metricHSIBenchmarkFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getMetricsIndex]
          .hsi_benchmark;
      }
      return null;
    },
    metricInternationalBenchmarkFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getMetricsIndex]
          .international_benchmark;
      }
      return null;
    },
    //-----------------------------------------------------------------------------------
    // SUMMARY WORKFORCE
    //-----------------------------------------------------------------------------------
    // Grand Summary
    // Summary Workforce Table
    // Workforce Filter
    workforceTableWorkforceFilter() {
      console.log(
        "workforceTableWorkforceFilter: ",
        this.$store.state.prototypedata.specialityData.length
      );
      if (this.$store.state.prototypedata.specialityData.length > 5) {
        console.log(
          "workforceTableWorkforceFilter: ",
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("summary_workforce")
          ]
        );
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_workforce")
        ].summary_workforce;
      } else {
        let summaryArray = new Array();
        summaryArray.push("No data");
        return summaryArray;
      }
    },
    workforceSummaryTableWorkforceFilter() {
      console.log("workforceSummaryTableWorkforceFilter");
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        var i = this.getDataSetIndex("cost_per_staff");

        if (i >= 0) {
          return this.$store.state.prototypedata.specialityData[i].workforce;
        }
      }
      return null;
    },
    workforceSummaryTableQuantityFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceSummaryTableQuantityFilter)
      );

      return str;
    },
    workforceSummaryTableQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("cost_per_staff")
        ].workforce_quantity;
      }
      return null;
    },
    workforceSummaryTableCostPerStaffFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("cost_per_staff")
        ].cost_per_staff;
      }
      return null;
    },
    workforceSummaryTableCostPerStaffFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceSummaryTableCostPerStaffFilter)
      );

      return str;
    },
    workforceSummaryTableTotalWorkforceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("cost_per_staff")
        ].total_workforce_cost;
      }
      return null;
    },
    workforceSummaryTableTotalWorkforceCostFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceSummaryTableTotalWorkforceCostFilter)
      );

      return str;
    },
    specialityPatientEpisodesFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("patient_episodes_total")
        ].patient_episodes;
      }
      return null;
    },
    specialityPatientEpisodesTotalFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("patient_episodes_total")
        ].patient_episodes_total;
      }
      return null;
    },
    specialityPatientEpisodesTotalFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.specialityPatientEpisodesTotalFilter1)
      );

      return str;
    },
    patientEpisodeProductivityFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculatePatientEpisodeProductivityCost(
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("patient_episodes_total")
          ].patient_episodes_total
        );
      }
      return null;
    },
    // Grand Summary
    // Summary Workforce Table
    // Workforce Quantity Filter
    workforceTableQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        var i = 0;
        i = this.getDataSetIndex("summary_workforce");

        if (i >= 0) {
          return this.$store.state.prototypedata.specialityData[i]
            .summary_workforce_quantity;
        }

        return null;
      } else {
        let summaryArray = new Array();
        summaryArray.push("No data");
        return summaryArray;
      }
    },
    summaryWorkforceQuantitySum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceTableQuantityFilter)
      );

      return str;
    },
    assetsFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].assets;
      }
      return null;
    },
    assetsQuantityFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].assets_quantity;
      }
      return null;
    },
    assetsQuantityFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsQuantityFilter1)
      );

      return str;
    },

    assetsCapitalCostPerItemFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].capital_cost_per_item;
      }
      return null;
    },
    assetsCapitalCostPerItemFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsCapitalCostPerItemFilter1)
      );

      return str;
    },
    assetsTotalCapitalCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].total_capital_cost;
      }
      return null;
    },
    assetsTotalCapitalCostFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsTotalCapitalCostFilter1)
      );

      return str;
    },
    assetsLifeCycleFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].life_cycle;
      }
      return null;
    },
    assetsLifeCycleFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsLifeCycleFilter1)
      );

      return str;
    },
    assetsAnnualisedCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].annualised_cost;
      }
      return null;
    },
    assetsAnnualisedCostFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsAnnualisedCostFilter1)
      );

      return str;
    },
    assetsAnnualisedMaintenanceCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].annualised_maintenance_cost;
      }
      return null;
    },
    assetsAnnualisedMaintenanceCostFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsAnnualisedMaintenanceCostFilter1)
      );

      return str;
    },
    assetsTotalCapitalCostOverallFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateAssetsTotalCapitalCost(
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("annualised_cost")
          ].total_capital_cost
        );
      }
      return null;
    },
    assetsAnnualisedCostOverallFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateAssetsAnnualisedCost(
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("annualised_cost")
          ].annualised_cost
        );
      }
      return null;
    },
    assetsAnnualisedMaintenanceCostOverallFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateAssetsAnnualisedMaintenanceCost(
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("annualised_cost")
          ].annualised_maintenance_cost
        );
      }
      return null;
    },
    // Grand Summary
    // Summary Workforce Table
    // Workforce TotalCost Filter
    summaryWorkforceTotalCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 5) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_workforce")
        ].summary_workforce_total_cost;
      } else {
        let summaryArray = new Array();
        summaryArray.push("No data");
        return summaryArray;
      }
    },
    summaryWorkforceTotalCostSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryWorkforceTotalCostFilter)
      );

      return str;
    },

    //-----------------------------------------------------------------------------------
    // SUMMARY ASSET
    //-----------------------------------------------------------------------------------
    // Grand Summary
    // Summary Asset Table
    // Asset Filter
    summaryAssetFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 6) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_assets")
        ].summary_assets;
      }
      return null;
    },
    // Grand Summary
    // Summary Asset Table
    // Assets Quantity Filter
    summaryAssetQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 6) {
        var i = this.getDataSetIndex("summary_assets");

        if (i >= 0) {
          return this.$store.state.prototypedata.specialityData[i]
            .summary_assets_quantity;
        }
      }
      return null;
    },
    summaryAssetQuantityFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryAssetQuantityFilter)
      );

      return str;
    },
    // Grand Summary
    // Summary Asset Table
    // Asset Total Capital Cost Filter
    summaryAssetTotalCapitalCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 6) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_assets")
        ].summary_assets_total_capital_cost;
      }
      return null;
    },
    summaryAssetTotalCapitalCostFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryAssetTotalCapitalCostFilter)
      );

      return str;
    },

    // Grand Summary
    // Summary Patient Table
    // Patient Episodes Filter
    summaryPatientFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 7) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_patient_episodes")
        ].summary_patient_episodes;
      }
      return null;
    },
    // Grand Summary
    // Summary Patient Table
    // Patient Quantity Filter
    summaryPatientQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 7) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_patient_episodes")
        ].summary_patient_quantity;
      }
      return null;
    },
    summaryPatientQuantityFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryPatientQuantityFilter)
      );

      return str;
    },
  },
  methods: {   
       getTileData(tileNumber) {
      //console.log("getTileData", tileNumber);

      let defaultValues = new Array();
      let index = -1;

      let currentvalue = "";

      // TODO: Fix These
      defaultValues.push(this.summaryPatientQuantityFilterSum); // 7,103,369
      defaultValues.push(this.summaryAssetQuantityFilterSum);   // 4,076"
      defaultValues.push(this.summaryWorkforceQuantitySum);    //"14,215.89");
      defaultValues.push(this.specialityFinanceTotalCostBase); //"1,762,693,644"
      defaultValues.push(this.propertyFacilityManagementFMCost);  // 26,299,026
      defaultValues.push(this.assetsAnnualisedCostFilter1Sum);  // "42,234,201");
      defaultValues.push(this.summaryWorkforceTotalCostSum); //"588,032,670");
      defaultValues.push(this.specialityFinanceBeneficiaryCapitationRate);  //1,469");

      //for (var i = 0; i<20; i++)
      //{
       // defaultValues.push("0");
      //}


      //this.apiPayload.apiString = "grandsummarytwo-collection";
      //this.logData();
      index = this.getDataSetIndex("summary_overview");

      currentvalue = defaultValues[tileNumber];

      if (index >= 0) {
        //console.log("index > 0");
        if (
          tileNumber <=
          this.$store.state.prototypedata.specialityData[index].summary_overview
            .length
        ) {
          //console.log("tileNumber");
          if (
            this.$store.state.prototypedata.specialityData[index]
              .summary_overview[tileNumber] == null ||
            this.$store.state.prototypedata.specialityData[index]
              .summary_overview[tileNumber].length == 0
          ) {
            currentvalue = defaultValues[tileNumber];
          } else {
            currentvalue =
              this.$store.state.prototypedata.specialityData[index]
                .summary_overview[tileNumber];
          }
        }
      }

      return currentvalue;
    },

    getPopulation()
    {
      var i = this.getPopulationIndex;
      console.log ("stage 2 getPopulation", i);

      if (i > -1)
      {
        if (this.$store.state.prototypedata.specialityData.length > 0) 
        {
            this.population = this.$store.state.prototypedata.specialityData[this.getPopulationIndex].population[0];
            console.log ("stage 2 getPopulation: ", this.population);
        }
      }
      
      return this.population;
    },
    getDataSetIndex(item) {
      var i = 0;
      var id = -1;

      if (this.$store.state.prototypedata.specialityData.length > 0) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          if (item in this.$store.state.prototypedata.specialityData[i]) {
            id = i;
          }
        }
      }

      //console.log("getDataSetIndex", item, id);

      return id;
    },
    logData() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        console.log(
          "logData: length: ",
          this.$store.state.prototypedata.specialityData.length
        );
        for (
          var i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          console.log(
            "item[",
            i,
            "]=",
            this.$store.state.prototypedata.specialityData[i]
          );
        }
      }
    },
    fetchData(speciality) {
      console.log("fetchData: ");
      this.apiPayload.apiString = speciality.apiString;
      this.apiPayload.speciality = "fetchData";

      this.specialityApi = speciality.apiString;

      this.getMongoDBData();
    },
    getMongoDBData() {
      //console.log("called from: ", calledfrom);
      //console.log(this.apiPayload.apiString);
      //console.log(this.apiPayload.speciality);
      //console.log("----");

      //if (this.activeSummary == "workforceCost") {
      //this.fillData("workforceCost");
      //}

      this.$store.dispatch("GetData", this.apiPayload).then((data) => {
        console.log("data", data);
      });
    },
    specialityHeader(header) {
      return header;
    },
    editActiveStageSummary(activeSummary) {
      this.activeSummary = activeSummary;
    },
    fillData(tileValue) {
      //console.log ('fillData:', tileValue);
      this.activeSummary = tileValue;

      console.log("fillData", tileValue);
      this.datacollection = {
        //labels: ['Nursing', 'Medical','Technicians','AHPs','Management','Administration','Drivers','System HQ'],
        labels: this.getBarGraphLabels(),
        datasets: [
          {
            label: this.getGraphTitle(),
            backgroundColor: "#183BCA",
            data: this.getBarGraphDataValues(),
          },
        ],
      };
    },
    editSpecialityType(specialityTypeName) {
      if (specialityTypeName == "Patient Episodes") {
        this.specialityType = "patientEpisodes";
      }
      if (specialityTypeName == "Finance") {
        this.specialityType = "finance";
      }
      if (specialityTypeName == "Workforce") {
        this.specialityType = "workforce";
      }
      if (specialityTypeName == "Space & Property") {
        this.specialityType = "space";
      }

      if (specialityTypeName == "Tactical Assumptions") {
        this.specialityType = "tactical";
      }

      console.log(
        "editSpecialityType (",
        specialityTypeName,
        ")=",
        this.specialityType
      );
    },
    
    editImprovementType(specialityTypeName) {
      console.log ("editImprovement: ", specialityTypeName);
      if (specialityTypeName == "Diagnostics") {
        this.improvementType = "diagnostics";
      }
      if (specialityTypeName == "Outpatient Clinics") {
        this.improvementType = "OPD";
      }
      if (specialityTypeName == "OR") {
        this.improvementType = "OR";
      }
      if (specialityTypeName == "Emergency Room (ER)") {
        this.improvementType = "ER";
      }

      if (specialityTypeName == "Bed Flow") {
        this.improvementType = "bedflow";
      }

      console.log(
        "editImprovementType (",
        specialityTypeName,
        ")=",
        this.improvementType
      );
    },
    getBarGraphDataValues() {
      var data = new Array();
      var temp;
      var str;

      console.log("getBarGraphDataValues");

      //console.log("DataValues: this.activeSummary = ", this.activeSummary);

      if (this.activeSummary == "workforceCost") {
        console.log("begin temp1");
        temp = this.workforceTableQuantityFilter;
        console.log("end temp1");
      } else if (this.activeSummary == "assets") {
        console.log("begin temp2");
        temp = this.summaryAssetQuantityFilter;
        console.log("end temp2");
      } else if (this.activeSummary == "finance") {
        console.log("begin temp3");
        temp = this.summaryAssetQuantityFilter;
        console.log("end temp3");
      } else if (this.activeSummary == "patientEpisode") {
        console.log("begin temp4");
        temp = this.summaryPatientQuantityFilter;
        console.log("end temp4");
      }

      //console.log("temp", temp);

      if (this.isEmpty(temp) == false || typeof temp !== "undefined") {
        console.log("temp", temp);
        for (var i = 0; i < temp.length; i++) {
          str = temp[i].replaceAll(",", "");
          data.push(str);
        }
      }

      console.log("getBarGraphDataValues:", data);

      return data;
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },    
    isNumeric(str) 
    {
        if (typeof str != "string") 
        {
            return false // we only process strings!  
        }
        
        // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        return !isNaN(str) && !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    calculateArray(data) {
      let arrayValuesFloatArray = new Array();
      let str = "";
      let parse = false;

      if (typeof data != "undefined") {
        //tempValue = 0;
        str = "";
        parse = false;

        for (var i = 0; i < data.length; i++) {
          //console.log("data[i] = ", data[i]);
          str = String(data[i]);
          str = str.replaceAll(",", "");
          //str = str.replaceAll(",", ".");

          //str = str.replaceAll(".", ",");

          if (str.includes(".")) {
            parse = true;
          }

          //console.log("str[i] = ", str);

          arrayValuesFloatArray.push(parseFloat(str));

          //console.log("arrayValuesFloatArray[i]=", arrayValuesFloatArray[i]);

          //tempValue = tempValue + str;
        }
      }

      //console.log("tempValue = ", tempValue);

      const total = arrayValuesFloatArray.reduce(function (a, b) {
        return a + b;
      });
      if (parse == true) {
        return total.toFixed(2);
      } else {
        return total;
      }
    },
    getBarGraphLabels() {
      var data = new Array();
      var temp;

      if (this.activeSummary == "workforceCost") {
        temp = this.workforceTableWorkforceFilter;
      } else if (this.activeSummary == "assets") {
        temp = this.summaryAssetFilter;
      } else if (this.activeSummary == "finance") {
        temp = this.specialityFinanceNameFilter1;
      } else if (this.activeSummary == "patientEpisode") {
        temp = this.summaryPatientFilter;
      }

      for (var i = 0; i < temp.length; i++) {
        data.push(temp[i]);
      }

      //console.log("getBarGraphLabels", data);

      //console.log ('temp', temp);
      //console.log ('data', data);

      return data;
    },
    getGraphTitle() {
      var str;

      if (this.activeSummary == "workforceCost") {
        str = "Workforce Quantity";
      } else if (this.activeSummary == "assets") {
        str = "Asset Quantity";
      } else if (this.activeSummary == "finance") {
        str = "Finance Quantity";
      } else if (this.activeSummary == "patientEpisode") {
        str = "Patient Episodes Quantity";
      }
      return str;
    },

    summaryTotalQuantityFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateTotalQuantity(
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("cost_per_staff")
          ].workforce_quantity
        );
      }
      return null;
    },
    // Summary Overview
    // Controls the information on the boxes within the Stage
    specialityOverviewFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_overview")
        ].summary_overview;
      }
      return null;
    },
    editStageSummary(stageSummary) {
      this.stageSummary = stageSummary;

      if (this.stageSummary == "stage") {
        this.apiPayload.apiString = "grandsummaryone-collection";

        console.log("stage: getData");

        this.getMongoDBData();
        this.population = this.getPopulation();
        this.fillData("workforceCost");

        
      }

      if (this.stageSummary == "buildingsFM") {
        this.apiPayload.apiString = "fm_build1_collection";
        this.getMongoDBData();
      }

      if (this.stageSummary == "specialities") {
        console.log(
          " this.selectedSpecialityArea.selected",
          this.selectedSpecialityArea
        );

        this.editSpecialityType(this.selectedSpecialityArea);
        this.apiPayload.apiString = this.specialityApi;
        this.getMongoDBData();
      }

      if (this.stageSummary == "opImprov") {
        console.log(
          " this.improvementSpecialityAra.selected",
          this.improvementSpecialityArea
        );

        this.editImprovementType(this.improvementSpecialityArea);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      }
    },
  },
};
</script>
<style>
.chartcontainersummaryone {
  border: 1px solid;
  border-color: #e5e9f2;
  height: 400px;
  display: inline-block;
  width: 350px; /* was 26 rem */
  margin-right: 20px;
  padding-bottom: 5px;
}
.title_text1 {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.hello1 {
color: #000000;
font-family: arial, sans-serif;
font-size: 16px;
font-weight: bold;
margin-top: 20px;
margin-bottom: 30px;
}

/*a:link {
  color: green;
  background-color: transparent;
  text-decoration: none;
}
a:visited {
  color: pink;
  background-color: transparent;
  text-decoration: none;
}
a:hover {
  color: red;
  background-color: transparent;
  text-decoration: underline;
}
a:active {
  color: yellow;
  background-color: transparent;
  text-decoration: underline;
}*/

</style>
