import Shell from '../../../layouts/Shell.vue'
import bpsummary from '../views/bpsummary.vue'
 
const routes = [
    {
        path: '/',
        name: 'LayoutModule',
        component: Shell,
        children: [
        {
            path: '/bpsummary',
            name: 'bpsummary',
            component: bpsummary,
            meta: {
                requiresAuth: true
            }
        }]
    }
]

export default routes
